import { ceMediaIds, htmlMediaIds } from "@/helpers/adMedia";

export type CheckListArea = {
  code: number;
  name: string;
  prefectureIds: number[];
};

export type CheckListAdMedia = {
  code: number;
  name: string;
  adMediaIds: number[];
};

export type CheckListQuery = {
  date_from: string;
  days_after: number;
  check_area: CheckListArea;
  check_media: CheckListAdMedia;
};

export const resetCheckListQuery: () => CheckListQuery = () => ({
  date_from: "",
  days_after: 0,
  check_area: resetCheckListArea(),
  check_media: resetCheckListAdMedia()
});

export const resetCheckListArea: () => CheckListArea = () => ({
  code: 0,
  name: "",
  prefectureIds: []
});

export const resetCheckListAdMedia: () => CheckListAdMedia = () => ({
  code: 0,
  name: "",
  adMediaIds: []
});

// adMediaIds.tsから引用
export const CheckListAdMedias: CheckListAdMedia[] = [
  { code: 1, name: "全て", adMediaIds: ceMediaIds().concat(htmlMediaIds()) },
  { code: 2, name: "OTD", adMediaIds: ceMediaIds() },
  { code: 3, name: "NCE", adMediaIds: ceMediaIds() },
  { code: 4, name: "HTML", adMediaIds: htmlMediaIds() }
];

export const CheckListAreas: CheckListArea[] = [
  // 全国は指定なし
  {
    code: 1,
    name: "全て",
    // prefectureIds: [...Array(47)].map((_, i) => i + 1)
    prefectureIds: []
  },
  { code: 2, name: "関東", prefectureIds: [8, 9, 10, 11, 12, 13, 14] },
  { code: 3, name: "中部", prefectureIds: [21, 23, 24] },
  { code: 4, name: "関西", prefectureIds: [25, 26, 27, 28, 29, 30] },
  { code: 5, name: "岡山・香川", prefectureIds: [33, 37] },
  { code: 6, name: "鳥取・島根", prefectureIds: [31, 32] },
  { code: 7, name: "北海道", prefectureIds: [1] },
  { code: 8, name: "青森県", prefectureIds: [2] },
  { code: 9, name: "岩手県", prefectureIds: [3] },
  { code: 10, name: "宮城県", prefectureIds: [4] },
  { code: 11, name: "秋田県", prefectureIds: [5] },
  { code: 12, name: "山形県", prefectureIds: [6] },
  { code: 13, name: "福島県", prefectureIds: [7] },
  { code: 14, name: "茨城県", prefectureIds: [8] },
  { code: 15, name: "栃木県", prefectureIds: [9] },
  { code: 16, name: "群馬県", prefectureIds: [10] },
  { code: 17, name: "埼玉県", prefectureIds: [11] },
  { code: 18, name: "千葉県", prefectureIds: [12] },
  { code: 19, name: "東京都", prefectureIds: [13] },
  { code: 20, name: "神奈川県", prefectureIds: [14] },
  { code: 21, name: "新潟県", prefectureIds: [15] },
  { code: 22, name: "富山県", prefectureIds: [16] },
  { code: 23, name: "石川県", prefectureIds: [17] },
  { code: 24, name: "福井県", prefectureIds: [18] },
  { code: 25, name: "山梨県", prefectureIds: [19] },
  { code: 26, name: "長野県", prefectureIds: [20] },
  { code: 27, name: "岐阜県", prefectureIds: [21] },
  { code: 28, name: "静岡県", prefectureIds: [22] },
  { code: 29, name: "愛知県", prefectureIds: [23] },
  { code: 30, name: "三重県", prefectureIds: [24] },
  { code: 31, name: "滋賀県", prefectureIds: [25] },
  { code: 32, name: "京都府", prefectureIds: [26] },
  { code: 33, name: "大阪府", prefectureIds: [27] },
  { code: 34, name: "兵庫県", prefectureIds: [28] },
  { code: 35, name: "奈良県", prefectureIds: [29] },
  { code: 36, name: "和歌山県", prefectureIds: [30] },
  { code: 37, name: "鳥取県", prefectureIds: [31] },
  { code: 38, name: "島根県", prefectureIds: [32] },
  { code: 39, name: "岡山県", prefectureIds: [33] },
  { code: 40, name: "広島県", prefectureIds: [34] },
  { code: 41, name: "山口県", prefectureIds: [35] },
  { code: 42, name: "徳島県", prefectureIds: [36] },
  { code: 43, name: "香川県", prefectureIds: [37] },
  { code: 44, name: "愛媛県", prefectureIds: [38] },
  { code: 45, name: "高知県", prefectureIds: [39] },
  { code: 46, name: "福岡県", prefectureIds: [40] },
  { code: 47, name: "佐賀県", prefectureIds: [41] },
  { code: 48, name: "長崎県", prefectureIds: [42] },
  { code: 49, name: "熊本県", prefectureIds: [43] },
  { code: 50, name: "大分県", prefectureIds: [44] },
  { code: 51, name: "宮崎県", prefectureIds: [45] },
  { code: 52, name: "鹿児島県", prefectureIds: [46] },
  { code: 53, name: "沖縄県", prefectureIds: [47] }
];
