














































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import DraftDealRepository, { DraftDeal } from "@/repositories/draftDeals";
import dayjs from "dayjs";

type DataType = {
  loading: boolean;
  running: boolean;
  isError: boolean;
  isNew: boolean;
  draftDeals: DraftDeal[];
  selected: number[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      running: false,
      isError: false,
      isNew: false,
      draftDeals: [],
      selected: []
    };
  },
  methods: {
    show(isNew: boolean, draftDeals: DraftDeal[]) {
      this.isNew = isNew;
      this.index(draftDeals);
      (this.$refs.modal as BModal).show();
    },
    hide() {
      (this.$refs.modal as BModal).hide();
    },
    index: async function(draftDeals: DraftDeal[]) {
      this.loading = true;
      if (this.isNew) {
        // 新たに長期グループ設定をする場合
        this.draftDeals = draftDeals;
        this.setSelected(draftDeals);
      } else {
        // すでに存在する長期グループの一覧を表示する場合
        const draftDeal = draftDeals[0];
        await DraftDealRepository.groupIndex(
          draftDeal.deal_unit.first_deal_id
        ).then(response => {
          this.draftDeals = response;
          this.setSelected(response);
        });
      }
      this.loading = false;
    },
    save: async function() {
      if (
        !confirm("選択されている案件を長期グループ設定します。よろしいですか。")
      ) {
        return false;
      }
      // 長期グループ設定処理
      this.isError = false;
      this.running = true;
      // 長期グループを設定
      const enabledDraftDeals = this.enabledDraftDeals();
      if (this.isNew) {
        // 選択している案件が2件未満の場合
        if (enabledDraftDeals.length < 2) {
          this.isError = true;
          this.running = false;
          return false;
        }
        await DraftDealRepository.saveDraftDealGroup(enabledDraftDeals)
          .then(() => {
            this.$emit("success");
          })
          .catch(() => {
            this.$emit("error");
          })
          .finally(() => {
            this.running = false;
            this.hide();
          });
      } else {
        const disabledDraftDeals = this.disabledDraftDeals();
        if (disabledDraftDeals.length > 1) {
          this.running = false;
          this.$emit("success");
          this.hide();
        }
        await DraftDealRepository.releaseDraftDealGroup(disabledDraftDeals)
          .then(() => {
            this.$emit("success");
          })
          .catch(() => {
            this.$emit("error");
          })
          .finally(() => {
            this.running = false;
            this.hide();
          });
      }
    },
    enabledDraftDeals: function(): DraftDeal[] {
      const draftDeals: DraftDeal[] = [];
      this.selected.forEach((deal_id: number) => {
        const draftDeal = this.draftDeals.find(
          (draftDeal: DraftDeal) => draftDeal.deal_unit.deal_id === deal_id
        );
        if (draftDeal) draftDeals.push(draftDeal);
      });
      return draftDeals;
    },
    disabledDraftDeals: function(): DraftDeal[] {
      const dealIds = this.draftDeals.map(
        (draftDeal: DraftDeal) => draftDeal.deal_unit.deal_id
      );
      const disabledIds = dealIds.filter(id => {
        return !this.selected.includes(id);
      });
      // 解除対象のレコードのみ取得
      const disabledDraftDeals: DraftDeal[] = [];
      disabledIds.forEach((deal_id: number) => {
        const draftDeal = this.draftDeals.find(
          (draftDeal: DraftDeal) => draftDeal.deal_unit.deal_id === deal_id
        );
        if (draftDeal) disabledDraftDeals.push(draftDeal);
      });
      return disabledDraftDeals;
    },
    setSelected: function(draftDeals: DraftDeal[]) {
      this.selected = draftDeals.map(
        (draftDeal: DraftDeal) => draftDeal.deal_unit.deal_id
      );
    },
    dateFormat: function(date: string): string {
      return dayjs(date).format("YYYY年M月D日");
    }
  }
});
