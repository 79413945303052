import repository from "@/repositories/repository";

export type UserLastBrowsingYomiMgt = {
  id?: number;
  user_id?: number;
  user_last_browsing_from?: string;
  user_last_browsing_to?: string;
};

export default {
  get: async (token: string): Promise<UserLastBrowsingYomiMgt> => {
    const response = await repository(token).get(
      `/api/user/last/browsing/yomi/management`
    );
    return response.data;
  }
};
