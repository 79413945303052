

































































































import Vue from "vue";

const container = Vue.extend({
  template: `
    <div class="pb-3">
      <slot></slot>
    </div>
  `
});

export default Vue.extend({
  components: {
    container
  }
});
