





























































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { AxiosError } from "axios";

import DateInput from "@/components/DateInput.vue";
import InputCurrency from "@/components/helpers/InputCurrency.vue";

import SearchAreaModal from "./modals/SearchAreaModal.vue";
import DealUnitModal from "./modals/DealUnitModal.vue";
import YomiListModal from "./modals/YomiListModal.vue";

import DealRepository, { Deal, newDeal } from "@/repositories/deals";
import ClientRepository, { Client } from "@/repositories/clients";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import SaleChannelRepository, {
  SaleChannel
} from "@/repositories/saleChannels";
import MediaRepository, { Media } from "@/repositories/medias";
import { Area } from "@/repositories/areas";
import SaleDefRepository, { SaleDef } from "@/repositories/saleDefs";
import UserRepository, { User } from "@/repositories/users";
import { Yomi } from "@/repositories/yomi";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isNew: boolean;
  isError: { import: boolean; deal: boolean };
  errors: string[];
  processing: boolean;
  deal?: Deal;
  orgAdMediaId?: number;
  orgAdArea?: {
    id?: number;
    name?: string;
  };
  staff?: number;
  users: User[];
  filter: {
    client: {
      name: string;
    };
  };
  year: number;
  month?: number;
  date?: number;
  user?: User;
  clients: Client[];
  clientAttributes: ClientAttribute[];
  saleChannels: SaleChannel[];
  medias: Media[];
  saleDefs: SaleDef[];
  isSearchAreaActive: boolean;
  isGetYomiActive: boolean;
};

export default Vue.extend({
  components: {
    DateInput,
    SearchAreaModal,
    DealUnitModal,
    YomiListModal,
    InputCurrency
  },
  props: {
    wakuDeal: {
      type: Object as PropType<Deal>,
      required: false
    },
    isCopy: {
      type: Boolean,
      required: false,
      default: false
    },
    isWeek: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultYear: {
      type: Number,
      required: false
    },
    defaultMonth: {
      type: Number,
      required: false
    },
    defaultDate: {
      type: Number,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    dateReadonly: {
      type: Boolean,
      required: false,
      default: false
    },
    asAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function(): DataType {
    const day = this.wakuDeal
      ? dayjs(this.wakuDeal.published_date.date.from)
      : dayjs();
    return {
      loading: false,
      isNew: true,
      isError: { import: false, deal: false },
      errors: [],
      processing: false,
      deal: undefined,
      orgAdMediaId: this.wakuDeal ? this.wakuDeal.ad_media?.id : undefined,
      orgAdArea: this.wakuDeal ? this.wakuDeal.ad_area : undefined,
      staff: undefined,
      users: [],
      filter: {
        client: {
          name: ""
        }
      },
      year: this.defaultYear ?? day.year(),
      month: this.defaultMonth ?? day.month() + 1,
      date: this.defaultDate ?? day.date(),
      user: { id: 0, name: "", email: "", disabled: 0 },
      clients: [],
      clientAttributes: [],
      saleChannels: [],
      medias: [],
      saleDefs: [],
      isSearchAreaActive: true,
      isGetYomiActive: true
    };
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    ready: function(): boolean {
      return (
        this.clients.length > 0 &&
        this.clientAttributes.length > 0 &&
        this.saleChannels.length > 0 &&
        this.medias.length > 0 &&
        this.saleDefs.length > 0
      );
    },
    dayOfWeek: function() {
      const day = dayjs(
        new Date(this.$data.year, this.$data.month - 1, this.$data.date)
      );
      if (day.isValid()) {
        return day.format("ddd");
      }
      return undefined;
    },
    isDealAdmin: function(): boolean {
      return AuthHelper.isDealAdmin();
    },
    editable: function(): boolean {
      if (this.readonly) return false;
      // 担当者を判定条件とする
      const user = this.deal?.staff;
      // 権限を判定
      const isOwn = user?.id ? AuthHelper.isOwn(user.id) : false;

      return this.isNew || this.isDealAdmin || isOwn;
    },
    pastEditable: function(): boolean {
      // 「管理者として操作」にチェックを入れた際は全ての項目を更新できる
      if (!this.readonly && this.isDealAdmin && this.asAdmin) return true;

      // 管理者以外の場合、枠表の掲載日 >= 明後日 なら更新可
      if (this.deal) {
        const publishedDate = dayjs(this.deal.published_date.date.from).startOf(
          "day"
        );
        const dayAfterTomorrow = dayjs()
          .startOf("day")
          .add(2, "day");
        // 掲載日 >= 明後日なら true
        dayjs.extend(isSameOrAfter);
        return publishedDate.isSameOrAfter(dayAfterTomorrow);
      }
      return false;
    },
    effectUnit: function(): boolean {
      // 長期案件かつ、商品媒体またはエリアに変更がある場合 (一括更新を禁止する条件)
      const isLongTerm = this.deal?.is_long_term ?? false;
      const isAdMediaChanged = this.orgAdMediaId !== this.deal?.ad_media?.id;
      const isAdAreaChanged = this.orgAdArea?.id !== this.deal?.ad_area?.id;
      return isLongTerm && (isAdMediaChanged || isAdAreaChanged);
    },
    isYomiChanged: function(): boolean {
      return this.wakuDeal?.yomi_deal?.id !== this.deal?.yomi_deal?.id;
    },
    isWholeAreaSelectable: function(): boolean {
      // 新規 or 既に「全国」が選択されている場合、選択可
      if (
        this.isNew ||
        this.orgAdArea?.id === 1 ||
        this.deal?.ad_area.id === 1
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    defaultYear: function() {
      this.year = this.defaultYear;
      this.syncDate();
    },
    defaultMonth: function() {
      this.month = this.defaultMonth;
      this.syncDate();
    },
    defaultDate: function() {
      this.date = this.defaultDate;
      this.syncDate();
    },
    isCopy: function() {
      this.isNew = this.isCopy;
      if (
        this.wakuDeal.client.deleted_at &&
        (this.wakuDeal.client.deleted_at != "" ||
          this.wakuDeal.client.deleted_at != null)
      ) {
        this.wakuDeal.client = {
          id: undefined,
          name: "",
          attribute: {
            id: undefined,
            name: ""
          },
          deleted_at: undefined
        };
      }
      this.deal = Object.assign({}, this.wakuDeal);
      this.deal.published_date.date.to = "";
      this.syncPrice();
      this.syncDeal();
    },
    wakuDeal: async function() {
      this.loading = true;
      await this.setWakuDeal();
      this.loading = false;
    }
  },
  methods: {
    setWakuDeal: async function() {
      this.isNew = this.wakuDeal && !this.isCopy ? false : true;
      this.deal = this.wakuDeal ? Object.assign({}, this.wakuDeal) : newDeal();
      this.orgAdMediaId = this.wakuDeal
        ? this.wakuDeal.ad_media?.id
        : undefined;
      this.orgAdArea = this.wakuDeal
        ? { id: this.wakuDeal.ad_area.id, name: this.wakuDeal.ad_area.name }
        : undefined;
      this.staff = this.deal.staff?.id;
      if (
        this.deal.staff &&
        this.deal.staff.id &&
        (this.deal.staff.id == null || this.deal.staff.id == undefined)
      ) {
        this.staff = this.deal.user?.id;
      }
      const day = this.wakuDeal
        ? dayjs(this.wakuDeal.published_date.date.from)
        : dayjs();
      this.year = this.defaultYear ?? day.year();
      this.month = this.defaultMonth ?? day.month() + 1;
      this.date = this.defaultDate ?? day.date();
      this.syncPrice();
    },
    show: function() {
      if (this.isNew && !this.isCopy) {
        this.deal = newDeal();
        this.syncDate();
        this.syncPrice();
        this.syncDeal();
      }
      this.isError = { import: false, deal: false };
      (this.$refs["modal"] as BModal).show();
    },
    hide: function() {
      (this.$refs["modal"] as BModal).hide();
    },
    syncDate: async function() {
      if (this.isWeek) {
        this.syncWeekDate();
      } else {
        if (this.deal && this.deal.published_date && this.month) {
          const date = dayjs(
            new Date(this.year, this.month - 1, this.date)
          ).format("YYYY-MM-DD");
          this.deal.published_date.date.from = date;
          // this.deal.published_date.date.to = date;
        }
      }
    },
    syncWeekDate: function() {
      if (this.deal && this.deal.published_date && this.month) {
        const date = dayjs(new Date(this.year, this.month - 1, this.date));
        if (
          dayjs()
            .add(1, "day")
            .isAfter(date.startOf("week"))
        ) {
          this.deal.published_date.date.from = dayjs()
            .add(2, "day")
            .format("YYYY-MM-DD");
        } else {
          this.deal.published_date.date.from = date
            .startOf("week")
            .format("YYYY-MM-DD");
        }
        this.deal.published_date.date.to = date
          .endOf("week")
          .format("YYYY-MM-DD");
        this.deal.is_long_term = true;
      }
    },
    syncPrice: function() {
      if (this.deal && this.deal.media_price) {
        this.priceFormat(this.deal.media_price, true);
      }
      if (this.deal && this.deal.working_price) {
        this.priceFormat(this.deal.working_price);
      }
    },
    syncDeal: function() {
      if (this.deal) {
        if (this.isCopy) {
          this.deal.yomi_deal = undefined;
        }
        this.deal.user = this.user;
        if (!this.isCopy) {
          this.staff = this.user ? this.user.id : 0;
        }
      }
    },
    searchClient: async function(value: string) {
      if (value != "") {
        this.clients = await ClientRepository.searchActive(value, "");
      } else {
        this.clients = await ClientRepository.actives("");
      }
    },
    changeClient: function() {
      if (
        this.deal &&
        this.deal.client &&
        this.deal.client.id &&
        this.deal.client.id > 0
      ) {
        this.searchClientAttribute();
      }
    },
    searchClientAttribute() {
      if (!this.deal) return;
      const client = this.clients.find(
        x => this.deal && x.id === this.deal.client.id
      );
      if (client && this.deal.client.attribute) {
        if (client.client_attribute_id) {
          this.deal.client.attribute.id = client.client_attribute_id;
        } else {
          this.deal.client.attribute.id = 0;
        }
      }
    },
    selectArea: async function(area: Area) {
      if (!this.deal || !this.deal.ad_area) return;
      this.deal.ad_area.id = area.id;
      this.deal.ad_area.name = area.name;
    },
    getYomi: function() {
      this.isError.import = false;
      this.errors = [];
      this.errors = this.verify(true);
      if (this.errors.length > 0) {
        this.isError.import = true;
        return false;
      }
      (this.$refs.yomiListModal as BModal).show();
    },
    priceFormat(price: string, is_media = false) {
      var price = this.setHalfFont(price, ",");
      if (!isNaN(Number(price))) {
        price = Number(price).toLocaleString();
      } else {
        price = "";
      }
      if (this.deal != undefined) {
        is_media
          ? (this.deal.media_price = price)
          : (this.deal.working_price = price);
      }
    },
    timeFormat: function(is_from: boolean) {
      let time = "";
      if (this.deal && this.deal.published_date.time) {
        if (is_from) {
          time = this.deal.published_date.time.from;
        } else {
          time = this.deal.published_date.time.to;
        }
        time = this.setHalfFont(time, ":");
        if (isNaN(Number(time))) time = "";
        if (time.length < 4) time = "0" + time;
        time = isNaN(Number(time))
          ? ""
          : time.substr(0, 2) + ":" + time.substr(2, 2);
        time = time.match(/^([0-2][0-9]):([0-5][0-9])$/) ? time : "";
        is_from
          ? (this.deal.published_date.time.from = time)
          : (this.deal.published_date.time.to = time);
      }
    },
    setHalfFont: function(font: string, split: string) {
      if (font == "" || font == null) {
        return "";
      }
      // 全角入力された場合半角に自動で戻す
      const result = String(font)
        .split(split)
        .join("")
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(font) {
          return String.fromCharCode(font.charCodeAt(0) - 0xfee0);
        });
      return result;
    },
    setMedia: function() {
      if (this.deal) {
        this.deal.ad_media.is_only_nationwide = 0;
        let id = this.deal.ad_media.id;
        let media = this.medias.find(x => x.id === id);
        if (
          media &&
          media.is_only_nationwide &&
          media.is_only_nationwide === 1
        ) {
          this.deal.ad_area.id = 1;
          this.deal.ad_media.is_only_nationwide = 1;
        }
      }
    },
    // 掲載時間の補完機能
    // setPublishedTime: function() {
    //   if (this.deal && this.deal.published_date.time != undefined) {
    //     this.deal.published_date.time = { from: "", to: "" };
    //   }
    //   if (this.deal && this.deal.published_date.time) {
    //     var from_hour = 5;
    //     var to_hour = 29;
    //     // 夜帯枠
    //     if (this.deal.ad_media.id == 3) {
    //       from_hour = 19;
    //       to_hour = 25;
    //     }
    //     this.deal.published_date.time.from = from_hour + ":00";
    //     this.deal.published_date.time.to = to_hour + ":00";
    //   }
    // },
    setToPublishedDate: function() {
      if (this.deal) {
        if (this.deal.is_long_term) {
          this.deal.published_date.date.to = this.deal.published_date.date.from;
        } else {
          this.deal.published_date.date.to = "";
        }
      }
    },
    // 予約するを押下した際の処理
    reserve: async function() {
      this.processing = true;
      this.errors = [];
      this.errors = this.verify(false);
      if (this.errors.length > 0) {
        this.isError.deal = true;
        this.processing = false;
        return false;
      }
      this.setDeal();
      if (this.isNew) {
        await DealRepository.store(this.deal, "")
          .then((deals: Deal[]) =>
            this.$emit(
              "success",
              this.deal,
              deals.filter((deal: Deal) => this.inWaitingSlot(deal))
            )
          )
          .catch(this.handleError)
          .finally(() => {
            this.processing = false;
          });
      } else if (this.deal && this.deal.id) {
        if (this.deal.is_long_term) {
          (this.$refs.updateUnitConfirmModal as BModal).show();
          this.processing = false;
          return false;
        }
        await this.update();
      }
      this.close();
    },
    update: async function() {
      if (this.deal && this.deal.id) {
        this.processing = true;
        await DealRepository.update(this.deal.id, this.deal, "")
          .then(() => this.$emit("success", this.deal))
          .catch(this.handleError)
          .finally(() => {
            this.processing = false;
          });
      }
      this.close();
    },
    updateSingle: async function() {
      if (this.deal && this.deal.id) {
        this.processing = true;
        if (this.deal.yomi_deal) {
          // 長期案件の単独での更新の場合はヨミ表連携をしない (ここでヨミ表の連携を元に戻しておく)
          this.deal.yomi_deal = this.wakuDeal?.yomi_deal;
        }
        this.update();
      }
    },
    updateUnit: async function() {
      if (this.deal && this.deal.id) {
        this.processing = true;
        await this.setDeal();
        await DealRepository.updateUnit(this.deal.id, this.deal, "")
          .then(() => this.$emit("success", this.deal))
          .catch(this.handleError)
          .finally(() => {
            this.processing = false;
          });
      }
      this.close();
    },
    handleError: function(error: AxiosError) {
      this.$emit("fail", error, this.deal);
    },
    remove: function() {
      if (!confirm("案件を解除します。よろしいですか。")) {
        return false;
      }
      this.$emit("remove", this.deal);
      this.close();
    },
    close: function() {
      const modal = this.$refs.modal as BModal;
      if (modal) {
        modal.hide();
      }
    },
    importYomi: function(yomi: Yomi) {
      if (this.deal) {
        if (yomi != undefined) {
          this.deal.yomi_deal = { id: yomi.id };
          if (!this.deal.id) {
            // 更新時は 掲載日To を更新しない
            this.deal.published_date.date.to = yomi.published_date.to ?? "";
          }
          this.deal.is_long_term = yomi.is_long_term;
          this.deal.title = yomi.name;
          this.priceFormat(yomi.media_price, true);
          this.priceFormat(yomi.working_price);
          this.deal.sales_probability_def = yomi.sales_probability_def;
          this.deal.sales_channel = yomi.sales_channel;
        }
      }
    },
    setDeal: function() {
      if (this.deal) {
        this.deal.staff = { id: this.staff };
        // 金額フォーマットリセット
        this.deal.media_price = this.deal.media_price
          ? String(this.deal.media_price)
              .split(",")
              .join("")
          : undefined;
        this.deal.working_price = this.deal.working_price
          ? String(this.deal.working_price)
              .split(",")
              .join("")
          : undefined;
        if (
          this.deal.media_price === undefined ||
          this.deal.media_price === ""
        ) {
          this.deal.media_price = "0";
        }
        if (
          this.deal.working_price === undefined ||
          this.deal.working_price === ""
        ) {
          this.deal.working_price = "0";
        }
      }
    },
    verify: function(is_import: boolean) {
      this.isError = { import: false, deal: false };
      const msg: string[] = [];
      if (this.deal) {
        // 掲載日
        if (
          this.deal.published_date.date.from == null ||
          this.deal.published_date.date.from == ""
        ) {
          msg.push("掲載日を入力してください。");
        }
        if (this.deal.published_date.date.from.split("-").length < 3) {
          msg.push("掲載日は日まで入力してください。");
        }
        if (
          this.deal.published_date.date.to != undefined &&
          this.deal.published_date.date.to != null &&
          this.deal.published_date.date.to != "" &&
          this.deal.published_date.date.to.split("-").length < 3
        ) {
          msg.push("掲載日は日まで入力してください。");
        }
        const from = dayjs(this.deal.published_date.date.from);
        const to = dayjs(this.deal.published_date.date.to);
        if (
          this.deal.published_date.date.to != undefined &&
          this.deal.published_date.date.to != null &&
          this.deal.published_date.date.to != "" &&
          from.isAfter(to)
        ) {
          msg.push("掲載日の日付は開始<終了となるように入力してください。");
        }
        // クライアント
        if (this.deal.client.id == undefined || this.deal.client.id == 0) {
          msg.push("クライアントを選択してください。");
        }
        // 掲載媒体
        if (this.deal.ad_media.id == undefined || this.deal.ad_media.id == 0) {
          msg.push("掲載媒体を選択してください。");
        }
        // エリア
        if (this.deal.ad_area.id == undefined || this.deal.ad_area.id == 0) {
          msg.push("エリアを選択してください。");
        }
      }
      // ヨミ表連携のためのvalidationはここで終了
      if (is_import) {
        return msg;
      }
      if (this.deal) {
        // 制作
        if (
          this.deal.work_plan == undefined ||
          this.deal.work_plan.type == undefined
        ) {
          msg.push("制作を選択してください。");
        }
        // ヨミ確度 *Phase3.0追加:受注案件選択時のみバリデーションが必要
        if (this.deal.ad_type === 1) {
          if (
            this.deal.sales_probability_def.id == undefined ||
            this.deal.sales_probability_def.id == 0
          ) {
            msg.push("ヨミ確度を選択してください。");
          }
          if (
            (!this.deal.yomi_deal || !this.deal.yomi_deal.id) &&
            this.isFixedDeal(this.deal.sales_probability_def.id)
          ) {
            msg.push(
              "ヨミ表と連携されていない枠はヨミ確度M以上を選択することができません。"
            );
          }
          // キャンセル待ち枠
          if (
            this.inWaitingSlot(this.deal) &&
            this.isFixedDeal(this.deal.sales_probability_def.id)
          ) {
            msg.push(
              "キャンセル待ちの枠はヨミ確度M以上を選択することができません。"
            );
          }
        }
        // 自社広告選択時の月跨ぎ選択禁止バリデーションルール
        if (
          this.deal.published_date.date.to &&
          dayjs(this.deal.published_date.date.from).format("YYYY-MM") !==
            dayjs(this.deal.published_date.date.to).format("YYYY-MM")
        ) {
          msg.push("長期案件の入力は月を跨がないように入力してください。");
        }
      }
      return msg;
    },
    inWaitingSlot: function(deal?: Deal) {
      // キャンセル待ち枠
      // 枠の順番 > 掲載媒体の枠数 = キャンセル待ちの枠
      const media = this.medias.find(x => x.id === deal?.ad_media.id);
      return deal &&
        media &&
        media.flame_count &&
        deal.slot_priority > media.flame_count
        ? true
        : false;
    },
    isFixedDeal: function(def_id?: number) {
      // ヨミ確度「M」以上
      return def_id && def_id <= 2 ? true : false;
    },
    showDealUnitModal: function() {
      (this.$refs["dealUnitModal"] as BModal).show();
    },
    onRemove: function() {
      this.hide();
      this.$emit("remove");
    },
    onMove: function() {
      this.hide();
      this.$emit("move");
    },
    onCancel: function() {
      if (this.deal && this.orgAdArea) {
        this.deal.ad_area.id = this.orgAdArea.id;
        this.deal.ad_area.name = this.orgAdArea.name;
      }
    },
    changeIsOrdered: function() {
      if (this.deal && this.deal.ad_type === 0) {
        this.deal.sales_probability_def.id = 1;
      } else if (this.deal) {
        this.deal.sales_probability_def = {};
      }
    }
  },
  mounted: async function() {
    this.loading = true;
    // this.user = await UserRepository.currentUser("");
    //TODO UserRepository.currentUser の実装を変える
    this.user = this.$store.getters["auth/user"];
    this.users = await UserRepository.list("" /*TODO*/);
    this.clients = await ClientRepository.actives("" /*TODO*/);
    this.clientAttributes = await ClientAttributeRepository.actives(
      "" /*TODO*/
    );
    this.saleChannels = await SaleChannelRepository.actives("" /*TODO*/);
    this.medias = await MediaRepository.actives("" /*TODO*/);
    this.saleDefs = await SaleDefRepository.actives("", { is_waku: true });

    this.isNew = this.wakuDeal && !this.isCopy ? false : true;
    this.deal = this.wakuDeal ? Object.assign({}, this.wakuDeal) : newDeal();

    //TODO エリアが未設定のデータ(データ不整合？？)を表示する際にレンダ時にエラーを防ぐため
    if (this.deal.ad_area === null) {
      this.deal.ad_area = { id: undefined };
    }

    this.syncDate();
    this.syncPrice();
    if (this.isNew) {
      this.syncDeal();
    }

    this.loading = false;
  }
});
