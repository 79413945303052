import repository from "@/repositories/repository";

export type Deal = {
  id?: number;
  staff?: {
    id?: number;
    name?: string;
  };
  user?: {
    id?: number;
    name?: string;
    emai?: string;
    disabled?: number;
  };
  yomi_deal?: {
    id?: number;
  };
  is_long_term: boolean;
  published_date: {
    date: {
      from: string;
      to: string;
    };
    time?: {
      from: string;
      to: string;
    };
  };
  client: {
    id?: number;
    name: string;
    attribute: {
      id?: number;
      name: string;
    };
    deleted_at?: string;
  };
  sales_channel: {
    id?: number;
  };
  ad_media: {
    id?: number;
    name?: string;
    is_only_nationwide?: number;
  };
  ad_area: {
    id?: number;
    name?: string;
  };
  slot_priority: number;
  media_price?: string;
  working_price?: string;
  title: string;
  sales_probability_def: {
    id?: number;
    name?: string;
  };
  work_plan: {
    type?: number;
    ipg_notes: string;
  };
  remarks: string;
  ad_type?: number;
};

type Query = {
  id?: number;
  name: string;
};

type QueryForUnit = {
  deal_id: number;
};

export default {
  list: async (token: string, query?: Query): Promise<Deal[]> => {
    const response = await repository(token).get("/api/deals", {
      params: { ...query }
    });
    return response.data;
  },

  unit: async (token: string, query?: QueryForUnit): Promise<Deal[]> => {
    const response = await repository(token).get("/api/deals/unit", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: number, token: string): Promise<Deal | undefined> => {
    const response = await repository(token).get(`/api/deals/${id}`);
    if (response.data == null) {
      return undefined;
    }
    return response.data.data;
  },

  store: async (data: unknown, token: string): Promise<Deal[]> => {
    const response = await repository(token).post(`/api/deals`, data);
    return response.data;
  },

  update: async (id: number, data: Deal, token: string): Promise<void> => {
    const response = await repository(token).put(`/api/deals/${id}`, data);
    return response.data;
  },

  updateUnit: async (id: number, data: Deal, token: string): Promise<void> => {
    const response = await repository(token).put(`/api/deals/unit/${id}`, data);
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    const response = await repository(token).delete(`/api/deals/${id}`);
  },

  destroyUnit: async (dealId: number, token: string): Promise<Deal[]> => {
    const response = await repository(token).delete(
      `/api/deals/unit/${dealId}`
    );
    return response.data;
  }
};

export const newDeal: () => Deal = () => ({
  is_long_term: false,
  published_date: {
    date: {
      from: "",
      to: ""
    },
    time: {
      from: "",
      to: ""
    }
  },
  client: {
    id: undefined,
    name: "",
    attribute: {
      id: undefined,
      name: ""
    }
  },
  sales_channel: {
    id: undefined
  },
  ad_media: {},
  ad_area: {
    id: undefined,
    name: undefined
  },
  slot_priority: 1,
  media_price: undefined,
  working_price: undefined,
  title: "未定",
  sales_probability_def: {},
  work_plan: {
    type: undefined,
    ipg_notes: ""
  },
  remarks: "",
  ad_type: 1
});
