



























































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";

import NewAgencyStaffModal from "@/components/NewAgencyStaffModal.vue";

import AgencyStaffsRepository, {
  AgencyStaff
} from "@/repositories/agencyStaffs";
import SaleChannelRepository from "@/repositories/saleChannels";

import { StaffType, staffTypes } from "@/helpers/staffType";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  isFormShown: boolean;
  isNew: boolean;
  agencyName: string;
  agencyId: number;
  staffId: number;
  agencyStaffs: AgencyStaff[];
  staffType: StaffType[];
};

export default Vue.extend({
  components: {
    Menu,
    NewAgencyStaffModal
  },
  data: function(): DataType {
    return {
      loading: false,
      isCreate: false,
      isUpdate: false,
      isDelete: false,
      isFormShown: false,
      isNew: false,
      agencyName: "",
      agencyId: 0,
      staffId: 0,
      agencyStaffs: [],
      staffType: staffTypes
    };
  },
  methods: {
    showNewModal: function() {
      this.isNew = true;
      this.staffId = 0;
      this.showModal();
    },
    showEditModal: function(id: number) {
      this.isNew = false;
      this.staffId = id;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newAgencyStaffModal as any).show();
    },
    closeNewForm() {
      this.isFormShown = false;
    },
    onCreate: async function(agencys: AgencyStaff[]) {
      this.isCreate = true;
      this.loading = true;
      this.agencyStaffs = await agencys;
      this.loading = false;
    },
    onUpdate: async function(agencys: AgencyStaff[]) {
      this.isUpdate = true;
      this.loading = true;
      this.agencyStaffs = await agencys;
      this.loading = false;
    },
    onDelete: async function(agencys: AgencyStaff[]) {
      this.isDelete = true;
      this.loading = true;
      this.agencyStaffs = await agencys;
      this.loading = false;
    }
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isAgencyStaffWritable();
    }
  },
  mounted: async function() {
    this.loading = true;
    this.agencyId = Number(this.$route.params.id);
    var agency = await SaleChannelRepository.get(this.agencyId, "");
    this.agencyName = agency != null ? agency.name : "";
    this.agencyStaffs = await AgencyStaffsRepository.list(this.agencyId, "");
    this.loading = false;
  }
});
