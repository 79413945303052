















































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";

import TextPreviewModal from "@/components/modals/TextPreviewModal.vue";
import LongTermFilesModal from "@/components/modals/LongTermFilesModal.vue";

import {
  DraftSheetStatus,
  draftSheetStatuses
} from "@/helpers/draftSheetStatus";
import { PublicationTime, publicationTimes } from "@/helpers/publicationTime";

import DraftSheetRepository, {
  CeDraftDetail,
  newCeDraftDetail
} from "@/repositories/draftSheet";
import CeAdResourceRepository from "@/repositories/ceAdResource";
import repository from "@/repositories/repository";
import * as ResponseHelper from "@/helpers/response";
import RegistrationAreaModal from "@/components/modals/RegistrationAreaModal.vue";
import { RegistrationAreaModalArgs } from "@/repositories/registrationArea";

type DataType = {
  isRegistrationAreaShown: boolean;
  isTextPreviewShown: boolean;
  isLongTermFilesShown: boolean;
  activeOtdTab: boolean;
  ceDraftDetail: CeDraftDetail;
  draftStatuses: DraftSheetStatus[];
  publicationTimes: PublicationTime[];
  success: boolean;
  successUpload: boolean;
  successUpdateLongTerm: boolean;
  serverError: boolean;
  validationError: boolean;
  validationErrorMessages: string[];
  running: boolean;
  downloading: boolean;
  draftSheetId?: number;
  resources: {
    otd: {
      top_banner_1?: File;
      top_banner_2?: File;
      detail_banner?: File;
      top_banner_pdo?: File;
    };
    nce: {
      top_banner_1?: File;
      top_banner_2?: File;
      detail_banner?: File;
      top_banner_pdo?: File;
    };
    text_resource?: File;
  };
  successExport: boolean;
};

export default Vue.extend({
  components: {
    RegistrationAreaModal,
    TextPreviewModal,
    LongTermFilesModal
  },
  data: function(): DataType {
    return {
      isRegistrationAreaShown: false,
      isTextPreviewShown: false,
      isLongTermFilesShown: false,
      activeOtdTab: true,
      ceDraftDetail: newCeDraftDetail(),
      draftStatuses: draftSheetStatuses,
      publicationTimes: publicationTimes,
      success: false,
      successUpload: false,
      successUpdateLongTerm: false,
      serverError: false,
      validationError: false,
      validationErrorMessages: [],
      running: false,
      downloading: false,
      draftSheetId: undefined,
      resources: {
        otd: {
          top_banner_1: undefined,
          top_banner_2: undefined,
          detail_banner: undefined,
          top_banner_pdo: undefined
        },
        nce: {
          top_banner_1: undefined,
          top_banner_2: undefined,
          detail_banner: undefined,
          top_banner_pdo: undefined
        },
        text_resource: undefined
      },
      successExport: false
    };
  },
  computed: {
    isReExported: function(): boolean {
      return (
        this.ceDraftDetail.ce_ad_resource.stock_folder_path.path !== null && // 素材フォルダパスがあるか
        this.ceDraftDetail.draft_sheet_path.path !== null && // 入稿書パスがあるか
        this.ceDraftDetail.draft_deal.deal.work_plan_type.code !== 4 // 制作種別が「4: 未定」じゃないか
      );
    },
    isExported: function(): boolean {
      return (
        this.ceDraftDetail.ce_ad_resource.stock_folder_path.path !== null && // 素材フォルダパスがあるか
        this.ceDraftDetail.draft_sheet_path.path === null && // 入稿書パスがないか
        this.ceDraftDetail.draft_deal.deal.work_plan_type.code !== 4 // 制作種別が「4: 未定」じゃないか
      );
    },
    isEnabledUpload: function(): boolean {
      const otdTopBanner1 = this.resources.otd.top_banner_1;
      const otdTopBanner2 = this.resources.otd.top_banner_2;
      const otdDetailBanner = this.resources.otd.detail_banner;
      const otdTopBannerPdo = this.resources.otd.top_banner_pdo;
      const nceTopBanner1 = this.resources.nce.top_banner_1;
      const nceTopBanner2 = this.resources.nce.top_banner_2;
      const nceDetailBanner = this.resources.nce.detail_banner;
      const textResource = this.resources.text_resource;

      // 何か1つでも素材をアップロードしている場合はtrue
      return (
        otdTopBanner1 === undefined &&
        otdTopBanner2 === undefined &&
        otdDetailBanner === undefined &&
        otdTopBannerPdo === undefined &&
        nceTopBanner1 === undefined &&
        nceTopBanner2 === undefined &&
        nceDetailBanner === undefined &&
        textResource === undefined
      );
    },
    isDisabledDownload: function(): boolean {
      const otdTopBanner1 = this.ceDraftDetail.ce_ad_resource.otd_top_banner_1
        .extend;
      const otdTopBanner2 = this.ceDraftDetail.ce_ad_resource.otd_top_banner_2
        .extend;
      const otdDetailBanner = this.ceDraftDetail.ce_ad_resource
        .otd_detail_banner.extend;
      const otdTopBannerPdo = this.ceDraftDetail.ce_ad_resource
        .otd_top_banner_pdo.extend;
      const nceTopBanner1 = this.ceDraftDetail.ce_ad_resource.nce_top_banner_1
        .extend;
      const nceTopBanner2 = this.ceDraftDetail.ce_ad_resource.nce_top_banner_2
        .extend;
      const nceDetailBanner = this.ceDraftDetail.ce_ad_resource
        .nce_detail_banner.extend;
      const textResource = this.ceDraftDetail.ce_ad_resource.text_resource
        .extend;

      // 何か1つでも素材をアップロードしている場合はtrue
      return (
        otdTopBanner1 === null &&
        otdTopBanner2 === null &&
        otdDetailBanner === null &&
        otdTopBannerPdo === null &&
        nceTopBanner1 === null &&
        nceTopBanner2 === null &&
        nceDetailBanner === null &&
        textResource === null
      );
    }
  },
  methods: {
    resetAdResource: function() {
      this.resources = {
        otd: {
          top_banner_1: undefined,
          top_banner_2: undefined,
          detail_banner: undefined,
          top_banner_pdo: undefined
        },
        nce: {
          top_banner_1: undefined,
          top_banner_2: undefined,
          detail_banner: undefined,
          top_banner_pdo: undefined
        },
        text_resource: undefined
      };
    },
    setActiveTab(active: boolean) {
      this.activeOtdTab = active;
    },
    showRegistrationAreaModal() {
      this.isRegistrationAreaShown = true;
      (this.$refs.registrationAreaModal as any).show(
        this.ceDraftDetail.registration_area.id
      );
    },
    showTextPreviewModal() {
      this.isTextPreviewShown = true;
      (this.$refs.textPreviewModal as any).show();
    },
    showLongTermFilesModal() {
      this.isLongTermFilesShown = true;
      (this.$refs.longTermFilesModal as any).show();
    },
    closeForm() {
      this.isRegistrationAreaShown = false;
      this.isTextPreviewShown = false;
      this.isTextPreviewShown = false;
      this.isLongTermFilesShown = false;
    },
    setRegistrationArea: function(registrationArea: RegistrationAreaModalArgs) {
      this.ceDraftDetail.registration_area = registrationArea;
      (this.$refs.registrationAreaModal as any).close();
      this.closeForm();
    },
    exportDraftSheet: async function() {
      this.running = true;
      await repository()
        .get(
          `/api/draftDeals/${this.ceDraftDetail.draft_deal.id}/draftSheets/${this.draftSheetId}/export`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(response, "入稿書.xlsx");
          this.successExport = true;
          this.getCeDetail();
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.running = false;
        });
    },
    update: async function() {
      this.validationError = this.valid(this.ceDraftDetail);
      if (!this.validationError) {
        this.running = true;
        await DraftSheetRepository.updateCeDetail(this.ceDraftDetail)
          .then(() => {
            this.success = true;
            this.serverError = false;
            this.updateHeaderInfo(); // ヘッダーの情報更新(登録用エリアの変更を想定)
            this.getCeDetail(); // 素材情報の更新
          })
          .catch(() => {
            this.success = false;
            this.serverError = true;
          })
          .finally(() => {
            this.running = false;
          });
      }
    },
    valid: function(ceDraftDetail: CeDraftDetail): boolean {
      this.validationErrorMessages = [];
      // 掲載時間
      if (!ceDraftDetail.draft_deal.publication_time.code)
        this.validationErrorMessages.push("掲載時間を選択してください");

      // 登録用掲載時間
      const publishedTime = ceDraftDetail.published_time;
      if (!publishedTime.from || !publishedTime.to)
        this.validationErrorMessages.push("登録用掲載時間を入力してください");

      if (
        publishedTime.from &&
        publishedTime.to &&
        Number(this.removeSemicolon(publishedTime.from)) >
          Number(this.removeSemicolon(publishedTime.to))
      )
        this.validationErrorMessages.push(
          "登録用掲載時間toはfromよりも後の時間を入力してください"
        );

      // 素材名
      if (!ceDraftDetail.material_name)
        this.validationErrorMessages.push("素材名を入力してください");

      // NCE長期掲載用広告ID(mindsID)
      const nceMindsId = ceDraftDetail.ce_ad_resource.nce_minds_id;
      if (nceMindsId && String(nceMindsId).length > 8)
        this.validationErrorMessages.push(
          "NCE長期掲載用広告IDは8桁までの数字で入力してください"
        );
      if (nceMindsId && !String(nceMindsId).match(/^[0-9]*$/)) {
        this.validationErrorMessages.push(
          "NCE長期掲載用広告IDは半角数字で入力してください"
        );
      }

      return this.validationErrorMessages.length > 0;
    },
    timeFormat: function(is_from: boolean) {
      // 掲載時間は5:00 ~ 29:00までを許容する（業務上の理由で24時間表記ではない)
      const published_time = this.ceDraftDetail.published_time;
      let time = "";
      if (is_from && published_time.from) {
        time = published_time.from;
      } else if (published_time.to) {
        time = published_time.to;
      } else {
        return;
      }
      time = this.setHalfFont(time, ":");
      if (isNaN(Number(time))) time = "";
      if (time.length < 4) time = "0" + time;
      time = isNaN(Number(time))
        ? ""
        : time.substr(0, 2) + ":" + time.substr(2, 2);
      time = time.match(/^([0-2][0-9]):([0-5][0-9])$/) ? time : "";
      is_from ? (published_time.from = time) : (published_time.to = time);
    },
    setHalfFont: function(font: string, split: string) {
      if (font == "" || font == null) {
        return "";
      }
      // 全角入力された場合半角に自動で戻す
      const result = String(font)
        .split(split)
        .join("")
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(font) {
          return String.fromCharCode(font.charCodeAt(0) - 0xfee0);
        });
      return result;
    },
    removeSemicolon: function(timeString: string): string {
      return timeString.replace(":", "");
    },
    updateHeaderInfo: function() {
      (this as any).$parent.ceDraftDetail = this.ceDraftDetail;
    },
    onUploaded: function(e: any) {
      const files: Array<File> = e.target.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (this.activeOtdTab) {
          this.setOtdResource(file);
        } else {
          this.setNceResource(file);
        }
      }
    },
    setOtdResource: function(file: File) {
      const fileName = file.name;
      const fileType = file.type;

      // OTD トップバナー1
      // アニメーション秒数が0またはnullのときは_Aがなくてもトップバナー1とする
      if (
        this.ceDraftDetail.ce_ad_resource.animation_seconds.otd === 0 ||
        this.ceDraftDetail.ce_ad_resource.animation_seconds.otd === null
      ) {
        if (
          fileType === "image/png" &&
          !fileName.match(/_A.png$/) &&
          !fileName.match(/_B.png$/) &&
          !fileName.match(/^mm_/)
        ) {
          this.resources.otd.top_banner_1 = file;
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.src = URL.createObjectURL(
            file
          );
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.name = file.name;
        }
      } else if (
        fileType === "image/png" &&
        fileName.match(/_A.png$/) !== null
      ) {
        this.resources.otd.top_banner_1 = file;
        this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.src = URL.createObjectURL(
          file
        );
        this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.name = file.name;
      }

      // OTD トップバナー2
      // AN秒数が1秒以上の場合のみ「_B」がついている画像をトップバナー2に指定する
      if (this.ceDraftDetail.ce_ad_resource.animation_seconds.otd) {
        if (fileType === "image/png" && fileName.match(/_B.png$/) !== null) {
          this.resources.otd.top_banner_2 = file;
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.src = URL.createObjectURL(
            file
          );
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.name = file.name;
        }
      }

      // OTD 詳細バナー
      if (fileType === "image/png" && fileName.match(/^mm_/) !== null) {
        this.resources.otd.detail_banner = file;
        this.ceDraftDetail.ce_ad_resource.otd_detail_banner.src = URL.createObjectURL(
          file
        );
        this.ceDraftDetail.ce_ad_resource.otd_detail_banner.name = file.name;
      }

      // テキスト素材
      if (fileType === "text/plain") {
        this.resources.text_resource = file;
        this.ceDraftDetail.ce_ad_resource.text_resource.name = file.name;
        this.readTextResource(file);
      }

      // PDOファイル
      if (fileName.match(/.pdo$/) !== null) {
        this.resources.otd.top_banner_pdo = file;
        this.ceDraftDetail.ce_ad_resource.otd_top_banner_pdo.name = file.name;
      }
    },
    setNceResource: function(file: File) {
      const fileName = file.name;
      const fileType = file.type;

      // NCE トップバナー1
      // アニメーション秒数が0またはnullのときは_Aがなくてもトップバナー1とする
      if (
        this.ceDraftDetail.ce_ad_resource.animation_seconds.nce === 0 ||
        this.ceDraftDetail.ce_ad_resource.animation_seconds.nce === null
      ) {
        if (
          fileType === "image/jpeg" &&
          fileName.match(/.jpg$/) !== null &&
          !fileName.match(/^detail_/) &&
          !fileName.match(/_A.jpg$/) &&
          !fileName.match(/_B.jpg$/)
        ) {
          this.resources.nce.top_banner_1 = file;
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.src = URL.createObjectURL(
            file
          );
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.name = file.name;
        }
      } else if (
        fileType === "image/jpeg" &&
        fileName.match(/_A.jpg$/) !== null
      ) {
        this.resources.nce.top_banner_1 = file;
        this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.src = URL.createObjectURL(
          file
        );
        this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.name = file.name;
      }

      // NCE トップバナー2
      // AN秒数が1秒以上の場合のみ「_B」がついている画像をトップバナー2に指定する
      if (this.ceDraftDetail.ce_ad_resource.animation_seconds.nce) {
        if (fileType === "image/jpeg" && fileName.match(/_B.jpg$/) !== null) {
          this.resources.nce.top_banner_2 = file;
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.src = URL.createObjectURL(
            file
          );
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.name = file.name;
        }
      }

      // NCE 詳細バナー
      if (fileType === "image/jpeg" && fileName.match(/^detail_/) !== null) {
        this.resources.nce.detail_banner = file;
        this.ceDraftDetail.ce_ad_resource.nce_detail_banner.src = URL.createObjectURL(
          file
        );
        this.ceDraftDetail.ce_ad_resource.nce_detail_banner.name = file.name;
      }

      // テキスト素材
      if (fileType === "text/plain") {
        this.resources.text_resource = file;
        this.ceDraftDetail.ce_ad_resource.text_resource.name = file.name;
        this.readTextResource(file);
      }
    },
    readTextResource: async function(file: File) {
      const formData = new FormData();
      formData.append("text", file);
      await CeAdResourceRepository.encodeText(formData).then(response => {
        this.ceDraftDetail.ce_ad_resource.text_resource.text = response[0];
      });
    },
    upload: async function() {
      const draftSheetId = this.ceDraftDetail.id;
      if (draftSheetId === undefined) return false;

      // FormDataセット
      this.running = true;
      const formData: FormData | undefined = this.createFormData();
      if (formData === undefined) return false;

      // S3に格納処理
      await CeAdResourceRepository.upload(draftSheetId, formData)
        .then(() => {
          this.resetAdResource();
          this.getCeDetail();
          this.successUpload = true;
        })
        .catch(() => {
          this.serverError = true;
        })
        .finally(() => {
          this.running = false;
        });
    },
    createFormData: function(): FormData | undefined {
      const formData = new FormData();
      const resource = this.resources;
      const activeOtdTab = this.activeOtdTab;
      let isAppend = false;

      // OTD
      if (activeOtdTab && resource.otd.top_banner_1) {
        formData.append("otd_top_banner_1", resource.otd.top_banner_1);
        isAppend = true;
      }
      if (activeOtdTab && resource.otd.top_banner_2) {
        formData.append("otd_top_banner_2", resource.otd.top_banner_2);
        isAppend = true;
      }
      if (activeOtdTab && resource.otd.detail_banner) {
        formData.append("otd_detail_banner", resource.otd.detail_banner);
        isAppend = true;
      }
      if (activeOtdTab && resource.otd.top_banner_pdo) {
        formData.append("otd_top_banner_pdo", resource.otd.top_banner_pdo);
        isAppend = true;
      }

      // NCE
      if (!activeOtdTab && resource.nce.top_banner_1) {
        formData.append("nce_top_banner_1", resource.nce.top_banner_1);
        isAppend = true;
      }
      if (!activeOtdTab && resource.nce.top_banner_2) {
        formData.append("nce_top_banner_2", resource.nce.top_banner_2);
        isAppend = true;
      }
      if (!activeOtdTab && resource.nce.detail_banner) {
        formData.append("nce_detail_banner", resource.nce.detail_banner);
        isAppend = true;
      }

      // テキスト素材
      if (resource.text_resource) {
        formData.append("text_resource", resource.text_resource);
        isAppend = true;
      }

      return isAppend ? formData : undefined;
    },
    setResourceMimeTypes: function() {
      // バイナリにmimeTypeを付与し画像を表示できるようにする
      this.setOtdMimeType();
      this.setNceMimeType();
    },
    setOtdMimeType: function() {
      // トップバナー1
      if (this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_1.binary
        );
      }

      // トップバナー2
      if (this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.otd_top_banner_2.binary
        );
      }

      // 詳細バナー
      if (this.ceDraftDetail.ce_ad_resource.otd_detail_banner.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.otd_detail_banner.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.otd_detail_banner.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.otd_detail_banner.binary
        );
      }
    },
    setNceMimeType: function() {
      // トップバナー1
      if (this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_1.binary
        );
      }

      // トップバナー2
      if (this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.nce_top_banner_2.binary
        );
      }

      // 詳細バナー
      if (this.ceDraftDetail.ce_ad_resource.nce_detail_banner.binary) {
        let mimeType = this.findMimeType(
          this.ceDraftDetail.ce_ad_resource.nce_detail_banner.extend
        );
        // バイナリに情報を付与
        this.ceDraftDetail.ce_ad_resource.nce_detail_banner.src = this.addBinary(
          mimeType,
          this.ceDraftDetail.ce_ad_resource.nce_detail_banner.binary
        );
      }
    },
    addBinary: function(mimeType: string | null, binary: string): string {
      return mimeType !== null
        ? "data:" + mimeType + ";base64," + binary
        : binary;
    },
    findMimeType: function(extend?: string): string | null {
      if (!extend) return null;

      const fileType = extend.toLowerCase();
      switch (fileType) {
        case "png":
          return "image/png";
        case "jpeg":
        case "jpg":
          return "image/jpeg";
        case "pdo":
          return "";
        default:
          return "";
      }
    },
    downloadResources: async function() {
      this.downloading = true;
      const id = this.ceDraftDetail.id;
      await repository()
        .get(`/api/draftSheets/${id}/ceAdResources/export`, {
          responseType: "blob"
        })
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(response, "広告素材.zip");
        })
        .catch(() => {
          this.serverError = true;
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    getCeDetail: async function() {
      if (this.draftSheetId !== undefined) {
        this.running = true;
        await DraftSheetRepository.getCeDetail(this.draftSheetId)
          .then(response => {
            this.ceDraftDetail = response;
            this.setResourceMimeTypes();
            this.updateHeaderInfo(); // ヘッダー情報の反映
          })
          .finally(() => {
            this.running = false;
          });
      }
    },
    onUpdateLongTerm: function() {
      this.getCeDetail();
      this.successUpdateLongTerm = true;
    },
    onError: function() {
      this.serverError = true;
    }
  },
  mounted: function() {
    this.running = true;
    this.draftSheetId = Number(this.$route.params.draftSheetId); // パラメータから入稿書情報ID取得
    this.getCeDetail();
    this.running = false;
  }
});
