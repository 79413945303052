import repository from "@/repositories/repository";

export type ClientAttribute = {
  id: number;
  name: string;
  order: number;
  disabled: number;
};

type Query = {
  id?: number;
  name: string;
  order: number;
  disabled: number;
};

export default {
  list: async (token: string, query?: Query): Promise<ClientAttribute[]> => {
    const response = await repository(token).get("/api/client/attributes", {
      params: { ...query }
    });
    return response.data;
  },

  actives: async (token: string): Promise<ClientAttribute[]> => {
    const response = await repository(token).get(
      "/api/client/attribute/actives"
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<ClientAttribute> => {
    const response = await repository(token).get(
      `/api/client/attributes/${id}`
    );
    return response.data;
  },

  store: async (data: any, token: string): Promise<ClientAttribute[]> => {
    const response = await repository(token).post(`/api/client/attributes`, {
      data: data
    });
    return response.data;
  },

  update: async (
    id: number,
    data: any,
    token: string
  ): Promise<ClientAttribute[]> => {
    const response = await repository(token).put(
      `/api/client/attributes/${id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<ClientAttribute[]> => {
    const response = await repository(token).delete(
      `/api/client/attributes/${id}`
    );
    return response.data;
  }
};

export const newClientAttribute: () => ClientAttribute = () => ({
  id: 0,
  name: "",
  order: 0,
  disabled: 0
});
