











































































































































import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";

import Menu from "@/components/AdminMenu.vue";

import FiscalYearRepository, { FiscalYear } from "@/repositories/fiscalYear";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  isUpdate: boolean;
  fiscalYears: {
    id: number;
    fiscalYear: number;
    beginYear: number;
    beginMonth: number;
    endYear: number;
    endMonth: number;
  }[];
  fiscalyear: FiscalYear[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      isError: false,
      isUpdate: false,
      fiscalYears: [],
      fiscalyear: []
    };
  },
  components: {
    Menu
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isFiscalYearEditable();
    }
  },
  methods: {
    updateFiscalYear: async function() {
      let action = confirm("本当に事業年度を更新してもよろしいですか。");
      if (!action) {
        return false;
      }
      this.isError = false;
      var postData: FiscalYear[] = [];
      this.fiscalYears.forEach(element => {
        // 事業年度開始・終了を日付に変換
        var thisYear = dayjs()
          .year(element.beginYear)
          .month(element.beginMonth - 1)
          .date(1);
        var nextYear = dayjs()
          .year(element.endYear)
          .month(element.endMonth - 1)
          .date(1);
        if (!this.validation(thisYear, nextYear)) {
          this.isError = true;
          return false;
        }

        var lastDay = Number(nextYear.endOf("month").format("DD"));
        nextYear = nextYear.date(lastDay);
        postData.push({
          id: element.id,
          begin: thisYear.format("YYYY-MM-DD"),
          end: nextYear.format("YYYY-MM-DD")
        });
      });
      await FiscalYearRepository.update(postData, "")
        .then(() => {
          this.isUpdate = true;
        })
        .catch(() => {
          //TODO エラー時でも「更新しました。」になる
          this.isUpdate = false;
          this.isError = true;
        });
    },
    validation: function(begin: Dayjs, end: Dayjs) {
      if (begin.isAfter(end)) {
        return false;
      }
      if (Number(begin.format("YYYYMM")) === Number(end.format("YYYYMM"))) {
        return false;
      }
      return true;
    }
  },
  mounted: async function() {
    this.loading = true;
    var years: number[] = [];
    years.push(dayjs().year());
    var fiscalYears = await FiscalYearRepository.store(years, "");
    var beginYear = 0;
    var beginMonth = 0;
    var endYear = 0;
    var endMonth = 0;
    // 年と月を分解
    fiscalYears.forEach(element => {
      beginYear = Number(element.begin.split("-")[0]);
      beginMonth = Number(element.begin.split("-")[1]);
      endYear = Number(element.end.split("-")[0]);
      endMonth = Number(element.end.split("-")[1]);
      this.fiscalYears.push({
        id: element.id,
        fiscalYear: beginYear,
        beginYear: beginYear,
        beginMonth: beginMonth,
        endYear: endYear,
        endMonth: endMonth
      });
    });
    this.loading = false;
  }
});
