import repository from "@/repositories/repository";

export type DraftDeal = {
  id?: number;
  deal_id: number;
  user: { id: number; name: string };
  window_ipg_staff?: { id?: number; name: string };
  client: {
    id: number;
    ipg_staff_user_id?: number;
    ad_placement_time_type?: number;
    legacy_model_animation_seconds?: number;
    name: string;
    new_model_animation_seconds?: number;
    other_notes?: number;
    staff_selection_type?: number;
  };
  published_date_from: string;
  published_time_from: string;
  published_time_to: string;
  material_fixed_date?: string;
  ad_media: {
    id?: number;
    name?: string;
  };
  ad_area: {
    id?: number;
    name?: string;
  };
  sales_channel?: {
    id: number;
    name: string;
  };
  sales_probability_def: {
    id?: number;
    name?: string;
  };
  publication_time: number;
  publication_time_other: string;
  conventional_animation_time: number;
  new_animation_time: number;
  title: string;
  ad_type: number;
  work_plan_type: number;
  production?: number;
  ipg_plan_notes?: string;
  status: number;
  next_action?: string;
  note: string;
  hold: boolean;
  is_grouped: boolean;
  is_draft_sheet_created: boolean;
  deal_unit: {
    first_deal_id: number;
    yomi_deal_id: number;
    deal_id: number;
  };
  latest_draft_sheet_status?: number;
};

type Query = {
  id?: number;
  user_id: number;
  window_ipg_staff?: { id: number; name: string };
  client_id: number;
  published_date_from: string;
  material_fixed_date: string;
  ad_media_id: number;
  ad_area_id: number;
  publication_time: number;
  publication_time_other: string;
  conventional_animation_time: number;
  new_animation_time: number;
  title: string;
  work_plan_type: number;
  production: number;
  ipg_plan_notes: string;
  status: number;
  next_action: string;
  production_note: string;
  note: string;
};

type SearchQuery = {
  from: string;
  to: string;
  staff: number;
  window_staff: number;
  client: number;
  media: number;
  area: number;
  work_plan_type: number;
  draft_status: number;
  draft_sheet_status: number;
  next_action: boolean;
  is_over: boolean;
  is_progressed: boolean;
};

export default {
  list: async (query?: Query): Promise<DraftDeal[]> => {
    const response = await repository().get(`/api/drafts`, {
      params: { ...query }
    });
    return response.data;
  },

  search: async (data: SearchQuery): Promise<DraftDeal[]> => {
    const response = await repository().post(`/api/drafts/search/index`, {
      data: data
    });
    return response.data.data;
  },

  groupIndex: async (firstDealId: number): Promise<DraftDeal[]> => {
    const response = await repository().get(
      `/api/drafts/findDraftDealGroup/${firstDealId}`
    );
    return response.data.data;
  },

  get: async (id: number): Promise<DraftDeal> => {
    const response = await repository().get(`/api/drafts/${id}`);
    return response.data;
  },

  find: async (id: number): Promise<DraftDeal> => {
    const response = await repository().get(`/api/drafts/find/${id}`);
    return response.data.data;
  },

  store: async (data: DraftDeal): Promise<DraftDeal> => {
    const response = await repository().post(`/api/drafts`, {
      data: data
    });
    return response.data;
  },

  update: async (data: DraftDeal): Promise<void> => {
    const response = await repository().put(`/api/drafts/${data.id}/`, {
      data: data
    });
    return response.data;
  },

  saveDraftDealGroup: async (data: DraftDeal[]): Promise<void> => {
    const response = await repository().post(
      `/api/drafts/saveDraftDealGroup/`,
      {
        data: data
      }
    );
    return response.data;
  },

  releaseDraftDealGroup: async (data: DraftDeal[]): Promise<void> => {
    const response = await repository().post(
      `/api/drafts/releaseDraftDealGroup/`,
      {
        data: data
      }
    );
    return response.data;
  },

  destroy: async (id: number): Promise<void> => {
    await repository().delete(`/api/drafts/${id}`);
  }
};

export const newDraftDeal: () => DraftDeal = () => ({
  deal_id: 0,
  user: { id: 0, name: "" },
  client: {
    id: 0,
    name: ""
  },
  published_date_from: "",
  published_time_from: "",
  published_time_to: "",
  material_fixed_date: "",
  ad_media: {},
  ad_area: {},
  sales_probability_def: {},
  publication_time: 0,
  publication_time_other: "",
  conventional_animation_time: 0,
  new_animation_time: 0,
  title: "",
  ad_type: 1,
  work_plan_type: 0,
  status: 1,
  next_action: "",
  note: "",
  hold: false,
  is_grouped: false,
  is_draft_sheet_created: false,
  deal_unit: {
    first_deal_id: 0,
    yomi_deal_id: 0,
    deal_id: 0
  },
  latest_draft_sheet_status: 0
});
