export type AdMedia = {
  code: string;
  name: string;
  flames: number;
};

export type TimeRange = {
  from: string;
  to: string;
};

export const ceMediaIds = function(): number[] {
  return [1, 2, 8]; // CE(終日),CE(夜帯),CE(調整枠)
};

export const htmlMediaIds = function(): number[] {
  return [7, 9]; // GGHTML,GGHTML(調整枠)
};

export const ceAndHtmlMediaIds = function(): number[] {
  return [1, 2, 8, 7, 9]; // CE(終日),CE(夜帯),CE(調整枠),GGHTML,GGHTML(調整枠)
};

export const defaultPublishedTime = function(
  adMediaId: number
): TimeRange | undefined {
  switch (adMediaId) {
    case 1: // CE(終日)
    case 8: // CE(調整枠)
    case 7: // GGHTML
    case 9: // GGHTML(調整枠)
      return { from: "5:00", to: "29:00" };
    case 2: // CE(夜帯)
      return { from: "19:00", to: "25:00" };
    case 3: // GGM PJ
    case 4: // Yahoo! PJ
    case 6: // GGM OL
    case 10: // DCA
      return { from: "0:00", to: "24:00" };
    case 5: // GGM 網掛け
      return { from: "0:00", to: "OA終了時刻" };
    default:
      return undefined;
  }
};
