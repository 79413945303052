import repository from "@/repositories/repository";

export type Item = {
  id?: number;
  order: number;
  name: string;
  official_name: string;
  flame_count: number;
  has_lsp: number;
  is_only_nationwide: number;
  is_region_specifiable: number;
  is_not_shared: number;
  materials_limit: number;
  disabled: number;
};

type Query = {
  id?: number;
  name: string;
};

export default {
  list: async (token: string, query?: Query): Promise<Item[]> => {
    const response = await repository(token).get("/api/adMedias", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: number, token: string): Promise<Item> => {
    const response = await repository(token).get(`/api/adMedias/${id}`);
    return response.data;
  },

  search: async (itemName: string, token: string): Promise<Item[]> => {
    const response = await repository(token).post(`/api/adMedias/search`, {
      itemName: itemName
    });
    return response.data;
  },

  store: async (data: Item, token: string): Promise<Item[]> => {
    const response = await repository(token).post(`/api/adMedias/`, {
      data: data
    });
    return response.data;
  },

  update: async (data: Item, token: string): Promise<Item[]> => {
    const response = await repository(token).put(`/api/adMedias/${data.id}`, {
      data: data
    });
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<Item[]> => {
    const response = await repository(token).delete(`/api/adMedias/${id}`);
    return response.data;
  }
};

export const newItem: () => Item = () => ({
  id: 0,
  order: 0,
  name: "",
  official_name: "",
  flame_count: 1,
  has_lsp: 0,
  is_only_nationwide: 0,
  is_region_specifiable: 0,
  is_not_shared: 0,
  materials_limit: 0,
  disabled: 0
});
