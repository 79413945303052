import { render, staticRenderFns } from "./YomiManagement.vue?vue&type=template&id=676dd2f6&scoped=true&"
import script from "./YomiManagement.vue?vue&type=script&lang=ts&"
export * from "./YomiManagement.vue?vue&type=script&lang=ts&"
import style0 from "./YomiManagement.vue?vue&type=style&index=0&id=676dd2f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676dd2f6",
  null
  
)

export default component.exports