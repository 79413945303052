export type PublicationTime = {
  code: number;
  name: string;
};

export const publicationTime = (code: number): string | undefined =>
  publicationTimes.find(x => x.code === code)?.name;

export const publicationTimes: PublicationTime[] = [
  { code: 1, name: "終日掲載" },
  { code: 2, name: "放送終了まで" },
  { code: 3, name: "その他" }
];
