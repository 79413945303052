import repository from "@/repositories/repository";

export type Role = {
  id: number;
  name: string;
};

type Query = {
  id?: number;
  name: string;
};

export default {
  list: async (token: string, query?: Query): Promise<Role[]> => {
    const response = await repository(token).get("/api/roles", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: number, token: string): Promise<Role> => {
    const response = await repository(token).get(`/api/roles/${id}`);
    return response.data;
  }
};
