

































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import Menu from "@/components/AdminMenu.vue";
import NewSaleChannelModal from "@/components/NewSaleChannelModal.vue";

import SaleChannelRepository, {
  SaleChannel
} from "@/repositories/saleChannels";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  salesChannel: SaleChannel[];
  isFormShown: boolean;
  loading: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  isNew: boolean;
  id: number;
};

export default Vue.extend({
  components: {
    Menu,
    NewSaleChannelModal
  },
  data: function(): DataType {
    return {
      salesChannel: [],
      isFormShown: false,
      loading: false,
      isCreate: false,
      isUpdate: false,
      isDelete: false,
      isNew: false,
      id: 0
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.salesChannel && this.salesChannel.length > 0;
    },
    editable: function(): boolean {
      return AuthHelper.isSalesChannelWritable();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.id = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.id = id;
      this.isNew = false;
      this.showModal();
    },
    showModal: function() {
      this.isFormShown = true;
      (this.$refs.newSaleChannelModal as any).show();
    },
    closeForm: function() {
      this.isFormShown = false;
    },
    onSuccess: async function(datas: SaleChannel[]) {
      this.isCreate = true;
      this.loading = true;
      this.salesChannel = await datas;
      this.loading = false;
    },
    onUpdate: async function(datas: SaleChannel[]) {
      this.loading = true;
      this.isUpdate = true;
      this.salesChannel = await datas;
      this.loading = false;
    },
    onDelete: async function(datas: SaleChannel[]) {
      this.loading = true;
      this.isDelete = true;
      this.salesChannel = await datas;
      this.loading = false;
    }
  },
  mounted: async function() {
    this.loading = true;
    this.salesChannel = await SaleChannelRepository.list("" /*TODO*/);
    this.loading = false;
  }
});
