


























































































import Vue, { PropType } from "vue";
import { ClientStaff } from "@/repositories/clientStaffs";
import { AgencyStaff } from "@/repositories/agencyStaffs";

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    mode: {
      type: Number,
      default: 0
    },
    clientStaffs: {
      type: Array as PropType<ClientStaff[]>,
      required: true
    },
    agencyStaffs: {
      type: Array as PropType<AgencyStaff[]>,
      required: true
    }
  },
  computed: {
    showClientStaffs(): boolean {
      return this.mode !== 2;
    },
    showAgencyStaffs(): boolean {
      return this.mode !== 1;
    }
  },
  mounted: function() {
    this.clientStaffs.forEach(clientStaff => {
      clientStaff.staff_type = 0;
    });
    this.agencyStaffs.forEach(agencyStaff => {
      agencyStaff.staff_type = 0;
    });
  }
});
