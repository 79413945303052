































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import * as AuthHelper from "@/helpers/auth";
import ChannelRepository, {
  Channel,
  newChannel
} from "@/repositories/channels";

type DataType = {
  loading: boolean;
  channel: Channel;
  errorMsg: string[];
  isError: boolean;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      channel: newChannel(),
      errorMsg: [],
      isError: false
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        let channel: Channel;
        channel = await ChannelRepository.get(this.id);
        if (channel) {
          this.channel = channel;
        }
      }
      this.loading = false;
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.isError = false;
      this.errorMsg = [];
      this.channel = newChannel();
    },
    async postChannel() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ChannelRepository.store(this.channel);
      (this.$refs.modal as BModal).hide();
      this.$emit("success");
      return this.channel;
    },
    async updateChannel(id: number) {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ChannelRepository.update(id, this.channel);
      (this.$refs.modal as BModal).hide();
      this.$emit("update");
      return this.channel;
    },
    async deleteChannel(id: number) {
      let action = confirm("チャンネル情報の削除を行います。よろしいですか？");
      if (!action) {
        return false;
      }
      await ChannelRepository.destroy(id);
      (this.$refs.modal as BModal).hide();
      this.$emit("delete");
    },
    validation: function() {
      let msg: string[];
      msg = [];
      if (this.channel.name === null || this.channel.name === "") {
        msg.push("チャンネル名を入力してください。");
      }
      if (this.channel.gcn === null || this.channel.gcn === "") {
        msg.push("GCNは必須です。");
      }
      if (!this.channel.gcn.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
        msg.push("GCNは半角英数字記号で入力してください。");
      }
      return msg;
    }
  }
});
