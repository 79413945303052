
































































































import Vue from "vue";

import Menu from "@/components/Menu.vue";
import RegisterCalendarTable from "@/components/registerCalendars/RegisterCalendars.vue";
import dayjs from "dayjs";
import Datepicker from "@/components/calendars/Datepicker.vue";
import RegisterCalendarRepository, {
  RegisterCalendar,
  RegisterCalendarQuery,
  resetRegisterCalendar
} from "@/repositories/registerCalendar";
import { compare } from "@/helpers/sort";

type DataType = {
  loading: boolean;
  error: boolean;
  success: boolean;
  query: RegisterCalendarQuery;
  ceList: RegisterCalendar[]; // CE用のテーブルに表示する
  htmlList: RegisterCalendar[]; // HTML用のテーブルに表示する
};

export default Vue.extend({
  components: {
    Menu,
    RegisterCalendarTable,
    Datepicker
  },
  data: function(): DataType {
    return {
      loading: false,
      error: false,
      success: false,
      query: resetRegisterCalendar(),
      ceList: [],
      htmlList: []
    };
  },
  computed: {
    calendarShown: function(): boolean {
      return !this.loading;
    }
  },
  methods: {
    reset: function() {
      this.error = false;
      this.success = false;
      this.loading = false;
    },
    search: async function() {
      this.reset();
      this.loading = true;
      await RegisterCalendarRepository.list(this.query)
        .then(response => {
          this.distinction(response);
        })
        .catch(e => {
          console.log("error", e);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    distinction(list: RegisterCalendar[]) {
      this.htmlList = [];
      this.ceList = [];
      if (list !== null) {
        list.map((item: RegisterCalendar) => {
          if (item.registration_media.code === 3) {
            // 3: HTML
            this.htmlList.push(item);
          } else if (
            item.registration_media.code === 1 ||
            item.registration_media.code === 2
          ) {
            // 1: OTD 2:NCE
            this.ceList.push(item);
          }
        });
      }
    },
    sortByCe: function(itemName: string, isSortByAsc: boolean) {
      this.ceList = this.sortBy(this.ceList, itemName, isSortByAsc);
    },
    sortByHtml: function(itemName: string, isSortByAsc: boolean) {
      this.htmlList = this.sortBy(this.htmlList, itemName, isSortByAsc);
    },
    sortBy: function(
      list: RegisterCalendar[],
      itemName: string,
      isSortByAsc: boolean
    ): RegisterCalendar[] {
      switch (itemName) {
        case "registrationMedia": // 媒体
          return list.sort(
            compare(a => a.registration_media.name, isSortByAsc)
          );
        case "publishedDate": // 掲載日
          return list.sort(compare(a => a.published_date, isSortByAsc));
        case "client": // クライアント
          return list.sort(compare(a => a.client.name, isSortByAsc));
        case "materialName": // 素材名
          return list.sort(compare(a => a.material_name, isSortByAsc));
      }
      return list;
    },
    onUpdate: function() {
      this.search();
      this.success = true;
    },
    onError: function() {
      this.reset();
      this.error = true;
    },
    onLoad: function(loading: boolean) {
      this.loading = loading;
    }
  },
  mounted: function() {
    this.query.date_from = dayjs().format("YYYY-MM-DD");
    const endOfMonth = dayjs()
      .endOf("month")
      .date();
    this.query.date_to = dayjs()
      .date(endOfMonth)
      .format("YYYY-MM-DD");
    this.search();
  }
});
