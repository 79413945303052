import repository from "@/repositories/repository";

export type SalesGoalsByMedias = {
  ad_media_mgt?: {
    id: number;
    project_name: string;
    name: string;
  };
  amounts: {
    id: number[];
    year_month: string[];
    amount: string[];
  };
}[];

type Query = {
  user_id: number;
  date: {
    from: string;
    to: string;
  }[];
};

type FiscalYearQuery = {
  from: string;
  to: string;
};

export default {
  list: async (
    token: string,
    query?: FiscalYearQuery
  ): Promise<SalesGoalsByMedias[]> => {
    const response = await repository(token).get("/api/sale/goal/medias/", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: string, token: string): Promise<SalesGoalsByMedias> => {
    const response = await repository(token).get(`/api/sale/goal/medias/${id}`);
    return response.data;
  },

  store: async (token: string, query?: SalesGoalsByMedias[]): Promise<void> => {
    const response = await repository(token).post(`/api/sale/goal/medias/`, {
      params: { ...query }
    });
    return response.data;
  }
};
