import repository from "@/repositories/repository";

export type Media = {
  id?: number;
  name: string;
  flame_count: number;
  official_name: string;
  is_only_nationwide: number;
};

type Query = {
  id?: number;
  name: string;
  official_name: string;
};

export default {
  list: async (token: string, query?: Query): Promise<Media[]> => {
    const response = await repository(token).get("/api/adMedias", {
      params: { ...query }
    });
    return response.data;
  },

  actives: async (token: string): Promise<Media[]> => {
    const response = await repository(token).get("/api/adMedia/actives");
    return response.data;
  },

  get: async (id: number, token: string): Promise<Media> => {
    const response = await repository(token).get(`/api/adMedias/${id}`);
    return response.data;
  }
};
