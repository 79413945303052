


































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import ClientAttributeRepository, {
  ClientAttribute,
  newClientAttribute
} from "@/repositories/clientAttributes";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  clientAttribute: ClientAttribute;
  isError: boolean;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      clientAttribute: {
        id: 0,
        name: "",
        order: 0,
        disabled: 0
      },
      isError: false
    };
  },
  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    isClientAttributeEditable: function(): boolean {
      return AuthHelper.isClientAttributeEditable();
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function() {
      this.resetData();
      if (!this.isNew) {
        var attribute = await ClientAttributeRepository.get(this.id, "");
        if (attribute != null) {
          this.clientAttribute = attribute;
        }
      }
      this.loading = false;
    }
  },
  methods: {
    show: async function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.isError = false;
      this.clientAttribute = newClientAttribute();
    },
    async postClientAttribute() {
      this.isError = this.validation();
      if (this.isError) {
        return false;
      }
      var result = await ClientAttributeRepository.store(
        this.clientAttribute,
        "" /*TODO*/
      );
      (this.$refs.modal as BModal).hide();
      this.$emit("success", result);
    },
    updateClientAttribute: async function() {
      this.isError = this.validation();
      if (this.isError) {
        return false;
      }
      var result = await ClientAttributeRepository.update(
        this.id,
        this.clientAttribute,
        ""
      );
      (this.$refs.modal as BModal).hide();
      this.$emit("update", result);
    },
    deleteClientAttribute: async function() {
      var action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      var result = await ClientAttributeRepository.destroy(this.id, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("delete", result);
    },
    validation: function() {
      if (
        this.clientAttribute.name === null ||
        this.clientAttribute.name === ""
      ) {
        return true;
      }
      return false;
    }
  }
});
