export type RegistrationCalendarStatus = {
  code: number;
  name: string;
};

export const registerCalendarStatusName = (code: number): string | undefined =>
  registerCalendarStatus.find(x => x.code === code)?.name;

export const registerCalendarStatus: RegistrationCalendarStatus[] = [
  { code: 3, name: "登録待ち" },
  { code: 2, name: "登録完了" },
  { code: 1, name: "チェック完了" }
];
