




























































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import AgencyStaffRepository, {
  AgencyStaff,
  newAgencyStaff
} from "@/repositories/agencyStaffs";
import { StaffType, staffTypes } from "@/helpers/staffType";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  staffType: StaffType[];
  staff: AgencyStaff;
  isError: boolean;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      staffType: staffTypes,
      staff: {
        id: 0,
        name: "",
        email: "",
        tel: "",
        staff_type: 0,
        disabled: 0
      },
      isError: false,
      errorMsg: []
    };
  },
  props: {
    isNew: {
      type: Boolean
    },
    id: {
      type: Number
    },
    agencyId: {
      type: Number
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isAgencyStaffWritable();
    },
    deletable: function(): boolean {
      return AuthHelper.isAgencyStaffDeletable();
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.staff = newAgencyStaff();
      this.staff.sales_channel_id = this.agencyId;
    },
    createStaff: async function() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await AgencyStaffRepository.store(this.staff, "");
      this.$emit("create", result);
      (this.$refs.modal as BModal).hide();
    },
    updateStaff: async function(id: number) {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await AgencyStaffRepository.update(this.staff, "");
      this.$emit("update", result);
      (this.$refs.modal as BModal).hide();
    },
    deleteStaff: async function(id: number) {
      var action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      var result = await AgencyStaffRepository.destroy(this.id, "" /*TODO*/);
      this.$emit("delete", result);
      (this.$refs.modal as BModal).hide();
    },
    validation: function() {
      var msg: string[];
      msg = [];
      if (this.staff.name === null || this.staff.name === "") {
        msg.push("担当者名を入力してください。");
      }
      var validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.staff.email != null &&
        this.staff.email != "" &&
        !validEmail.test(this.staff.email)
      ) {
        msg.push("メールアドレスの形式が正しくありません。");
      }
      var validphone = /^[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$/;
      if (
        this.staff.tel != null &&
        this.staff.tel != "" &&
        !validphone.test(this.staff.tel)
      ) {
        msg.push("電話番号の形式が正しくありません。");
      }
      if (this.staff.staff_type === 0) {
        msg.push("担当者種別を選択してください。");
      }
      return msg;
    }
  },
  mounted: function() {
    this.staff.sales_channel_id = this.agencyId;
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        var staff = await AgencyStaffRepository.get(this.id, "");
        if (staff != null) {
          this.staff = staff;
        }
      }
      this.loading = false;
    },
    agencyId: function(oldValue, newValue) {
      this.staff.sales_channel_id = this.agencyId;
    }
  }
});
