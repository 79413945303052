





























import Vue from "vue";
import Menu from "@/components/Menu.vue";
import DraftHeader from "@/components/draftCalendars/DraftHeader.vue";
import CeDraftDetails from "@/components/draftCalendars/CeDraftDetails.vue";
import HtmlDraftDetails from "@/components/draftCalendars/HtmlDraftDetails.vue";
import {
  CeDraftDetail,
  HtmlDraftDetail,
  newCeDraftDetail,
  newHtmlDraftDetail
} from "@/repositories/draftSheet";
import { ceMediaIds, htmlMediaIds } from "@/helpers/adMedia";
type Datatype = {
  ceDraftDetail: CeDraftDetail;
  htmlDraftDetail: HtmlDraftDetail;
};
export default Vue.extend({
  components: {
    DraftHeader,
    CeDraftDetails,
    HtmlDraftDetails,
    Menu
  },
  data: function(): Datatype {
    return {
      ceDraftDetail: newCeDraftDetail(),
      htmlDraftDetail: newHtmlDraftDetail()
    };
  },
  computed: {
    isCe: function(): boolean {
      return ceMediaIds().includes(Number(this.$route.params.adMediaId));
    },
    isHtml: function(): boolean {
      return htmlMediaIds().includes(Number(this.$route.params.adMediaId));
    }
  }
});
