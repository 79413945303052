import repository from "@/repositories/repository";

export type SaleChannel = {
  id?: number;
  name: string;
  disabled: number;
  deleted_at: string;
  agency: [];
};

type Query = {
  id?: number;
  name: string;
  disabled: number;
  deleted_at: string;
};

export default {
  list: async (token: string, query?: Query): Promise<SaleChannel[]> => {
    const response = await repository(token).get("/api/sale/channels", {
      params: { ...query }
    });
    return response.data;
  },

  actives: async (token: string): Promise<SaleChannel[]> => {
    const response = await repository(token).get("/api/sale/channel/actives");
    return response.data;
  },

  get: async (id: number, token: string): Promise<SaleChannel> => {
    const response = await repository(token).get(`/api/sale/channels/${id}`);
    return response.data;
  },

  store: async (data: SaleChannel, token: string): Promise<SaleChannel[]> => {
    const response = await repository(token).post(`/api/sale/channels/`, {
      data: data
    });
    return response.data;
  },

  update: async (
    id: number,
    data: SaleChannel,
    token: string
  ): Promise<SaleChannel[]> => {
    const response = await repository(token).put(`/api/sale/channels/${id}`, {
      data: data
    });
    return response.data;
  },
  destroy: async (id: number, token: string): Promise<SaleChannel[]> => {
    const response = await repository(token).delete(`/api/sale/channels/${id}`);
    return response.data;
  }
};

export const newSaleChannel: () => SaleChannel = () => ({
  id: 0,
  name: "",
  disabled: 0,
  deleted_at: "",
  agency: []
});
