export type TargetPrice = {
  code: number;
  name: string;
};

export const targetPrice = (code: number): string | undefined =>
  allTargetPrice.find(x => x.code === code)?.name;

export const allTargetPrice: TargetPrice[] = [
  { code: 1, name: "媒体費＋制作費" },
  { code: 2, name: "媒体費のみ" },
  { code: 3, name: "制作費のみ" }
];
