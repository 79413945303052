



















































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import { Prefecture, prefectures } from "@/helpers/prefecture";
import AreaRepository, { Area, newArea } from "@/repositories/areas";
import * as AuthHelper from "@/helpers/auth";
import { AxiosError } from "axios";

type DataType = {
  loading: boolean;
  area: Area;
  areaId: number;
  prefectures: Prefecture[];
  selectPrefectures: number[];
  isError: boolean;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      area: newArea(),
      areaId: this.id,
      prefectures: prefectures,
      selectPrefectures: [],
      isError: false,
      errorMsg: []
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    isAdAreaWritable: function(): boolean {
      return AuthHelper.isAdAreaWritable();
    },
    isAdAreaDeletable: function(): boolean {
      return AuthHelper.isAdAreaDeletable();
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.area = newArea();
      this.selectPrefectures = [];
    },
    async postArea() {
      this.isError = false;
      this.errorMsg = [];
      await this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await AreaRepository.store(
        {
          name: this.area.name,
          prefectures: this.selectPrefectures,
          description: this.area.description ? this.area.description : null
        },
        ""
      )
        .then(() => {
          (this.$refs.modal as BModal).hide();
          this.$emit("success_update");
          return this.$data;
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.errorMsg.push(
              "すでに選択した都道府県でエリアが登録されています。"
            );
          } else {
            this.errorMsg.push("エラーが発生しました。");
          }
        });
    },
    async updateArea(id: number) {
      this.isError = false;
      this.errorMsg = [];
      await this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await AreaRepository.update(
        id,
        {
          name: this.area.name,
          prefectures: this.selectPrefectures,
          description: this.area.description
        },
        ""
      )
        .then(() => {
          (this.$refs.modal as BModal).hide();
          this.$emit("success_update");
          return this.$data;
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.errorMsg.push(
              "すでに選択した都道府県でエリアが登録されています。"
            );
          } else {
            this.errorMsg.push("エラーが発生しました。");
          }
        });
    },
    async deleteArea(id: number) {
      let action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      await AreaRepository.destroy(id, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("delete");
    },
    validation: async function() {
      if (this.area.name === null || this.area.name === "") {
        this.errorMsg.push("エリア名を入力してください。");
      }
      if (this.selectPrefectures.length < 1) {
        this.errorMsg.push("都道府県を１つ以上選択してください。");
      }
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        let area = await AreaRepository.get(this.id, "");
        this.selectPrefectures = [];
        if (area.length > 0) {
          this.area = area[area.length - 1];
          area.forEach(element => {
            if (element.prefecture_id != null) {
              this.selectPrefectures.push(element.prefecture_id);
            }
          });
        }
      }
      this.loading = false;
    }
  }
});
