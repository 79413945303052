import store from "@/store";

export function abilities(): string[] {
  const abilities = store.getters["auth/abilities"];
  return abilities;
}

export function isSalesWriter(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("sales:write");
}

export function canDeleteSales(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("sales:delete");
}

export function isAdminSalesEditor(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.sales:edit");
}

export function canShowBudget(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.sales:edit");
}

export function canDownloadSales(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.sales:download");
}

export function isDraftViewable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("draft:view");
}

export function isDraftEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("draft:edit");
}

export function isDealAdmin(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.deals:edit");
}

export function isDealEditor(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("deals:edit");
}

export function isUserAdmin(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.users:edit");
}

export function isClientWritable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.clients:write");
}

export function isClientUpdatable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.clients:update");
}

export function isClientDeletable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.clients:delete");
}

export function isClientStaffWritable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.client.staffs:write");
}

export function isClientStaffDeletable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.client.staffs:delete");
}

export function isClientAttributeEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.client.attributes:edit");
}

export function isAdAreaWritable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.adarea:write");
}

export function isAdAreaDeletable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.adarea:delete");
}

export function isAdMediaEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.admedia:edit");
}

export function isAdMediaManagementEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.admedia.management:edit");
}

export function isSalesChannelWritable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.saleschannels:write");
}

export function isSalesChannelUpdatable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.saleschannels:update");
}

export function isSalesChannelDeletable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.saleschannels:delete");
}

export function isAgencyStaffWritable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.agency.staffs:write");
}

export function isAgencyStaffDeletable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.agency.staffs:delete");
}

export function isSalesProbabilityDefEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.others:edit");
}

export function isEmailTemplateEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.others:edit");
}

export function isFiscalYearEditable(): boolean {
  const abilities: string[] = store.getters["auth/abilities"];
  return abilities.includes("admin.others:edit");
}

export function isOwn(userId: number): boolean {
  const user = store.getters["auth/user"];
  return user && user.id === userId;
}
