










































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewSaleDefModal from "@/components/NewSaleDefsModal.vue";

import SaleDefRepository, { SaleDef } from "@/repositories/saleDefs";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  saleDefs: SaleDef[];
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
  isSuccess: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  saleDefId: number;
};

export default Vue.extend({
  components: {
    Menu,
    NewSaleDefModal
  },

  data: function(): DataType {
    return {
      saleDefs: [],
      isFormShown: false,
      isNew: true,
      loading: false,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      saleDefId: 0
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.saleDefs && this.saleDefs.length > 0;
    },
    editable: function(): boolean {
      return AuthHelper.isSalesProbabilityDefEditable();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.saleDefId = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.isNew = false;
      this.saleDefId = id;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newSaleDefsModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function(saleDefs: SaleDef[]) {
      this.isSuccess = true;
      this.saleDefs = saleDefs;
    },
    onUpdate: async function(saleDefs: SaleDef[]) {
      this.isUpdate = true;
      this.saleDefs = saleDefs;
    },
    onDelete: async function(saleDefs: SaleDef[]) {
      this.isDelete = true;
      this.saleDefs = saleDefs;
    }
  },
  mounted: async function() {
    this.loading = true;
    this.saleDefs = await SaleDefRepository.list("" /*TODO*/);
    this.loading = false;
  }
});
