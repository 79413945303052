export type MediaProject = {
  code: number;
  name: string;
};

export const mediaProject = (code: number): string | undefined =>
  mediaProjects.find(x => x.code === code)?.name;

export const mediaProjects: MediaProject[] = [
  { code: 1, name: "HTML" },
  { code: 2, name: "CE" },
  { code: 3, name: "他媒体" },
  { code: 4, name: "GGM" }
];
