




































































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import AreaRepository, { Area } from "@/repositories/areas";

import { prefectures, Prefecture } from "@/helpers/prefecture";

type DataType = {
  prefectures: Prefecture[];
  allAreas: Area[];
  selectArea?: number;
  selectPrefectures: number[];
  areaFilter: string;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      prefectures: prefectures,
      allAreas: [],
      selectArea: undefined,
      selectPrefectures: [],
      areaFilter: ""
    };
  },
  props: {
    area: {
      type: Number,
      required: false,
      default: undefined
    },
    prefecturesSelectable: {
      type: Boolean,
      required: false,
      default: false
    },
    subsetOnlySelectable: {
      // area プロパティの都道府県に包含された(縮小された)エリアのみ選択可
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    areas: function(): Area[] {
      if (this.areaFilter.trim() !== "") {
        const filters = this.areaFilter.replaceAll("　", " ").split(" ");
        return this.allAreas.filter(area =>
          filters.every(filter => area.name.includes(filter))
        );
      }
      return this.allAreas;
    },
    selectedArea: function(): Area | undefined {
      return this.allAreas.find(x => x.id === this.selectArea);
    }
  },
  mounted: async function() {
    if (this.subsetOnlySelectable && this.area && this.area !== 1) {
      this.allAreas = await AreaRepository.smallAreas("", this.area);
    } else {
      this.allAreas = await AreaRepository.list("" /*TODO*/);
    }
  },
  methods: {
    show() {
      this.searchPrefectures(this.area);
      this.selectArea = this.area;
      (this.$refs.modal as BModal).show();
    },
    clearArea: async function() {
      this.allAreas = await AreaRepository.list("" /*TODO*/);
      this.selectPrefectures = [];
      this.selectArea = undefined;
    },
    searchArea: async function(is_clear = false) {
      if (!this.prefecturesSelectable) {
        return;
      }
      if (is_clear) {
        this.selectArea = undefined;
      }
    },
    searchPrefectures: async function(id: number) {
      if (!id) return;
      var area = await AreaRepository.get(id, "");
      if (area != null) {
        this.selectPrefectures = [];
      }
      area.forEach(element => {
        if (element.prefecture_id != null) {
          this.selectPrefectures.push(element.prefecture_id);
        }
      });
      this.searchArea();
    },
    postArea: async function() {
      if (!this.selectArea && this.selectPrefectures.length === 1) {
        // エリア未選択 かつ 単一都道府県 TODO 都道府県を選択できなくなったので通らない
        var areas = await AreaRepository.list2([], "");
        var area = areas.find(
          x => x.prefecture_id === this.selectPrefectures[0]
        );
        if (area === undefined) {
          var prefecture = this.prefectures.find(
            x => x.code === String(this.selectPrefectures[0])
          );
          if (prefecture != undefined) {
            var selectArea = await AreaRepository.store(
              {
                name: prefecture.name,
                prefectures: this.selectPrefectures,
                description: null
              },
              ""
            );
            if (selectArea != null) {
              this.selectArea = selectArea;
              this.searchPrefectures(selectArea);
            }
          }
        }
      } else if (!this.selectArea && this.selectPrefectures.length < 1) {
        alert("都道府県またはエリアを1つ選択してください。");
        return false;
      }
      const selectedArea = this.allAreas.find(
        area => area.id === this.selectArea
      );
      this.$emit("selectArea", selectedArea);
      (this.$refs.modal as BModal).hide();
    },
    range: function(length: number) {
      return Array.from(Array(length), (_, k) => k);
    }
  }
});
