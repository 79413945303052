














































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewClientStaffModal from "@/components/NewClientStaffModal.vue";

import ClientRepository, { Client, newClient } from "@/repositories/clients";
import ClientStaffsRepository, {
  ClientStaff
} from "@/repositories/clientStaffs";

import { StaffType, staffTypes } from "@/helpers/staffType";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  isNewFormShown: boolean;
  loading: boolean;
  isFormShown: boolean;
  isNew: boolean;
  successStaff: boolean;
  updateStaff: boolean;
  deleteStaff: boolean;
  staffType: StaffType[];
  clientStaffs: ClientStaff[];
  client: Client;
  clientId: number;
  staffId: number;
};
export default Vue.extend({
  components: {
    Menu,
    NewClientStaffModal
  },
  data: function(): DataType {
    return {
      isNewFormShown: false,
      loading: true,
      isFormShown: false,
      isNew: false,
      successStaff: false,
      updateStaff: false,
      deleteStaff: false,
      staffType: staffTypes,
      clientStaffs: [],
      clientId: 0,
      staffId: 0,
      client: newClient()
    };
  },
  methods: {
    showModal() {
      this.isFormShown = true;
      (this.$refs.newClientStaffModal as any).show();
    },
    showForm(isModal: boolean, id = 0) {
      this.isNew = isModal;
      this.staffId = id;
      this.showModal();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function() {
      this.successStaff = true;
      this.isFormShown = false;
      this.clientStaffs = await ClientStaffsRepository.list(this.clientId, "");
    },
    onSuccessUpdate: async function() {
      this.updateStaff = true;
      this.isFormShown = false;
      this.clientStaffs = await ClientStaffsRepository.list(this.clientId, "");
    },
    onDelete: async function() {
      this.deleteStaff = true;
      this.isFormShown = false;
      this.clientStaffs = await ClientStaffsRepository.list(this.clientId, "");
    }
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading;
    },
    isClientStaffWritable: function(): boolean {
      return AuthHelper.isClientStaffWritable();
    }
  },
  mounted: async function() {
    this.loading = true;
    this.clientId = Number(this.$route.params.id);
    this.client = await ClientRepository.get(this.clientId);
    this.clientStaffs = await ClientStaffsRepository.list(this.clientId, "");
    this.loading = false;
  }
});
