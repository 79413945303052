






































































































import Vue from "vue";

import * as AuthHelper from "@/helpers/auth";

const container = Vue.extend({
  template: `
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col col-lg-6">
          <slot></slot>
        </div>
      </div>
    </div>
  `
});

export default Vue.extend({
  components: {
    container
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    isSalesWriter: function() {
      return AuthHelper.isSalesWriter();
    },
    isDraftViewable: function() {
      return AuthHelper.isDraftViewable();
    }
  },
  methods: {
    yomiManagement: function() {
      this.$router.push({ path: "/yomi/management" });
    },

    waku: function() {
      this.$router.push({ path: "/waku/month" });
    }
  }
});
