





























































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewItemModal from "@/components/NewItemModal.vue";

import ItemRepository, { Item } from "@/repositories/items";

import { WakuType, wakuTypes } from "@/helpers/wakuType";
import * as AuthHelper from "@/helpers/auth";

type Items = {
  id: number;
  order: number;
  name: string;
  official_name: string;
  flame_count: number;
  waku_type: string;
  disabled: number;
};

type DataType = {
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  getItems: Item[];
  items: Items[];
  itemId: number;
  wakuTypes: WakuType[];
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
};

export default Vue.extend({
  components: {
    Menu,
    NewItemModal
  },

  data: function(): DataType {
    return {
      isCreate: false,
      isUpdate: false,
      isDelete: false,
      getItems: [],
      items: [],
      itemId: 0,
      wakuTypes: wakuTypes,
      isFormShown: false,
      isNew: true,
      loading: false
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.items && this.items.length > 0;
    },
    editable: function(): boolean {
      return AuthHelper.isAdMediaEditable();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.itemId = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.isNew = false;
      this.itemId = id;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newItemModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function(items: Item[]) {
      this.isCreate = true;
      this.loading = true;
      this.getItems = items;
      await this.setItems();
      this.loading = false;
    },
    onUpdate: async function(items: Item[]) {
      this.isUpdate = true;
      this.loading = true;
      this.getItems = items;
      await this.setItems();
      this.loading = false;
    },
    onDelete: async function(items: Item[]) {
      this.isDelete = true;
      this.loading = true;
      this.getItems = items;
      await this.setItems();
      this.loading = false;
    },
    addOrder: async function(id: number, add: number) {
      let currentItem = this.getItems.find(x => x.id === id);
      let order = 0;
      if (currentItem != undefined) {
        order = currentItem.order + add;
        if (order < 1) {
          return false;
        } else if (order > this.items.length) {
          order = this.items.length;
        }
        let item = this.getItems.find(x => x.order === order);
        // 順序を入れ替える
        if (item != undefined) {
          item.order = currentItem.order;
          currentItem.order = order;
          await ItemRepository.update(item, "");
          await ItemRepository.update(currentItem, "");
          // 一覧再整形
          this.loading = true;
          this.getItems = await ItemRepository.list("" /*TODO*/);
          await this.setItems();
          this.loading = false;
          this.isUpdate = true;
        }
      }
    },
    setItems: function() {
      this.items = [];
      var item: Items;
      this.getItems.forEach(element => {
        item = {
          id: element.id != undefined ? element.id : 0,
          order: element.order,
          name: element.name,
          official_name: element.official_name,
          flame_count: element.flame_count,
          waku_type: "",
          disabled: element.disabled
        };
        if (element.has_lsp === 1) {
          item.waku_type = item.waku_type + this.setWakuType(item.waku_type, 1);
        }
        if (element.is_only_nationwide === 1) {
          item.waku_type = item.waku_type + this.setWakuType(item.waku_type, 2);
        }
        if (element.is_region_specifiable === 1) {
          item.waku_type = item.waku_type + this.setWakuType(item.waku_type, 3);
        }
        if (element.is_not_shared === 1) {
          item.waku_type = item.waku_type + this.setWakuType(item.waku_type, 4);
        }
        this.items.push(item);
      });
    },
    setWakuType: function(item: string, type_id: number) {
      var wakuType = this.wakuTypes.find(x => x.code === type_id);
      if (wakuType != undefined) {
        return wakuType.name + " ";
      }
      return "";
    }
  },
  mounted: async function() {
    this.loading = true;
    this.getItems = await ItemRepository.list("" /*TODO*/);
    await this.setItems();
    this.loading = false;
  }
});
