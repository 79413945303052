import repository from "@/repositories/repository";

export type SalesGoalsByUserAndAdMedia = {
  user: {
    id: number;
    name: string;
    ad_media_mgt: {
      [key: number]: any;
      id: number;
      project_name: string;
      name: string;
      amounts: {
        id: number[];
        year_month: string[];
        amount: string[];
      }[];
    }[];
  };
}[];

type Query = {
  user_id?: number;
  date: {
    from: string;
    to: string;
  }[];
};

type FiscalYearQuery = {
  from: string;
  to: string;
};

export default {
  list: async (
    token: string,
    query?: FiscalYearQuery //会計年度
  ): Promise<SalesGoalsByUserAndAdMedia[]> => {
    const response = await repository(token).get(
      "/api/sale/goal/users_and_media",
      {
        params: { ...query }
      }
    );
    return response.data;
  },
  store: async (
    token: string,
    query?: SalesGoalsByUserAndAdMedia[]
  ): Promise<void> => {
    const response = await repository(token).post(
      `/api/sale/goal/users_and_media`,
      {
        params: { ...query }
      }
    );
    return response.data;
  },
  match: async (token: string, query?: Query): Promise<number[]> => {
    const response = await repository(token).post(
      `/api/sale/goal/users_and_media/match/`,
      {
        params: { ...query }
      }
    );
    return response.data;
  }
};
