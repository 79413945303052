import repository from "@/repositories/repository";

export type SaleDef = {
  id?: number;
  name: string;
  percent: number;
  disabled_yomi: number;
  disabled_waku: number;
  disabled: number;
};

type Query = {
  id?: number;
  name?: string;
  is_yomi?: boolean;
  is_waku?: boolean;
};

export default {
  list: async (token: string, query?: Query): Promise<SaleDef[]> => {
    const response = await repository(token).get("/api/sale/probaility/defs", {
      params: { ...query }
    });
    return response.data;
  },

  actives: async (token: string, query?: Query): Promise<SaleDef[]> => {
    const response = await repository(token).get(
      "/api/sale/probaility/def/actives",
      {
        params: { ...query }
      }
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<SaleDef> => {
    const response = await repository(token).get(
      `/api/sale/probaility/defs/${id}`
    );
    return response.data;
  },

  store: async (data: SaleDef, token: string): Promise<SaleDef[]> => {
    const response = await repository(token).post(`/api/sale/probaility/defs`, {
      data: data
    });
    return response.data;
  },

  update: async (data: SaleDef, token: string): Promise<SaleDef[]> => {
    const response = await repository(
      token
    ).put(`/api/sale/probaility/defs/${data.id}`, { data: data });
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<SaleDef[]> => {
    const response = await repository(token).delete(
      `/api/sale/probaility/defs/${id}`
    );
    return response.data;
  }
};

export const newSaleDef: () => SaleDef = () => ({
  id: 0,
  name: "",
  percent: 0,
  disabled_yomi: 0,
  disabled_waku: 0,
  disabled: 0
});
