



















































































































import Vue, { PropType } from "vue";

import dayjs from "dayjs";
import weekYear from "dayjs/plugin/weekYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
import "dayjs/locale/ja";
dayjs.locale("ja");

import _ from "lodash";

import MediaFilter from "@/components/MediaFilter.vue";
import Deal from "@/models/deal";

type DataType = {
  now: dayjs.Dayjs;
  filter: {
    media: unknown[];
  };
};
export default Vue.extend({
  components: {
    MediaFilter
  },
  data: function(): DataType {
    return {
      now: dayjs(),
      filter: {
        media: []
      }
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().year()
    },
    week: {
      type: Number,
      required: true,
      default: dayjs().week(),
      validator: function(value) {
        return 1 <= value && value <= 54;
      }
    },
    waku: {
      type: Object,
      required: true
    },
    adMedia: {
      type: Array,
      required: true
    },
    media: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  computed: {
    filteredMedia: function(): any[] {
      return this.adMedia.filter((x: any) => this.filter.media.includes(x.id));
    },
    showable: function(): boolean {
      return (
        this.waku &&
        this.waku[this.year] &&
        this.waku[this.year][this.week] &&
        this.adMedia &&
        this.adMedia.length > 0
      );
    },
    today: function() {
      return dayjs().startOf("day");
    },
    days: function() {
      const week: dayjs.Dayjs[] = [];
      const startOfWeek = dayjs()
        .year(this.year)
        .week(this.week)
        .startOf("week");
      for (let d = 0; d < 7; d++) {
        week.push(startOfWeek.add(d, "day"));
      }
      return week;
    }
  },
  watch: {
    media: function() {
      this.filter.media = this.media;
    }
  },
  methods: {
    isMediaActive: function(id: string) {
      return this.filteredMedia.map(x => x.id).includes(id);
    },
    onChange: function(selected: any) {
      this.filter.media = selected;
      this.$emit("changeFilter", selected);
    },
    countWaku: function(
      year: number,
      week: number,
      dayOfWeek: number,
      mediaId: number,
      slotPriority: number
    ): number {
      return this.waku[year]
        ? this.waku[year][week]
          ? this.waku[year][week][dayOfWeek]
            ? this.waku[year][week][dayOfWeek][mediaId]
              ? this.waku[year][week][dayOfWeek][mediaId][slotPriority]
                ? this.waku[year][week][dayOfWeek][mediaId][slotPriority].length
                : 0
              : 0
            : 0
          : 0
        : 0;
    },
    reservation: function(
      year: number,
      week: number,
      dayOfWeek: number,
      mediaId: number,
      slotPriority: number,
      index = 0
    ): boolean {
      // 該当日の枠存在チェック
      if (this.isWaku(year, week, dayOfWeek, mediaId, slotPriority)) {
        const deals: Deal[] = this.waku[year][week][dayOfWeek][mediaId][
          slotPriority
        ];
        // 該当枠内にヨミ確度「M／J」の枠が1つ以上ある場合 true
        return deals.find(
          x =>
            x.sales_probability_def.name === "M" ||
            x.sales_probability_def.name === "J"
        )
          ? true
          : false;
      }
      return false;
    },
    getWaku: function(
      year: number,
      week: number,
      dayOfWeek: number,
      mediaId: number,
      slotPriority: number,
      index = 0
    ) {
      return this.isWaku(year, week, dayOfWeek, mediaId, slotPriority)
        ? this.waku[year][week][dayOfWeek][mediaId][slotPriority][index]
        : [];
    },
    wakuLabel: function(
      year: number,
      week: number,
      dayOfWeek: number,
      mediaId: number,
      slotPriority: number
    ) {
      return this.isWaku(year, week, dayOfWeek, mediaId, slotPriority)
        ? this.waku[year][week][dayOfWeek][mediaId][slotPriority].map(
            (waku: any) =>
              `${waku.client.abbreviation}: ${
                waku.ad_area ? waku.ad_area.name : ""
              }`
          )
        : [];
    },
    isWaku: function(
      year: number,
      week: number,
      dayOfWeek: number,
      mediaId: number,
      slotPriority: number
    ): boolean {
      // 枠が存在する場合 true
      return this.waku[year]
        ? this.waku[year][week]
          ? this.waku[year][week][dayOfWeek]
            ? this.waku[year][week][dayOfWeek][mediaId]
              ? this.waku[year][week][dayOfWeek][mediaId][slotPriority]
              : false
            : false
          : false
        : false;
    },
    getCount: function(year: number, week: number, dateIndex: number): number {
      if (
        this.waku[year][week] == undefined ||
        this.waku[year][week][dateIndex] == undefined
      ) {
        return 0;
      }

      //CE系のみ //TODO 固定値
      const targetMedia = this.adMedia.filter((x: any) =>
        x.name.startsWith("CE")
      );
      const counts = targetMedia.map((media: any) => {
        const slots = this.waku[year][week][dateIndex][media.id];
        if (slots && Array.isArray(slots)) {
          slots.map((slot: Array<unknown>) => {
            return slot != undefined ? slot.length : 0;
          });
        }
        return slots
          ? Object.keys(slots)
              .map((key: string) => Number(key))
              .filter(slotPriority => slotPriority <= media.flame_count)
              .map(slotPriority =>
                slots[slotPriority] != undefined
                  ? slots[slotPriority].length
                  : 0
              )
          : 0;
      });
      const sum = _.sum(_.flatten(counts));
      return sum;
    },
    hasAlert: function(count: number) {
      // 15枠を超える場合は警告表示
      return count >= 15;
    }
  },
  mounted: function() {
    this.filter.media = this.media;
    if (this.media.length > 0) {
      (this as any).$refs.mediaFilter.changeSelected(this.media);
    }
  }
});
