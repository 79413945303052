




















































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import dayjs from "dayjs";

import YomiRepository, { Yomi } from "@/repositories/yomi";

type DataType = {
  error: boolean;
  yomi: Yomi[];
  import?: Yomi;
  loading: boolean;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      error: false,
      yomi: [],
      import: undefined,
      loading: false
    };
  },
  props: {
    dealId: {
      type: Number,
      required: false
    },
    from: {
      type: String,
      required: false
    },
    to: {
      type: String,
      required: false
    },
    is_long_term: {
      type: Boolean,
      required: false
    },
    userId: {
      type: Number,
      required: false
    },
    clientId: {
      type: Number,
      required: false
    },
    clientAttributeId: {
      type: Number,
      required: false
    },
    adMediaId: {
      type: Number,
      required: false
    },
    adAreaId: {
      type: Number,
      required: false
    },
    yomiDeal: {
      type: Object,
      required: false
    }
  },
  computed: {
    ready: function(): boolean {
      return !this.loading;
    },
    hasYomiDeal: function(): boolean {
      return !!this.yomiDeal;
    }
  },
  methods: {
    show() {
      this.error = false;
      this.import = undefined;
      this.fetchYomi();
      (this.$refs.modal as BModal).show();
    },
    dateFormat: function(date: string) {
      if (date == null || date == "") {
        return "";
      }
      const year = Number(String(date).substr(0, 4));
      const month = Number(String(date).substr(4, 2)) - 1;
      const day = Number(String(date).substr(6, 2));
      if (day > 0) {
        return dayjs()
          .year(year)
          .month(month)
          .date(day)
          .format("MM/DD(dd)");
      } else {
        return dayjs()
          .year(year)
          .month(month)
          .format("MM月");
      }
    },
    resetDate: function(date: string) {
      if (date == null || date == "") {
        return "";
      }
      const year = Number(String(date).substr(0, 4));
      const month = Number(String(date).substr(4, 2)) - 1;
      const day = Number(String(date).substr(6, 2));
      if (day > 0) {
        return dayjs()
          .year(year)
          .month(month)
          .date(day)
          .format("YYYY-MM-DD");
      } else {
        return dayjs()
          .year(year)
          .month(month)
          .date(1)
          .format("YYYY-MM");
      }
    },
    priceFormat(price: string) {
      if (price == "" || price == null) {
        return "";
      }
      return Number(price).toLocaleString();
    },
    fetchYomi: async function() {
      this.loading = true;
      const yomi = await YomiRepository.listForDeal("", {
        user_id: this.userId,
        client_id: this.clientId,
        client_attribute_id: this.clientAttributeId,
        ad_media_id: this.adMediaId,
        ad_area_id: this.adAreaId,
        is_long_term: this.is_long_term,
        published_from: this.dealId ? undefined : this.from,
        published_to: this.dealId ? undefined : this.to,
        deal_id: this.dealId
      });
      this.loading = false;
      this.yomi = yomi;
    },
    setYomi: function(yomi: Yomi) {
      // 選択状態の解除
      this.yomi.forEach(yomi => {
        yomi.style = "";
      });
      this.import = yomi;
      const index = this.yomi.findIndex(x => x.id === yomi.id);
      yomi.style = "background: #dcf0f2; ";
      this.yomi.splice(index, 1, yomi);
    },
    update: function(confirmYomi = true) {
      this.error = false;
      if (this.import === undefined) {
        this.error = true;
        return false;
      }
      if (confirmYomi && this.yomiDeal) {
        (this.$refs.updateConfirmModal as BModal).show();
        return false;
      }
      const date = this.import.published_date.to ?? "";
      this.import.published_date.to = this.resetDate(date);
      this.$emit("importYomi", this.import);
      (this.$refs.modal as BModal).hide();
    },
    forceUpdate: function() {
      this.update(false);
    },
    cancelConfirm: function() {
      (this.$refs.updateConfirmModal as BModal).hide();
    }
  }
});
