import repository from "@/repositories/repository";
import store from "@/store";

export type User = {
  id: number;
  name: string;
  email: string;
  role_id?: number[];
  disabled: number;
};

type Query = {
  all?: boolean;
};

export default {
  list: async (token: string, query?: Query): Promise<User[]> => {
    const response = await repository(token).get("/api/users", {
      params: { ...query }
    });
    return response.data;
  },

  currentUser: async (token: string): Promise<User> => {
    const response = await repository(token).get(`/api/user/`);
    return response.data.user;
  },

  get: async (id: number, token: string): Promise<User> => {
    const response = await repository(token).get(`/api/users/${id}`);
    return response.data;
  },

  store: async (data: any, token: string): Promise<User[]> => {
    const response = await repository(token).post(`/api/users`, {
      data: data
    });

    return response.data;
  },

  update: async (data: User, token: string): Promise<User[]> => {
    const response = await repository(token).put(`/api/users/${data.id}`, {
      data: data
    });
    store.dispatch("auth/getRole");
    return response.data;
  },

  search: async (search: string, token: string): Promise<User[]> => {
    const response = await repository(token).get(`/api/users/search/${search}`);
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<User[]> => {
    const response = await repository(token).delete(`/api/users/${id}`);
    return response.data;
  }
};

export const newUser: () => User = () => ({
  id: 0,
  name: "",
  email: "",
  role_id: [],
  disabled: 0
});
