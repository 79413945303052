







































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import SaleDefRepository, {
  SaleDef,
  newSaleDef
} from "@/repositories/saleDefs";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  saleDef: SaleDef;
  saleDefs: SaleDef[];
  isError: boolean;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      saleDef: newSaleDef(),
      saleDefs: [],
      isError: false,
      errorMsg: []
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isSalesProbabilityDefEditable();
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        var saleDef = await SaleDefRepository.get(this.id, "");
        if (saleDef != null) {
          this.saleDef = saleDef;
        }
      }
      this.loading = false;
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.saleDef = newSaleDef();
    },
    postSaleDef: async function() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      (this.$refs.modal as BModal).hide();
      this.saleDefs = await SaleDefRepository.store(this.saleDef, "");
      this.$emit("success", this.saleDefs);
    },
    updateSaleDef: async function(id: number) {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      this.saleDefs = await SaleDefRepository.update(this.saleDef, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("update", this.saleDefs);
    },
    deleteSaleDef: async function(id: number) {
      var action = confirm("本当に削除してもよろしいですか。");
      if (!action) {
        return false;
      }
      this.saleDefs = await SaleDefRepository.destroy(id, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("delete", this.saleDefs);
    },
    validation: function() {
      var msg: string[] = [];
      if (this.saleDef.name === null || this.saleDef.name === "") {
        msg.push("ヨミ確度名を入力してください。");
      }
      this.saleDef.percent = Number(this.saleDef.percent);
      if (this.saleDef.percent === null) {
        msg.push("確度を入力してください。");
      }
      return msg;
    }
  }
});
