// Phase3.0で追加された入稿書管理画面以降の入稿書単位におけるステータス

export type DraftSheetStatus = {
  code: number;
  name: string;
};

export const getDraftSheetStatusNameByCode = (code: number): string =>
  draftSheetStatuses.find(x => x.code === code)?.name ?? "";

export const getDraftSheetStatusCodeByName = (name: string): number =>
  draftSheetStatuses.find(x => x.name === name)?.code ?? 0;

export const draftSheetStatuses: DraftSheetStatus[] = [
  { code: 1, name: "入稿完了" },
  { code: 2, name: "営業確認完了" },
  { code: 3, name: "入稿メール済" },
  { code: 4, name: "ダブルチェック完了" },
  { code: 5, name: "確定済" }
];
