



















































































import Vue, { PropType } from "vue";
import dayjs from "dayjs";

import { BModal } from "bootstrap-vue";

import EmailTemplatesRepository, {
  EmailTemplate
} from "@/repositories/emailTemplates";
import YomiRepository from "@/repositories/yomi";
import ClientRepository, { Client } from "@/repositories/clients";
import ClientStaffRepository from "@/repositories/clientStaffs";
import MediaRepository, { Media } from "@/repositories/medias";
import AreaRepository, { Area } from "@/repositories/areas";
import { EmailTag, emailtags } from "@/helpers/emailTag";
import { defaultPublishedTime } from "@/helpers/adMedia";

type SendMailType = {
  id: number;
  client_name?: string;
  client_staff_name?: string;
  client_staff_tel?: string;
  client_staff_email?: string;
  media_official_name: string;
  media_price: number;
  production_price: number;
  published_date_from: string;
  published_date_to?: string;
  published_time: string;
  billing_month: string;
  area_name: string;
  comment: string;
};

type DataType = {
  loading: boolean;
  emailTemplate: EmailTemplate;
  clients: Client[];
  areas: Area[];
  medias: Media[];
  title: string;
  text: string;
  tags: EmailTag[];
  sendMailDatas: { month: number; detail: SendMailType[] }[];
};

export default Vue.extend({
  props: {
    ids: {
      type: Array as PropType<number[]>,
      required: true,
      default: [0]
    }
  },
  data: function(): DataType {
    return {
      loading: false,
      emailTemplate: {
        id: 0,
        type: 0,
        title: "",
        text_header: "",
        text_body: "",
        text_footer: ""
      },
      title: "",
      text: "",
      clients: [],
      areas: [],
      medias: [],
      tags: emailtags,
      sendMailDatas: []
    };
  },
  computed: {
    formShown: function(): boolean {
      return !this.loading;
    }
  },
  mounted: async function() {
    this.emailTemplate = await EmailTemplatesRepository.get(1, "");
    this.clients = await ClientRepository.actives("");
    this.areas = await AreaRepository.list("");
    this.medias = await MediaRepository.list("");
  },
  methods: {
    show: function() {
      this.loading = true;
      if (this.ids.length > 0) {
        this.setData().then(() => {
          this.textFormat();
          this.loading = false;
        });
      }
      (this.$refs.modal as BModal).show();
    },
    setData: async function() {
      this.sendMailDatas = [];
      var deals = await YomiRepository.gets(this.ids, "");
      var staffs = await ClientStaffRepository.list(
        deals[deals.length - 1].client.id,
        ""
      );
      deals.forEach(deal => {
        let staff = staffs.find(x => x.staff_type === 1);
        const time = defaultPublishedTime(deal.ad_media.id);
        let detail: SendMailType = {
          id: deal.id != undefined ? deal.id : 0,
          client_name: deal.client.name,
          client_staff_name: staff != undefined ? staff.name : "",
          client_staff_tel: staff != undefined ? staff.tel : "",
          client_staff_email: staff != undefined ? staff.email : "",
          media_official_name: deal.ad_media.official_name ?? "",
          media_price: Number(deal.media_price),
          production_price: Number(deal.working_price),
          published_date_from: deal.published_date.from,
          published_date_to: deal.published_date.to,
          published_time: time ? `${time.from}~${time.to}` : "時間なし",
          billing_month: deal.billing_month,
          area_name: deal.ad_area.name ?? "",
          comment: ""
        };
        // dayjsの使用で実際の月の-1月が取得されるので+1
        let currentMonth = Number(dayjs(deal.billing_month).month()) + 1;
        // 請求月が存在しない場合は新たに配列を追加
        if (
          this.sendMailDatas.find(x => x.month == currentMonth) == undefined
        ) {
          this.sendMailDatas.push({ month: currentMonth, detail: [] });
        }
        // 該当する請求月の配列に明細データを格納
        this.sendMailDatas.forEach(data => {
          if (data.month == currentMonth) {
            data.detail.push(detail);
          }
        });
      });

      // 「計上月」の昇順に並び替え
      this.sendMailDatas = this.sendMailDatas.sort((firstData, secondData) => {
        return firstData.month - secondData.month;
      });
    },
    textFormat: async function() {
      /* メール件名関連 */
      this.title = this.replaceText(
        this.emailTemplate.title,
        this.sendMailDatas[0].month,
        this.sendMailDatas[0].detail[0]
      );

      /* メール本文関連 */
      // メール本文ヘッダー
      this.text = this.emailTemplate.text_header + "\n";

      // メール明細
      let body = this.emailTemplate.text_body;
      const start = body.split("[details-start]")[0];
      let repeat = body.split("[details-end]\n")[0];
      repeat = repeat.split("[details-start]")[1];
      const end = body.split("[details-end]\n")[1];

      // 案件の数だけ明細部分を複製
      this.sendMailDatas.forEach(data => {
        this.text = this.text + start;
        for (let cnt = 0; cnt < data.detail.length; cnt++) {
          let detail = data.detail[cnt];
          let texts = repeat.split("\n");
          if (cnt > 0) {
            // 最初の案件以外は先頭に改行を設定
            this.text += "\n";
          }
          texts.forEach(spliceText => {
            body = this.replaceText(spliceText, data.month, detail);
            this.text += body;
            if (body != "") {
              this.text += "\n";
            }
          });
        }
        this.text = this.text + end + "\n";
        this.text = this.replaceText(this.text, data.month, data.detail[0]);
      });

      // メール本文フッター
      this.text = this.text + this.emailTemplate.text_footer;
    },
    replaceText: function(text: string, month: number, data: SendMailType) {
      let tagCount = 0;
      let replaceTag = "";
      let result = text;

      this.tags.forEach(tag => {
        // 置換対象データをテンプレートから抽出
        tagCount = result.split("[" + tag.name + "]").length - 1;
        while (tagCount > 0) {
          // 置換後情報取得
          replaceTag = this.replaceValue(tag.code, month, data);

          if (tag.code === 7 && replaceTag === "0円") {
            // 制作費が0円の場合はスキップ
            result = "";
          } else {
            // メールタグ置換
            result = this.replaceTag(result, tag.name, tagCount, replaceTag);
          }

          tagCount = tagCount - 1;
        }
      });

      return result;
    },
    replaceValue: function(code: number, month: number, data: SendMailType) {
      switch (code) {
        case 1:
          // クライアント名
          // 案件のクライアントの名前に置換
          return String(data.client_name);

        case 2:
          // クライアント担当者名
          // 案件のクライアントで担当者種別が「発注者」になっている担当者の名前に置換
          return String(data.client_staff_name);

        case 3:
          // クライアント担当者電話番号
          // 案件のクライアントで担当者種別が「発注者」になっている担当者の電話番号に置換
          return String(data.client_staff_tel);

        case 4:
          // クライアント担当者メールアドレス
          // 案件のクライアントで担当者種別が「発注者」になっている担当者のメールアドレスに置換
          return String(data.client_staff_email);

        case 5:
          // 掲載媒体名正式名称
          // 案件の掲載媒体の正式名称に置換
          return String(data.media_official_name);

        case 6:
          // 媒体費
          // 案件の媒体費に置換
          return Number(data.media_price).toLocaleString() + "円";

        case 7:
          // 制作費
          // 案件の制作費に置換
          return Number(data.production_price).toLocaleString() + "円";

        case 8:
          // 掲載日
          // 案件の掲載日の「年月日(曜日)」に置換
          let from = dayjs(data.published_date_from);
          let to = dayjs(data.published_date_to);
          let date = from.format("YYYY年M月D日(dd)");

          if (!from.isSame(to)) {
            // 長期掲載の場合「〜」で掲載日を表記する
            date += " 〜 " + to.format("YYYY年M月D日(dd)");
          }
          return date;

        case 9:
          // 掲載時間
          // 案件の掲載時間に置換
          return String(data.published_time);

        case 10:
          // エリア名
          // 案件のエリアの名前に置換
          return String(data.area_name);

        case 11:
          // 備考
          // 空欄に置換
          return String(data.comment);

        case 12:
          // ご請求月
          // 案件の計上月の「月」に置換
          return dayjs(data.billing_month).format("M月");

        case 13:
          // ご請求年月
          // 案件の計上月の「年月」に置換
          return dayjs(data.billing_month).format("YYYY年M月");

        case 14:
          // 入金予定年月
          // 計上月翌月の「年月」に置換
          return dayjs(data.billing_month)
            .add(1, "month")
            .format("YYYY年M月");
      }

      return "";
    },
    replaceTag: function(
      text: string,
      tag: string,
      index: number,
      replace: string
    ) {
      const indexOf = text.indexOf("[" + tag + "]");
      const before = text.substr(0, indexOf);
      let after = text.substr(indexOf);
      let afterTagEnd = after.indexOf("]");
      after = after.substr(afterTagEnd + 1);

      return before + replace + after;
    },
    copyText: function(text: string) {
      navigator.clipboard.writeText(text);
    }
  }
});
