export function compare<T, K>(
  fn: (_: T) => K,
  isSortByAsc: boolean
): (x: T, y: T) => number {
  return function(a: T, b: T) {
    const A = fn(a);
    const B = fn(b);
    if (isSortByAsc) {
      if (A < B) return -1;
      if (A > B) return 1;
    } else {
      if (A > B) return -1;
      if (A < B) return 1;
    }
    return 0;
  };
}
