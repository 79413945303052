






































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import dayjs, { Dayjs } from "dayjs";
import Datepick from "@/components/calendars/Datepicker.vue";

import { BModal } from "bootstrap-vue";

import StaffDetailModal from "@/components/modals/StaffDetailModal.vue";
import AdPlacementTimeModal from "@/components/modals/AdPlacementTimeModal.vue";
import PublishedTimeModal from "@/components/modals/PublishedTimeModal.vue";
import AnimationSecondsModal from "@/components/modals/AnimationSecondsModal.vue";
import DraftDetailModal from "@/components/modals/DraftDetailModal.vue";

import {
  draftStatuses,
  DraftStatus,
  DraftHeaderStatus,
  draftHeaderStatuses,
  getDraftStatusCodeByName
} from "@/helpers/draftStatus";
import { productions, Production } from "@/helpers/productions";
import { workPlanTypes, WorkPlanType } from "@/helpers/workPlanType";
import { publicationTimes, PublicationTime } from "@/helpers/publicationTime";

import { Client } from "@/repositories/clients";
import { User } from "@/repositories/users";
import { Area } from "@/repositories/areas";
import { Media } from "@/repositories/medias";
import DraftDealRepository, { DraftDeal } from "@/repositories/draftDeals";
import * as AuthHelper from "@/helpers/auth";
import {
  DraftSheetStatus,
  draftSheetStatuses,
  getDraftSheetStatusCodeByName
} from "@/helpers/draftSheetStatus";
import { ceAndHtmlMediaIds } from "@/helpers/adMedia";

type DataType = {
  loading: boolean;
  isStaffDetailShown: boolean;
  isTimeShown: boolean;
  isPublishedTimeShown: boolean;
  isAnimationShown: boolean;
  isDetailShown: boolean;
  currentFromDate: Dayjs;
  currentToDate: Dayjs;
  selectedDraftDeals: number[];
  statuses: DraftStatus[];
  workPlanTypes: WorkPlanType[];
  productions: Production[];
  publicationTimes: PublicationTime[];
  header: {
    staff: number;
    windowStaff: number;
    client: number;
    media: number;
    area: number;
    workType: number;
    statusName: string;
  };
  drafts: DraftDeal[];
  draft?: DraftDeal;
  dealId: number;
  draft_id: number;
  detailValue: string;
  isEdit: boolean;
  targetAdAreaIds: number[];
  draftSheetStatuses: DraftSheetStatus[];
  draftHeaderStatuses: DraftHeaderStatus[];
};

export default Vue.extend({
  components: {
    Datepick,
    StaffDetailModal,
    AdPlacementTimeModal,
    PublishedTimeModal,
    AnimationSecondsModal,
    DraftDetailModal
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      loading: true,
      isStaffDetailShown: false,
      isTimeShown: false,
      isPublishedTimeShown: false,
      isAnimationShown: false,
      isDetailShown: false,
      currentFromDate: dayjs(),
      currentToDate: dayjs(),
      selectedDraftDeals: [],
      statuses: draftStatuses,
      productions: productions,
      workPlanTypes: workPlanTypes,
      publicationTimes: publicationTimes,
      header: {
        staff: 0,
        windowStaff: 0,
        client: 0,
        media: 0,
        area: 0,
        workType: 0,
        statusName: ""
      },
      drafts: [],
      draft: undefined,
      dealId: 0,
      draft_id: 0,
      detailValue: "",
      isEdit: false,
      targetAdAreaIds: ceAndHtmlMediaIds(), // CE(終日)、CE(調整枠)、CE(夜帯)、GGHTML、GGHTML(調整枠)
      draftSheetStatuses: draftSheetStatuses, // 入稿書ステータス
      draftHeaderStatuses: draftHeaderStatuses // ヘッダー表示用ステータス一覧
    };
  },
  props: {
    isOver: {
      type: Boolean,
      required: false
    },
    isProductioning: {
      type: Boolean,
      required: false
    },
    isFirstActioning: {
      type: Boolean,
      required: false
    },
    isProgressed: {
      type: Boolean,
      required: false
    },
    clients: {
      type: Array as PropType<Client[]>,
      required: false
    },
    users: {
      type: Array as PropType<User[]>,
      required: false
    },
    areas: {
      type: Array as PropType<Area[]>,
      required: false
    },
    medias: {
      type: Array as PropType<Media[]>,
      required: false
    }
  },
  computed: {
    isDraftEditable: function() {
      return AuthHelper.isDraftEditable();
    }
  },
  watch: {
    isProductioning: function() {
      this.setDrafts();
    },
    isFirstActioning: function() {
      this.setDrafts();
    },
    isProgressed: function() {
      this.setDrafts();
    }
  },
  methods: {
    shownDraftDealGroupModal: function(draftDeal: DraftDeal) {
      this.$emit("shownGroupModal", false, draftDeal);
    },
    searchDates: function(from: Dayjs, to: Dayjs) {
      this.currentFromDate = from;
      this.currentToDate = to;
      this.setDrafts();
    },
    setDrafts: async function() {
      // ローディング開始
      this.$emit("loading");
      this.drafts = [];
      // 検索条件の設定
      let searchData = this.setSearchData();
      try {
        const drafts = await DraftDealRepository.search(searchData);
        if (drafts.length > 0) {
          drafts.forEach(draft => {
            // IPG担当者（窓口）
            if (draft.window_ipg_staff) {
              let window_ipg_staff_id = draft.window_ipg_staff.id;
              if (window_ipg_staff_id == null && draft.client) {
                window_ipg_staff_id = draft.client.ipg_staff_user_id;
              }
              draft.window_ipg_staff.id = window_ipg_staff_id;
            }
            //エリアが未設定のデータ
            if (draft.ad_area === undefined) {
              draft.ad_area = { id: undefined, name: "" };
            }
          });
          this.drafts = drafts;
          if (this.isOver) {
            let shown = drafts.length > 0 ? true : false;
            this.$emit("shown", shown);
          }
        }
      } catch (e) {
        console.log("入稿カレンダー一覧取得に失敗しました", e);
      } finally {
        // ローディングを停止
        this.$emit("loading");
        // 長期グループ設定ボタン表示フラグ判定用に入稿カレンダーレコードの表示数を親コンポーネント側でカウント。
        if (this.drafts.length >= 1) {
          this.$emit("draftRecordCountUp", this.drafts.length);
        }
      }
    },
    setSearchData: function() {
      // ヘッダーにて選択されたステータス名から入稿カレンダーと入稿書のステータスに該当するコード値を検索(見つからなかった場合には0を代入)
      let draftStatusCode = getDraftStatusCodeByName(this.header.statusName);
      let draftSheetStatusCode = getDraftSheetStatusCodeByName(
        this.header.statusName
      );
      let searchData = {
        from: this.currentFromDate.format("YYYY-MM-DD"),
        to: this.currentToDate.format("YYYY-MM-DD"),
        staff: this.header.staff,
        window_staff: this.header.windowStaff,
        client: this.header.client,
        media: this.header.media,
        area: this.header.area,
        work_plan_type: this.header.workType,
        draft_status: draftStatusCode,
        draft_sheet_status: draftSheetStatusCode,
        next_action: false,
        is_over: this.isOver,
        is_progressed: this.isProgressed
      };
      // 制作中のみ表示する
      if (this.isProductioning) {
        searchData.from = this.currentFromDate
          .add(-9, "year")
          .format("YYYY-MM-DD");
        searchData.to = this.currentToDate.add(1, "year").format("YYYY-MM-DD");
        searchData.draft_status = 4;
      }
      // 初稿出しのみ表示する
      if (this.isFirstActioning) {
        searchData.from = this.currentFromDate
          .add(-9, "year")
          .format("YYYY-MM-DD");
        searchData.to = this.currentToDate.add(1, "year").format("YYYY-MM-DD");
        searchData.next_action = true;
      }
      return searchData;
    },
    setStyle: function(draft: DraftDeal) {
      let style = "font-size: 10px; ";
      if (draft.hold) {
        // 保留状態
        style += "background: green; color: white; ";
      } else if (
        draft.status === 2 ||
        (draft.latest_draft_sheet_status &&
          draft.latest_draft_sheet_status !== 1)
      ) {
        // ステータスが確定済以降のもの(入稿書レコードが存在しつつ、入稿完了ではないもの)
        style += "background: #e0e0e0; ";
      } else if (draft.status === 1) {
        // ステータスが入稿完了
        style += "background: #b8b8b8; ";
      } else if (dayjs().format("YYYY-MM-DD") === draft.material_fixed_date) {
        // 今日が素材確定日
        style += "background: #fff2cc; ";
      } else if (dayjs().isAfter(dayjs(draft.material_fixed_date))) {
        // 今日よりも素材確定日が過去
        style += "background: #ffcc66; ";
      }
      return style;
    },
    setPublicationTime: function(draft: DraftDeal) {
      let time = "";
      let find = this.publicationTimes.find(
        x => x.code === draft.publication_time
      );
      if (find != undefined) {
        if (find.code < 3) {
          time = find.name;
        } else {
          // その他の場合はフリーワードを表示
          time = draft.publication_time_other;
        }
      }
      return time;
    },
    setWorkType: function(draft: DraftDeal) {
      let plan = this.workPlanTypes.find(x => x.code === draft.work_plan_type);
      return plan != undefined ? plan.name : "";
    },
    setDate: function(draft: DraftDeal) {
      return dayjs(draft.published_date_from).format("MM/DD(dd)");
    },
    setTime: function(draft: DraftDeal) {
      let from = draft.published_time_from;
      let to = draft.published_time_to;
      if (!draft.published_time_to) {
        return from ? from : "";
      }
      return from ? from + " 〜 " + to : "--:-- " + "〜 " + to;
    },
    draftHold: async function(hold: boolean) {
      await this.selectedDraftDeals.forEach(id => {
        const draft = this.drafts.find(x => x.id === id);
        if (draft != undefined) {
          draft.hold = hold;
          this.update(draft);
        }
      });
      this.selectedDraftDeals = [];
      await this.setDrafts();
    },
    draftGrouped: function(): DraftDeal[] {
      const draftDeals: DraftDeal[] = [];
      this.selectedDraftDeals.forEach(id => {
        const draft = this.drafts.find(x => x.id === id);
        if (draft) draftDeals.push(draft);
      });
      return draftDeals;
    },
    changeDraft: async function(draft: DraftDeal) {
      if (confirm("入稿カレンダーを更新します。よろしいですか。")) {
        await this.update(draft);
        this.onReload();
      }
    },
    update: async function(draft: DraftDeal) {
      await DraftDealRepository.update(draft);
    },
    showStaffsModal: async function(draft: DraftDeal) {
      if (!this.isDraftEditable) return;
      this.isStaffDetailShown = true;
      this.draft = draft;
      (this.$refs.staffDetailModal as BModal).show();
    },
    showDetailModal(draft: DraftDeal, val: string, isEdit = false) {
      this.draft = draft;
      this.detailValue = val;
      this.isEdit = isEdit;
      this.isDetailShown = true;
      (this.$refs.draftDetailModal as BModal).show();
    },
    closeForm() {
      this.isStaffDetailShown = false;
      this.isTimeShown = false;
      this.isPublishedTimeShown = false;
      this.isAnimationShown = false;
      this.isDetailShown = false;
      this.onReload();
    },
    username(id: number) {
      const user = this.users.find(x => x.id === id);
      return user ? user.name : "";
    },
    statusText(code: number) {
      const status = this.statuses.find(x => x.code === code);
      return status ? status.name : "";
    },
    onReload: function() {
      this.$emit("reload");
    }
  }
});
