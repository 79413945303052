import repository from "@/repositories/repository";

export type Client = {
  id?: number;
  name: string;
  abbreviation: string;
  client_attribute_id: number;
  material_fixed_required_days: number;
  ad_placement_time_type: number;
  legacy_model_animation_seconds: number;
  new_model_animation_seconds: number;
  other_notes: string;
  ipg_staff_user: {
    id: number;
    name?: string;
  };
  staff_selection_type: number;
  registration_code: string;
  disabled: number;
  staff: [];
};

type Query = {
  id?: number;
  name: string;
  abbreviation: string;
  client_attribute_id: number;
  material_fixed_required_days: number;
  ad_placement_time_type: number;
  legacy_model_animation_seconds: number;
  new_model_animation_seconds: number;
  other_notes: string;
  ipg_staff_user: {
    id: number;
    name?: string;
  };
  staff_selection_type: number;
  registration_code: string;
  disabled: number;
};

export default {
  list: async (token: string, query?: Query): Promise<Client[]> => {
    const response = await repository(token).get("/api/clients", {
      params: { ...query }
    });
    return response.data;
  },

  actives: async (token: string): Promise<Client[]> => {
    const response = await repository(token).get("/api/client/actives");
    return response.data;
  },

  get: async (id: number): Promise<Client> => {
    const response = await repository().get(`/api/clients/${id}`);
    return response.data;
  },

  store: async (data: any, token: string): Promise<Client> => {
    const response = await repository(token).post(`/api/clients`, {
      data: data
    });
    return response.data;
  },

  update: async (id: number, data: Client, token: string): Promise<void> => {
    const response = await repository(token).put(`/api/clients/${id}`, {
      data: data
    });
    return response.data;
  },

  searchActive: async (
    clientName: string,
    token: string
  ): Promise<Client[]> => {
    const response = await repository(token).get(
      `/api/clients/search/active/${clientName}`
    );
    return response.data;
  },

  search: async (clientName: string, token: string): Promise<Client[]> => {
    const response = await repository(token).get(
      `/api/clients/search/${clientName}`
    );
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    const response = await repository(token).delete(`/api/clients/${id}`);
  }
};

export const newClient: () => Client = () => ({
  id: 0,
  name: "",
  abbreviation: "",
  client_attribute_id: 0,
  material_fixed_required_days: 5,
  ad_placement_time_type: 1,
  legacy_model_animation_seconds: 0,
  new_model_animation_seconds: 0,
  other_notes: "",
  ipg_staff_user: {
    id: 0,
    name: ""
  },
  staff_selection_type: 0,
  registration_code: "",
  disabled: 0,
  staff: []
});
