
















































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import { Prefecture, prefectures } from "@/helpers/prefecture";
import RegistrationAreaRepository, {
  newRegistrationArea,
  RegistrationArea
} from "@/repositories/registrationArea";
import * as AuthHelper from "@/helpers/auth";
import { AxiosError } from "axios";

type DataType = {
  loading: boolean;
  registrationArea: RegistrationArea;
  registrationAreaId: number;
  prefectures: Prefecture[];
  selectPrefectures: number[];
  isError: boolean;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      registrationArea: newRegistrationArea(),
      registrationAreaId: this.id,
      prefectures: prefectures,
      selectPrefectures: [],
      isError: false,
      errorMsg: []
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.registrationArea = newRegistrationArea();
      this.selectPrefectures = [];
    },
    async postRegistrationArea() {
      this.isError = false;
      this.errorMsg = [];
      await this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await RegistrationAreaRepository.store(
        {
          name: this.registrationArea.name,
          prefectures: this.selectPrefectures,
          description: this.registrationArea.description
            ? this.registrationArea.description
            : null
        },
        "" /*TODO*/
      )
        .then(() => {
          (this.$refs.modal as BModal).hide();
          this.$emit("success_update");
          return this.$data;
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.errorMsg.push(
              "すでに選択した都道府県でエリアが登録されています。"
            );
          } else {
            this.errorMsg.push("エラーが発生しました。");
          }
        });
    },
    async updateRegistrationArea(id: number) {
      this.isError = false;
      this.errorMsg = [];
      await this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await RegistrationAreaRepository.update(
        id,
        {
          name: this.registrationArea.name,
          prefectures: this.selectPrefectures,
          description: this.registrationArea.description
            ? this.registrationArea.description
            : ""
        },
        "" /*TODO*/
      )
        .then(() => {
          (this.$refs.modal as BModal).hide();
          this.$emit("success_update");
          return this.$data;
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.errorMsg.push(
              "すでに選択した都道府県でエリアが登録されています。"
            );
          } else {
            this.errorMsg.push("エラーが発生しました。");
          }
        });
    },
    async deleteRegistrationArea(id: number) {
      let action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      await RegistrationAreaRepository.destroy(id, "" /*TODO*/);
      (this.$refs.modal as BModal).hide();
      this.$emit("delete");
    },
    validation: async function() {
      if (
        this.registrationArea.name === null ||
        this.registrationArea.name === ""
      ) {
        this.errorMsg.push("登録用エリア名を入力してください。");
      }
      if (this.selectPrefectures.length < 1) {
        this.errorMsg.push("都道府県を１つ以上選択してください。");
      }
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        let registrationArea = await RegistrationAreaRepository.get(
          this.id,
          ""
        );
        this.selectPrefectures = registrationArea.prefectures;
        this.registrationArea.id = registrationArea.id;
        this.registrationArea.name = registrationArea.name;
        this.registrationArea.description = registrationArea.description;
      }
      this.loading = false;
    }
  }
});
