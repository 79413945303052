

































































































import Vue, { PropType } from "vue";
import { DraftDeal, newDraftDeal } from "@/repositories/draftDeals";
import {
  CeDraftDetail,
  HtmlDraftDetail,
  newCeDraftDetail,
  newHtmlDraftDetail
} from "@/repositories/draftSheet";

export default Vue.extend({
  name: "DraftHeader",
  props: {
    draftDeal: {
      type: Object as PropType<DraftDeal>,
      required: false,
      default: () => {
        newDraftDeal();
      }
    },
    ceDraftDetail: {
      type: Object as PropType<CeDraftDetail>,
      required: false,
      default: () => {
        newCeDraftDetail();
      }
    },
    htmlDraftDetail: {
      type: Object as PropType<HtmlDraftDetail>,
      required: false,
      default: () => {
        newHtmlDraftDetail();
      }
    },
    isCe: {
      type: Boolean,
      required: false,
      default: false
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
