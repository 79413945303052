






















































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewAreaModal from "@/components/NewAreaModal.vue";

import AreaRepository, { Area } from "@/repositories/areas";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  adAreas: Area[];
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
  fetched: boolean;
  success: boolean;
  updateArea: boolean;
  deleteArea: boolean;
  areaId: number;
  searchArea: string;
};

export default Vue.extend({
  components: {
    Menu,
    NewAreaModal
  },
  data: function(): DataType {
    return {
      adAreas: [],
      isFormShown: false,
      isNew: true,
      loading: false,
      fetched: false,
      success: false,
      updateArea: false,
      deleteArea: false,
      areaId: 0,
      searchArea: ""
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.adAreas && this.adAreas.length > 0;
    },
    isAdAreaWritable: function(): boolean {
      return AuthHelper.isAdAreaWritable();
    }
  },
  methods: {
    showNewForm() {
      this.areaId = 0;
      this.isNew = true;
      this.showModal();
    },
    showEditForm(id: number) {
      this.areaId = id;
      this.isNew = false;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newAreaModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function() {
      this.success = true;
      this.adAreas = await AreaRepository.list("");
    },
    onSuccessUpdate: async function() {
      this.updateArea = true;
      this.adAreas = await AreaRepository.list("");
    },
    onDelete: async function() {
      this.deleteArea = true;
      this.adAreas = await AreaRepository.list("");
    },
    onSearch: async function() {
      this.loading = true;
      if (this.searchArea != null && this.searchArea != "") {
        this.adAreas = await AreaRepository.list3(this.searchArea, "");
      } else {
        this.adAreas = await AreaRepository.list("");
      }
      this.loading = false;
    }
  },
  mounted: async function() {
    this.loading = true;
    this.adAreas = await AreaRepository.list("");
    this.loading = false;
    this.fetched = true;
  }
});
