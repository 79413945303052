





































































































































































































































































































































import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";

import SalesChart from "@/components/charts/SalesChart.vue";

import { allSaleGraph, SaleGraph } from "@/helpers/saleGraph";

import FiscalYearRepository, { FiscalYear } from "@/repositories/fiscalYear";
import AdMediaMgtRepository, { ItemMgt } from "@/repositories/itemManagements";
import YomiRepository, { Yomi } from "@/repositories/yomi";
import UserRepository, { User } from "@/repositories/users";

import * as AuthHelper from "@/helpers/auth";

type FiscalYears = {
  quarter: number;
  year: number;
  month: number;
  fiscal_year?: number;
};

type DataType = {
  loading: boolean;
  users: User[];
  user?: User;
  mgtMedias: ItemMgt[];
  fiscalYears: FiscalYears[];
  saleGraphs: SaleGraph[];
  is_quarter: { max: boolean; min: boolean };
  quarter: number;
  date: dayjs.Dayjs;
  today: dayjs.Dayjs;
  currentDate: dayjs.Dayjs;
  selects: {
    from: { year: number; month: number };
    to: { year: number; month: number };
    user_id?: number;
  };
  sales?: any;
  saleMedias?: any;
  saleMediaDefs: {
    code: string;
    name: string;
  }[];
  befores?: number[];
  goals: {
    user?: number[];
  };
};

export default Vue.extend({
  components: {
    SalesChart
  },
  props: {
    isMgt: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canDownload: function() {
      return AuthHelper.isAdminSalesEditor();
    }
  },
  watch: {
    isUpdate: async function() {
      if (this.isUpdate) {
        await this.setSales();
        this.$emit("reset");
      }
    }
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      loading: false,
      user: undefined,
      users: [],
      saleGraphs: allSaleGraph,
      is_quarter: { max: false, min: false },
      today: now,
      currentDate: now,
      quarter: 0,
      date: now,
      selects: {
        from: { year: 0, month: 0 },
        to: { year: 0, month: 0 },
        user_id: undefined
      },
      sales: undefined,
      saleMedias: undefined,
      saleMediaDefs: [
        {
          code: "budget",
          name: "予算"
        },
        {
          code: "J",
          name: "実績"
        },
        {
          code: "JNalpha",
          name: "J+M+A+B+Nα"
        }
      ],
      befores: undefined,
      goals: { user: undefined },
      fiscalYears: [],
      mgtMedias: []
    };
  },
  mounted: async function() {
    this.loading = true; // ローディング開始
    this.users = await UserRepository.list(""); // 有効なユーザー一覧を取得
    this.user = await UserRepository.currentUser(""); // ログイン中のユーザーを取得
    this.mgtMedias = await AdMediaMgtRepository.list(""); // 有効な商品一覧を取得
    await this.setFiscalyear(); // 事業年度を設定
    await this.setSales(); // 予算と実績を設定
  },
  methods: {
    setFiscalyear: async function() {
      let begins = [];
      let year = dayjs().year() - 9;
      let i = 0;
      while (i <= 10) {
        begins.push(year + i);
        i++;
      }
      const fiscalYears = await FiscalYearRepository.store(begins, "");
      let result: FiscalYears[] = [];
      fiscalYears.forEach(year => {
        let beginYear = Number(year.begin.split("-")[0]);
        let beginMonth = Number(year.begin.split("-")[1]);
        let currentDate = dayjs().set("year", beginYear);
        currentDate = currentDate.set("month", beginMonth - 1);
        let endYear = Number(year.end.split("-")[0]);
        let endMonth = Number(year.end.split("-")[1]);
        let quarter = 1;
        let index = 1;
        while (quarter <= 4 && index <= 3) {
          result.push({
            quarter: quarter,
            year: Number(currentDate.format("YYYY")),
            month: Number(currentDate.format("MM")),
            fiscal_year: beginYear
          });
          if (
            Number(currentDate.format("YYYY")) === endYear &&
            Number(currentDate.format("MM")) == endMonth
          ) {
            break;
          }
          currentDate = currentDate.add(1, "month");
          if (index == 3) {
            quarter++;
            index = 1;
          } else {
            index++;
          }
        }
      });
      this.fiscalYears = result;
    },
    getEven: function(index: number) {
      return index % 2 != 0 ? true : false;
    },
    getScore: function(score: any, type: string, index = 0) {
      // 通年・クォーター・各月ごとのグラフ 売上集計（予算・実績）
      let result = {
        label: "",
        budget: 0,
        pointJ: 0,
        pointM: 0,
        pointA: 0,
        pointB: 0,
        pointNα: 0,
        pointC: 0,
        pointD: 0,
        pointN: 0
      };
      if (!this.fiscalYears || !score) return "";
      let actualScores = undefined;
      let goalScore = undefined;
      switch (type) {
        case "fiscalYear":
          // 通年
          result.label = "通年";
          actualScores = score[type]["sum"]["actual"];
          goalScore = score[type]["sum"]["budget"];
          break;
        case "quarter":
          // クォーター
          result.label = score[type]["q"] + "Q";
          actualScores = score[type]["sum"]["actual"];
          goalScore = score[type]["sum"]["budget"];
          break;
        case "months":
          // 各月(1月目〜3月目)
          const month =
            dayjs(`${score[type][index]["yearMonth"]}-01`).month() + 1;
          result.label = month + "月";
          actualScores = score[type][index]["sum"]["actual"];
          goalScore = score[type][index]["sum"]["budget"];
          break;
      }
      // 売上集計（実績）
      if (actualScores) {
        result.pointJ = actualScores["J"];
        result.pointM = actualScores["M"];
        result.pointA = actualScores["A"];
        result.pointB = actualScores["B"];
        result.pointNα = actualScores["Nalpha"];
        result.pointC = actualScores["C"];
        result.pointD = actualScores["D"];
        result.pointN = actualScores["N"];
      }
      // 売上集計（予算）
      if (goalScore) {
        result.budget = goalScore;
      }
      return result;
    },
    getDefsScoreList: function(
      type: string,
      monthsIndex: number | null,
      rowIndex: number
    ) {
      if (!this.sales) return;

      // 通年・クォーター・各月ごとの一覧 売上集計（予算・実績）
      const score =
        type === "months"
          ? this.sales[type][monthsIndex ?? 0]
          : this.sales[type];

      const scorePreviousYear =
        type === "months"
          ? this.sales["previousYear"][type][monthsIndex ?? 0]
          : this.sales["previousYear"][type];
      switch (rowIndex) {
        case 0:
          // 予算
          if (score && score.sum.budget) {
            return score.sum.budget.toLocaleString();
          }
          break;
        case 2:
          // 達成率（実績 / 予算)
          // 予算がないときは0%
          if (score && score.sum.achievement_rate.J) {
            return score.sum.achievement_rate.J + "%";
          }
          return "0%";
          break;
        case 3:
          // Nαまでの達成率(J+M+A+B+Nαの実績 / 予算)
          // 予算がないときは0%
          if (score && score.sum.achievement_rate.J_Na) {
            return score.sum.achievement_rate.J_Na + "%";
          }
          return "0%";
          break;
        case 4:
          // 残(予算 - 実績)
          // 予算がないときは0円
          if (score && score.sum.budget && score.sum.balance) {
            if (score.sum.balance < 0) {
              // 予算 < 実績 の場合残は0円で表示
              return 0;
            }
            return score.sum.balance.toLocaleString();
          }
          break;
        case 5:
          // J+M+Aの実績
          if (score && score.sum.actual.J_M_A) {
            return score.sum.actual.J_M_A.toLocaleString();
          }
          break;
        case 6:
          // J+M+A+B+Nαの実績
          if (score && score.sum.actual.J_M_A_B_Nalpha) {
            return score.sum.actual.J_M_A_B_Nalpha.toLocaleString();
          }
          break;
        case 1:
        case 7:
          // 実績
          // Jの実績
          if (score && score.sum.actual.J) {
            return score.sum.actual.J.toLocaleString();
          }
          break;
        case 8:
          // Mの実績
          if (score && score.sum.actual.M) {
            return score.sum.actual.M.toLocaleString();
          }
          break;
        case 9:
          // Aの実績
          if (score && score.sum.actual.A) {
            return score.sum.actual.A.toLocaleString();
          }
          break;
        case 10:
          // Bの実績
          if (score && score.sum.actual.B) {
            return score.sum.actual.B.toLocaleString();
          }
          break;
        case 11:
          // Nαの実績
          if (score && score.sum.actual.Nalpha) {
            return score.sum.actual.Nalpha.toLocaleString();
          }
          break;
        case 12:
          // Cの実績
          if (score && score.sum.actual.C) {
            return score.sum.actual.C.toLocaleString();
          }
          break;
        case 13:
          // Dの実績
          if (score && score.sum.actual.D) {
            return score.sum.actual.D.toLocaleString();
          }
          break;
        case 14:
          // Nの実績
          if (score && score.sum.actual.N) {
            return score.sum.actual.N.toLocaleString();
          }
          break;
        case 15:
          // オチの実績
          if (score && score.sum.actual.Ochi) {
            return score.sum.actual.Ochi.toLocaleString();
          }
          break;
        case 16:
          // 前年の売上
          if (scorePreviousYear && scorePreviousYear.sum.actual.J) {
            return scorePreviousYear.sum.actual.J.toLocaleString();
          }
          break;
      }
      return 0;
    },
    getMediasScoreList: function(
      type: string,
      mediaIndex: number,
      def: string,
      monthIndex = 0
    ) {
      // 通年・クォーター
      const id = this.mgtMedias[mediaIndex].id;
      // saleMediasのデータから実績スコアを取得している
      if (this.saleMedias && this.saleMedias[type] && id) {
        const score =
          type === "months"
            ? this.saleMedias[type][monthIndex][id]
            : this.saleMedias[type]["sum"][id];
        return score && score["sum"][def]
          ? score["sum"][def].toLocaleString()
          : 0;
      }
      return 0;
    },
    getQuater: function(year: number, month: number) {
      let fiscalyear = this.fiscalYears.find(
        x => x.year == year && x.month == month
      );
      return fiscalyear ? fiscalyear.quarter : 0;
    },
    getYear: function(date: Dayjs) {
      let fiscalYear = this.fiscalYears.find(
        x => x.month === date.month() + 1 && x.year === date.year()
      );
      if (!fiscalYear) return;
      let year = fiscalYear.year;
      if (fiscalYear.year <= 2020 && fiscalYear.quarter === 4) {
        year = year - 1;
      }
      return year;
    },
    addMonth: function(month: number) {
      this.is_quarter.max = false;
      this.is_quarter.min = false;
      this.date = this.date.add(month, "month").startOf("month");
      let fiscalYear = this.fiscalYears.find(
        x => x.month === this.date.month() + 1 && x.year === this.date.year()
      );
      if (fiscalYear == undefined) return false;
      if (
        this.date.year() === this.today.year() + 1 &&
        fiscalYear.quarter === 4
      ) {
        this.is_quarter.max = true;
      } else if (
        this.date.year() === this.today.year() - 9 &&
        fiscalYear.quarter === 1
      ) {
        this.is_quarter.min = true;
      }
      this.setSales(); // 月を変更するタイミングで予実データ更新
    },
    setSelectsDate: function() {
      // CSV出力の期間の値をグラフに表示中のクオーターの期間で補完 (From/To それぞれの年月)
      let dates = this.setPostyears(0, "YYYY-MM-DD");
      this.selects.from.year = dayjs(dates[1].from).year();
      this.selects.from.month = dayjs(dates[1].from).month() + 1;
      this.selects.to.year = dayjs(dates[1].to).year();
      this.selects.to.month = dayjs(dates[1].to).month() + 1;
    },
    // mounted・月の更新・ユーザーの選択で呼ばれる予実データの取得処理
    setSales: async function() {
      this.loading = true;
      this.$emit("loading");
      if (!this.isMgt && this.user) {
        this.selects.user_id = this.user.id;
      }
      // ヨミ表から実績の取得、併せて売上目標のデータも別テーブルから併せて取得
      const saleDefs = await YomiRepository.summaryActual("", {
        is_def: true,
        user_id: this.selects.user_id,
        fiscalYear: this.getYear(this.date),
        quarter: this.getQuater(this.date.year(), this.date.month() + 1)
      });
      this.sales = saleDefs;
      // ヨミ表から商品ごとの実績を取得、併せて売上目標データも別テーブルから併せて取得
      const saleMedias = await YomiRepository.summaryActualByAdMedia("", {
        is_def: false,
        user_id: this.selects.user_id,
        fiscalYear: this.getYear(this.date),
        quarter: this.getQuater(this.date.year(), this.date.month() + 1)
      });
      this.saleMedias = saleMedias;
      this.setSelectsDate();
      this.$emit("loading");
      this.loading = false;
    },
    setPostyears: function(add: number, format: string) {
      let post: { from: string; to: string }[] = [];
      const fiscalYear = this.getYear(this.date) || this.date.year(); // 事業年度を現在の日付から取得
      // DBから取得した全ての事業年度(数年分)一覧の中から現在の年度と合うものを取得
      let fiscalyears = this.fiscalYears.filter(
        x => x.fiscal_year === fiscalYear + add
      );
      if (fiscalyears == undefined) {
        return [];
      }
      // 現在にあたる事業年度の開始年月日を取得
      let from = dayjs()
        .year(fiscalyears[0].year)
        .month(fiscalyears[0].month - 1)
        .date(1);
      // 現在にあたる事業年度の終了年月日を取得
      let to = dayjs()
        .year(fiscalyears[fiscalyears.length - 1].year)
        .month(fiscalyears[fiscalyears.length - 1].month - 1);
      to = to.date(to.endOf("month").date());
      // 通年
      post.push({
        from: from.format(format),
        to: to.format(format)
      });
      // クォーター
      let quarter = this.getQuater(this.date.year(), this.date.month() + 1);
      let current = fiscalyears.filter(x => x.quarter == quarter);
      if (current.length < 1) {
        return [];
      }
      from = dayjs()
        .year(current[0].year)
        .month(current[0].month - 1)
        .date(1);
      to = dayjs()
        .year(current[current.length - 1].year)
        .month(current[current.length - 1].month - 1);
      to = to.date(to.endOf("month").date());
      post.push({
        from: from.format(format),
        to: to.format(format)
      });
      // 各月
      current.forEach(current => {
        from = dayjs()
          .year(current.year)
          .month(current.month - 1)
          .date(1);
        to = from;
        to = to.date(to.endOf("month").date());
        post.push({
          from: from.format(format),
          to: to.format(format)
        });
      });
      return post;
    },
    csvDownload: async function() {
      let yearMonthFrom = dayjs(
        new Date(this.selects.from.year, this.selects.from.month - 1, 1)
      ).format("YYYY-MM");
      let yearMonthTo = dayjs(
        new Date(this.selects.to.year, this.selects.to.month - 1, 1)
      ).format("YYYY-MM");
      const csv = await YomiRepository.download("", {
        from: yearMonthFrom,
        to: yearMonthTo,
        user_id: this.selects.user_id
      });
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download = "sales_" + dayjs().format("YYYYMMDD") + ".csv";
      link.click();
      URL.revokeObjectURL(url);
      if (link && link.parentNode) {
        link.parentNode.removeChild(link);
      }
    },
    setCsvDate: function() {
      let dates: { from: string; to: string }[] = [];
      let from = dayjs()
        .year(this.selects.from.year)
        .month(this.selects.from.month - 1)
        .date(1);
      let to = dayjs()
        .year(this.selects.to.year)
        .month(this.selects.to.month - 1)
        .date(1);
      if (to.isBefore(from)) {
        alert("日付の選択を開始よりも終了を未来の日付にしてください。");
        return dates;
      }
      if (from.isBefore("2022-01-01") && to.isAfter("2021-12-31")) {
        alert(
          "2021年度以前と2022年度以降のデータを同時に選択しないでください。"
        );
        return dates;
      }
      while (to.isAfter(from)) {
        dates.push({
          from: from.date(1).format("YYYY-MM-DD"),
          to: from.date(from.endOf("month").date()).format("YYYY-MM-DD")
        });
        from = from.add(1, "month");
      }
      dates.push({
        from: from.date(1).format("YYYY-MM-DD"),
        to: from.date(from.endOf("month").date()).format("YYYY-MM-DD")
      });
      return dates;
    },
    setHeader: function(dates: { from: string; to: string }[]) {
      let header = "\ufeff,,";
      dates.forEach(date => {
        header += "," + dayjs(date.from).format("M月");
      });
      return header + "\n";
    }
  }
});
