import repository from "@/repositories/repository";

export type AgencyStaff = {
  id: number;
  sales_channel_id?: number;
  name: string;
  email: string;
  tel: string;
  staff_type: number;
  disabled: number;
};

type Query = {
  id?: number;
  client_id: number;
  name: string;
  email: number;
  tel: string;
  staff_type: number;
  disabled: number;
};

export default {
  list: async (
    salesChannelId: number,
    token: string
  ): Promise<AgencyStaff[]> => {
    const response = await repository(token).get(
      `/api/agency/staffs/search/${salesChannelId}`
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<AgencyStaff> => {
    const response = await repository(token).get(`/api/agency/staffs/${id}`);
    return response.data;
  },

  store: async (data: any, token: string): Promise<AgencyStaff[]> => {
    const response = await repository(token).post(`/api/agency/staffs`, {
      data: data
    });
    return response.data;
  },

  update: async (data: AgencyStaff, token: string): Promise<AgencyStaff[]> => {
    const response = await repository(token).put(
      `/api/agency/staffs/${data.id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<AgencyStaff[]> => {
    const response = await repository(token).delete(`/api/agency/staffs/${id}`);
    return response.data;
  }
};

export const newAgencyStaff: () => AgencyStaff = () => ({
  id: 0,
  sales_channel_id: undefined,
  name: "",
  email: "",
  tel: "",
  staff_type: 0,
  disabled: 0
});
