






































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import AgencyStaffRepository, {
  AgencyStaff
} from "@/repositories/agencyStaffs";
import ClientStaffsRepository, {
  ClientStaff
} from "@/repositories/clientStaffs";
import DealRepository, { Deal } from "@/repositories/deals";
import ClientRepository, { Client } from "@/repositories/clients";
import BusinessPartnerStaffRepository, {
  BusinessPartnerStaff
} from "@/repositories/businessPartnerStaffs";

import NewStaffTable from "@/components/modals/StaffDetailModal/NewStaffTable.vue";
import NewEditTable from "@/components/modals/StaffDetailModal/NewEditTable.vue";
import { DraftDeal } from "@/repositories/draftDeals";

type DataType = {
  agencyStaffs: AgencyStaff[];
  clientStaffs: ClientStaff[];
  edit: { client?: ClientStaff[]; agency?: AgencyStaff[] };
  businessPartnerStaffs?: BusinessPartnerStaff;
  loading: boolean;
  success: boolean;
  isEditMode: boolean;
  mode: number;
};

export default Vue.extend({
  components: {
    NewStaffTable,
    NewEditTable
  },
  data: function(): DataType {
    return {
      agencyStaffs: [],
      clientStaffs: [],
      edit: { client: undefined, agency: undefined },
      businessPartnerStaffs: undefined,
      loading: true,
      success: false,
      isEditMode: false,
      mode: 0
    };
  },
  props: {
    draft: {
      type: Object as PropType<DraftDeal>,
      required: false
    }
  },
  watch: {
    draft: function(val, oldVal) {
      if (val != oldVal) {
        this.fetch();
        this.mode =
          this.draft && this.draft.client.staff_selection_type
            ? this.draft.client.staff_selection_type
            : 0;
      }
    }
  },
  methods: {
    show() {
      (this.$refs.modal as BModal).show();
    },
    resetData() {
      this.isEditMode = false;
      this.success = false;
      this.agencyStaffs = [];
      this.clientStaffs = [];
    },
    async fetchClientStaffs() {
      this.loading = true;
      const clientStaffs = await ClientStaffsRepository.list(
        this.draft.client.id,
        ""
      );
      this.clientStaffs = clientStaffs.sort(
        (a, b) => a.staff_type - b.staff_type
      );
      this.edit.client = this.clientStaffs;
      this.loading = false;
    },
    async fetchAgencyStaffs() {
      this.loading = true;
      if (this.draft.sales_channel == undefined) {
        this.loading = false;
        this.isEditMode = true;
        this.agencyStaffs = [];
        this.edit.agency = [];
        return false;
      }
      const agencyStaffs = await AgencyStaffRepository.list(
        this.draft.sales_channel.id,
        ""
      );
      this.agencyStaffs = agencyStaffs.sort(
        (a, b) => a.staff_type - b.staff_type
      );
      this.edit.agency = this.agencyStaffs;
      this.loading = false;
    },
    async fetch() {
      this.resetData();
      if (this.draft == undefined) {
        this.loading = false;
        return false;
      }
      await this.fetchClientStaffs();
      await this.fetchAgencyStaffs();
      await this.fetchBusinessPartners();
    },
    async fetchBusinessPartners() {
      this.loading = true;
      if (this.draft == undefined || this.draft.id === undefined) {
        this.loading = false;
        return;
      }
      const businessPartnerStaffs = await BusinessPartnerStaffRepository.search(
        "",
        {
          id: this.draft.id
        }
      );
      if (
        businessPartnerStaffs.client.length == 0 &&
        businessPartnerStaffs.agency.length == 0
      ) {
        this.loading = false;
        this.isEditMode = true;
        return;
      }
      this.mode = 4;
      this.isEditMode = false;
      this.clientStaffs = businessPartnerStaffs.client.sort(
        (a, b) => a.staff_type - b.staff_type
      );
      this.agencyStaffs = businessPartnerStaffs.agency.sort(
        (a, b) => a.staff_type - b.staff_type
      );
      this.loading = false;
    },
    store: async function() {
      var action = confirm("担当者情報を更新します。よろしいですか。");
      if (!action) return false;
      const businessPartnerStaffs = await BusinessPartnerStaffRepository.store(
        "",
        {
          id: this.draft.id,
          client: this.edit.client,
          agency: this.edit.agency
        }
      );
      this.success = true;
      this.fetch();
      this.$emit("onReload");
    }
  }
});
