


















































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import ClientStaffsRepository, {
  ClientStaff,
  newClientStaff
} from "@/repositories/clientStaffs";

import { StaffType, staffTypes } from "@/helpers/staffType";
import * as AuthHelper from "@/helpers/auth";

type Datatype = {
  loading: boolean;
  staffType: StaffType[];
  staff: ClientStaff;
  isError: boolean;
  errorMsg: string[];
};
export default Vue.extend({
  data: function(): Datatype {
    return {
      loading: false,
      staffType: staffTypes,
      staff: newClientStaff(),
      isError: false,
      errorMsg: []
    };
  },
  props: {
    isNew: {
      type: Boolean
    },
    id: {
      type: Number
    },
    clientId: {
      type: Number
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    isClientStaffWritable: function(): boolean {
      return AuthHelper.isClientStaffWritable();
    },
    isClientStaffDeletable: function(): boolean {
      return AuthHelper.isClientStaffDeletable();
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.isError = false;
      this.errorMsg = [];
      this.staff = newClientStaff();
      this.staff.client_id = this.clientId;
    },
    createStaff: async function() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ClientStaffsRepository.store(this.staff, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("success");
    },
    updateStaff: async function(id: number) {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ClientStaffsRepository.update(id, this.staff, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("update");
    },
    deleteStaff: async function(id: number) {
      var action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      await ClientStaffsRepository.destroy(id, "" /*TODO*/);
      (this.$refs.modal as BModal).hide();
      this.$emit("delete");
    },
    validation: function() {
      var msg: string[];
      msg = [];
      if (this.staff.name === null || this.staff.name === "") {
        msg.push("担当者名を入力してください。");
      }
      var validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.staff.email != null &&
        this.staff.email != "" &&
        !validEmail.test(this.staff.email)
      ) {
        msg.push("メールアドレスの形式が正しくありません。");
      }
      var validphone = /^[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$/;
      if (
        this.staff.tel != null &&
        this.staff.tel != "" &&
        !validphone.test(this.staff.tel)
      ) {
        msg.push("電話番号の形式が正しくありません。");
      }
      if (this.staff.staff_type === 0) {
        msg.push("担当者種別を選択してください。");
      }
      return msg;
    }
  },
  mounted: function() {
    this.staff.client_id = this.clientId;
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        var staff: ClientStaff;
        staff = await ClientStaffsRepository.get(this.id, "");
        if (staff != null) {
          this.staff = staff;
        }
      }
      this.loading = false;
    },
    clientId: function(oldValue, newValue) {
      this.staff.client_id = this.clientId;
    }
  }
});
