














































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";

import { PublicationTime, publicationTimes } from "@/helpers/publicationTime";

import DraftDealRepository, {
  DraftDeal,
  newDraftDeal
} from "@/repositories/draftDeals";

type DataType = {
  deal: DraftDeal;
  publicationTimes: PublicationTime[];
  selectTime: number;
  other: string;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      deal: newDraftDeal(),
      publicationTimes: publicationTimes,
      selectTime: 4,
      other: ""
    };
  },
  props: {
    draft: {
      type: Object as PropType<DraftDeal>,
      required: false
    }
  },
  watch: {
    draft: function() {
      this.deal = this.draft || newDraftDeal();
    }
  },
  methods: {
    show: async function() {
      (this.$refs.modal as BModal).show();
    },
    update: async function() {
      var action = confirm("入稿カレンダーを更新します。よろしいですか。");
      if (!action) return false;
      if (!this.deal) return false;
      // デフォルト値を反映の場合クライアント情報から取得
      if (this.deal.publication_time == 4) {
        this.deal.publication_time =
          this.deal.client.ad_placement_time_type ?? 0;
      }
      await DraftDealRepository.update(this.deal);
      (this.$refs.modal as BModal).hide();
      this.$emit("close");
    }
  },
  mounted: function() {
    this.deal = this.draft || newDraftDeal();
  }
});
