


















































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";

import DraftDealRepository, {
  DraftDeal,
  newDraftDeal
} from "@/repositories/draftDeals";

type DataType = {
  deal?: DraftDeal;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      deal: newDraftDeal()
    };
  },
  props: {
    draft: {
      type: Object as PropType<DraftDeal>,
      required: false
    }
  },
  watch: {
    draft: function() {
      this.deal = this.draft || newDraftDeal();
    }
  },
  methods: {
    show: async function() {
      (this.$refs.modal as BModal).show();
    },
    timeFormat: function(is_from: boolean) {
      var time = "";
      if (this.deal) {
        if (this.deal.published_time_from || this.deal.published_time_to) {
          if (is_from) {
            time = this.deal.published_time_from;
          } else {
            time = this.deal.published_time_to;
          }
          time = this.setHalfFont(time, ":");
          if (isNaN(Number(time))) time = "";
          if (time.length < 4) time = "0" + time;
          time = isNaN(Number(time))
            ? ""
            : time.substr(0, 2) + ":" + time.substr(2, 2);
          time = time.match(/^([0-2][0-9]):([0-5][0-9])$/) ? time : "";
          is_from
            ? (this.deal.published_time_from = time)
            : (this.deal.published_time_to = time);
        }
      }
    },
    setHalfFont: function(time: string, split: string) {
      if (time == "" || time == null) {
        return "";
      }
      var result = String(time)
        .split(split)
        .join("");
      // 全角入力された場合半角に自動で戻す
      result = result.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(font) {
        return String.fromCharCode(font.charCodeAt(0) - 0xfee0);
      });
      return result;
    },
    update: async function() {
      var action = confirm("入稿カレンダーを更新します。よろしいですか。");
      if (!action) return false;
      if (!this.deal) return false;
      await DraftDealRepository.update(this.deal);
      (this.$refs.modal as BModal).hide();
      this.$emit("close");
    }
  },
  mounted: function() {
    this.deal = this.draft || newDraftDeal();
  }
});
