


































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import dayjs from "dayjs";
import { AxiosError } from "axios";

import DealRepository, { Deal, newDeal } from "@/repositories/deals";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  deal?: Deal;
};

export default Vue.extend({
  props: {
    wakuDeal: {
      type: Object as PropType<Deal>,
      required: false
    }
  },
  data: function(): DataType {
    return {
      loading: false,
      deal: undefined
    };
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    isDealAdmin: function(): boolean {
      return AuthHelper.isDealAdmin();
    },
    editable: function(): boolean {
      const user = this.deal?.user;
      const isOwn = user?.id ? AuthHelper.isOwn(user.id) : false;
      return this.isDealAdmin || isOwn;
    }
  },
  watch: {
    wakuDeal: function() {
      this.loading = true;
      this.deal = this.wakuDeal ? Object.assign({}, this.wakuDeal) : newDeal();
      this.loading = false;
    }
  },
  methods: {
    show: function() {
      (this.$refs["modal"] as BModal).show();
    },
    hide: function() {
      (this.$refs["modal"] as BModal).hide();
    },
    getDate: function(date: string) {
      if (this.deal) {
        return dayjs(date).format("YYYY年M月D日");
      }
      return "";
    },
    getTime: function(deal?: Deal) {
      if (deal && deal.published_date.time) {
        var time = "";
        if (
          deal.published_date.time.from != null &&
          deal.published_date.time.from != ""
        ) {
          time += deal.published_date.time.from;
        }
        if (
          deal.published_date.time.to != null &&
          deal.published_date.time.to != ""
        ) {
          time += "〜" + deal.published_date.time.to;
        }
        return time;
      }
      return "";
    },
    getAttribute: function(deal?: Deal) {
      if (deal && deal.client.attribute) {
        return deal.client.attribute.name;
      }
      return "";
    },
    getMedia: function(deal?: Deal) {
      if (deal && deal.ad_media.name) {
        return deal.ad_media.name;
      }
      return "";
    },
    getPrice: function(price: number) {
      if (!isNaN(Number(price))) {
        return Number(price).toLocaleString();
      }
      return "";
    },
    getWorkType: function(work_type: number) {
      switch (work_type) {
        case 1:
          return "クライアント";
          break;
        case 2:
          return "IPG";
          break;
        case 3:
          return "ロゴ差替";
          break;
        case 4:
          return "未定";
          break;
      }
      return "";
    },
    remove: function() {
      if (!confirm("案件を削除します。よろしいですか。")) {
        return false;
      }
      this.$emit("remove", this.deal);
      this.close();
    },
    close: function() {
      const modal = this.$refs.modal as BModal;
      if (modal) {
        modal.hide();
      }
    }
  },
  mounted: async function() {
    this.loading = true;
    this.deal = this.wakuDeal ? Object.assign({}, this.wakuDeal) : newDeal();

    //TODO エリアが未設定のデータ(データ不整合？？)を表示する際にレンダ時にエラーを防ぐため
    if (this.deal.ad_area === null) {
      this.deal.ad_area = { id: undefined };
    }
    this.loading = false;
  }
});
