export type SaleGraph = {
  code: string;
  name: string;
};

export const saleGraph = (code: string): string | undefined =>
  allSaleGraph.find(x => x.code === code)?.name;

export const allSaleGraph: SaleGraph[] = [
  { code: "budget", name: "予算" },
  { code: "actual.all", name: "実績" },
  { code: "achievement_rate.J", name: "達成率" },
  { code: "achievement_rate.J_Na", name: "Nαまでの達成率" },
  { code: "balance", name: "残" },
  { code: "actual.J_M_A", name: "J+M+A" },
  { code: "actual.J_M_A_B_Nalpha", name: "J+M+A+B+Nα" },
  { code: "actual.J", name: "J" },
  { code: "actual.M", name: "M" },
  { code: "actual.A", name: "A" },
  { code: "actual.B", name: "B" },
  { code: "actual.Nalpha", name: "Nα" },
  { code: "actual.C", name: "C" },
  { code: "actual.D", name: "D" },
  { code: "actual.N", name: "N" },
  { code: "actual.Ochi", name: "オチ" },
  { code: "actual.previous-year", name: "前年" }
];
