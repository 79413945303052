export type WakuType = {
  code: number;
  name: string;
};

export const wakuType = (code: number): string | undefined =>
  wakuTypes.find(x => x.code === code)?.name;

export const wakuTypes: WakuType[] = [
  { code: 1, name: "LSPあり" },
  { code: 2, name: "全国のみ" },
  { code: 3, name: "地方指定可" },
  { code: 4, name: "相席なし" }
];
