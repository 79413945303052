


























import dayjs from "dayjs";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";

export default Vue.extend({
  components: {
    DatePicker
  },
  props: {
    value: {
      type: String,
      required: false
    },
    valueFormat: {
      type: String,
      default: "YYYY-MM"
    },
    displayFormat: {
      type: String,
      default: "YYYY-M"
    },
    valueAttr: {
      type: Object,
      default: undefined
    },
    disabledDate: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput: function(value: string) {
      this.$emit("input", value);
    },
    disabled2daysAgo(date: any) {
      if (this.disabledDate) {
        var today = dayjs().add(1, "day");
        today = today.subtract(1, "month");
        return date < today;
      }
    }
  }
});
