import repository from "@/repositories/repository";

export type EmailTemplate = {
  id?: number;
  type: number;
  title: string;
  text_header: string;
  text_body: string;
  text_footer: string;
};

type Query = {
  id?: number;
  title: string;
  text: string;
};

export default {
  list: async (token: string, query?: Query): Promise<EmailTemplate[]> => {
    const response = await repository(token).get("/api/email/templates", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (type: number, token: string): Promise<EmailTemplate> => {
    const response = await repository(token).get(
      `/api/email/templates/${type}`
    );
    return response.data;
  },

  update: async (
    data: EmailTemplate,
    token: string
  ): Promise<EmailTemplate> => {
    const response = await repository(token).put(
      `/api/email/templates/${data.id}`,
      {
        data: data
      }
    );
    return response.data;
  }
};
