import repository from "@/repositories/repository";

export type Deal = {
  id?: number;
  user_id: number;
  client_id: number;
  client_attribute_id: number;
  sales_channel_id: number;
  ad_media_id: number;
  title: string;
  is_long_term: number;
  published_date_from: string;
  published_date_to: string;
  published_time_from?: string;
  published_time_to?: string;
  ad_area_id: number;
  sales_probability_def_id: number;
  media_price: number;
  work_plan_type: number;
  ipg_plan_notes: number;
  working_price: number;
  remarks: number;
  yomi_deal?: {
    id: number;
  };
};

type Query = {
  year: number;
  month: number; // 1-12
  around: boolean;
};

type WeekQuery = {
  year: number;
  week: number;
};

type DateQuery = {
  year: number;
  month: number;
  date: number;
};

export default {
  list: async (token: string, query: Query): Promise<unknown> => {
    const response = await repository(token).get(
      `/api/waku/${query.year}/month/${query.month}`,
      {
        params: { ...query }
      }
    );
    return response.data;
  },

  week: async (token: string, query: WeekQuery): Promise<unknown> => {
    const response = await repository(token).get(
      `/api/waku/${query.year}/week/${query.week}`,
      {
        params: { ...query }
      }
    );
    return response.data;
  },

  date: async (token: string, query: DateQuery): Promise<unknown> => {
    const response = await repository(token).get(
      `/api/waku/${query.year}/month/${query.month}/date/${query.date}`,
      {
        params: { ...query }
      }
    );
    return response.data;
  }
};
