




































































































































































































































































import Vue from "vue";
import dayjs, { Dayjs } from "dayjs";

import Menu from "@/components/Menu.vue";
import DraftDealGroupModal from "@/components/modals/DraftDealGroupModal.vue";

import DraftCalendarTable from "@/components/draftCalendars/DraftCalendars.vue";
import * as AuthHelper from "@/helpers/auth";

import { DraftDeal } from "@/repositories/draftDeals";
import ClientRepository, { Client } from "@/repositories/clients";
import UserRepository, { User } from "@/repositories/users";
import AreaRepository, { Area } from "@/repositories/areas";
import MediaRepository, { Media } from "@/repositories/medias";

type DataType = {
  loading: boolean;
  error: boolean;
  errorMessage: string[];
  success: boolean;
  shownOverDrafts: boolean;
  shownDraftDealGroup: boolean;
  isSelectFrom: boolean;
  isSelectTo: boolean;
  isSelectCurrent: boolean;
  isProductioning: boolean;
  isFirstActioning: boolean;
  isProgressed: boolean;
  today: dayjs.Dayjs;
  currentFromDate: dayjs.Dayjs;
  currentToDate: dayjs.Dayjs;
  selectFromYear: number;
  selectFromMonth: number;
  selectFromDate: number;
  selectToYear: number;
  selectToMonth: number;
  selectToDate: number;
  fromEndOf: number;
  toEndOf: number;
  draftRecordCount: number;
  clients: Client[];
  users: User[];
  areas: Area[];
  medias: Media[];
};

export default Vue.extend({
  components: {
    Menu,
    DraftCalendarTable,
    DraftDealGroupModal
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      loading: false,
      error: false,
      errorMessage: [],
      success: false,
      shownOverDrafts: false,
      shownDraftDealGroup: false,
      isSelectFrom: false,
      isSelectTo: false,
      isSelectCurrent: true,
      isProductioning: false,
      isFirstActioning: false,
      isProgressed: false,
      today: now,
      currentFromDate: now,
      currentToDate: now,
      selectFromYear: now.year(),
      selectFromMonth: now.month() + 1,
      selectFromDate: now.date(),
      selectToYear: now.year(),
      selectToMonth: now.month() + 1,
      selectToDate: now.date(),
      fromEndOf: 0,
      toEndOf: 0,
      draftRecordCount: 0,
      clients: [],
      users: [],
      areas: [],
      medias: []
    };
  },
  computed: {
    calendarShown: function(): boolean {
      return !this.loading;
    },
    isDraftEditable: function() {
      return AuthHelper.isDraftEditable();
    }
  },
  mounted: async function() {
    await this.todayWeek();
    this.clients = await ClientRepository.actives(""); // 有効なクライアントを全て取得
    this.users = await UserRepository.list("");
    this.areas = await AreaRepository.list("");
    this.medias = await MediaRepository.actives("");
  },
  methods: {
    shownDrafts: function(shown: boolean) {
      this.shownOverDrafts = shown;
    },
    shownDraftDealGroupModal: function(isNew: boolean, draftDeal?: DraftDeal) {
      let draftDealGroups: DraftDeal[] = [];
      if (isNew) {
        const overDraftDeals: DraftDeal[] = (this as any).$refs[
          "overDraftCalendarTable"
        ].draftGrouped();
        const draftDeals: DraftDeal[] = (this as any).$refs.draftCalendarTable.draftGrouped();
        draftDealGroups = draftDeals.concat(overDraftDeals);
        // バリデーション
        this.error = this.valid(draftDealGroups);
        if (this.error) return false;
      } else {
        draftDealGroups = draftDeal ? [draftDeal] : [];
      }
      (this.$refs["draftDealGroupModal"] as any).show(isNew, draftDealGroups);
    },
    valid: function(draftDeals: DraftDeal[]): boolean {
      this.errorMessage = [];
      // 必須チェック
      if (draftDeals.length === 0)
        this.errorMessage.push("案件を選択してください。");

      const firstDraftDeal: DraftDeal = draftDeals[0];
      // 掲載日(月)一致チェック
      const differentMonths = draftDeals.filter(
        (draftDeal: DraftDeal) =>
          dayjs(draftDeal.published_date_from).month() !==
          dayjs(firstDraftDeal.published_date_from).month()
      );
      if (differentMonths.length > 0)
        this.errorMessage.push(
          "掲載日の月が異なるため長期グループ化することができません。"
        );

      // クライアント一致チェック
      const differentClients = draftDeals.filter(
        (draftDeal: DraftDeal) =>
          draftDeal.client.id !== firstDraftDeal.client.id
      );
      if (differentClients.length > 0)
        this.errorMessage.push(
          "クライアントが異なるため長期グループ化することができません。"
        );

      // 掲載媒体一致チェック
      const differentAdMedias = draftDeals.filter(
        (draftDeal: DraftDeal) =>
          draftDeal.ad_media.id !== firstDraftDeal.ad_media.id
      );
      if (differentAdMedias.length > 0)
        this.errorMessage.push(
          "掲載媒体が異なるため長期グループ化することができません。"
        );

      // エリア一致チェック
      const differentAdAreas = draftDeals.filter(
        (draftDeal: DraftDeal) =>
          draftDeal.ad_area.id !== firstDraftDeal.ad_area.id
      );
      if (differentAdAreas.length > 0)
        this.errorMessage.push(
          "エリアが異なるため長期グループ化することができません。"
        );

      // 広告タイプ一致チェック
      const differentAdTypes = draftDeals.filter(
        (draftDeal: DraftDeal) => draftDeal.ad_type !== firstDraftDeal.ad_type
      );
      if (differentAdTypes.length > 0)
        this.errorMessage.push(
          "広告タイプが異なるため長期グループ化することができません。"
        );

      // 入稿書レコードが作成されていない案件のみが選択されていること
      const existDraftSheetRecord = draftDeals.filter(
        (draftDeal: DraftDeal) => draftDeal.is_draft_sheet_created
      );
      if (existDraftSheetRecord.length > 0)
        this.errorMessage.push(
          "入稿が進んでいる案件は長期グループ化することができません。"
        );

      // 長期グループ設定されていない案件のみが選択されていること
      const grouped = draftDeals.filter(
        (draftDeal: DraftDeal) => draftDeal.is_grouped
      );
      if (grouped.length > 0)
        this.errorMessage.push(
          "既に長期グループ化されている案件は長期グループ化することができません。"
        );

      return this.errorMessage.length > 0;
    },
    addDate: async function(date: number) {
      this.currentFromDate = await this.currentFromDate.add(date, "week");
      this.currentToDate = await this.currentFromDate.add(1, "week");
      this.setCurrentDates();
    },
    todayWeek: async function() {
      this.currentFromDate = await dayjs();
      this.currentToDate = this.currentFromDate.add(1, "week");
      this.setCurrentDates();
      this.isSelectCurrent = true;
    },
    setCurrentDates: function() {
      this.selectFromYear = this.currentFromDate.year();
      this.selectFromMonth = this.currentFromDate.month() + 1;
      this.selectFromDate = this.currentFromDate.date();
      this.selectToYear = this.currentToDate.year();
      this.selectToMonth = this.currentToDate.month() + 1;
      this.selectToDate = this.currentToDate.date();
      this.setEndOfDate();
      this.switchDisabled();
      this.searchDates();
    },
    setEndOfDate: function() {
      const currentFromYearMonth = dayjs()
        .year(this.selectFromYear)
        .month(this.selectFromMonth - 1)
        .date(1);

      // 選択された年月に合わせて「日」の最大(28/29/30/31)を決める
      this.fromEndOf = currentFromYearMonth.endOf("month").date();

      // 年月を変更した結果、選択中の「日」が変更後の最大(28/29/30/31)を超えた場合、変更後の最大の「日」に合わせて補正する
      this.selectFromDate =
        this.selectFromDate > this.fromEndOf
          ? this.fromEndOf
          : this.selectFromDate;

      const currentToYearMonth = dayjs()
        .year(this.selectToYear)
        .month(this.selectToMonth - 1)
        .date(1);

      // 選択された年月に合わせて「日」の最大(28/29/30/31)を決める
      this.toEndOf = currentToYearMonth.endOf("month").date();

      // 年月を変更した結果、選択中の「日」が変更後の最大(28/29/30/31)を超えた場合、変更後の最大の「日」に合わせて補正する
      this.selectToDate =
        this.selectToDate > this.toEndOf ? this.toEndOf : this.selectToDate;
    },
    switchDisabled: function() {
      this.isSelectFrom = false;
      this.isSelectTo = false;
      this.isSelectCurrent = false;
      if (
        (this.currentFromDate.year() === this.today.year() - 9 &&
          this.currentFromDate.month() + 1 === 1 &&
          this.currentFromDate.date() === 1) ||
        (this.currentToDate.year() === this.today.year() - 9 &&
          this.currentToDate.month() + 1 === 1 &&
          this.currentToDate.date() === 1)
      ) {
        this.isSelectFrom = true;
      } else if (
        (this.currentFromDate.year() === this.today.year() + 1 &&
          this.currentFromDate.month() + 1 === 12 &&
          this.currentFromDate.date() === 31) ||
        (this.currentToDate.year() === this.today.year() + 1 &&
          this.currentToDate.month() + 1 === 12 &&
          this.currentToDate.date() === 31)
      ) {
        this.isSelectTo = true;
      }
    },
    searchDrafts: function() {
      this.currentFromDate = this.currentFromDate
        .year(this.selectFromYear)
        .month(this.selectFromMonth - 1)
        .date(this.selectFromDate);

      this.currentToDate = this.currentFromDate
        .year(this.selectToYear)
        .month(this.selectToMonth - 1)
        .date(this.selectToDate);

      this.searchDates();
    },
    searchDates: function() {
      this.draftRecordCount = 0; // 表示されている入稿レコードの数をリセット
      (this as any).$refs.draftCalendarTable.searchDates(
        this.currentFromDate,
        this.currentToDate
      );
      (this as any).$refs.overDraftCalendarTable.searchDates(
        this.currentFromDate,
        this.currentToDate
      );
    },
    draftHold: function(hold: boolean) {
      let msg = hold ? "選択した行を保留状態に" : "選択した行の保留状態を解除";
      var action = confirm(msg + "します。よろしいですか。");
      if (!action) {
        return false;
      }
      (this as any).$refs.draftCalendarTable.draftHold(hold);
      (this as any).$refs.overDraftCalendarTable.draftHold(hold);
      alert(msg + "しました。");
    },
    draftRecordCountUp: function(count: number) {
      this.draftRecordCount += count;
    },
    reset: function() {
      this.error = false;
      this.success = false;
    },
    onUpdate: function() {
      this.reset();
      this.success = true;
      this.searchDates();
    },
    onError: function() {
      this.reset();
      this.error = true;
      this.errorMessage = ["サーバエラーが発生しました。"];
    }
  }
});
