


































import Vue, { PropType } from "vue";

type DataType = {
  selected: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      selected: []
    };
  },
  props: {
    allMedia: {
      type: Array as PropType<any[]>,
      required: true,
      default: (): [] => []
    },
    value: {
      type: Array as PropType<string[]>,
      default: (): [] => []
    },
    all: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selected: function(newValue, oldValue) {
      return this.$emit("input", newValue);
    }
  },
  methods: {
    onChange: function() {
      this.$emit("onChange", this.$data.selected);
    },
    changeSelected: function(selected: any) {
      this.$data.selected = [...selected];
    }
  },
  mounted: function() {
    this.$data.selected = this.all
      ? this.allMedia.map((x: any) => x.id)
      : [...this.value];
    this.$emit("input", this.$data.selected);
  }
});
