





































































import Vue, { PropType } from "vue";
import { ClientStaff } from "@/repositories/clientStaffs";
import { AgencyStaff } from "@/repositories/agencyStaffs";
import { BusinessPartnerStaff } from "@/repositories/businessPartnerStaffs";

import { StaffType, staffType, staffTypes } from "@/helpers/staffType";

type DataType = {
  staffTypes: StaffType[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      staffTypes: staffTypes
    };
  },
  methods: {
    getStaffType: function(type: number) {
      let staffType = this.staffTypes.find(x => x.code === Number(type));
      return staffType != undefined ? staffType.name : "";
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    mode: {
      type: Number,
      default: 0
    },
    clientStaffs: {
      type: Array as PropType<ClientStaff[]>,
      required: true
    },
    agencyStaffs: {
      type: Array as PropType<AgencyStaff[]>,
      required: true
    }
  }
});
