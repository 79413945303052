import repository from "@/repositories/repository";

export type Area = {
  id?: number;
  name: string;
  prefecture_id: number;
  description?: string;
};

type Query = {
  id?: number;
  name: string;
};

export default {
  list: async (token: string, query?: Query): Promise<Area[]> => {
    const response = await repository(token).get("/api/adAreas", {
      params: { ...query }
    });
    return response.data;
  },

  list2: async (prefectures: number[], token: string): Promise<Area[]> => {
    const response = await repository(token).post("/api/adAreas/search", {
      prefectures: prefectures
    });
    return response.data;
  },

  list3: async (name: string, token: string): Promise<Area[]> => {
    const response = await repository(token).get(
      `/api/adAreas/index/search/${name}`
    );
    return response.data;
  },

  smallAreas: async (token: string, id?: number): Promise<Area[]> => {
    const response = await repository(token).get(
      `/api/adAreas/smallAreas/${id}`
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<Area[]> => {
    const response = await repository(token).get(`/api/adAreas/${id}`);
    return response.data;
  },

  store: async (
    data: { name: string; prefectures: number[]; description: string | null },
    token: string
  ): Promise<number> => {
    const response = await repository(token).post(`/api/adAreas`, {
      data: data
    });
    return response.data;
  },

  update: async (
    id: number,
    data: { name: string; prefectures: number[]; description?: string },
    token: string
  ): Promise<void> => {
    const response = await repository(token).put(`/api/adAreas/${id}`, {
      name: data.name,
      prefectures: data.prefectures,
      description: data.description
    });
    return response.data;
  },

  search: async (userName: string, token: string): Promise<Area[]> => {
    const response = await repository(token).post(`/api/adAreas/adArea`, {
      userName: userName
    });
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    const response = await repository(token).delete(`/api/adAreas/${id}`);
  }
};

export const newArea: () => Area = () => ({
  id: 0,
  name: "",
  prefecture_id: 0,
  description: undefined
});
