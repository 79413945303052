




















































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewRegistrationAreaModal from "@/components/NewRegistrationAreaModal.vue";
import { RegistrationArea } from "@/repositories/registrationArea";
import * as AuthHelper from "@/helpers/auth";
import RegistrationAreaRepository from "@/repositories/registrationArea";

type DataType = {
  registrationAreas: RegistrationArea[];
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
  success: boolean;
  updateRegistrationArea: boolean;
  deleteRegistrationArea: boolean;
  registrationAreaId: number;
  searchRegistrationArea: string;
};
export default Vue.extend({
  components: {
    Menu,
    NewRegistrationAreaModal
  },
  data: function(): DataType {
    return {
      registrationAreas: [],
      isFormShown: false,
      isNew: true,
      loading: false,
      success: false,
      updateRegistrationArea: false,
      deleteRegistrationArea: false,
      registrationAreaId: 0,
      searchRegistrationArea: ""
    };
  },
  computed: {
    listShown: function(): boolean {
      return (
        !this.loading &&
        this.registrationAreas &&
        this.registrationAreas.length > 0
      );
    }
  },
  methods: {
    showNewForm() {
      this.registrationAreaId = 0;
      this.isNew = true;
      this.showModal();
    },
    showEditForm(id: number) {
      this.registrationAreaId = id;
      this.isNew = false;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newRegistrationAreaModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function() {
      this.success = true;
      this.registrationAreas = await RegistrationAreaRepository.list(
        "" /*TODO*/
      );
    },
    onSuccessUpdate: async function() {
      this.updateRegistrationArea = true;
      this.registrationAreas = await RegistrationAreaRepository.list(
        "" /*TODO*/
      );
    },
    onDelete: async function() {
      this.deleteRegistrationArea = true;
      this.registrationAreas = await RegistrationAreaRepository.list(
        "" /*TODO*/
      );
    },
    onSearch: async function() {
      this.loading = true;
      if (
        this.searchRegistrationArea != null &&
        this.searchRegistrationArea != ""
      ) {
        this.registrationAreas = await RegistrationAreaRepository.search(
          this.searchRegistrationArea,
          ""
        );
      } else {
        this.registrationAreas = await RegistrationAreaRepository.list(
          "" /*TODO*/
        );
      }
      this.loading = false;
    }
  },
  mounted: async function() {
    this.loading = true;
    this.registrationAreas = await RegistrationAreaRepository.list("" /*TODO*/);
    this.loading = false;
  }
});
