




























import Vue from "vue";
import { BModal } from "bootstrap-vue";
import { EmailTag, emailtags } from "@/helpers/emailTag";

type DataType = {
  tags: EmailTag[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      tags: emailtags
    };
  },
  methods: {
    show() {
      (this.$refs.modal as BModal).show();
    }
  }
});
