






































































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewItemMgtModal from "@/components/NewItemManagementModal.vue";
import ItemMgtsRepository, {
  ItemMgt,
  ItemDetail
} from "@/repositories/itemManagements";
import * as AuthHelper from "@/helpers/auth";

type Items = {
  id: number;
  order: number;
  project_name: string;
  name: string;
  ad_media_id: string[];
  disabled: number;
};

type DataType = {
  isNew: boolean;
  isFormShown: boolean;
  loading: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  getItemMgts: ItemMgt[];
  getItemDetails: ItemDetail[];
  items: Items[];
  itemId: number;
};

export default Vue.extend({
  components: {
    Menu,
    NewItemMgtModal
  },
  data: function(): DataType {
    return {
      isNew: false,
      isFormShown: false,
      loading: false,
      isCreate: false,
      isUpdate: false,
      isDelete: false,
      getItemMgts: [],
      getItemDetails: [],
      items: [],
      itemId: 0
    };
  },

  methods: {
    showNew() {
      this.isNew = true;
      this.itemId = 0;
      this.showForm();
    },
    showEdit(id: number) {
      this.isNew = false;
      this.itemId = id;
      this.showForm();
    },
    closeForm() {
      this.isFormShown = false;
    },
    showForm() {
      this.isFormShown = true;
      (this.$refs.newItemMgtModal as any).show();
    },
    onCreate: async function(items: ItemMgt[]) {
      this.isCreate = true;
      this.loading = true;
      this.getItemMgts = await items;
      this.getItemDetails = await ItemMgtsRepository.list2("");
      await this.setItems();
      this.loading = false;
    },
    onUpdate: async function(items: ItemMgt[]) {
      this.isUpdate = true;
      this.loading = true;
      this.getItemMgts = await items;
      this.getItemDetails = await ItemMgtsRepository.list2("");
      await this.setItems();
      this.loading = false;
    },
    onDelete: async function(items: ItemMgt[]) {
      this.isDelete = true;
      this.loading = true;
      this.getItemMgts = await items;
      this.getItemDetails = await ItemMgtsRepository.list2("");
      await this.setItems();
      this.loading = false;
    },
    addOrder: async function(id: number, add: number) {
      // idが関数の第一引数で渡ってきている数字と等しいものをcurrentItemへ代入
      let currentItem = this.getItemMgts.find(x => x.id === id);
      let order = 0;
      if (currentItem != undefined) {
        order = currentItem.order + add;
        if (order < 1) {
          return false;
        } else if (order > this.items.length) {
          order = this.items.length;
        }
        let item = this.getItemMgts.find(x => x.order === order);
        // 順序を入れ替える
        if (item != undefined) {
          item.order = currentItem.order;
          currentItem.order = order;
          await ItemMgtsRepository.update(item, "");
          await ItemMgtsRepository.update(currentItem, "");
          // 一覧再整形
          this.loading = true;
          this.getItemMgts = await ItemMgtsRepository.list("" /*TODO*/);
          await this.setItems();
          this.loading = false;
          this.isUpdate = true;
        }
      }
    },
    setItems: function() {
      this.items = [];
      var itemDetails: string[];
      this.getItemMgts.forEach(element => {
        itemDetails = [];
        var details = this.getItemDetails.filter(
          x => x.ad_media_mgt_id === element.id
        );
        if (details != undefined) {
          details.forEach(detail => {
            itemDetails.push(detail.name);
          });
        }
        this.items.push({
          id: element.id != undefined ? element.id : 0,
          project_name: element.project_name,
          order: element.order,
          name: element.name,
          ad_media_id: itemDetails,
          disabled: element.disabled
        });
      });
    }
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.items && this.items.length > 0;
    },
    editable: function(): boolean {
      return AuthHelper.isAdMediaManagementEditable();
    }
  },
  mounted: async function() {
    this.loading = true;
    this.getItemMgts = await ItemMgtsRepository.list("" /*TODO*/);
    this.getItemDetails = await ItemMgtsRepository.list2("");
    await this.setItems();
    this.loading = false;
  }
});
