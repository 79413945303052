import repository from "@/repositories/repository";
import { CeAdResource } from "@/repositories/ceAdResource";
import { HtmlAdResource } from "@/repositories/htmlAdResource";

export type DraftSheet = {
  id?: number;
  draft_deal: {
    id?: number;
    deal: {
      id?: number;
      ad_media: {
        id?: number;
        name?: string;
      };
      work_plan_type: {
        code?: number;
        name?: string;
      };
    };
  };
  registration_area: {
    id?: number;
    name?: string;
  };
  status: number;
  material_name?: string;
  draft_sheet_path: {
    path?: string;
    url?: string;
    t_drive?: string;
  };
  stock_folder_path: {
    path?: string;
    url?: string;
    t_drive?: string;
  };
  description?: string;
  is_catv_restriction: boolean;
  is_updated: boolean;
};

// CE(OTD,NCE)掲載内容詳細画面向け情報
export type CeDraftDetail = {
  // 入稿書
  id?: number;
  status: number; // 入稿ステータス
  material_name?: string; // 素材名
  draft_sheet_path: {
    path?: string;
    url?: string;
    t_drive?: string;
  };
  description?: string; // 備考
  is_catv_restriction: boolean; // ケーブルテレビ掲出制限
  published_time: {
    // 掲載時間
    from?: string;
    to?: string;
  };
  is_updated: boolean; // 更新フラグ
  registration_area: {
    // 登録用エリア
    id?: number;
    name?: string;
  };
  draft_deal: {
    // 入稿カレンダー
    id?: number;
    window_ipg_staff: {
      id: number; // IPG担当者(窓口)(ユーザーID)
      name: string;
    };
    material_fixed_date: string; // 素材確定日
    next_action?: string; // next action
    note?: string; // 連絡事項
    publication_time: {
      code: number; // 終日掲載・放送終了まで・その他
      other?: string; // その他選択時の備考
    };
    deal: {
      // 案件
      id?: number;
      client: {
        // クライアント
        id: number;
        name: string;
        other_notes: string; // クライアントマスタの「デフォルト情報」の「その他」
      };
      staff: {
        // IPG担当(ユーザーID)
        id?: number;
        name: string;
      };
      published_date?: string; // 掲載日from TODO 複数日掲載の判定
      ad_media: {
        // 商品媒体
        id?: number;
        name?: string;
      };
      work_plan_type: {
        code: number;
        name: string;
      }; // 制作プラン種別0: 未定, 1: クライアント, 2: IPG, 3: ロゴ差替 TODO 0の場合には入稿書の出力が出来ない様にバリデーション必要
      published_time: {
        // 掲載時間
        from?: string;
        to?: string;
      };
      title: string; // タイトル
      ad_area: {
        // エリア
        id: number;
        name: string;
      };
    };
    first_deal_id: number;
  };
  ce_ad_resource: CeAdResource;
};

// HTML掲載内容詳細画面向け情報
export type HtmlDraftDetail = {
  // 入稿書
  id?: number;
  status: number; // 入稿ステータス
  material_name?: string; // 素材名
  draft_sheet_path: {
    path?: string;
    url?: string;
  };
  description?: string; // 備考
  is_catv_restriction: boolean; // ケーブルテレビ掲出制限
  published_time: {
    // 掲載時間
    from?: string;
    to?: string;
  };
  is_updated: boolean; // 更新フラグ
  registration_area: {
    // 登録用エリア
    id?: number;
    name?: string;
  };
  draft_deal: {
    // 入稿カレンダー
    id?: number;
    window_ipg_staff: {
      id: number; // IPG担当者(窓口)(ユーザーID)
      name: string;
    };
    material_fixed_date: string; // 素材確定日
    next_action?: string; // next action
    note?: string; // 連絡事項
    publication_time: {
      code: number; // 終日掲載・放送終了まで・その他
      other?: string; // その他選択時の備考
    };
    deal: {
      // 案件
      id?: number;
      client: {
        // クライアント
        id: number;
        name: string;
        other_notes: string; // クライアントマスタの「デフォルト情報」の「その他」
      };
      staff: {
        // IPG担当(ユーザーID)
        id?: number;
        name: string;
      };
      published_date?: string; // 掲載日from TODO 複数日掲載の判定
      ad_media: {
        // 商品媒体
        id?: number;
        name?: string;
      };
      work_plan_type: {
        code: number;
        name: string;
      }; // 制作プラン種別0: 未定, 1: クライアント, 2: IPG, 3: ロゴ差替 TODO 0の場合には入稿書の出力が出来ない様にバリデーション必要
      published_time: {
        // 掲載時間
        from?: string;
        to?: string;
      };
      ad_type: {
        // 広告タイプ
        code: number; // 0,1
        name: string; // 受注案件、自社案件
      };
      title: string; // タイトル
      ad_area: {
        // エリア
        id: number;
        name: string;
      };
    };
    first_deal_id: number;
  };
  html_ad_resource: HtmlAdResource;
};

export type CeAdResourceId = {
  ce_ad_resource_id: number;
};

export type HtmlAdResourceId = {
  html_ad_resource_id: number;
};

export type Query = {
  draft_deal_id?: number;
};

export default {
  // 入稿書管理画面にて使用。入稿書情報取得
  index: async (token: string, query?: Query): Promise<DraftSheet[]> => {
    const response = await repository(token).get(`/api/draftSheets/index/`, {
      params: { ...query }
    });
    return response.data;
  },

  // 長期掲載日設定
  saveDraftSheetGroup: async (
    draftSheetId: number,
    data: number[]
  ): Promise<void> => {
    const response = await repository().post(
      `/api/draftSheets/saveDraftSheetGroup/${draftSheetId}`,
      {
        data: data
      }
    );
    return response.data.data;
  },

  // 長期掲載設定済みの入稿カレンダーIDの取得
  longTermIndex: async (draftSheetId: number): Promise<number[]> => {
    const response = await repository().get(
      `/api/draftSheets/findDraftSheetGroup/${draftSheetId}`
    );
    return response.data;
  },

  // 掲載内容詳細(CE)情報取得
  getCeDetail: async (id: number): Promise<CeDraftDetail> => {
    const response = await repository().get(`/api/draftSheets/ce/${id}`);
    return response.data;
  },

  // 掲載内容詳細(HTML)情報取得
  getHtmlDetail: async (id: number): Promise<HtmlDraftDetail> => {
    const response = await repository().get(`/api/draftSheets/html/${id}`);
    return response.data;
  },

  // 入稿書情報新規登録
  store: async (token: string, data: DraftSheet): Promise<void> => {
    await repository(token).post(`/api/draftSheets/store`, {
      data: data
    });
  },

  // 入稿書管理画面用、入稿書情報更新
  update: async (token: string, data: DraftSheet): Promise<void> => {
    await repository(token).put(`/api/draftSheets/update/${data.id}`, {
      data: data
    });
  },

  // 掲載内容詳細(CE)情報更新
  updateCeDetail: async (data: CeDraftDetail): Promise<void> => {
    const response = await repository().put(
      `/api/draftDetails/ce/update/${data.id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  // 掲載内容詳細(HTML)情報更新
  updateHtmlDetail: async (data: HtmlDraftDetail): Promise<void> => {
    const response = await repository().put(
      `/api/draftDetails/html/update/${data.id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  // 入稿書管理画面、入稿書情報削除
  delete: async (token: string, data: DraftSheet): Promise<void> => {
    await repository(token).delete(`/api/draftSheets/delete/${data.id}`);
  },

  // NCE枠登録用RPA連携用データ出力画面、ステータス一括更新
  updateStatus: async (token: string): Promise<void> => {
    const response = await repository(token).put(
      `/api/draftSheets/updateStatus`
    );
    return response.data;
  }
};

export const newDraftSheet: () => DraftSheet = () => ({
  draft_deal: {
    id: undefined,
    deal: {
      ad_media: {},
      work_plan_type: {}
    }
  },
  draft_sheet_path: {},
  registration_area: {},
  status: 5,
  stock_folder_path: {},
  is_catv_restriction: false,
  is_updated: false,
  id: undefined
});

// CEの掲載内容情報初期化
export const newCeDraftDetail: () => CeDraftDetail = () => ({
  // 入稿書
  id: 0,
  status: 5, // 入稿ステータス
  material_name: "", // 素材名
  description: "", // 備考
  draft_sheet_path: {},
  is_catv_restriction: false, // ケーブルテレビ掲出制限
  published_time: {
    // 掲載時間
    from: "",
    to: ""
  },
  is_updated: false, // 更新フラグ
  registration_area: {
    // 登録用エリア
    id: 0,
    name: ""
  },
  draft_deal: {
    // 入稿カレンダー
    id: 0,
    window_ipg_staff: {
      id: 0, // IPG担当者(窓口)(ユーザーID)
      name: ""
    },
    material_fixed_date: "", // 素材確定日
    next_action: "", // next action
    note: "", // 連絡事項
    publication_time: {
      code: 1, // 終日掲載・放送終了まで・その他
      other: "" // その他選択時の備考
    },
    deal: {
      // 案件
      id: 0,
      client: {
        // クライアント
        id: 0,
        name: "",
        other_notes: "" // クライアントマスタの「デフォルト情報」の「その他」
      },
      staff: {
        // IPG担当(ユーザーID)
        id: 0,
        name: ""
      },
      published_date: "", // 掲載日from TODO 複数日掲載の判定 DraftSheetEntity内publishedDatesToString()にて行っているが動作未確認
      ad_media: {
        // 商品媒体
        id: 0,
        name: ""
      },
      work_plan_type: {
        code: 0,
        name: ""
      }, // 制作プラン種別0: 未定, 1: クライアント, 2: IPG, 3: ロゴ差替 TODO 0の場合には入稿書の出力が出来ない様にバリデーション必要
      published_time: {
        // 掲載時間
        from: "",
        to: ""
      },
      title: "", // タイトル
      ad_area: {
        // エリア
        id: 0,
        name: ""
      }
    },
    first_deal_id: 0
  },
  ce_ad_resource: {
    // CE広告素材情報
    id: 0,
    nce_minds_id: 0,
    animation_seconds: {
      otd: 0,
      nce: 0
    },
    stock_folder_path: {},
    otd_top_banner_1: {},
    otd_top_banner_2: {},
    otd_detail_banner: {},
    otd_top_banner_pdo: {},
    nce_top_banner_1: {},
    nce_top_banner_2: {},
    nce_detail_banner: {},
    text_resource: {}
  }
});

// HTMLの掲載内容情報初期化
export const newHtmlDraftDetail: () => HtmlDraftDetail = () => ({
  // 入稿書
  id: 0,
  status: 5, // 入稿ステータス
  material_name: "", // 素材名
  description: "", // 備考
  draft_sheet_path: {},
  is_catv_restriction: false, // ケーブルテレビ掲出制限
  published_time: {
    // 掲載時間
    from: "",
    to: ""
  },
  is_updated: false, // 更新フラグ
  registration_area: {
    // 登録用エリア
    id: 0,
    name: ""
  },
  draft_deal: {
    // 入稿カレンダー
    id: 0,
    window_ipg_staff: {
      id: 0, // IPG担当者(窓口)(ユーザーID)
      name: ""
    },
    material_fixed_date: "", // 素材確定日
    next_action: "", // next action
    note: "", // 連絡事項
    publication_time: {
      code: 0, // 終日掲載・放送終了まで・その他
      other: "" // その他選択時の備考
    },
    deal: {
      // 案件
      id: 0,
      client: {
        // クライアント
        id: 0,
        name: "",
        other_notes: "" // クライアントマスタの「デフォルト情報」の「その他」
      },
      staff: {
        // IPG担当(ユーザーID)
        id: 0,
        name: ""
      },
      published_date: "", // 掲載日from TODO 複数日掲載の判定
      ad_media: {
        // 商品媒体
        id: 0,
        name: ""
      },
      work_plan_type: {
        code: 0,
        name: ""
      }, // 制作プラン種別0: 未定, 1: クライアント, 2: IPG, 3: ロゴ差替 TODO 0の場合には入稿書の出力が出来ない様にバリデーション必要
      published_time: {
        // 掲載時間
        from: "",
        to: ""
      },
      ad_type: {
        code: 0,
        name: ""
      }, // 広告タイプ(0:受注案件、1:自社案件)
      title: "", // タイトル
      ad_area: {
        // エリア
        id: 0,
        name: ""
      }
    },
    first_deal_id: 0
  },
  html_ad_resource: {
    // HTML広告素材情報
    id: 0,
    animation_seconds: 0, // AN秒数
    destination: {
      code: 0,
      name: ""
    }, // 遷移先
    channel: {
      // チャンネル
      id: 0,
      name: "",
      gcn: "",
      disabled: false,
      client_name: ""
    },
    program: {
      name: "", // 番組名
      broadcast_date: {
        // 放送日
        from: "",
        to: ""
      },
      broadcast_time: {
        // 放送時間
        from: "",
        to: ""
      }
    },
    stock_folder_path: {},
    top_banner_1: {},
    top_banner_2: {},
    popup_banner: {}
  }
});
