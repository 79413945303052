export type Production = {
  code: number;
  name: string;
};

export const production = (code: number): string | undefined =>
  productions.find(x => x.code === code)?.name;

export const productions: Production[] = [
  { code: 0, name: "IPG" },
  { code: 1, name: "3b" },
  { code: 2, name: "s2" },
  { code: 3, name: "2000" },
  { code: 4, name: "3c" },
  { code: 5, name: "ns" },
  { code: 6, name: "tkm" }
];
