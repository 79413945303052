import repository from "@/repositories/repository";

export type ItemMgt = {
  id?: number;
  order: number;
  project_name: string;
  name: string;
  ad_media_id: number[];
  target_price: number;
  disabled: number;
};

export type ItemDetail = {
  id: number;
  ad_media_mgt_id: number;
  name: string;
};

type Query = {
  id?: number;
  name: string;
};

export default {
  list: async (token: string, query?: Query): Promise<ItemMgt[]> => {
    const response = await repository(token).get("/api/adMedia/managements", {
      params: { ...query }
    });
    return response.data;
  },

  list2: async (token: string): Promise<ItemDetail[]> => {
    const response = await repository(token).get(
      "/api/adMedia/managements/detail/index"
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<ItemMgt> => {
    const response = await repository(token).get(
      `/api/adMedia/managements/${id}`
    );
    return response.data;
  },

  get2: async (id: number, token: string): Promise<ItemDetail[]> => {
    const response = await repository(token).get(
      `/api/adMedia/managements/detail/show/${id}`
    );
    return response.data;
  },

  store: async (data: ItemMgt, token: string): Promise<ItemMgt[]> => {
    const response = await repository(token).post(`/api/adMedia/managements`, {
      data: data
    });
    return response.data;
  },

  update: async (data: ItemMgt, token: string): Promise<ItemMgt[]> => {
    const response = await repository(token).put(
      `/api/adMedia/managements/${data.id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<ItemMgt[]> => {
    const response = await repository(token).delete(
      `/api/adMedia/managements/${id}`
    );
    return response.data;
  }
};

export const newItemMgt: () => ItemMgt = () => ({
  id: 0,
  order: 0,
  project_name: "",
  name: "",
  ad_media_id: [],
  target_price: 0,
  disabled: 0
});
