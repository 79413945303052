import repository from "@/repositories/repository";
import { AxiosError } from "axios";

export type FiscalYear = {
  id: number;
  begin: string;
  end: string;
};

type Query = {
  id?: number;
  begin: string;
  end: string;
};

export default {
  list: async (token: string, query?: Query): Promise<FiscalYear> => {
    const response = await repository(token).get("/api/fiscal/year", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (begin: string, token: string): Promise<FiscalYear> => {
    const response = await repository(token).get(`/api/fiscal/year/${begin}`);
    return response.data;
  },

  store: async (years: number[], token: string): Promise<FiscalYear[]> => {
    const response = await repository(token).post(`/api/fiscal/year`, {
      years: years
    });
    return response.data;
  },

  update: async (datas: FiscalYear[], token: string): Promise<void> => {
    await datas.forEach(element => {
      repository(token)
        .put(`/api/fiscal/year/${element.id}`, {
          data: element
        })
        .catch((e: AxiosError) => {
          throw e;
        });
    });
  }
};
