





























import Vue from "vue";
export default Vue.extend({
  props: ["month", "week", "day", "today", "getCount"],
  methods: {
    hasAlert: function(count: number) {
      // 15枠を超える場合は警告表示
      return count >= 15;
    }
  }
});
