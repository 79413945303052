





























import Vue from "vue";

import Header from "@/components/Header.vue";

export default Vue.extend({
  data: function() {
    return {
      navbarTitle: process.env.MIX_APP_NAME || "ADSYS"
    };
  },
  components: {
    Header
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    logout: function() {
      // this.$store.dispatch("auth/logout").then(() => {
      this.$router.push({ path: "/login" });
      // });
    },
    home: function() {
      this.$router.push({ path: "/home" });
    }
  }
});
