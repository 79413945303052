






























































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import dayjs from "dayjs";
import { AxiosError } from "axios";
import _ from "lodash";

import Menu from "@/components/Menu.vue";
import WakuMonthCalendar from "@/components/WakuMonthCalendar.vue";
import NewWakuModal from "@/components/NewWakuModal.vue";

import WakuRepository from "@/repositories/waku";
import AdMediaRepository from "@/repositories/medias";
import { Deal } from "@/repositories/deals";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  filterMedia: string[];
  today: dayjs.Dayjs;
  isNewFormShown: boolean;
  waku: unknown;
  adMedia?: unknown[];
  message: string;
  success: boolean;
  warningMessage: string;
  warning: boolean;
  hasError: boolean;
  error?: string;
  loading: boolean;
  date: number;
};

export default Vue.extend({
  components: {
    Menu,
    WakuMonthCalendar,
    NewWakuModal
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      filterMedia: [],
      today: now,
      isNewFormShown: false,
      waku: {},
      adMedia: undefined,
      message: "",
      success: false,
      warningMessage: "",
      warning: false,
      hasError: false,
      error: undefined,
      loading: true,
      date: dayjs()
        .add(2, "day")
        .date()
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().year()
    },
    month: {
      type: Number,
      required: true,
      default: dayjs().month() + 1, // 0-11(dayjs.month) to 1-12(prop)
      validator: function(value) {
        return 1 <= value && value <= 12;
      }
    },
    media: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  computed: {
    currentDate: function() {
      const date = dayjs()
        .year(this.year)
        .month(this.month - 1); // 1-12(prop) to 0-11(dayjs.month)
      return this.month - 1 != dayjs().month()
        ? date.startOf("month") // 月初を基準に
        : dayjs().year(this.year);
      // return dayjs()
      //   .year(this.year)
      //   .month(this.month - 1) // 1-12(prop) to 0-11(dayjs.month)
      //   .startOf("month"); // 月初を基準に
    },
    editable: function() {
      // 掲載日が2日以上過去の場合新規登録を不可
      const pastDay = dayjs().add(2, "day");
      const current = dayjs()
        .year(this.year)
        .month(this.month - 1)
        .endOf("month");
      return AuthHelper.isDealEditor() && current.isAfter(pastDay);
    }
  },
  watch: {
    month: async function(val, oldVal) {
      if (val !== oldVal) {
        this.fetchWaku(this.year, this.month);
      }
    },
    media: function() {
      this.filterMedia = this.media;
    }
  },
  methods: {
    changeFilterMedia: function(filterMedia: any) {
      this.filterMedia = filterMedia;
    },
    showNewForm() {
      this.isNewFormShown = true;
      (this.$refs.newWakuModal as BModal).show();
    },
    closeNewForm() {
      this.isNewFormShown = false;
    },
    isThisMonth: function(): boolean {
      const today = dayjs();
      const startOfThisMonth = dayjs()
        .year(today.year())
        .month(today.month())
        .startOf("month");
      return startOfThisMonth.isSame(this.currentDate);
    },
    weekRoute: function(): Record<string, unknown> {
      const today = dayjs();
      const isThisMonth = today.isSame(this.currentDate, "month");
      const day = isThisMonth ? today : this.currentDate; //現在月であれば、現在週へ移動
      return {
        name: "waku.week",
        params: {
          year: day.year().toString(),
          week: day.week().toString(),
          media: this.filterMedia
        }
      };
    },
    previousMonthRoute: function(): Record<string, unknown> {
      const nextMonth = this.currentDate.add(-1, "month");
      return this.monthRoute(nextMonth);
    },
    nextMonthRoute: function(): Record<string, unknown> {
      const nextMonth = this.currentDate.add(1, "month");
      return this.monthRoute(nextMonth);
    },
    thisMonthRoute: function(): Record<string, unknown> {
      const todayMonth = this.currentDate
        .year(this.today.year())
        .month(this.today.month());
      return this.monthRoute(todayMonth);
    },
    monthRoute: function(day: dayjs.Dayjs): Record<string, unknown> {
      const year = day.year();
      const month = day.month() + 1; // 0-11(dayjs.month) to 1-12(params)
      return {
        name: "waku.month",
        params: {
          year: year.toString(),
          month: month.toString(),
          media: this.filterMedia
        }
      };
    },
    fetchWaku: async function(year: number, month: number) {
      this.loading = true;
      await WakuRepository.list("" /*TODO*/, {
        year: year,
        month: month,
        around: true
      }).then(waku => {
        this.waku = this.mergeWaku(this.waku, waku);
        if (year === this.year && month === this.month) {
          this.loading = false;
        }
      });
    },
    mergeWaku: function(waku1: any, waku2: any) {
      if (!waku1) return waku2;
      if (!waku2) return waku1;

      const mergedWaku = _.defaultsDeep(waku2, waku1);
      return mergedWaku;
    },
    add() {
      const date = dayjs().add(1, "day");
      if (this.currentDate.isAfter(date)) {
        this.date = this.currentDate.date();
      } else {
        this.date = dayjs()
          .add(2, "day")
          .date();
      }
      this.showNewForm();
    },
    onSuccess: async function(deal: Deal, waitingDeals: Deal[] = []) {
      this.message = deal.id
        ? "枠表案件の更新が完了しました。"
        : "枠表案件の新規登録が完了しました。";
      this.success = true;
      if (waitingDeals.length > 0) {
        this.message = [
          this.message,
          "キャンセル待ちがありますのでご確認ください。"
        ].join("\n");
        this.warning = true;
        this.warningMessage = waitingDeals
          .map((deal: Deal) => {
            // [掲載日：YYYY年M月D日　媒体：xxx　クライアント：xxx　タイトル：xxx]がキャンセル待ちになっています。
            return `[掲載日：${dayjs(deal.published_date.date.from).format(
              "YYYY年M月D日"
            )}　媒体：${deal.ad_media.name}　クライアント：${
              deal.client.name
            }　タイトル：${deal.title}]がキャンセル待ちになっています。`;
          })
          .join("\n");
      }
      this.fetchWaku(this.year, this.month);
    },
    fail(error: AxiosError) {
      this.hasError = true;
      if (error.response?.status === 403) {
        this.error = "権限が不足しています。";
      } else {
        this.error = "エラーが発生しました";
      }
    }
  },
  mounted: async function() {
    await AdMediaRepository.list("" /*TODO*/).then(media => {
      this.loading = false;
      this.adMedia = media;
      this.filterMedia = media.map((x: any) => x.id);
    });

    this.fetchWaku(this.year, this.month);
  }
});
