import repository from "@/repositories/repository";
import RegistrationAreaModal from "@/components/modals/RegistrationAreaModal.vue";

export type RegistrationArea = {
  id?: number;
  name: string;
  prefectures: number[];
  description?: string;
};

export type RegistrationAreaModalArgs = {
  id?: number;
  name?: string;
};

type Query = {
  id?: number;
  name: string;
};

export type RegistrationAreaQuery = {
  name: string;
  prefectures: number[];
  description: string | null;
};

export default {
  list: async (token: string, query?: Query): Promise<RegistrationArea[]> => {
    const response = await repository(token).get("/api/registrationAreas", {
      params: { ...query }
    });
    return response.data;
  },

  search: async (name: string, token: string): Promise<RegistrationArea[]> => {
    const response = await repository(token).get(
      `/api/registrationAreas/index/search/${name}`
    );
    return response.data;
  },

  // 詳細押下した際のモーダル内の情報取得メソッド
  get: async (id: number, token: string): Promise<RegistrationArea> => {
    const response = await repository(token).get(
      `/api/registrationAreas/${id}`
    );
    return response.data;
  },

  store: async (
    data: RegistrationAreaQuery,
    token: string
  ): Promise<number> => {
    const response = await repository(token).post(`/api/registrationAreas`, {
      data: data
    });
    return response.data;
  },

  update: async (
    id: number,
    data: RegistrationAreaQuery,
    token: string
  ): Promise<void> => {
    const response = await repository(token).put(
      `/api/registrationAreas/${id}`,
      {
        data: data
      }
    );
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    await repository(token).delete(`/api/registrationAreas/${id}`);
  }
};

export const newRegistrationArea: () => RegistrationArea = () => ({
  id: 0,
  name: "",
  prefectures: [],
  description: ""
});

export const newRegistrationAreaModalArgs: () => RegistrationAreaModalArgs = () => ({
  id: 0,
  name: ""
});
