






















































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";

import NewChannelModal from "@/components/NewChannelModal.vue";
import ChannelRepository, { Channel } from "@/repositories/channels";
import * as AuthHelper from "@/helpers/auth";

type Datatype = {
  id: number;
  isFormShown: boolean;
  channels: Channel[];
  isNew: boolean;
  loading: boolean;
  success: boolean;
  updateChannel: boolean;
  deleteChannel: boolean;
};
export default Vue.extend({
  components: {
    NewChannelModal,
    Menu
  },
  data: function(): Datatype {
    return {
      id: 0,
      isFormShown: false,
      channels: [],
      isNew: true,
      loading: false,
      success: false,
      updateChannel: false,
      deleteChannel: false
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.channels && this.channels.length > 0;
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.id = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.id = id;
      this.isNew = false;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newChannelModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function() {
      this.success = true;
      this.channels = await ChannelRepository.list();
    },
    onSuccessUpdate: async function() {
      this.updateChannel = true;
      this.channels = await ChannelRepository.list();
    },
    onDelete: async function() {
      this.deleteChannel = true;
      this.channels = await ChannelRepository.list();
    }
  },
  mounted: async function() {
    this.loading = true;
    this.channels = await ChannelRepository.list();
    this.loading = false;
  }
});
