











































































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import { MediaProject, mediaProjects } from "@/helpers/mediaProject";
import ItemRepository, { Item } from "@/repositories/items";
import { allTargetPrice, TargetPrice } from "@/helpers/targetPrices";
import ItemMgtsRepository, {
  ItemMgt,
  ItemDetail,
  newItemMgt
} from "@/repositories/itemManagements";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  errorMsg: string[];
  projects: MediaProject[];
  adMedias: Item[];
  item: ItemMgt;
  targetPrices: TargetPrice[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      isError: false,
      errorMsg: [],
      projects: mediaProjects,
      adMedias: [],
      item: newItemMgt(),
      targetPrices: allTargetPrice
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isAdMediaManagementEditable();
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.item = newItemMgt();
    },
    postItem: async function() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await ItemMgtsRepository.store(this.item, "");
      this.$emit("create", result);
      (this.$refs.modal as BModal).hide();
    },
    updateItem: async function() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await ItemMgtsRepository.update(this.item, "");
      this.$emit("update", result);
      (this.$refs.modal as BModal).hide();
    },
    deleteItem: async function(id: number) {
      let action = confirm(
        "商品媒体（管理用）から削除します。よろしいですか。"
      );
      if (!action) {
        return false;
      }
      let result = await ItemMgtsRepository.destroy(id, "");
      this.$emit("delete", result);
      (this.$refs.modal as BModal).hide();
    },
    validation: function() {
      var msg: string[] = [];
      if (this.item.name === null || this.item.name === "") {
        msg.push("商品媒体(管理用)名を入力してください。");
      }
      if (this.item.project_name === null || this.item.project_name === "") {
        msg.push("プロジェクトを選択してください。");
      }
      if (this.item.ad_media_id.length < 1) {
        msg.push("商品媒体を選択してください。");
      }
      if (this.item.target_price < 1) {
        msg.push("計上費用を選択してください。");
      }
      return msg;
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        var item = await ItemMgtsRepository.get(this.id, "");
        var details: ItemDetail[] = [];
        if (item != null) {
          var adMedias: number[];
          adMedias = [];
          details = await ItemMgtsRepository.get2(this.id, "");
          details.forEach(element => {
            adMedias.push(element.id);
          });
          item.ad_media_id = adMedias;
          this.item = item;
        }
      }
      this.loading = false;
    }
  },
  mounted: async function() {
    this.adMedias = await ItemRepository.list("");
  }
});
