import { ActionTree, Module } from "vuex";
import { AxiosResponse, AxiosError } from "axios";

import repository from "@/repositories/repository";

class State {
  loggedIn = false;
  user?: User;
  role?: string;
  abilities: string[] = [];
}

type User = unknown; //TODO

const mutations = {
  setUser(state: State, payload: User): void {
    state.user = payload;
    state.loggedIn = true;
  },
  setAbilities(state: State, payload: string[]): void {
    state.abilities = payload;
  },
  logout(state: State): void {
    state.loggedIn = false;
    state.user = undefined;
    state.role = undefined;
    state.abilities = [];
  }
};

const getters = {
  isLoggedIn(state: State): boolean {
    return state.loggedIn;
  },
  user(state: State): User {
    return state.user;
  },
  role(state: State): string | undefined {
    return state.role;
  },
  abilities(state: State): string[] {
    return state.abilities;
  }
};

const actions: ActionTree<State, void> = {
  login({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const userinfo = (await repository().get("/userinfo")).data; // userinfo of Google OAuth (including token.)
        if (!userinfo) return;

        const loginPath = "/api/login";
        await repository()
          .get("/sanctum/csrf-cookie")
          .then(() => {
            repository()
              .post(loginPath, {
                token: userinfo.token
              })
              .then(async (response: AxiosResponse) => {
                const res = response.data;
                commit("setUser", res.user);
                commit("setAbilities", res.abilities);
                resolve();
              })
              .catch((error: AxiosError) => {
                reject(error);
              });
          });
      }, 1000);
    });
  },
  logout({ commit }): Promise<void> {
    return new Promise(resolve => {
      setTimeout(async () => {
        const logoutUrl = "/api/logout";
        repository()
          .post(logoutUrl)
          .finally(() => {
            commit("logout");
            resolve();
          });
      }, 1000);
    });
  },
  getRole({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      const userUrl = "/api/user";
      setTimeout(async () => {
        repository()
          .get(userUrl)
          .then(async (response: AxiosResponse) => {
            const res = response.data;
            commit("setUser", res.user);
            commit("setAbilities", res.abilities);
            resolve();
          })
          .catch((error: AxiosError) => {
            reject(error);
          });
      }, 1000);
    });
  }
};

const state: State = new State();

const auth: Module<State, void> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};

export default auth;
