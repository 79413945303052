




















































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import Menu from "@/components/AdminMenu.vue";
import NewClientAttributeModal from "@/components/NewClientAttributeModal.vue";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  clientAttributes: ClientAttribute[];
  isFormShown: boolean;
  loading: boolean;
  success: boolean;
  successUpdate: boolean;
  successDelete: boolean;
  isNew: boolean;
  id: number;
};

export default Vue.extend({
  components: {
    Menu,
    NewClientAttributeModal
  },
  data: function(): DataType {
    return {
      clientAttributes: [],
      isFormShown: false,
      loading: false,
      success: false,
      successUpdate: false,
      successDelete: false,
      isNew: false,
      id: 0
    };
  },
  computed: {
    listShown: function(): boolean {
      return (
        !this.loading &&
        this.clientAttributes &&
        this.clientAttributes.length > 0
      );
    },
    isClientAttributeEditable: function(): boolean {
      return AuthHelper.isClientAttributeEditable();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.id = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.id = id;
      this.isNew = false;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newClientAttributeModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function(datas: ClientAttribute[]) {
      this.loading = true;
      this.success = true;
      this.clientAttributes = datas;
      this.loading = false;
    },
    onUpdate: async function(datas: ClientAttribute[]) {
      this.loading = true;
      this.successUpdate = true;
      this.clientAttributes = datas;
      this.loading = false;
    },
    onDelete: async function(datas: ClientAttribute[]) {
      this.loading = true;
      this.successDelete = true;
      this.clientAttributes = datas;
      this.loading = false;
    },
    addOrder: async function(id: number, add: number) {
      var current = this.clientAttributes.find(x => x.id === id);
      var order = 0;
      if (current != undefined) {
        order = current.order + add;
        if (order < 1) {
          return false;
        } else if (order > this.clientAttributes.length) {
          order = this.clientAttributes.length;
        }
        var attribute = this.clientAttributes.find(x => x.order === order);
        // 順序を入れ替える
        if (attribute != undefined) {
          attribute.order = current.order;
          current.order = order;
          await ClientAttributeRepository.update(attribute.id, attribute, "");
          await ClientAttributeRepository.update(id, current, "");
          // 一覧再整形
          this.loading = true;
          this.clientAttributes = await ClientAttributeRepository.list(
            "" /*TODO*/
          );
          this.loading = false;
          this.successUpdate = true;
        }
      }
    }
  },
  mounted: async function() {
    this.loading = true;
    this.clientAttributes = await ClientAttributeRepository.list("" /*TODO*/);
    this.loading = false;
  }
});
