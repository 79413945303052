import repository from "@/repositories/repository";

export type SalesGoalsByUsers = {
  user?: {
    id: number;
    name: string;
  };
  amounts: {
    id: number[];
    year_month: string[];
    amount: string[];
  };
}[];

type Query = {
  user_id?: number;
  date: {
    from: string;
    to: string;
  }[];
};

type FiscalYearQuery = {
  from: string;
  to: string;
};

export default {
  list: async (
    token: string,
    query?: FiscalYearQuery
  ): Promise<SalesGoalsByUsers[]> => {
    const response = await repository(token).get("/api/sale/goal/users/", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: string, token: string): Promise<SalesGoalsByUsers> => {
    const response = await repository(token).get(`/api/sale/goal/users/${id}`);
    return response.data;
  },

  match: async (token: string, query?: Query): Promise<number[]> => {
    const response = await repository(token).post(
      `/api/sale/goal/users/match/`,
      {
        params: { ...query }
      }
    );
    return response.data;
  },

  store: async (token: string, query?: SalesGoalsByUsers[]): Promise<void> => {
    const response = await repository(token).post(`/api/sale/goal/users/`, {
      params: { ...query }
    });
    return response.data;
  }
};
