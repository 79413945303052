































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";

import DraftDealRepository, { DraftDeal } from "@/repositories/draftDeals";

type DataType = {
  note: string;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      note: ""
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    draft: {
      type: Object as PropType<DraftDeal>,
      required: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function() {
      this.note = this.value;
    }
  },
  methods: {
    show: async function() {
      (this.$refs.modal as BModal).show();
    },
    update: async function() {
      if (!this.isEdit) {
        return false;
      }
      var action = confirm("入稿カレンダーを更新します。よろしいですか。");
      if (!action) {
        return false;
      }
      this.draft.note = this.note;
      await DraftDealRepository.update(this.draft);
      (this.$refs.modal as BModal).hide();
      this.$emit("close");
    }
  }
});
