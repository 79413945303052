import repository from "@/repositories/repository";

import { ClientStaff } from "@/repositories/clientStaffs";
import { AgencyStaff } from "@/repositories/agencyStaffs";

export type BusinessPartnerStaff = {
  client: {
    id: number;
    client_id: number;
    name: string;
    email: string;
    tel: string;
    staff_type: number;
    disabled: number;
  }[];
  agency: {
    id: number;
    sales_channel_id: number;
    name: string;
    email: string;
    tel: string;
    staff_type: number;
    disabled: number;
  }[];
};

type Query = {
  id: number;
};

type PostQuery = {
  id?: number;
  client?: ClientStaff[];
  agency?: AgencyStaff[];
};

export default {
  list: async (token: string): Promise<BusinessPartnerStaff[]> => {
    const response = await repository(token).get(
      `/api/business/partner/staffs`
    );
    return response.data;
  },

  search: async (
    token: string,
    query: Query
  ): Promise<BusinessPartnerStaff> => {
    const response = await repository(token).get(
      `/api/business/partner/staffs/search/${query.id}`
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<BusinessPartnerStaff> => {
    const response = await repository(token).get(
      `/api/business/partner/staffs/${id}`
    );
    return response.data;
  },

  store: async (
    token: string,
    query: PostQuery
  ): Promise<BusinessPartnerStaff[]> => {
    const response = await repository(token).post(
      `/api/business/partner/staffs`,
      {
        params: query
      }
    );
    return response.data;
  }
};
