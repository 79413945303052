



































































































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import DraftDealRepository, { DraftDeal } from "@/repositories/draftDeals";
import DraftSheetRepository, {
  CeDraftDetail,
  HtmlDraftDetail
} from "@/repositories/draftSheet";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Datepicker from "@/components/calendars/Datepicker.vue";

type DataType = {
  loading: boolean;
  running: boolean;
  error: boolean;
  draftDeals: DraftDeal[];
  selected: number[];
  date_setter: {
    date_from: string;
    date_to: string;
  };
};

export default Vue.extend({
  components: {
    Datepicker
  },
  data: function(): DataType {
    return {
      loading: false,
      running: false,
      error: false,
      draftDeals: [],
      selected: [],
      date_setter: {
        date_from: "",
        date_to: ""
      }
    };
  },
  props: {
    ceDraftDetail: {
      type: Object as PropType<CeDraftDetail>,
      required: false
    },
    htmlDraftDetail: {
      type: Object as PropType<HtmlDraftDetail>,
      required: false
    },
    draftSheetId: {
      type: Number,
      required: true
    },
    firstDealId: {
      type: Number,
      required: false
    }
  },
  methods: {
    show() {
      this.index();
      (this.$refs.modal as BModal).show();
    },
    hide() {
      (this.$refs.modal as BModal).hide();
    },
    index: async function() {
      this.loading = true;
      // 長期グループの一覧を表示
      await DraftDealRepository.groupIndex(this.firstDealId)
        .then(response => {
          this.draftDeals = response;
          this.getRegisteredIds();
          this.setDatepicker();
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRegisteredIds: async function() {
      // 長期掲載設定済みの入稿カレンダーIDを取得
      await DraftSheetRepository.longTermIndex(this.draftSheetId)
        .then(response => {
          this.selected = response;
        })
        .catch(e => {
          console.log(e);
        });
    },
    setDatepicker() {
      // 範囲一括指定の初期値設定
      this.date_setter.date_from = this.draftDeals[0].published_date_from;
      this.date_setter.date_to = this.draftDeals[
        this.draftDeals.length - 1
      ].published_date_from;
    },
    save: async function() {
      if (
        !confirm("選択されている案件で長期掲載日設定します。よろしいですか。")
      ) {
        return false;
      }
      // 長期掲載日設定処理
      this.running = true;
      // 長期掲載日を設定
      await DraftSheetRepository.saveDraftSheetGroup(
        this.draftSheetId,
        this.selected
      )
        .then(() => {
          this.$emit("success");
        })
        .catch(() => {
          this.$emit("error");
        })
        .finally(() => {
          this.running = false;
          this.hide();
        });
    },
    setFilteredDraftDeals: function(): DraftDeal[] {
      dayjs.extend(isBetween);
      // dayjsのisBetween,isAfter,isBeforeが指定範囲の最初と最後を含まない処理の為Fromは前日、Toは翌日で範囲指定
      const from =
        this.date_setter.date_from !== null
          ? dayjs(this.date_setter.date_from).subtract(1, "day")
          : null;
      const to = this.date_setter.date_to
        ? dayjs(this.date_setter.date_to).add(1, "day")
        : null;

      // バリデーション
      if (!this.valid(from, to)) return [];

      // 掲載日が指定範囲に含まれる入稿カレンダーレコードを抽出
      return this.draftDeals.filter(draftDeal => {
        // 両方指定がある場合
        if (from !== null && to !== null) {
          return dayjs(draftDeal.published_date_from).isBetween(from, to);
        }
        // 日付fromのみ指定
        if (from !== null && to === null) {
          return dayjs(draftDeal.published_date_from).isAfter(from);
        }
        // 日付toのみ指定
        if (from === null && to !== null) {
          return dayjs(draftDeal.published_date_from).isBefore(to);
        }
      });
    },
    valid: function(from: Dayjs | null, to: Dayjs | null): boolean {
      this.error = false;
      if (from !== null && to !== null && !to.isAfter(from)) {
        this.error = true;
        return false;
      }
      return true;
    },
    setSelected: function() {
      // 掲載日が指定範囲に含まれる入稿カレンダーレコードを抽出
      const filteredDraftDeals = this.setFilteredDraftDeals();

      // 抽出した入稿カレンダーレコードのIDを抽出し選択
      if (filteredDraftDeals !== []) {
        this.selected = [];
        filteredDraftDeals.forEach(draftDeal => {
          if (draftDeal.id) {
            this.selected.push(draftDeal.id);
          }
        });
        // 今表示している案件が候補に入らない場合強制的にいれる
        const currentDraftDeal = this.ceDraftDetail
          ? this.ceDraftDetail.draft_deal
          : this.htmlDraftDetail.draft_deal;
        if (
          currentDraftDeal &&
          currentDraftDeal.id &&
          !this.selected.includes(currentDraftDeal.id)
        ) {
          this.selected.push(currentDraftDeal.id);
        }
      }
    },
    dateFormat: function(date: string): string {
      return dayjs(date).format("YYYY年M月D日");
    },
    isDraftDealSelectable: function(draftDeal: DraftDeal): boolean {
      const currentDraftDeal = this.ceDraftDetail
        ? this.ceDraftDetail.draft_deal
        : this.htmlDraftDetail.draft_deal;
      return currentDraftDeal.id === draftDeal.id;
    }
  }
});
