
























































import Vue from "vue";
import { BModal } from "bootstrap-vue";

type DataType = {
  data: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      data: []
    };
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    rowDelimitedChar25(): string {
      // テキスト素材がない場合は空文字で返却
      const text = this.value;
      if (text === undefined || text === null) return "";
      // 改行コードを\nに統一
      const textRows = text.replace(/\r?\n/g, "\n").split(/\n/);
      let result = "";
      textRows.map(function(textRow: string) {
        let count = 0;
        for (let i = 0; i < textRow.length; i++) {
          // 半角の場合0.5、全角の場合1で文字数をカウント
          count += textRow[i].match(/[ -~]/) ? 0.5 : 1;
          // １行を25文字で表示する要件があるため25文字を超えている場合は強制的に改行コードを追加
          if (count > 25) {
            result += "\n";
            count = 0; // 25文字超えたらカウントをリセット
          }
          result += textRow[i];
        }
        result += "\n";
      });

      return result;
    }
  },
  methods: {
    show() {
      (this.$refs.modal as BModal).show();
    },
    hide() {
      (this.$refs.modal as BModal).hide();
    }
  }
});
