


































































































































































































































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import ClientRepository, { Client, newClient } from "@/repositories/clients";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import UserRepository, { User } from "@/repositories/users";
import clients from "@/repositories/clients";
import NewSaleDefsModalVue from "./NewSaleDefsModal.vue";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  client: Client;
  clientAttributes: ClientAttribute[];
  users: User[];
  errorMsg: string[];
  isError: boolean;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      client: newClient(),
      clientAttributes: [],
      users: [],
      errorMsg: [],
      isError: false
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return (
        !this.loading &&
        this.users.length > 0 &&
        this.clientAttributes.length > 0
      );
    },
    isClientWritable: function(): boolean {
      return AuthHelper.isClientWritable();
    },
    isClientUpdatable: function(): boolean {
      return AuthHelper.isClientUpdatable();
    },
    isClientDeletable: function(): boolean {
      return AuthHelper.isClientDeletable();
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(newId, oldId) {
      this.resetData();
      if (!this.isNew) {
        await ClientRepository.get(newId)
          .then(response => {
            this.client = response;
          })
          .catch(e => {
            console.log(e);
            this.errorMsg.push("クライアントの詳細情報取得に失敗しました");
            this.isError = true;
          });
      }
      // 新規作成の場合も編集の場合もここでローダーを停止
      this.loading = false;
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.isError = false;
      this.errorMsg = [];
      this.client = newClient();
    },
    async postClient() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ClientRepository.store(this.client, "" /*TODO*/);
      (this.$refs.modal as BModal).hide();
      this.$emit("success");
      return this.client;
    },
    async updateClient(id: number) {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      await ClientRepository.update(id, this.client, "" /*TODO*/);
      (this.$refs.modal as BModal).hide();
      this.$emit("update");
      return this.client;
    },
    async deleteClient(id: number) {
      if (this.client.staff.length == 0) {
        let action = confirm("本当に削除してよろしいですか？");
        if (!action) {
          return false;
        }
        await ClientRepository.destroy(id, "" /*TODO*/);
        (this.$refs.modal as BModal).hide();
        this.$emit("delete");
      } else {
        this.errorMsg = this.deleteValidation();
        if (this.errorMsg.length > 0) {
          this.isError = true;
          return false;
        }
      }
    },
    validation: function() {
      let msg: string[];
      msg = [];
      if (this.client.name === null || this.client.name === "") {
        msg.push("クライアント名を入力してください。");
      }
      if (
        this.client.abbreviation === null ||
        this.client.abbreviation === ""
      ) {
        msg.push("略称を入力してください。");
      }
      if (this.client.client_attribute_id === 0) {
        msg.push("クライアント属性を選択してください。");
      }
      if (this.client.ipg_staff_user.id === 0) {
        msg.push("IPG担当(窓口)を選択してください。");
      }
      if (
        this.client.registration_code === null ||
        this.client.registration_code === ""
      ) {
        msg.push("広告主IDを入力してください。");
      }
      if (
        this.client.registration_code &&
        (this.client.registration_code.length < 3 ||
          this.client.registration_code.length > 5)
      ) {
        msg.push("広告主IDは3桁〜5桁で入力してください。");
      }
      return msg;
    },
    deleteValidation: function() {
      let msg: string[];
      msg = [];
      if (this.client.staff.length > 0) {
        msg.push("担当者が登録済みのため削除できません。");
      }
      return msg;
    }
  },
  mounted: async function() {
    this.users = await UserRepository.list("" /*TODO*/);
    this.clientAttributes = await ClientAttributeRepository.list("");
  }
});
