

































































import Vue from "vue";

import * as AuthHelper from "@/helpers/auth";

export default Vue.extend({
  computed: {
    isSalesWriter: function() {
      return AuthHelper.isSalesWriter();
    },
    isDraftViewable: function() {
      return AuthHelper.isDraftViewable();
    }
  }
});
