



































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";

import DraftDealRepository, {
  DraftDeal,
  newDraftDeal
} from "@/repositories/draftDeals";

type DataType = {
  isError: boolean;
  deal?: DraftDeal;
  selectAnime: number;
  disabled: boolean;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      isError: false,
      deal: newDraftDeal(),
      selectAnime: -1,
      disabled: false
    };
  },
  props: {
    draft: {
      type: Object as PropType<DraftDeal>,
      required: false
    }
  },
  watch: {
    draft: function() {
      this.selectAnime = -1;
      this.disabled = true;
      this.deal = this.draft || newDraftDeal();
      if (
        this.deal.id != null &&
        this.deal.conventional_animation_time > 0 &&
        this.deal.new_animation_time > 0
      ) {
        this.selectAnime = 2;
        this.disabled = false;
      } else if (
        this.deal.conventional_animation_time == 0 &&
        this.deal.new_animation_time == 0
      ) {
        this.selectAnime = 1;
        this.disabled = true;
      }
    }
  },
  methods: {
    show: async function() {
      this.isError = false;
      (this.$refs.modal as BModal).show();
    },
    update: async function() {
      var action = confirm("入稿カレンダーを更新します。よろしいですか。");
      if (!action) return false;
      if (!this.deal) return false;
      this.isError = false;
      switch (Number(this.selectAnime)) {
        case 0: // デフォルト値を反映の場合クライアント情報から取得
          this.deal.conventional_animation_time =
            this.deal.client.legacy_model_animation_seconds ?? 0;
          this.deal.new_animation_time =
            this.deal.client.new_model_animation_seconds ?? 0;
          break;
        case 1: // ANなしの場合秒数をリセット
          this.deal.conventional_animation_time = 0;
          this.deal.new_animation_time = 0;
          break;
        case 2: // ANあり
          if (
            this.deal.conventional_animation_time < 1 &&
            this.deal.new_animation_time < 1
          ) {
            this.isError = true;
            break;
          }
          break;
      }
      if (this.isError) {
        return false;
      }
      await DraftDealRepository.update(this.deal);
      (this.$refs.modal as BModal).hide();
      this.$emit("close");
    }
  },
  mounted: function() {
    this.deal = this.draft || newDraftDeal();
  }
});
