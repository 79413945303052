export type Destination = {
  code: number;
  name: string;
};

export const DestinationCode = (code: number): string | undefined =>
  destinations.find(x => x.code === code)?.name;

export const DestinationName = (name: string): number | undefined =>
  destinations.find(x => x.name === name)?.code;

export const destinations: Destination[] = [
  { code: 1, name: "広告詳細" },
  { code: 2, name: "番組詳細" },
  { code: 3, name: "CH選局" },
  { code: 4, name: "過去番組詳細" },
  { code: 5, name: "コンテンツ詳細" },
  { code: 6, name: "コンテンツ詳細(リスト形式)" },
  { code: 7, name: "コンテンツ直接再生" }
];
