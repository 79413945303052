












































































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import { AxiosError } from "axios";

import dayjs from "dayjs";
import "dayjs/locale/ja";
dayjs.locale("ja");

import Menu from "@/components/Menu.vue";
import WakuDayCalendar from "@/components/WakuDayCalendar.vue";
import NewWakuModal from "@/components/NewWakuModal.vue";

import WakuRepository from "@/repositories/waku";
import AdMediaRepository from "@/repositories/medias";
import { Deal } from "@/repositories/deals";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  isNewFormShown: boolean;
  filterMedia: string[];
  waku: unknown;
  adMedia: unknown[];
  asAdmin: boolean;
  message: string;
  success: boolean;
  warningMessage: string;
  warning: boolean;
  hasError: boolean;
  error?: string;
  loading: boolean;
};

export default Vue.extend({
  components: {
    Menu,
    WakuDayCalendar,
    NewWakuModal
  },
  data: function(): DataType {
    return {
      isNewFormShown: true,
      filterMedia: [],
      waku: {},
      adMedia: [],
      asAdmin: false,
      message: "",
      success: false,
      warningMessage: "",
      warning: false,
      hasError: false,
      error: undefined,
      loading: true
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().year()
    },
    month: {
      type: Number,
      required: true,
      default: dayjs().month() + 1, // 0-11(dayjs.month) to 1-12(prop)
      validator: function(value) {
        return 1 <= value && value <= 12;
      }
    },
    date: {
      type: Number,
      required: true,
      default: dayjs().date(),
      validator: function(value) {
        return 1 <= value && value <= 31;
      }
    }
  },
  computed: {
    currentDate: function() {
      return dayjs()
        .year(this.year)
        .month(this.month - 1)
        .date(this.date)
        .startOf("day");
    },
    editable: function() {
      // 掲載日が2日以上過去の場合新規登録を不可
      const pastDay = dayjs().add(1, "day");
      const current = dayjs()
        .year(this.year)
        .month(this.month - 1)
        .date(this.date);
      return AuthHelper.isDealEditor() && current.isAfter(pastDay);
    }
  },
  watch: {
    date: async function(val, oldVal) {
      if (val !== oldVal) {
        this.fetchWaku(this.year, this.month, this.date);
      }
    }
  },
  methods: {
    asAdminChanged: function(event: Event) {
      this.asAdmin = (event.target as HTMLInputElement).checked;
    },
    changeFilterMedia: function(filterMedia: any) {
      this.filterMedia = filterMedia;
    },
    isToday: function(): boolean {
      const today = dayjs();
      const startOfToday = dayjs()
        .year(today.year())
        .month(today.month())
        .date(today.date())
        .startOf("day");
      return startOfToday.isSame(this.currentDate);
    },
    previousDayRoute: function(): Record<string, unknown> {
      const previousDay = this.currentDate.add(-1, "day");
      return this.dayRoute(previousDay);
    },
    nextDayRoute: function(): Record<string, unknown> {
      const nextDay = this.currentDate.add(1, "day");
      return this.dayRoute(nextDay);
    },
    todayRoute: function(): Record<string, unknown> {
      const today = dayjs();
      const todayDate = this.currentDate
        .year(today.year())
        .month(today.month())
        .date(today.date())
        .startOf("day");
      return this.dayRoute(todayDate);
    },
    dayRoute: function(day: dayjs.Dayjs): Record<string, unknown> {
      return {
        name: "waku.day",
        params: {
          year: day.year().toString(),
          month: (day.month() + 1).toString(),
          date: day.date().toString(),
          media: this.filterMedia
        }
      };
    },
    monthRoute: function() {
      return {
        name: "waku.month",
        params: {
          year: this.currentDate.year().toString(),
          month: (this.currentDate.month() + 1).toString(), // 0-11(dayjs.month) to 1-12(params)
          media: this.filterMedia
        }
      };
    },
    weekRoute: function(): Record<string, unknown> {
      var year = this.currentDate.year().toString();
      // 年またぎの週を考慮
      if (this.currentDate.year() != this.currentDate.endOf("week").year()) {
        year = this.currentDate
          .endOf("week")
          .year()
          .toString();
      }
      return {
        name: "waku.week",
        params: {
          year: year,
          week: this.currentDate.week().toString(),
          media: this.filterMedia
        }
      };
    },
    showNewForm() {
      this.isNewFormShown = true;
      (this.$refs.newWakuModal as BModal).show();
    },
    closeNewForm() {
      this.isNewFormShown = false;
    },
    dateFormat(date: dayjs.Dayjs): string {
      return date.format("YYYY年M月D日(ddd)");
    },
    fetchWaku: async function(
      year: number,
      month: number,
      date: number,
      showLoading = true
    ) {
      if (showLoading) {
        this.loading = true;
      }
      await WakuRepository.date("" /*TODO*/, {
        year: year,
        month: month,
        date: date
      }).then(waku => {
        this.waku = waku;
        if (year === this.year && month === this.month && date === this.date) {
          this.loading = false;
        }
      });
    },
    onSuccess: async function(deal: Deal, waitingDeals: Deal[] = []) {
      this.message = deal.id
        ? "枠表案件の更新が完了しました。"
        : "枠表案件の新規登録が完了しました。";
      this.success = true;
      if (waitingDeals.length > 0) {
        this.message = [
          this.message,
          "キャンセル待ちがありますのでご確認ください。"
        ].join("\n");
        this.warning = true;
        this.warningMessage = waitingDeals
          .map((deal: Deal) => {
            // [掲載日：YYYY年M月D日　媒体：xxx　クライアント：xxx　タイトル：xxx]がキャンセル待ちになっています。
            return `[掲載日：${dayjs(deal.published_date.date.from).format(
              "YYYY年M月D日"
            )}　媒体：${deal.ad_media.name}　クライアント：${
              deal.client.name
            }　タイトル：${deal.title}]がキャンセル待ちになっています。`;
          })
          .join("\n");
      }
      this.fetchWaku(this.year, this.month, this.date, false);
    },
    onRemove: async function() {
      this.message = "枠表案件の予約を解除しました。";
      this.success = true;
      this.fetchWaku(this.year, this.month, this.date, false);
    },
    reload: async function() {
      this.fetchWaku(this.year, this.month, this.date, true);
    },
    fail(error: AxiosError) {
      this.hasError = true;
      if (error.response?.status === 403) {
        this.error = "権限が不足しています。";
      } else {
        this.error = "エラーが発生しました";
      }
    }
  },
  mounted: async function() {
    const adMedia = await AdMediaRepository.list("" /*TODO*/);
    this.adMedia = adMedia;
    this.filterMedia = adMedia.map((x: any) => x.id);

    this.fetchWaku(this.year, this.month, this.date);
  }
});
