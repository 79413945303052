import repository from "@/repositories/repository";

export type CeAdResource = {
  id?: number;
  nce_minds_id?: number;
  animation_seconds: {
    otd?: number;
    nce?: number;
  };
  stock_folder_path: {
    path?: string;
    url?: string;
    t_drive?: string;
  };
  otd_top_banner_1: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  otd_top_banner_2: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  otd_detail_banner: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  otd_top_banner_pdo: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
  };
  nce_top_banner_1: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  nce_top_banner_2: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  nce_detail_banner: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  text_resource: {
    path?: string;
    name?: string;
    extend?: string;
    text?: any;
    binary?: string;
  };
};

export default {
  upload: async (draft_sheet_id: number, formData: FormData): Promise<void> => {
    const headers = { "Content-Type": "multipart/form-data" };
    await repository().post(
      `/api/draftSheets/${draft_sheet_id}/ceAdResources/upload`,
      formData,
      { headers }
    );
  },

  encodeText: async (formData: FormData): Promise<string> => {
    const headers = { "Content-Type": "multipart/form-data" };
    const response = await repository().post(
      `/api/ceAdResources/encodeText`,
      formData,
      {
        headers
      }
    );
    return response.data;
  }
};
