import repository from "@/repositories/repository";

export type HtmlAdResource = {
  // HTML広告素材情報
  id?: number;
  animation_seconds?: number; // AN秒数
  destination: {
    code: number;
    name: string;
  }; // 遷移先
  channel: {
    // チャンネル
    id?: number;
    name?: string;
    gcn?: string;
    disabled?: boolean;
    client_name?: string;
  };
  program: {
    name?: string; // 番組名
    broadcast_date: {
      // 放送日
      from?: string;
      to?: string;
    };
    broadcast_time: {
      // 放送時間
      from?: string;
      to?: string;
    };
  };
  stock_folder_path: {
    path?: string;
    url?: string;
    t_drive?: string;
  };
  top_banner_1: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  top_banner_2: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
  popup_banner: {
    path?: string;
    name?: string;
    extend?: string;
    binary?: string;
    src?: string;
  };
};

export default {
  upload: async (draft_sheet_id: number, formData: FormData): Promise<void> => {
    const headers = { "Content-Type": "multipart/form-data" };
    await repository().post(
      `/api/draftSheets/${draft_sheet_id}/htmlAdResources/upload`,
      formData,
      { headers }
    );
  }
};
