



























































import Vue from "vue";

import { isRouteForGuest } from "@/router";

export default Vue.extend({
  computed: {
    isGuestPage(): boolean {
      return isRouteForGuest(this.$route);
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("auth/logout").then(() => {
        location.href = "/logout";
        // this.$router.push({ path: "/login" });
      });
    }
  }
});
