




















































































































































































































































































































































































































import Vue, { PropType } from "vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import YearMonthPicker from "@/components/calendars/YearMonthPicker.vue";
import Datepick from "@/components/calendars/Datepicker.vue";
import DateInput from "@/components/DateInput.vue";

import { BModal } from "bootstrap-vue";
import YomiRepository, { newYomi, Yomi } from "@/repositories/yomi";
import ClientRepository, { Client } from "@/repositories/clients";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import SaleChannelRepository, {
  SaleChannel
} from "@/repositories/saleChannels";
import MediaRepository, { Media } from "@/repositories/medias";
import { Area } from "@/repositories/areas";
import SaleDefRepository, { SaleDef } from "@/repositories/saleDefs";
import UserRepository, { User } from "@/repositories/users";

import SearchAreaModal from "./modals/SearchAreaModal.vue";
import * as holiday_jp from "@holiday-jp/holiday_jp";

import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isCopy: boolean;
  isError: boolean;
  isSearchAreaActive: boolean;
  errorMsg: string[];
  processing: boolean;
  clients: Client[];
  clientAttributes: ClientAttribute[];
  saleChannels: SaleChannel[];
  medias: Media[];
  orgAdArea?: {
    id?: number;
    name?: string;
  };
  saleDefs: SaleDef[];
  yomi: Yomi;
  user: User;
  fromYearMonth: string;
  fromDay: string;
  toYearMonth: string;
  toDay: string;
  clientName: string;
};
export default Vue.extend({
  components: {
    SearchAreaModal,
    YearMonthPicker,
    Datepick,
    DateInput
  },
  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    yomiDetail: {
      type: Object as PropType<Yomi>,
      required: false
    },
    isMgt: {
      type: Boolean,
      default: false
    }
  },
  data: function(): DataType {
    return {
      loading: false,
      isCopy: false,
      isError: false,
      processing: false,
      isSearchAreaActive: true,
      clients: [],
      clientAttributes: [],
      saleChannels: [],
      medias: [],
      orgAdArea: {
        id: undefined,
        name: undefined
      },
      saleDefs: [],
      errorMsg: [],
      yomi: newYomi(),
      user: { id: 0, name: "", email: "", disabled: 0 },
      fromYearMonth: "",
      fromDay: "",
      toYearMonth: "",
      toDay: "",
      clientName: ""
    };
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    canDeleteSales: function() {
      const cooperation =
        this.yomiDetail &&
        this.yomiDetail.deal_id &&
        this.yomiDetail.deal_id != null
          ? false
          : true;
      return AuthHelper.canDeleteSales() && cooperation;
    },
    isDealAdmin: function(): boolean {
      return AuthHelper.isDealAdmin();
    },
    editable: function(): boolean {
      const editable =
        this.yomi.deal_id && this.yomi.deal_id > 0 ? false : true;
      return this.isCopy || this.isNew || editable;
    },
    pastEditable: function(): boolean {
      // 管理者は更新できるようにする
      if (this.isDealAdmin) return true;

      // 管理者以外の場合、枠表の掲載日 >= 明後日 なら更新可
      if (this.yomi) {
        const publishedDate = dayjs(this.yomi.published_date.from).startOf(
          "day"
        );
        const dayAfterTomorrow = dayjs()
          .startOf("day")
          .add(2, "day");
        // 掲載日 >= 明後日なら true
        dayjs.extend(isSameOrAfter);
        return publishedDate.isSameOrAfter(dayAfterTomorrow);
      }
      return false;
    },
    canUpdate: function(): boolean {
      const user = this.yomiDetail?.user;
      const isOwn = user?.id ? AuthHelper.isOwn(user.id) : false;
      const isAdmin = AuthHelper.isAdminSalesEditor();
      return isOwn || isAdmin;
    },
    isWholeAreaSelectable: function(): boolean {
      // 編集可 or 新規 or 既に「全国」が選択されている場合、選択可
      if (
        this.editable ||
        this.isNew ||
        this.orgAdArea?.id === 1 ||
        this.yomi.ad_area.id === 1
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {
    yomiDetail: async function(val: Yomi, old: Yomi) {
      await this.setYomi();
    }
  },
  mounted: async function() {
    this.clients = await ClientRepository.actives("" /*TODO*/);
    this.clientAttributes = await ClientAttributeRepository.actives(
      "" /*TODO*/
    );
    this.saleChannels = await SaleChannelRepository.actives("" /*TODO*/);
    this.medias = await MediaRepository.actives("" /*TODO*/);
    this.saleDefs = await SaleDefRepository.actives("", { is_yomi: true });
    this.user = await UserRepository.currentUser("");
    this.yomi = this.yomiDetail || newYomi();
    this.yomi.user = this.user;
  },

  methods: {
    setYomi: async function() {
      this.loading = true;
      // 初期化処理
      await this.reset();
      // 詳細表示の場合
      if (!this.isNew) {
        this.yomi = Object.assign({}, this.yomiDetail);
        this.yomi.sales_probability_def.before = this.yomiDetail.sales_probability_def.id;
        if (this.yomi.sales_channel == null) {
          this.yomi.sales_channel = { id: 0 };
        }
        // 掲載日
        if (this.yomi.published_date.from != "") {
          this.fromYearMonth =
            this.yomiDetail.date_format === "MM月"
              ? dayjs(this.yomi.published_date.from).format("YYYY-MM")
              : this.yomi.published_date.from;
          this.fromDay =
            this.yomiDetail.date_format === "MM月"
              ? ""
              : dayjs(this.yomi.published_date.from).format("DD");
        }
        //TODO: エリアが未設定のデータ
        if (this.yomi.ad_area === null) {
          this.yomi.ad_area = { id: undefined, name: "" };
        }
        this.orgAdArea = this.yomi
          ? { id: this.yomi.ad_area.id, name: this.yomi.ad_area.name }
          : undefined;

        if (this.yomi.published_date.to) {
          const published_date_to = String(this.yomi.published_date.to)
            .split("-")
            .join("");
          if (published_date_to.length > 0) {
            const year = Number(published_date_to.substr(0, 4));
            const month = Number(published_date_to.substr(4, 2));
            const date = Number(published_date_to.substr(6, 2));

            if (!isNaN(year) && !isNaN(month) && !isNaN(date)) {
              this.toYearMonth = dayjs()
                .year(year)
                .month(month - 1)
                .date(date)
                .format("YYYY-MM-DD");
            }

            this.toDay = dayjs(this.yomi.published_date.to).format("DD");
          }
        }
      }
      // 金額がnullの場合
      this.yomi.media_price =
        this.yomi.media_price != null ? this.yomi.media_price : "0";
      this.yomi.working_price =
        this.yomi.working_price != null ? this.yomi.working_price : "0";
      this.loading = false;
    },
    copy: async function() {
      this.isCopy = true;
      this.reset();
      this.yomi.id = undefined;
      this.yomi.deal_id = undefined;
      this.yomi.is_long_term = false;
    },
    reset: async function() {
      if (this.isNew) {
        this.yomi = newYomi();
      }
      let fromYearMonth = dayjs().add(2, "day");
      let billing_month = dayjs().add(2, "day");
      // 複製かつ案件の掲載日が現在の日付+2よりも未来の場合は案件の掲載日を使用する
      if (
        this.isCopy &&
        fromYearMonth.isBefore(dayjs(this.yomi.published_date.from))
      ) {
        fromYearMonth = dayjs(this.yomi.published_date.from);
        billing_month = dayjs(this.yomi.published_date.from);
      }
      this.fromYearMonth = fromYearMonth.format("YYYY-MM-DD");
      this.yomi.billing_month = billing_month.format("YYYY-MM");

      this.fromDay = "";
      this.toYearMonth = "";
      this.toDay = "";
      this.clientName = "";
      this.isError = false;
      this.errorMsg = [];
    },
    show() {
      this.isCopy = false;
      this.isError = false;
      this.setYomi();
      (this.$refs.modal as BModal).show();
    },
    priceFormat(price: string) {
      switch (price) {
        case "mediaPrice":
          this.yomi.media_price = this.setPrice(this.yomi.media_price);
          break;
        case "workingPrice":
          this.yomi.working_price = this.setPrice(this.yomi.working_price);
          break;
      }
    },
    changeDates: function() {
      if (!this.loading) {
        const date = this.fromYearMonth.split("-");
        this.yomi.billing_month = dayjs()
          .year(Number(date[0]))
          .month(Number(date[1]) - 1)
          .format("YYYY-MM");
        this.setMaterialFixedDate();
      }
    },
    setNoneDay: function() {
      return this.yomi.date_format === "MM月" ? true : false;
    },
    setPublishDates: function(from: string, to: string) {
      let str_from = dayjs(from).format("YYYY年M月D日(dd)");
      let str_to = "";
      if (to != null && to != "") {
        str_to = dayjs(to).format("YYYY年M月D日(dd)");
      }
      return str_to != "" ? str_from + " 〜 " + str_to : str_from;
    },
    setToPublishedDate: function() {
      if (this.yomi.is_long_term) {
        this.toYearMonth = this.fromYearMonth;
      } else {
        this.toYearMonth = "";
      }
    },
    setPrice: function(price: string) {
      if (price == null) {
        return "0";
      }
      price = price.split(",").join("");
      price = price.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(price) {
        return String.fromCharCode(price.charCodeAt(0) - 0xfee0);
      });
      if (!isNaN(Number(price))) {
        price = Number(price).toLocaleString();
      } else {
        price = "0";
      }
      return price;
    },
    setDay(isPublication: string) {
      let setDate = dayjs();
      let month;
      let date;
      if (isPublication === "from") {
        month = dayjs(this.fromYearMonth).month();
        date = dayjs(this.fromYearMonth).date();
        this.fromDay = "";
        if (
          Number(date) > 0 &&
          this.validationPublicationDate(
            dayjs(this.fromYearMonth).year(),
            month,
            date
          )
        ) {
          setDate = dayjs(this.fromYearMonth)
            .month(month)
            .date(date);
          this.fromDay = setDate.format("(dd)");
          if (this.yomi.client.id > 0) {
            this.setMaterialFixedDate();
          }
        }
      } else if (isPublication === "to") {
        month = dayjs(this.toYearMonth).month();
        date = dayjs(this.toYearMonth).date();
        this.toDay = "";
        if (
          Number(date) > 0 &&
          this.validationPublicationDate(
            dayjs(this.toYearMonth).year(),
            month,
            date,
            true
          )
        ) {
          setDate = dayjs(this.toYearMonth)
            .month(month)
            .date(date);
          this.toDay = setDate.format("dd");
        }
      }
    },
    setMedia: function() {
      this.yomi.ad_media.is_only_nationwide = 0;
      let id = this.yomi.ad_media.id;
      let media = this.medias.find(x => x.id === id);
      if (media && media.is_only_nationwide && media.is_only_nationwide === 1) {
        this.yomi.ad_area.id = 1;
        this.yomi.ad_area.name = "全国";
        this.yomi.ad_media.is_only_nationwide = 1;
      }
    },
    async searchClient() {
      if (this.clientName !== "") {
        this.clients = await ClientRepository.searchActive(this.clientName, "");
      } else {
        this.clients = await ClientRepository.actives("");
      }
    },
    changeClient() {
      if (this.yomi.client.id > 0) {
        this.searchClientAttribute();
        this.setMaterialFixedDate();
      } else {
        this.yomi.material_fixed_date = "";
      }
    },
    searchClientAttribute() {
      const client = this.clients.find(x => x.id === this.yomi.client.id);
      if (client && this.yomi.client_attribute) {
        this.yomi.client_attribute.id = client.client_attribute_id;
      } else {
        // this.yomi.client_attribute.id = 0;
      }
    },
    setMaterialFixedDate() {
      if (this.fromYearMonth.split("-").join("").length < 8) {
        this.yomi.material_fixed_date = "";
        return;
      }
      let index = 0;
      let materialFixedDate = dayjs(this.fromYearMonth);
      let client = this.clients.find(x => x.id === this.yomi.client.id);
      if (client) {
        while (index < client.material_fixed_required_days) {
          materialFixedDate = materialFixedDate.add(-1, "day");
          if (
            materialFixedDate.day() > 0 &&
            materialFixedDate.day() < 6 &&
            !holiday_jp.isHoliday(materialFixedDate.toDate())
          ) {
            index++;
          }
        }
        this.yomi.material_fixed_date = dayjs(materialFixedDate).format(
          "YYYY-MM-DD"
        );
      }
    },
    selectArea: async function(area: Area) {
      this.yomi.ad_area.id = area.id;
      this.yomi.ad_area.name = area.name;
    },
    async postYomi() {
      this.processing = true;
      if (!this.validation()) {
        this.processing = false;
        return false;
      }
      if (
        this.yomi.id &&
        this.yomi.deal_id &&
        this.yomi.deal_id != null &&
        this.yomi.sales_probability_def.before &&
        this.yomi.sales_probability_def.before < 9 &&
        this.yomi.sales_probability_def.id === 9
      ) {
        let action = confirm(
          "ヨミ確度をオチに変更すると連携している枠表案件が解除されます。よろしいですか。"
        );
        if (!action) {
          this.processing = false;
          return false;
        }
      }
      const data = await this.normalization();
      if (this.yomi.id == undefined) {
        await YomiRepository.create(data, "");
        alert("案件が登録されました。"); //TODO
      } else {
        await YomiRepository.update(data, true, "");
        alert("案件が更新されました。"); //TODO
      }
      this.processing = false;
      this.$emit("updateList");
      (this.$refs.modal as BModal).hide();
    },
    async deleteYomi() {
      const action = confirm("案件を削除します。よろしいですか。");
      if (!action) {
        return false;
      }
      if (this.yomi.id) {
        await YomiRepository.destroy(this.yomi.id, "");
      }
      alert("案件が削除されました。");
      this.$emit("updateList");
      (this.$refs.modal as BModal).hide();
    },
    validation() {
      const errorMsg = [];
      if (
        this.yomi.deal_id &&
        this.yomi.sales_probability_def.before &&
        this.yomi.sales_probability_def.before > 2 &&
        this.yomi.sales_probability_def.id <= 2
      ) {
        errorMsg.push(
          "ヨミ確度M以上にヨミ表では更新できません。枠表で更新してください。"
        );
      }
      if (
        (this.yomi.deal_id == 0 || this.yomi.deal_id == null) &&
        this.yomi.sales_probability_def.id <= 2
      ) {
        errorMsg.push(
          "枠表と連携されていない案件はヨミ確度M以上を選択することができません。"
        );
      }
      if (this.fromYearMonth === "" || this.fromYearMonth == null) {
        errorMsg.push("掲載日(月)を入力してください。");
      }
      if (
        this.toYearMonth &&
        this.toYearMonth != "" &&
        this.toYearMonth != null
      ) {
        if (dayjs(this.fromYearMonth).isAfter(dayjs(this.toYearMonth))) {
          errorMsg.push(
            "掲載日の日付は開始<終了となるように入力してください。"
          );
        }
        if (
          !dayjs(this.fromYearMonth).isSame(dayjs(this.toYearMonth), "year") ||
          !dayjs(this.fromYearMonth).isSame(dayjs(this.toYearMonth), "month")
        ) {
          errorMsg.push("長期案件の入力は月を跨がないように入力してください。");
        }
      }
      if (this.yomi.billing_month === "" || this.yomi.billing_month == null) {
        errorMsg.push("計上月を入力してください。");
      }
      if (this.yomi.client.id == 0) {
        errorMsg.push("クライアントを選択してください。");
      }
      if (this.yomi.ad_media.id == 0) {
        errorMsg.push("掲載媒体を選択してください。");
      }
      if (this.yomi.ad_area.id == 0) {
        errorMsg.push("エリアを選択してください。");
      }
      if (this.yomi.sales_probability_def.id == 0) {
        errorMsg.push("ヨミ確度を選択してください。");
      }
      if (this.isCopy && this.yomi.sales_probability_def.id <= 2) {
        errorMsg.push("ヨミ確度を「M」以下にしてください。");
      }
      if (
        this.yomi.media_price != "" &&
        String(Number(this.yomi.media_price.split(",").join(""))) === "NaN"
      ) {
        errorMsg.push("媒体費の入力が正しくありません。");
      }
      if (
        this.yomi.working_price != "" &&
        String(Number(this.yomi.working_price.split(",").join(""))) === "NaN"
      ) {
        errorMsg.push("制作費の入力が正しくありません。");
      }
      if (errorMsg.length > 0) {
        this.isError = true;
        this.errorMsg = errorMsg;
        return false;
      }
      return true;
    },
    validationPublicationDate(
      year: number,
      month: number,
      date: number,
      to = false
    ) {
      if (to && month === 0) {
        return true;
      }
      // 月入力チェック
      if (!(month >= 1 && month <= 12)) {
        return false;
      }
      // 日入力チェック
      if (date > 0) {
        const endOfDate = dayjs()
          .year(year)
          .month(month - 1)
          .endOf("month")
          .date();
        if (date != null && (date < 1 || date > endOfDate)) {
          return false;
        }
      }
      return true;
    },
    validationDate(date: string) {
      if (!date.match(/^\d{2}\/\d{2}$/)) {
        return false;
      }
      const month = Number(date.split("/")[0]) - 1;
      const day = date.split("/")[1];
      const endOfDate = dayjs()
        .set("month", month)
        .endOf("month")
        .date();
      if (Number(day) < 1 || Number(day) > endOfDate) {
        return false;
      }
      return true;
    },
    normalization() {
      // 掲載日from
      let publishedDateFrom = this.fromYearMonth.split("-").join("");
      if (publishedDateFrom.length == 8) {
        publishedDateFrom = dayjs(this.fromYearMonth).format("YYYYMMDD");
      } else {
        publishedDateFrom = publishedDateFrom + "00";
      }
      // 掲載日To
      let publishedDateTo = "";
      if (this.toYearMonth != null && this.toYearMonth != "") {
        publishedDateTo = this.toYearMonth.split("-").join("");
        if (publishedDateTo.length == 8) {
          publishedDateTo = dayjs(this.toYearMonth).format("YYYYMMDD");
        } else {
          publishedDateTo = "";
          this.yomi.is_long_term = false;
        }
      } else {
        this.yomi.is_long_term = false;
      }
      this.yomi.published_date = {
        from: publishedDateFrom,
        to: publishedDateTo
      };
      this.yomi.media_price = this.yomi.media_price.split(",").join("");
      this.yomi.working_price = this.yomi.working_price.split(",").join("");
      this.yomi.is_recently_updated = true;
      return this.yomi;
    }
  }
});
