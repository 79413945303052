import dayjs from "dayjs";

type Client = {
  id?: number;
  name: string;
  attribute: {
    id?: number;
    name: string;
  };
  deleted_at?: string;
};

type SalesChannel = {
  id?: number;
};

type AdMedia = {
  id?: number;
  name?: string;
  is_only_nationwide?: number;
};

type AdArea = {
  id?: number;
};

type SalesProbabilityDef = {
  id?: number;
  name?: string;
};

type WorkPlan = {
  type?: number;
  ipg_notes: string;
};

export default class Deal {
  id?: number;
  staff?: {
    id?: number;
    name?: string;
  };
  user?: {
    id?: number;
    name?: string;
    emai?: string;
    disabled?: number;
  };
  yomi_deal?: {
    id?: number;
  };
  is_long_term = false;
  published_date: {
    date: {
      from: string;
      to: string;
    };
    time?: {
      from: string;
      to: string;
    };
  };
  client: Client;
  sales_channel: SalesChannel;
  ad_media: AdMedia;
  ad_area: AdArea;
  media_price?: string;
  working_price?: string;
  title = "未定";
  slot_priority = 1;
  sales_probability_def: SalesProbabilityDef;
  work_plan: WorkPlan;
  remarks = "";
  ad_type?: number;

  constructor(
    client: Client,
    salesChannel: SalesChannel,
    adMedia: AdMedia,
    adArea: AdArea,
    salesProbabilityDef: SalesProbabilityDef,
    workPlan: WorkPlan
  ) {
    this.published_date = {
      date: {
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD")
      },
      time: {
        from: "",
        to: ""
      }
    };
    this.client = client;
    this.sales_channel = salesChannel;
    this.ad_media = adMedia;
    this.ad_area = adArea;
    this.sales_probability_def = salesProbabilityDef;
    this.work_plan = workPlan;
  }

  static clone(d: Deal, user: any): Deal {
    const deal = new Deal(
      d.client,
      d.sales_channel,
      d.ad_media,
      d.ad_area,
      d.sales_probability_def,
      d.work_plan
    );

    deal.is_long_term = false;
    deal.published_date.date.to = "";
    deal.published_date.time = d.published_date.time;
    deal.media_price = d.media_price;
    deal.working_price = d.working_price;
    deal.remarks = d.remarks;
    deal.title = d.title;

    deal.user = user;
    deal.staff = d.staff ?? user;

    deal.slot_priority = d.slot_priority;
    deal.ad_type = d.ad_type;

    return deal;
  }
}
