
































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import dayjs from "dayjs";
import { AxiosError } from "axios";

import DealRepository, { Deal } from "@/repositories/deals";
import { workPlanType } from "@/helpers/workPlanType";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  deals: Deal[];
  hasError: boolean;
  error?: string;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      deals: [],
      hasError: false,
      error: undefined
    };
  },
  props: {
    dealId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    asAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    pastEditable: function(): boolean {
      var isPast = true;
      const deal = this.deals[0];
      if (deal) {
        const pastDay = dayjs().add(1, "day");
        const current = dayjs(deal.published_date.date.from);
        isPast = current.isAfter(pastDay);
      }
      return isPast;
    }
  },
  mounted: async function() {
    this.deals = await DealRepository.unit("" /*TODO*/, {
      deal_id: this.dealId
    });
  },
  methods: {
    show() {
      (this.$refs.modal as BModal).show();
    },
    hide() {
      (this.$refs.modal as BModal).hide();
    },
    dateRoute: function(date: string): string {
      const day = dayjs(date);
      return `/waku/day/${day.year()}/${day.month() + 1}/${day.date()}/`;
    },
    dateFormat: function(date: string) {
      return dayjs(date).format("YYYY年M月D日(ddd)");
    },
    workPlanType: function(code: number) {
      return workPlanType(code);
    },
    isConfident: function(deal: Deal): boolean {
      const salesProbabilityDef = deal.sales_probability_def;
      return salesProbabilityDef
        ? salesProbabilityDef.name === "M" || salesProbabilityDef.name === "J"
        : false;
    },
    removeAll: function() {
      if (confirm("一覧の案件をすべて解除しますか？")) {
        DealRepository.destroyUnit(this.dealId, "" /**TODO */)
          .then(() => {
            this.$emit("remove");
            this.hide();
          })
          .catch((error: AxiosError) => {
            this.hasError = true;
            if (error.response?.status === 403) {
              this.error = "権限が不足しています。";
            } else {
              this.error = "エラーが発生しました。";
            }
          });
      }
    },
    move: function(date: string) {
      this.$router.push(this.dateRoute(date));
      this.$emit("move");
      this.hide();
    }
  }
});
