/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";

import Vue from "vue";

import router from "@/router";
import store from "@/store";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPlus,
  faSearch,
  faSpinner,
  faTimes,
  faComment,
  faChevronLeft,
  faCopy,
  faTrashAlt,
  faSyncAlt,
  faDownload,
  faCloudUploadAlt,
  faFileAlt,
  faImages,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { BootstrapVue, ModalPlugin } from "bootstrap-vue";

import App from "@/App.vue";

import Example from "@/components/ExampleComponent.vue";

library.add(
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPlus,
  faSearch,
  faSpinner,
  faTimes,
  faComment,
  faChevronLeft,
  faCopy,
  faTrashAlt,
  faSyncAlt,
  faDownload,
  faCloudUploadAlt,
  faFileAlt,
  faImages,
  faFilter
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(ModalPlugin);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("example-component", Example);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  // eslint-disable-line
  el: "#app",
  components: { App },
  template: "<App />",
  router,
  store
});
