import repository from "@/repositories/repository";

export type Channel = {
  id: number;
  name: string;
  gcn: string;
  client_name: string;
  disabled: boolean;
};

type Query = {
  id: number;
  name: string;
  gcn: string;
  client_name: string;
  disabled: boolean;
};

export default {
  list: async (query?: Query): Promise<Channel[]> => {
    const response = await repository().get("/api/channels", {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: number): Promise<Channel> => {
    const response = await repository().get(`/api/channels/${id}`);
    return response.data.shift(); // レスポンスは配列に格納されているが必要なデータは1件
  },

  store: async (data: Channel): Promise<Channel> => {
    const response = await repository().post(`/api/channels`, {
      data: data
    });
    return response.data;
  },

  update: async (id: number, data: Channel): Promise<void> => {
    const response = await repository().put(`/api/channels/${id}`, {
      data: data
    });
    return response.data;
  },

  destroy: async (id: number): Promise<void> => {
    await repository().delete(`/api/channels/${id}`);
  }
};

export const newChannel: () => Channel = () => ({
  id: 0,
  name: "",
  gcn: "",
  client_name: "",
  disabled: false
});
