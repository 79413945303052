











import Vue from "vue";

type DataType = {
  formattedValue: string;
  numericValue: number;
  isInputActive: boolean;
};

export default Vue.extend({
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: ""
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    value: function() {
      this.setFormattedValue();
    }
  },
  data: function(): DataType {
    return {
      formattedValue: "",
      numericValue: 0,
      isInputActive: false
    };
  },
  computed: {
    // displayValue: {
    //   get: function(vm: Vue) {
    //     if (vm.$data.isInputActive) {
    //       return vm.$props.value.toString();
    //     } else {
    //       return vm.$props.value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    //     }
    //   },
    //   set: function(modifiedValue: string, vm: Vue) {
    //     let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
    //     if (isNaN(newValue)) {
    //       newValue = 0;
    //     }
    //     vm.$emit("input", newValue);
    //   }
    // }
  },
  methods: {
    onFocus: function() {
      this.isInputActive = true;
      this.formattedValue = this.formattedValue.replace(/[^\d\.]/g, "");
    },
    onBlur: function() {
      this.isInputActive = false;
      let numericValue = parseFloat(
        this.formattedValue.replace(/[^\d\.]/g, "")
      );
      if (isNaN(numericValue)) {
        this.$emit("input", "");
        return;
      }
      this.numericValue = numericValue;
      this.formattedValue = this.format(String(this.numericValue));
      this.$emit("input", this.numericValue);
    },
    format: function(value: string) {
      return this.isInputActive
        ? value.toString()
        : value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    setFormattedValue: function() {
      if (this.value == null) {
        this.formattedValue = "";
      } else {
        this.formattedValue = this.format(String(this.value));
      }
      let numericValue = parseFloat(
        this.formattedValue.replace(/[^\d\.]/g, "")
      );
      if (isNaN(numericValue)) {
        this.$emit("input", "");
        return;
      }
      this.numericValue = numericValue;
      this.formattedValue = this.format(String(this.numericValue));
      this.$emit("input", this.numericValue);
    }
  },
  mounted: function() {
    this.setFormattedValue();
  }
});
