import repository from "@/repositories/repository";
import { ScoreParam } from "@/helpers/salesScore";
import { MediaScoreParam } from "@/helpers/mediaSale";

export type Yomi = {
  id?: number;
  deal_id?: number;
  name: string;
  user: { id: number; name?: string };
  client: {
    id: number;
    name?: string;
    abbreviation?: string;
    material_fixed_required_days?: number;
  };
  client_attribute?: { id: number; name?: string };
  sales_channel: { id: number; name?: string };
  //ad_media: { id: number; name?: string; is_only_nationwide?: number };
  ad_media: {
    id: number;
    name?: string;
    official_name?: string;
    is_only_nationwide?: number;
  };
  is_long_term: boolean;
  published_date: { from: string; to?: string };
  ad_area: { id?: number; name?: string };
  sales_probability_def: { id: number; name?: string; before?: number };
  media_price: string;
  working_price: string;
  material_fixed_date: string;
  order_expected_date: string;
  last_action_date: string;
  billing_month: string;
  next_action: string;
  notes: string;
  updated_at?: string;
  updated_user?: string;
  yomi_deal?: { id: number };
  is_recently_updated: boolean;
  date_format?: string;
  style?: string;
};

export type Csv = {
  budget: string[];
  performance: string[];
  rate: string[];
};

type Query = {
  from: string;
  to: string;
  client_id?: number;
  client_attribute_id?: number;
  ad_media_id?: number;
  ad_area_id?: number;
  deal_id?: number;
  name?: string;
  user_id?: number;
  sales_channel_id?: number;
  sales_probability_def_id?: number;
  is_mgt?: boolean;
};

type SaleQuery = {
  is_def?: boolean;
  user_id?: number;
  fiscalYear?: number;
  quarter?: number;
};

type CsvQuery = {
  from: string;
  to: string;
  user_id: number | undefined; // 2021.11.21 個人×予算での情報抽出のために追加
};

type QueryForDeal = {
  user_id: number;
  client_id: number;
  client_attribute_id: number;
  ad_media_id: number;
  ad_area_id: number;
  sales_channel_id?: number;
  is_long_term: boolean;
  published_from?: string;
  published_to?: string;
  deal_id?: number;
};

export default {
  list: async (token: string, query?: Query): Promise<Yomi[]> => {
    const response = await repository(token).get("/api/yomi", {
      params: { ...query }
    });
    return response.data;
  },

  list2: async (data: any, token: string, query?: Query): Promise<Yomi[]> => {
    const response = await repository(token).post(`/api/yomi/search/index`, {
      params: { ...query },
      id: data.id,
      from: data.published_date_from,
      to: data.published_date_to,
      client: data.client,
      client_attribute: data.client_attribute,
      sale_channel: data.sale_channel,
      media: data.media,
      area: data.area,
      sale_defs: data.sale_defs
    });
    return response.data;
  },

  listForDeal: async (token: string, query?: QueryForDeal): Promise<Yomi[]> => {
    const response = await repository(token).get(`/api/deals/yomi`, {
      params: { ...query }
    });
    return response.data;
  },

  get: async (id: number, token: string): Promise<Yomi> => {
    const response = await repository(token).get(`/api/yomi/${id}`);
    return response.data.data;
  },

  gets: async (ids: number[], token: string): Promise<Yomi[]> => {
    const response = await repository(token).post(`/api/yomi/shows`, {
      ids: ids
    });
    return response.data.data;
  },

  summaryActual: async (
    token: string,
    query: SaleQuery
  ): Promise<ScoreParam[]> => {
    const response = await repository(token).get(`/api/yomi/summary/actual`, {
      params: { ...query }
    });
    return response.data;
  },

  summaryActualByAdMedia: async (
    token: string,
    query: SaleQuery
  ): Promise<ScoreParam[]> => {
    const response = await repository(token).get(
      `/api/yomi/summary/actual/media`,
      {
        params: { ...query }
      }
    );
    return response.data;
  },

  getBefore: async (token: string, query: SaleQuery): Promise<number[]> => {
    const response = await repository(token).post(`/api/yomi/sales/before`, {
      params: { ...query }
    });
    return response.data;
  },

  download: async (token: string, query: CsvQuery): Promise<string> => {
    const response = await repository(token).get(
      `/api/yomi/summary/actual/media/csv`,
      {
        params: { ...query },
        responseType: "blob"
      }
    );
    return response.data;
  },

  create: async (data: any, token: string): Promise<void> => {
    const response = await repository(token).post(`/api/yomi/`, {
      data: data
    });
  },

  update: async (
    data: Yomi,
    is_notification: boolean,
    token: string
  ): Promise<void> => {
    const response = await repository(token).put(`/api/yomi/${data.id}`, {
      data: data,
      is_notification: is_notification
    });
    return response.data;
  },

  updates: async (token: string, data: number[]): Promise<Yomi[]> => {
    const response = await repository(token).post("/api/yomi/reset", {
      data: data
    });
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    const response = await repository(token).delete(`/api/yomi/${id}`);
    return response.data;
  }
};

export const newYomi: () => Yomi = () => ({
  deal_id: 0,
  name: "",
  user: { id: 0 },
  client: { id: 0 },
  client_attribute: { id: 0 },
  sales_channel: { id: 0 },
  ad_media: { id: 0 },
  is_long_term: false,
  published_date: { from: "" },
  ad_area: { id: undefined, name: undefined },
  sales_probability_def: { id: 0 },
  media_price: "",
  working_price: "",
  material_fixed_date: "",
  order_expected_date: "",
  last_action_date: "",
  billing_month: "",
  next_action: "",
  notes: "",
  is_recently_updated: true
});
