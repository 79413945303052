




































































































import Vue from "vue";
import CheckList from "@/components/outputs/CheckList.vue";
import RegistrationList from "@/components/outputs/RegistrationList.vue";
import RpaDataLinkage from "@/components/outputs/LinkRPA.vue";
import Menu from "@/components/Menu.vue";
export default Vue.extend({
  components: {
    CheckList,
    RegistrationList,
    RpaDataLinkage,
    Menu
  },
  methods: {}
});
