import { render, staticRenderFns } from "./SaleChannel.vue?vue&type=template&id=b7b23450&scoped=true&"
import script from "./SaleChannel.vue?vue&type=script&lang=ts&"
export * from "./SaleChannel.vue?vue&type=script&lang=ts&"
import style0 from "./SaleChannel.vue?vue&type=style&index=0&id=b7b23450&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7b23450",
  null
  
)

export default component.exports