
























































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";
import Datepick from "@/components/calendars/Datepicker.vue";
import * as holiday_jp from "@holiday-jp/holiday_jp";

import YomiRepository, { Yomi } from "@/repositories/yomi";
import ClientRepository, { Client } from "@/repositories/clients";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import SaleChannelRepository, {
  SaleChannel
} from "@/repositories/saleChannels";
import MediaRepository, { Media } from "@/repositories/medias";
import AreaRepository, { Area } from "@/repositories/areas";
import SaleDefRepository, { SaleDef } from "@/repositories/saleDefs";
import UserRepository, { User } from "@/repositories/users";
import UserLastBrowsingYomiRepository from "@/repositories/userLastBrowsingYomi";

import Menu from "@/components/Menu.vue";
import YomiSaleGraph from "@/components/YomiSaleGraph.vue";
import NewYomiModal from "@/components/NewYomiModal.vue";
import NewMailModal from "@/components/modals/NewMailModal.vue";
import HelpModal from "@/components/HelpYomi.vue";

type DataType = {
  loading: { sales: boolean; yomi: boolean };
  salesUpdate: boolean;
  yomiList: Yomi[];
  clients: Client[];
  clientAttributes: ClientAttribute[];
  saleChannels: SaleChannel[];
  medias: Media[];
  areas: Area[];
  saleDefs: SaleDef[];
  isNewYomi: boolean;
  isNewMail: boolean;
  isHelp: boolean;
  isNew: boolean;
  isSelectFromDate: boolean;
  isSelectToDate: boolean;
  isSelectCurrentDate: boolean;
  is_update: boolean;
  user_id: number;
  today: dayjs.Dayjs;
  currentFromDate: dayjs.Dayjs;
  currentToDate: dayjs.Dayjs;
  selectFromYear: number;
  selectFromMonth: number;
  selectToYear: number;
  selectToMonth: number;
  headerClient?: number;
  headerClientAttribute?: number;
  headerSaleChannel?: number;
  headerMedia?: number;
  headerArea?: number;
  headerSaleDefs?: number;
  yomiDetail?: Yomi;
  yomiIds: number[];
  sendEmails: number[];
};

export default Vue.extend({
  components: {
    Menu,
    Datepick,
    YomiSaleGraph,
    NewYomiModal,
    NewMailModal,
    HelpModal
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      loading: { sales: false, yomi: false },
      salesUpdate: false,
      yomiList: [],
      clients: [],
      clientAttributes: [],
      saleChannels: [],
      medias: [],
      areas: [],
      saleDefs: [],
      isNewYomi: false,
      isNewMail: false,
      isHelp: false,
      isNew: true,
      is_update: true,
      user_id: 1,
      today: now,
      currentFromDate: now,
      currentToDate: now,
      isSelectFromDate: false,
      isSelectToDate: false,
      isSelectCurrentDate: true,
      selectFromYear: now.year(),
      selectFromMonth: now.month() + 1,
      selectToYear: now.year(),
      selectToMonth: now.month() + 1,
      headerClient: undefined,
      headerClientAttribute: undefined,
      headerSaleChannel: undefined,
      headerArea: undefined,
      headerMedia: undefined,
      headerSaleDefs: undefined,
      yomiDetail: undefined,
      yomiIds: [],
      sendEmails: []
    };
  },
  computed: {
    shownYomi: function(): boolean {
      return !this.loading.yomi || !this.loading.sales;
    }
  },
  mounted: async function() {
    this.loading.yomi = true;
    this.loading.sales = true;
    var user: User = await UserRepository.currentUser("");
    this.user_id = user.id;
    this.saleChannels = await SaleChannelRepository.actives("" /*TODO*/);
    this.medias = await MediaRepository.actives("" /*TODO*/);
    this.areas = await AreaRepository.list("" /*TODO*/);
    this.saleDefs = await SaleDefRepository.actives("", { is_yomi: true });
    await this.setCurrentDate();
    // ヨミ表
    this.yomiFormat();
    this.clients = await ClientRepository.actives("" /*TODO*/);
    this.clientAttributes = await ClientAttributeRepository.actives(
      "" /*TODO*/
    );
  },
  methods: {
    editable: function(yomi?: Yomi): boolean {
      const editable = yomi && yomi.deal_id && yomi.deal_id > 0 ? false : true;
      return editable;
    },
    showNewForm(swirchModal: string) {
      if (swirchModal === "yomi") {
        this.isNewYomi = true;
        (this.$refs.newYomiModal as any).show();
      } else if (swirchModal === "mail") {
        this.isNewMail = true;
        (this.$refs.newMailModal as any).show();
      } else {
        this.isHelp = true;
        (this.$refs.helpModal as any).show();
      }
    },
    closeNewForm() {
      this.isNewYomi = false;
      this.isNewMail = false;
      this.isHelp = false;
    },
    showYomiModal(isNew: boolean, yomi?: Yomi) {
      this.yomiDetail = yomi;
      this.isNew = isNew;
      this.showNewForm("yomi");
    },
    showMailModal() {
      var isError = false;
      if (this.sendEmails.length >= 1) {
        var clientId = this.yomiList.find(
          x => x.id === this.sendEmails[this.sendEmails.length - 1]
        );
        this.sendEmails.forEach(id => {
          var yomi = this.yomiList.find(x => x.id === id);
          if (yomi != undefined && clientId != undefined) {
            if (clientId.client.id != yomi.client.id) {
              alert(
                "複数案件の発注メールを作成する場合は同クライアントの案件を選択してください。"
              );
              isError = true;
            }
          }
        });
      } else {
        alert("発注メールを作成する案件を選択してください。");
        isError = true;
      }
      if (isError) {
        return false;
      }
      this.showNewForm("mail");
    },
    showHelpModal() {
      this.showNewForm("help");
    },
    setCurrentDate: async function() {
      const current = await UserLastBrowsingYomiRepository.get("");
      if (current === null) return;
      this.currentFromDate = dayjs(current.user_last_browsing_from);
      this.currentToDate = dayjs(current.user_last_browsing_to);
      this.changeDate();
    },
    addMonth: function(month: number) {
      this.currentFromDate = this.currentFromDate.add(month, "month");
      this.currentToDate = this.currentToDate.add(month, "month");
      this.yomiFormat();
      this.changeDate();
    },
    todayMonth: async function() {
      this.currentFromDate = this.currentFromDate
        .year(this.today.year())
        .month(this.today.month());
      this.currentToDate = this.currentToDate
        .year(this.today.year())
        .month(this.today.month());
      this.yomiFormat();
      this.changeDate();
    },
    dateSpecification: function() {
      this.currentFromDate = this.currentFromDate
        .year(this.selectFromYear)
        .month(this.selectFromMonth - 1);
      this.currentToDate = this.currentToDate
        .year(this.selectToYear)
        .month(this.selectToMonth - 1);
      this.disabledDate();
      this.yomiFormat();
    },
    changeDate() {
      this.selectFromYear = this.currentFromDate.year();
      this.selectFromMonth = this.currentFromDate.month() + 1;
      this.selectToYear = this.currentToDate.year();
      this.selectToMonth = this.currentToDate.month() + 1;
      this.disabledDate();
    },
    disabledDate: function() {
      this.isSelectCurrentDate = false;
      this.isSelectFromDate = false;
      this.isSelectToDate = false;
      if (
        (this.selectFromYear === this.today.year() - 9 &&
          this.selectFromMonth === 1) ||
        (this.selectToYear === this.today.year() - 9 &&
          this.selectToMonth === 1)
      ) {
        this.isSelectFromDate = true;
      } else if (
        (this.selectFromYear === this.today.year() + 1 &&
          this.selectFromMonth === 12) ||
        (this.selectToYear === this.today.year() + 1 &&
          this.selectToMonth === 12)
      ) {
        this.isSelectToDate = true;
      } else if (
        this.selectFromYear === this.today.year() &&
        this.selectFromMonth === this.today.month() + 1 &&
        this.selectToYear === this.today.year() &&
        this.selectToMonth === this.today.month() + 1
      ) {
        this.isSelectCurrentDate = true;
      }
    },
    yomiFormat: async function() {
      this.loading.yomi = true;
      this.yomiList = [];
      this.yomiList = await YomiRepository.list("", {
        from: `${this.currentFromDate.format("YYYY-MM")}-01`,
        to: this.currentToDate.endOf("month").format("YYYY-MM-DD"),
        user_id: this.user_id,
        client_id: this.headerClient,
        client_attribute_id: this.headerClientAttribute,
        sales_channel_id: this.headerSaleChannel,
        sales_probability_def_id: this.headerSaleDefs,
        ad_media_id: this.headerMedia,
        ad_area_id: this.headerArea,
        is_mgt: false
      });
      var index = 0;
      this.yomiList.forEach(yomiList => {
        var yomi = this.setYomi(yomiList);
        this.yomiList.splice(index, 1, yomi);
        index++;
      });
      this.loading.yomi = false;
    },
    setYomi: function(yomi: Yomi) {
      var published_date_from = "";
      var media_price = "";
      var working_price = "";
      var style = "";
      var date_format = "MM/DD(dd)";

      // 掲載日
      published_date_from = this.setDate(yomi.published_date.from);
      if (published_date_from.split("-")[2] == "00") {
        date_format = "MM月";
        published_date_from =
          published_date_from.split("-")[0] +
          "-" +
          published_date_from.split("-")[1] +
          "-01";
      }
      yomi.published_date = {
        from: published_date_from,
        to: yomi.published_date.to
      };
      yomi.published_date.from = published_date_from;
      yomi.date_format = date_format;
      yomi.is_long_term = yomi.published_date.to != undefined ? true : false;
      yomi.sales_probability_def.before = yomi.sales_probability_def.id;
      // 金額フォーマット
      media_price = this.priceFormat(yomi.media_price);
      yomi.media_price = media_price;
      working_price = this.priceFormat(yomi.working_price);
      yomi.working_price = working_price;

      if (yomi.sales_channel == null) {
        yomi.sales_channel = { id: 0 };
      }

      //TODO: エリアが未設定のデータ
      if (yomi.ad_area === null) {
        yomi.ad_area = { id: undefined };
      }

      // 案件行のスタイル指定
      style = this.setStyle(yomi);
      yomi.style = style;

      return yomi;
    },
    setStyle: function(data: Yomi) {
      var style = "font-size: 11px; ";
      // ヨミ確度「M」以上の場合背景オレンジ
      // ヨミ確度「オチ」の場合背景グレー
      if (data.sales_probability_def.id <= 2) {
        style = style + "background:#FCE8B2; ";
      } else if (data.sales_probability_def.id == 9) {
        style = style + "background:#999999; ";
      }
      // 更新案件の場合青文字
      if (data.is_recently_updated) {
        style = style + "color:blue; ";
      }

      // 赤文字
      // 受注予定日が今日よりも過去の場合はtrue
      const orderExpectedDateIsBeforeToday = dayjs(
        data.order_expected_date
      ).isBefore(dayjs());
      // 素材確定日が今日よりも過去の場合はtrue
      const materialFixedDateIsBeforeToday = dayjs(
        data.material_fixed_date
      ).isBefore(dayjs());
      // ヨミ確度「N」〜「A」かつ 受注予定日に入力がない
      // ヨミ確度「N」〜「A」かつ 素材確定日がない
      // ヨミ確度「N」〜「A」かつ 受注予定日が現在よりも過去
      // ヨミ確度「N」〜「A」かつ 素材確定日が現在よりも過去
      if (
        data.sales_probability_def.id <= 8 &&
        data.sales_probability_def.id >= 3 &&
        (data.order_expected_date === null ||
          data.material_fixed_date === null ||
          orderExpectedDateIsBeforeToday ||
          materialFixedDateIsBeforeToday)
      ) {
        style = style + "color:red; ";
      }
      return style;
    },
    setDate: function(date: string) {
      var set = String(date)
        .split("-")
        .join("");
      if (date != null) {
        set =
          String(set).substr(0, 4) +
          "-" +
          String(set).substr(4, 2) +
          "-" +
          String(set).substr(6, 2);
      } else {
        set = "";
      }
      return set;
    },
    priceFormat(price: string) {
      if (price == null) {
        return "0";
      }
      price = price.split(",").join("");
      price = price.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(price) {
        return String.fromCharCode(price.charCodeAt(0) - 0xfee0);
      });
      if (!isNaN(Number(price))) {
        price = Number(price).toLocaleString();
      } else {
        price = "0";
      }
      return price;
    },
    setPublishDate: function(date: string) {
      return dayjs(date).format("MM/DD(dd)");
    },
    updateDate: function(id: number, date: string, changeNum: number) {
      var yomi = this.yomiList.find(x => x.id === id);
      if (yomi != undefined) {
        var column = this.setPickDate(changeNum, id, date);
        this.updateList(yomi, column);
      }
    },
    setPickDate: function(column: number, id: number, date: string) {
      var result = "";
      this.yomiList.forEach(element => {
        if (element.id === id) {
          switch (column) {
            case 1:
              element.published_date.from = date;
              result = "published_date_from";
              break;
            case 2:
              element.material_fixed_date = date;
              result = "material_fixed_date";
              break;
            case 3:
              element.order_expected_date = date;
              result = "order_expected_date";
              break;
            case 4:
              element.last_action_date = date;
              result = "last_action_date";
              break;
          }
        }
      });
      return result;
    },
    setYomiId: function(yomi: Yomi) {
      if (this.yomiIds.find(x => x == yomi.id) == undefined && yomi.id) {
        this.yomiIds.push(yomi.id);
        yomi.style = yomi.style + "background: #dcf0f2; ";
      } else {
        var ids: number[] = [];
        this.yomiIds.forEach(id => {
          if (id != yomi.id) {
            ids.push(id);
          }
        });
        this.yomiIds = ids;
        if (yomi.style) {
          var indexOf = yomi.style.indexOf("background: #dcf0f2; ");
          yomi.style = yomi.style.substr(0, indexOf);
        }
      }
    },
    updateList: async function(yomi: Yomi, changecolumn = "") {
      var action = confirm("ヨミ表案件を更新します。よろしいですか。");
      if (!action) {
        this.cancel(yomi);
        return false;
      }
      if (this.yomiIds.find(x => x == yomi.id) == undefined && yomi.id) {
        this.yomiIds.push(yomi.id);
      }
      // 枠表連携データチェック
      // var is_update = true;
      await this.yomiIds.forEach(id => {
        this.yomiList.forEach(currentYomi => {
          if (currentYomi.id == id) {
            // if (
            //   yomi.deal_id != null &&
            //   yomi.sales_probability_def.before &&
            //   yomi.sales_probability_def.before <= 2
            // ) {
            //   alert(
            //     "枠表と連携されているヨミ確度M以上の案件はヨミ表では更新できません。枠表で更新してください。"
            //   );
            //   this.cancel(yomi);
            //   this.is_update = false;
            // } else {
            if (
              currentYomi.deal_id &&
              currentYomi.deal_id != null &&
              currentYomi.sales_probability_def.before &&
              currentYomi.sales_probability_def.before < 9 &&
              currentYomi.sales_probability_def.id === 9
            ) {
              let action = confirm(
                "枠表と連携済み案件はヨミ確度をオチに変更すると連携している枠表案件が解除されます。よろしいですか。"
              );
              if (!action) {
                this.cancel(yomi);
                this.is_update = false;
                return false;
              }
            }
            this.normalization(yomi, currentYomi, changecolumn);
            if (this.is_update) {
              currentYomi.style = this.setStyle(currentYomi);
            }
            // }
          }
        });
      });
      this.yomiIds = [];
      this.sendEmails = [];
    },
    cancel: async function(yomi: Yomi) {
      if (yomi.id == undefined) {
        return false;
      }
      var before = await YomiRepository.get(yomi.id, "");
      var index = this.yomiList.findIndex(x => x.id === yomi.id);
      if (index > -1 && before != null) {
        this.yomiList.splice(index, 1, this.setYomi(before));
      }
    },
    normalization: async function(
      yomi: Yomi,
      currentYomi: Yomi,
      changecolumn: string
    ) {
      var error = this.validation(yomi, currentYomi, changecolumn);
      if (error != "") {
        alert(error);
        this.cancel(currentYomi);
        this.is_update = false;
        return false;
      }

      // currentYomi.published_date.from は 年月のみの場合 YYYY-MM-01
      const dayjs_published_date_from = dayjs(currentYomi.published_date.from);
      const isYearMonthOnly = currentYomi.date_format === "MM月";
      const isPublisehdDateUpdated = changecolumn === "published_date_from";
      const published_date_from =
        // 更新前の掲載日が年月のみ、かつ掲載日の更新でない場合は YYYYMM00 にする
        isYearMonthOnly && !isPublisehdDateUpdated
          ? dayjs_published_date_from.format("YYYYMM00")
          : dayjs_published_date_from.format("YYYYMMDD");

      var result: Yomi = {
        id: currentYomi.id,
        deal_id: currentYomi.deal_id,
        user: currentYomi.user,
        is_long_term: currentYomi.published_date.to != undefined ? true : false,
        published_date: {
          from: published_date_from,
          to: currentYomi.published_date.to
        },
        client: currentYomi.client,
        client_attribute: currentYomi.client_attribute,
        sales_channel: currentYomi.sales_channel,
        name: currentYomi.name,
        ad_media: currentYomi.ad_media,
        ad_area: currentYomi.ad_area,
        media_price: this.priceFormat(currentYomi.media_price)
          .split(",")
          .join(""),
        working_price: this.priceFormat(currentYomi.working_price)
          .split(",")
          .join(""),
        sales_probability_def: currentYomi.sales_probability_def,
        material_fixed_date: currentYomi.material_fixed_date,
        order_expected_date: currentYomi.order_expected_date,
        last_action_date: currentYomi.last_action_date,
        billing_month: currentYomi.billing_month,
        next_action: currentYomi.next_action,
        notes: currentYomi.notes,
        is_recently_updated: true
      };
      await YomiRepository.update(result, true, "");
      var index = this.yomiList.findIndex(x => x.id === result.id);
      if (index > -1) {
        this.yomiList.splice(index, 1, this.setYomi(result));
      }
      this.is_update = true;
    },
    validation(yomi: Yomi, currentYomi: Yomi, changecolumn: string) {
      switch (changecolumn) {
        case "published_date_from":
          if (
            currentYomi.published_date.from === "" ||
            currentYomi.published_date.from == null
          ) {
            return "掲載日の入力は必須です。";
          }
          var from = Number(
            String(currentYomi.published_date.from)
              .split("-")
              .join("")
          );
          var to = currentYomi.published_date.to
            ? Number(currentYomi.published_date.to)
            : null;
          if (to != null && !isNaN(to) && from > to) {
            return "掲載日の日付は開始<終了となるように入力してください。";
          }

          currentYomi.billing_month = yomi.published_date.from;
          currentYomi.published_date.from = yomi.published_date.from;
          currentYomi.material_fixed_date = this.setMaterialFixedDate(
            currentYomi.published_date.from,
            currentYomi.client.id
          );
          break;
        case "client_id":
          currentYomi.material_fixed_date = this.setMaterialFixedDate(
            currentYomi.published_date.from,
            currentYomi.client.id
          );
          currentYomi.client.id = yomi.client.id;
          break;
        case "client_attribute_id":
          currentYomi.client_attribute = yomi.client_attribute;
          break;
        case "sales_channel_id":
          currentYomi.sales_channel = yomi.sales_channel;
          break;
        case "name":
          currentYomi.name = yomi.name;
          break;
        case "ad_media_id":
          let media = this.medias.find(x => x.id === yomi.ad_media.id);
          if (media) {
            currentYomi.ad_media = yomi.ad_media;
            currentYomi.ad_media.is_only_nationwide = media.is_only_nationwide;
          } else {
            currentYomi.ad_media = yomi.ad_media;
          }
          break;
        case "ad_area_id":
          currentYomi.ad_area = yomi.ad_area;
          break;
        case "media_price":
          currentYomi.media_price = yomi.media_price;
          break;
        case "working_price":
          currentYomi.working_price = yomi.working_price;
          break;
        case "sales_probability_def_id":
          // if (
          //   currentYomi.deal_id == null &&
          //   currentYomi.sales_probability_def.id < 3
          // ) {
          //   return "枠表と連携していない案件はヨミ確度M以上は選択できません。";
          // }
          if (
            currentYomi.sales_probability_def.before &&
            currentYomi.sales_probability_def.before > 2 &&
            currentYomi.sales_probability_def.id <= 2
          ) {
            return "ヨミ表からはヨミ確度M以上は選択できません。枠表で更新してください。";
          }
          currentYomi.sales_probability_def.id = yomi.sales_probability_def.id;
          break;
        case "material_fixed_date":
          currentYomi.material_fixed_date = yomi.material_fixed_date;
          break;
        case "order_expected_date":
          currentYomi.order_expected_date = yomi.order_expected_date;
          break;
        case "last_action_date":
          currentYomi.last_action_date = yomi.last_action_date;
          break;
        case "next_action":
          currentYomi.next_action = yomi.next_action;
          break;
        case "notes":
          currentYomi.notes = yomi.notes;
          break;
        default:
          break;
      }
      return "";
    },
    setMaterialFixedDate(date: string, clientId: number) {
      let index = 0;
      let materialFixedDate = dayjs(date);
      const client = this.clients.find(x => x.id === clientId);
      let material_fixed_required_days = client
        ? client.material_fixed_required_days
        : 5;
      while (index < material_fixed_required_days) {
        materialFixedDate = materialFixedDate.add(-1, "day");
        if (
          materialFixedDate.day() > 0 &&
          materialFixedDate.day() < 6 &&
          !holiday_jp.isHoliday(materialFixedDate.toDate())
        ) {
          index++;
        }
      }
      return dayjs(materialFixedDate).format("YYYY-MM-DD");
    },
    async restoreState() {
      var action = confirm(
        "表示しているヨミ表案件の状態を黒文字に更新します。よろしいですか。"
      );
      if (!action) {
        return false;
      }
      var yomiIds: number[] = [];
      this.yomiList.forEach(yomi => {
        if (yomi.id) {
          yomiIds.push(yomi.id);
        }
      });
      await YomiRepository.updates("", yomiIds);
      this.yomiFormat();
    }
  }
});
