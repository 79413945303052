


















































import Vue from "vue";
import repository from "@/repositories/repository";
import * as ResponseHelper from "@/helpers/response";
import dayjs from "dayjs";
import DraftSheetRepository from "@/repositories/draftSheet";
import { AxiosError } from "axios";

type DataType = {
  running: boolean;
  isSuccess: boolean;
  isError: boolean;
  message: string;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      running: false,
      isSuccess: false,
      isError: false,
      message: "",
      errorMsg: []
    };
  },
  methods: {
    startSpinner: function() {
      this.running = true;
    },
    endSpinner: function() {
      this.running = false;
    },
    resetAlerts: function() {
      this.message = "";
      this.isSuccess = false;
      this.errorMsg = [];
      this.isError = false;
    },
    onSuccessExport: function() {
      this.message = "NewCE枠連携用登録RPAデータの出力が完了しました。";
      this.isSuccess = true;
    },
    onSuccessChange: function() {
      this.message =
        "抽出条件となっていたデータのステータスを「入稿完了」に変更しました。";
      this.isSuccess = true;
    },
    messageNoData: function() {
      this.errorMsg = [];
      this.errorMsg.push("対象のデータが存在しません。");
    },
    messageUnexpectedError: function() {
      this.errorMsg = [];
      this.errorMsg.push("予期しないエラーが発生しました。");
    },
    exportData: async function() {
      this.resetAlerts();
      this.startSpinner();
      await repository()
        .get("/api/rpaDataLinkage/export", {
          responseType: "blob"
        })
        .then(response => {
          // TODO:レスポンスヘッダからファイル名の取得に変更
          const blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download =
            "NCE枠登録用RPA連携シート_" +
            dayjs().format("YYYYMMDDHHmmss") +
            ".xlsx";
          link.click();
          URL.revokeObjectURL(url);
          if (link && link.parentNode) {
            link.parentNode.removeChild(link);
          }
          this.onSuccessExport();
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.messageNoData();
          } else {
            this.messageUnexpectedError();
          }
        })
        .finally(() => {
          this.endSpinner();
        });
    },
    changeStatus: async function() {
      let action = confirm(
        "「営業確認完了」となっているNCEの案件のステータスを一括で「入稿完了」へ変更いたします。よろしいですか？"
      );
      if (!action) {
        return false;
      }
      this.resetAlerts();
      this.startSpinner();
      await DraftSheetRepository.updateStatus("")
        .then(() => {
          this.onSuccessChange();
        })
        .catch((error: AxiosError) => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.messageNoData();
          } else {
            this.messageUnexpectedError();
          }
        })
        .finally(() => {
          this.endSpinner();
        });
    }
  }
});
