var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 border-bottom border-dark"},[_c('b-row',{staticClass:"align-items-center font-weight-bold"},[_c('b-col',{staticClass:"ml-5",attrs:{"cols":"1"}},[_c('a',{staticClass:"align-items-center text-decoration-none text-inherit cursor-pointer",on:{"click":function($event){return _vm.$router.back()}}},[_c('b-row',{staticClass:"align-items-center"},[_c('font-awesome-icon',{staticClass:"text-default mr-3",attrs:{"icon":"chevron-left","size":"1x"}}),_vm._v(" "),_c('div',[_vm._v("戻る")])],1)],1)]),_vm._v(" "),(_vm.draftDeal)?_c('b-col',[_c('b-row',{staticClass:"align-items-center"},[_vm._v("\n        "+_vm._s(_vm.draftDeal ? _vm.draftDeal.title : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.draftDeal ? _vm.draftDeal.ad_media.name : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.draftDeal ? _vm.draftDeal.ad_area.name : null)+"\n      ")])],1):_vm._e(),_vm._v(" "),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCe),expression:"isCe"}]},[_c('b-row',{staticClass:"align-items-center"},[_vm._v("\n        "+_vm._s(_vm.ceDraftDetail && _vm.ceDraftDetail.draft_deal.deal.title
            ? _vm.ceDraftDetail.draft_deal.deal.title
            : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.ceDraftDetail && _vm.ceDraftDetail.draft_deal.deal.ad_media.name
            ? _vm.ceDraftDetail.draft_deal.deal.ad_media.name
            : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.ceDraftDetail && _vm.ceDraftDetail.draft_deal.deal.ad_area.name
            ? _vm.ceDraftDetail.draft_deal.deal.ad_area.name +
              "→" +
              _vm.ceDraftDetail.registration_area.name
            : null)+"\n      ")])],1),_vm._v(" "),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHtml),expression:"isHtml"}]},[_c('b-row',{staticClass:"align-items-center"},[_vm._v("\n        "+_vm._s(_vm.htmlDraftDetail && _vm.htmlDraftDetail.draft_deal.deal.title
            ? _vm.htmlDraftDetail.draft_deal.deal.title
            : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.htmlDraftDetail && _vm.htmlDraftDetail.draft_deal.deal.ad_media.name
            ? _vm.htmlDraftDetail.draft_deal.deal.ad_media.name
            : null)+"\n        "),_c('div',{staticClass:"diagonal-line"}),_vm._v("\n        "+_vm._s(_vm.htmlDraftDetail && _vm.htmlDraftDetail.draft_deal.deal.ad_area.name
            ? _vm.htmlDraftDetail.draft_deal.deal.ad_area.name +
              "→" +
              _vm.htmlDraftDetail.registration_area.name
            : null)+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }