












import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";

export default Vue.extend({
  components: {
    Menu
  },
  methods: {}
});
