


























































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import { AxiosError } from "axios";

import UserRepository, { newUser, User } from "@/repositories/users";
import RoleRepository, { Role } from "@/repositories/roles";
import UserRolesRepository, { UserRole } from "@/repositories/userRoles";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  errorMsg: string[];
  roles: Role[];
  user: User;
  error?: string;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      isError: false,
      errorMsg: [],
      roles: [],
      user: newUser(),
      error: undefined
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.user = newUser();
    },
    async postUser() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      let result = await UserRepository.store(this.user, "" /*TODO*/).catch(
        this.handleError
      );
      if (result) {
        this.$emit("success", result);
        (this.$refs.modal as BModal).hide();
      }
      return this.$data;
    },
    async updateUser() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      let result = await UserRepository.update(this.user, "" /*TODO*/).catch(
        this.handleError
      );
      if (result) {
        this.$emit("successUpdate", result);
        (this.$refs.modal as BModal).hide();
        return this.$data;
      }
    },
    async deleteUser() {
      let action = confirm("本当に削除してよろしいですか？");
      if (!action) {
        return false;
      }
      let result = await UserRepository.destroy(
        this.user.id,
        "" /*TODO*/
      ).catch(this.handleError);
      if (result) {
        this.$emit("delete", result);
        (this.$refs.modal as BModal).hide();
      }
    },
    validation: function() {
      let msg: string[] = [];
      if (this.user.name === null || this.user.name === "") {
        msg.push("ユーザー名を入力してください。");
      }
      if (this.user.email === null || this.user.email === "") {
        msg.push("メールアドレスを入力してください。");
      }
      let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!validEmail.test(this.user.email)) {
        msg.push("メールアドレスの形式が正しくありません。");
      }
      return msg;
    },
    handleError: function(error: AxiosError) {
      if (error.response?.status === 403) {
        this.isError = true;
        this.errorMsg.push("権限が不足しています。");
        return;
      }
      if (error.response?.status === 500) {
        this.isError = true;
        this.errorMsg.push(
          "サーバサイドでエラーが発生しました。すでに案件など登録したユーザのアカウントは削除できません。"
        );
        return;
      }
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        let user = await UserRepository.get(this.id, "");
        if (user != null) {
          let userRoles = await UserRolesRepository.get(user.id, "");
          this.user = user;
          this.user.role_id = [];
          userRoles.forEach(user => {
            if (this.user.role_id) {
              this.user.role_id.push(user.role_id);
            }
          });
        }
      }
      this.loading = false;
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    isUserAdmin: function(): boolean {
      return AuthHelper.isUserAdmin();
    },
    editable: function(): boolean {
      return this.isUserAdmin || AuthHelper.isOwn(this.id);
    }
  },
  mounted: async function() {
    this.roles = await RoleRepository.list("");
  }
});
