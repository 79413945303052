


























































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import AdidRepository, { Adid } from "@/repositories/adId";

type DataType = {
  adidData: Adid;
  isError: boolean;
  isUpdate: boolean;
  isNetworkError: boolean;
};

export default Vue.extend({
  components: {
    Menu
  },
  data: function(): DataType {
    return {
      adidData: {
        adid: 0
      },
      isError: false,
      isUpdate: false,
      isNetworkError: false
    };
  },
  methods: {
    updateAdid: async function() {
      // バリデーション
      if (!this.validation(this.adidData.adid)) {
        this.isError = true;
        return;
      }
      // 確認アラート
      if (window.confirm("ADIDの最新番号を更新しますか？")) {
        // 「はい」が選択された場合のみADIDアップデート開始
        await AdidRepository.update(this.adidData, "")
          .then(() => {
            console.log("通信に成功しました");
            this.isUpdate = true; // 成功アラートの表示
          })
          .catch(e => {
            console.log("通信に失敗しました", e);
            this.isNetworkError = true; // 失敗アラートの表示
          });
      }
    },
    validation: function(adid: number) {
      // adidは900001から999999の間で設定
      if (adid < 900001 || adid > 999999) {
        return false;
      }
      return true;
    }
  },
  mounted: async function() {
    this.adidData = await AdidRepository.list("");
  }
});
