// Phase2.0から運用開始されている入稿カレンダーのレコード単位で管理されているステータス

export type DraftStatus = {
  code: number;
  name: string;
};

export type DraftHeaderStatus = {
  name: string;
};

export const getDraftStatusNameByCode = (code: number): string =>
  draftStatuses.find(x => x.code === code)?.name ?? "";

export const getDraftStatusCodeByName = (name: string): number =>
  draftStatuses.find(x => x.name === name)?.code ?? 0;

export const draftStatuses: DraftStatus[] = [
  { code: 8, name: "(仮)続報待ち" },
  { code: 7, name: "入稿待ち" },
  { code: 6, name: "ネタ待ち" },
  { code: 5, name: "初稿待ち" },
  { code: 4, name: "確認・修正待ち" },
  { code: 3, name: "デザイン確定待ち" },
  { code: 2, name: "確定済" },
  { code: 1, name: "入稿完了" } // Ph3にて名称を「進行済」→「入稿完了」に変更
];

// 入稿カレンダーのテーブルヘッダー表示・検索用
export const draftHeaderStatuses: DraftHeaderStatus[] = [
  { name: "(仮)続報待ち" },
  { name: "入稿待ち" },
  { name: "ネタ待ち" },
  { name: "初稿待ち" },
  { name: "確認・修正待ち" },
  { name: "デザイン確定待ち" },
  { name: "確定済" },
  { name: "ダブルチェック完了" },
  { name: "入稿メール済" },
  { name: "営業確認完了" },
  { name: "入稿完了" } // Ph3にて名称を「進行済」→「入稿完了」に変更
];
