












































































































































































































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";

import Menu from "../components/Menu.vue";
import NewYomiModal from "../components/NewYomiModal.vue";
import YomiSaleGraph from "../components/YomiSaleGraph.vue";
import YomiSaleBudget from "../components/YomiSaleBudget.vue";

import YomiRepository, { Yomi } from "@/repositories/yomi";
import UserRepository, { User } from "@/repositories/users";
import ClientRepository, { Client } from "@/repositories/clients";
import ClientAttributeRepository, {
  ClientAttribute
} from "@/repositories/clientAttributes";
import SaleChannelRepository, {
  SaleChannel
} from "@/repositories/saleChannels";
import MediaRepository, { Media } from "@/repositories/medias";
import AreaRepository, { Area } from "@/repositories/areas";
import SaleDefRepository, { SaleDef } from "@/repositories/saleDefs";
import UserLastBrowsingYomiMgtRepository from "@/repositories/userLastBrowsingYomiMgt";

import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: { sales: boolean; yomi: boolean };
  isShowGraph: boolean;
  isShowBudget: boolean;
  isNew: boolean;
  showModal: boolean;
  isSelectFromDate: boolean;
  isSelectToDate: boolean;
  isSelectCurrentDate: boolean;
  today: dayjs.Dayjs;
  currentFromDate: dayjs.Dayjs;
  currentToDate: dayjs.Dayjs;
  selectFromYear: number;
  selectFromMonth: number;
  selectToYear: number;
  selectToMonth: number;
  yomiList: Yomi[];
  users: User[];
  clients: Client[];
  clientAttributes: ClientAttribute[];
  saleChannels: SaleChannel[];
  medias: Media[];
  areas: Area[];
  saleDefs: SaleDef[];
  headerUser?: number;
  headerClient?: number;
  headerClientAttribute?: number;
  headerSaleChannel?: number;
  headerMedia?: number;
  headerArea?: number;
  headerSaleDefs?: number;
  detail?: Yomi;
};

export default Vue.extend({
  components: {
    Menu,
    NewYomiModal,
    YomiSaleGraph,
    YomiSaleBudget
  },
  data: function(): DataType {
    const now = dayjs();
    return {
      loading: { sales: false, yomi: false },
      showModal: false,
      isNew: false,
      isShowGraph: true,
      isShowBudget: false,
      today: now,
      currentFromDate: now,
      currentToDate: now,
      isSelectFromDate: false,
      isSelectToDate: false,
      isSelectCurrentDate: true,
      selectFromYear: now.year(),
      selectFromMonth: now.month() + 1,
      selectToYear: now.year(),
      selectToMonth: now.month() + 1,
      yomiList: [],
      users: [],
      clients: [],
      clientAttributes: [],
      saleChannels: [],
      medias: [],
      areas: [],
      saleDefs: [],
      headerUser: undefined,
      headerClient: undefined,
      headerClientAttribute: undefined,
      headerSaleChannel: undefined,
      headerArea: undefined,
      headerMedia: undefined,
      headerSaleDefs: undefined,
      detail: undefined
    };
  },
  computed: {
    shown: function(): boolean {
      return !this.loading.yomi || !this.loading.sales;
    },
    canShowBudget: function() {
      return AuthHelper.canShowBudget();
    },
    isAdminSalesEditor: function() {
      return AuthHelper.isAdminSalesEditor();
    },
    canDownloadSales: function() {
      return AuthHelper.canDownloadSales();
    }
  },
  mounted: async function() {
    this.loading.yomi = true;
    this.loading.sales = true;
    this.users = await UserRepository.list("");
    this.clients = await ClientRepository.actives("" /*TODO*/);
    this.clientAttributes = await ClientAttributeRepository.list("" /*TODO*/);
    this.saleChannels = await SaleChannelRepository.list("" /*TODO*/);
    this.medias = await MediaRepository.list("" /*TODO*/);
    this.areas = await AreaRepository.list("" /*TODO*/);
    this.saleDefs = await SaleDefRepository.list("" /*TODO*/);
    await this.setCurrentDate();
    this.yomiFormat();
  },
  methods: {
    closeModal: function() {
      this.showModal = false;
    },
    showForm: function(yomi: Yomi) {
      this.showModal = true;
      this.detail = yomi;
      (this.$refs.newYomiModal as any).show();
    },
    showSaleForm(showTarget: string) {
      if (showTarget === "graph") {
        this.isShowGraph = true;
        this.isShowBudget = false;
      } else {
        this.isShowGraph = false;
        this.isShowBudget = true;
      }
    },
    setCurrentDate: async function() {
      const current = await UserLastBrowsingYomiMgtRepository.get("");
      if (current === null) return;
      this.currentFromDate = dayjs(current.user_last_browsing_from);
      this.currentToDate = dayjs(current.user_last_browsing_to);
      this.changeDate();
    },
    // 表示案件の切り替え「<<（前月」「>>（次月）」
    addMonth: function(month: number) {
      this.currentFromDate = this.currentFromDate.add(month, "month");
      this.currentToDate = this.currentToDate.add(month, "month");
      this.yomiFormat();
      this.changeDate();
    },
    // 今月掲載日の案件表示
    todayMonth: function() {
      this.currentFromDate = this.currentFromDate
        .year(this.today.year())
        .month(this.today.month());
      this.currentToDate = this.currentToDate
        .year(this.today.year())
        .month(this.today.month());
      this.yomiFormat();
      this.changeDate();
    },
    dateSpecification: function() {
      this.currentFromDate = this.currentFromDate
        .year(this.selectFromYear)
        .month(this.selectFromMonth - 1);
      this.currentToDate = this.currentToDate
        .year(this.selectToYear)
        .month(this.selectToMonth - 1);
      this.disabledDate();
      this.yomiFormat();
    },
    changeDate: function() {
      this.selectFromYear = this.currentFromDate.year();
      this.selectFromMonth = this.currentFromDate.month() + 1;
      this.selectToYear = this.currentToDate.year();
      this.selectToMonth = this.currentToDate.month() + 1;
      this.disabledDate();
    },
    disabledDate: function() {
      this.isSelectCurrentDate = false;
      this.isSelectFromDate = false;
      this.isSelectToDate = false;
      if (
        (this.selectFromYear === this.today.year() - 9 &&
          this.selectFromMonth === 1) ||
        (this.selectToYear === this.today.year() - 9 &&
          this.selectToMonth === 1)
      ) {
        this.isSelectFromDate = true;
      } else if (
        (this.selectFromYear === this.today.year() + 1 &&
          this.selectFromMonth === 12) ||
        (this.selectToYear === this.today.year() + 1 &&
          this.selectToMonth === 12)
      ) {
        this.isSelectToDate = true;
      } else if (
        this.selectFromYear === this.today.year() &&
        this.selectFromMonth === this.today.month() + 1 &&
        this.selectToYear === this.today.year() &&
        this.selectToMonth === this.today.month() + 1
      ) {
        this.isSelectCurrentDate = true;
      }
    },
    getDate: function(date: string, format = "MM/DD(dd)") {
      if (date == null) return "";
      let split = date.split("-");
      var from = dayjs()
        .year(Number(split[0]))
        .month(Number(split[1]) - 1);
      return format === "MM月"
        ? from.date(1).format(format)
        : from.date(Number(split[2])).format(format);
    },
    // ヨミ表データ整形
    yomiFormat: async function() {
      this.loading.yomi = true;
      this.yomiList = await YomiRepository.list("", {
        from: `${this.currentFromDate.format("YYYY-MM")}-01`,
        to: this.currentToDate.endOf("month").format("YYYY-MM-DD"),
        user_id: this.headerUser,
        client_id: this.headerClient,
        client_attribute_id: this.headerClientAttribute,
        sales_channel_id: this.headerSaleChannel,
        sales_probability_def_id: this.headerSaleDefs,
        ad_media_id: this.headerMedia,
        ad_area_id: this.headerArea,
        is_mgt: true
      });
      var index = 0;
      this.yomiList.forEach(yomiList => {
        var yomi = this.setYomi(yomiList);
        this.yomiList.splice(index, 1, yomi);
        index++;
      });
      this.loading.yomi = false;
    },
    setYomi: function(yomi: Yomi) {
      var published_date_from = "";
      var media_price = "";
      var working_price = "";
      var style = "";
      var date_format = "MM/DD(dd)";
      // 掲載日
      published_date_from = this.setDate(yomi.published_date.from);
      if (published_date_from.split("-")[2] == "00") {
        date_format = "MM月";
        published_date_from =
          published_date_from.split("-")[0] +
          "-" +
          published_date_from.split("-")[1] +
          "-01";
      }
      yomi.date_format = date_format;
      yomi.published_date.from = published_date_from;
      //TODO: エリアが未設定のデータ
      if (yomi.ad_area === null) {
        yomi.ad_area = { id: undefined, name: "" };
      }

      // 案件行のスタイル指定
      // ヨミ確度「M」以上の場合背景オレンジ
      // ヨミ確度「オチ」の場合背景グレー
      if (yomi.sales_probability_def.id <= 2) {
        style = "background:#FCE8B2; ";
      } else if (yomi.sales_probability_def.id == 9) {
        style = "background:#999999; ";
      }

      // 青文字
      // 更新案件の場合
      if (yomi.is_recently_updated) {
        style = style + "color:blue; ";
      }

      // 赤文字
      // 受注予定日が今日よりも過去の場合はtrue
      const orderExpectedDateIsBeforeToday = dayjs(
        yomi.order_expected_date
      ).isBefore(dayjs());
      // 素材確定日が今日よりも過去の場合はtrue
      const materialFixedDateIsBeforeToday = dayjs(
        yomi.material_fixed_date
      ).isBefore(dayjs());
      // ヨミ確度「N」〜「A」かつ 受注予定日に入力がない
      // ヨミ確度「N」〜「A」かつ 素材確定日がない
      // ヨミ確度「N」〜「A」かつ 受注予定日が現在よりも過去
      // ヨミ確度「N」〜「A」かつ 素材確定日が現在よりも過去
      if (
        yomi.sales_probability_def.id <= 8 &&
        yomi.sales_probability_def.id >= 3 &&
        (yomi.order_expected_date === null ||
          yomi.material_fixed_date === null ||
          orderExpectedDateIsBeforeToday ||
          materialFixedDateIsBeforeToday)
      ) {
        style = style + "color:red; ";
      }
      yomi.style = style;

      return yomi;
    },
    // 日付整形
    setDate: function(date: string) {
      var set = String(date)
        .split("-")
        .join("");
      if (date != null) {
        set =
          String(set).substr(0, 4) +
          "-" +
          String(set).substr(4, 2) +
          "-" +
          String(set).substr(6, 2);
      } else {
        set = "";
      }
      return set;
    },
    // 金額整形
    priceFormat(price: string) {
      if (Number(price) == 0) {
        return "0";
      }
      return Number(price).toLocaleString();
    },
    download: function() {
      let header = [
        "担当者",
        "クライアント",
        "属性",
        "商流",
        "案件名",
        "商品名",
        "商品エリア",
        "媒体費",
        "制作費",
        "確度",
        "掲載日(from)",
        "掲載日(to)",
        "素材確定日",
        "受注予定日",
        "最終action日",
        "next action",
        "備考",
        "最終更新日時",
        "最終更新者"
      ];
      var csv = "\ufeff" + header.join(",") + "\n";
      this.yomiList.forEach(yomi => {
        var line = "";
        line += yomi.user.name + ",";
        line += yomi.client.name + ",";
        line += yomi.client_attribute ? yomi.client_attribute.name + "," : ",";
        line += yomi.sales_channel ? yomi.sales_channel.name + "," : ",";
        line += yomi.name != null ? yomi.name + "," : ",";
        line += yomi.ad_media.name + ",";
        line += yomi.ad_area.name + ",";
        line += yomi.media_price != null ? yomi.media_price + "," : "0,";
        line += yomi.working_price != null ? yomi.working_price + "," : "0,";
        line += yomi.sales_probability_def.name + ",";
        line += this.getDate(yomi.published_date.from) + ",";
        line += yomi.published_date.to
          ? this.getDate(this.setDate(yomi.published_date.to)) + ","
          : ",";
        line += this.getDate(yomi.material_fixed_date) + ",";
        line += this.getDate(yomi.order_expected_date) + ",";
        line += this.getDate(yomi.last_action_date) + ",";
        line += yomi.next_action != null ? yomi.next_action + "," : ",";
        line += yomi.notes != null ? yomi.notes + "," : ",";
        line += yomi.updated_at != null ? yomi.updated_at + "," : ",";
        line += yomi.updated_user != null ? yomi.updated_user + "\n" : "\n";
        csv += line;
      });
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "yomi_" + dayjs().format("YYYYMMDD") + ".csv";
      link.click();
    }
  }
});
