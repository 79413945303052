







































































































































































import Vue, { PropType } from "vue";
import RegisterCalendarRepository, {
  RegisterCalendar
} from "@/repositories/registerCalendar";
import {
  RegistrationCalendarStatus,
  registerCalendarStatus
} from "@/helpers/registrationCalendarStatus";

type Datatype = {
  isHTML: boolean;
  status: RegistrationCalendarStatus[];
  previousStatus: number;
  sorts: {
    registrationMedia: boolean;
    publishedDate: boolean;
    client: boolean;
    materialName: boolean;
  };
};

export default Vue.extend({
  components: {},
  data: function(): Datatype {
    return {
      isHTML: false,
      status: registerCalendarStatus,
      previousStatus: 0,
      sorts: {
        registrationMedia: false,
        publishedDate: false,
        client: false,
        materialName: false
      }
    };
  },
  props: {
    isHtml: {
      type: Boolean,
      required: false
    },
    list: {
      type: Array as PropType<RegisterCalendar[]>,
      required: false
    }
  },
  methods: {
    setBgColor: function(item: RegisterCalendar) {
      switch (item.status) {
        case 3: // 3:登録待ち
          return "bg-warning";
        case 2: // 2:登録完了
          return "bg-white";
        case 1: // 1:チェック完了
          return "bg-secondary";
      }
      return "bg-white";
    },
    sortBy: function(itemName: string) {
      switch (itemName) {
        case "registrationMedia": // 媒体名
          this.sorts.registrationMedia = !this.sorts.registrationMedia;
          this.sorts.publishedDate = false;
          this.sorts.client = false;
          this.sorts.materialName = false;
          this.$emit("sortBy", itemName, this.sorts.registrationMedia);
          break;
        case "publishedDate": // 掲載日
          this.sorts.publishedDate = !this.sorts.publishedDate;
          this.sorts.registrationMedia = false;
          this.sorts.client = false;
          this.sorts.materialName = false;
          this.$emit("sortBy", itemName, this.sorts.publishedDate);
          break;
        case "client": // クライアント名
          this.sorts.client = !this.sorts.client;
          this.sorts.registrationMedia = false;
          this.sorts.publishedDate = false;
          this.sorts.materialName = false;
          this.$emit("sortBy", itemName, this.sorts.client);
          break;
        case "materialName": // 素材名
          this.sorts.materialName = !this.sorts.materialName;
          this.sorts.registrationMedia = false;
          this.sorts.publishedDate = false;
          this.sorts.client = false;
          this.$emit("sortBy", itemName, this.sorts.materialName);
          break;
      }
    },
    getPreviousStatus: function(status: number) {
      // 更新前ステータスを退避
      this.previousStatus = status;
    },
    update: async function(item: RegisterCalendar, index: number) {
      if (confirm("登録カレンダーを更新します。よろしいですか。")) {
        this.$emit("loading", true);
        await RegisterCalendarRepository.updateStatus(item)
          .then(() => {
            this.$emit("success");
          })
          .catch(() => {
            this.$emit("error");
          })
          .finally(() => {
            this.$emit("loading", false);
          });
      } else {
        // キャンセル時は更新値を元の値に戻す
        this.list[index].status = this.previousStatus;
      }
    }
  },
  computed: {
    showADID: function(): boolean {
      return !this.isHtml;
    }
  }
});
