export type WorkPlanType = {
  code: number;
  name: string;
};

export const workPlanType = (code: number): string | undefined =>
  workPlanTypes.find(x => x.code === code)?.name;

export const workPlanTypes: WorkPlanType[] = [
  { code: 1, name: "クライアント" },
  { code: 2, name: "IPG" },
  { code: 3, name: "ロゴ差替" },
  { code: 4, name: "未定" }
];
