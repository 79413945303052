





























































import Vue from "vue";
import { BModal } from "bootstrap-vue";

export default Vue.extend({
  methods: {
    show() {
      (this.$refs.modal as BModal).show();
    }
  }
});
