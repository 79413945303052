




































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import RegistrationAreaRepository, {
  newRegistrationAreaModalArgs,
  RegistrationArea,
  RegistrationAreaModalArgs
} from "@/repositories/registrationArea";

import { prefectures, Prefecture } from "@/helpers/prefecture";

type DataType = {
  isValid: boolean;
  loading: boolean;
  prefectures: Prefecture[];
  allAreas: RegistrationArea[];
  selectPrefectures: number[];
  areaFilter: string;
  registrationArea: RegistrationAreaModalArgs;
};

export default Vue.extend({
  data: function(): DataType {
    return {
      isValid: false,
      loading: false,
      prefectures: prefectures,
      allAreas: [],
      selectPrefectures: [],
      areaFilter: "",
      registrationArea: newRegistrationAreaModalArgs()
    };
  },
  props: {
    fromDetail: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    areas: function(): RegistrationArea[] {
      if (this.areaFilter.trim() !== "") {
        const filters = this.areaFilter.replaceAll("　", " ").split(" ");
        return this.allAreas.filter(area =>
          filters.every(filter => area.name.includes(filter))
        );
      }
      return this.allAreas;
    },
    selectedArea: function(): RegistrationArea | undefined {
      return this.allAreas.find(x => x === this.registrationArea);
    }
  },
  methods: {
    show: function(registrationAreaId: number) {
      // モーダルを開いた時に検索条件をリセット
      this.areaFilter = "";
      const registrationArea = this.allAreas.find(
        area => area.id === registrationAreaId
      );
      if (registrationArea) {
        this.registrationArea = Object.assign({}, registrationArea);
        this.searchPrefectures(registrationAreaId);
      }
      (this.$refs.modal as BModal).show();
    },
    close: function() {
      this.loading = false;
      (this.$refs.modal as BModal).hide();
    },
    clearArea: async function() {
      this.allAreas = await RegistrationAreaRepository.list("" /*TODO*/);
      this.selectPrefectures = [];
    },
    searchArea: async function(is_clear = false) {
      if (is_clear) {
        this.registrationArea.id = undefined;
      }
    },
    searchPrefectures: async function(id?: number) {
      if (!id) {
        await this.clearArea();
        return;
      }
      const area = await RegistrationAreaRepository.get(id, "");
      this.selectPrefectures = area.prefectures;
      // idだけでなくnameも変えたい為暫定で追記
      this.registrationArea = Object.assign(
        {},
        { id: area.id, name: area.name }
      );
      await this.searchArea();
    },
    setRegistrationArea: function() {
      this.loading = true;
      if (!this.valid()) return false;
      if (this.fromDetail) {
        // 掲載内容詳細画面の場合
        this.$emit("changeRegistrationArea", this.registrationArea);
        this.loading = false;
        (this.$refs.modal as BModal).hide();
      } else {
        // 入稿管理の場合
        this.$emit("save", this.registrationArea);
      }
    },
    valid: function(): boolean {
      this.isValid = false;
      // 必須チェック
      if (!this.registrationArea.id) {
        this.isValid = true;
        return false;
      }
      return true;
    },
    range: function(length: number) {
      return Array.from(Array(length), (_, k) => k);
    }
  },
  mounted: async function() {
    this.allAreas = await RegistrationAreaRepository.list("" /*TODO*/);
  }
});
