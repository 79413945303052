export type StaffType = {
  code: number;
  name: string;
};

export const staffType = (code: number): string | undefined =>
  staffTypes.find(x => x.code === code)?.name;

export const staffTypes: StaffType[] = [
  { code: 1, name: "発注者" },
  { code: 2, name: "入稿者" },
  { code: 3, name: "発注者＆入稿者" }
];
