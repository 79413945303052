







































import Vue from "vue";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default Vue.extend({
  components: {
    DatePicker
  },
  props: {
    value: {
      type: String,
      required: false
    },
    valueFormat: {
      type: String,
      default: "YYYY-MM"
    },
    displayFormat: {
      type: String,
      default: "YYYY-M"
    },
    valueAttr: {
      type: Object,
      default: undefined
    },
    isClear: {
      type: Boolean,
      default: false
    },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    type: {
      type: String,
      default: "date"
    },
    disabledDate: {
      type: Boolean,
      default: false
    },
    startOfDate: {
      type: String,
      required: false
    }
  },
  methods: {
    disabledDays(date: any) {
      // 明日以前は選択不可
      if (this.disabledDate) return date < dayjs().add(1, "day");
      // 開始指定日付以前は選択不可
      if (this.startOfDate) return date < dayjs(this.startOfDate);
    },
    onInput: function(date: string /* YYYY-MM-DD */) {
      this.$emit("input", date);
    },
    onFocus: function(date: string) {
      this.$emit("focus", date);
    },
    onBlur: function(event: FocusEvent) {
      this.$emit("blur", (event.target as HTMLInputElement)?.value);
    },
    onClose: function(date: string) {
      this.$emit("close", date);
    }
  }
});
