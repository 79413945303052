
































import Vue from "vue";
import dayjs from "dayjs";

import YearMonthPicker from "@/components/calendars/YearMonthPicker.vue";
import DatePicker from "@/components/calendars/Datepicker.vue";

type DataType = {
  yearMonth: string;
  dayOfWeek: string;
  dayOfMonth: string;
  date: string;
};

export default Vue.extend({
  components: { DatePicker, YearMonthPicker },
  props: {
    value: {
      type: String,
      required: false
    },
    requireDayOfMonth: {
      type: Boolean,
      required: false,
      default: false
    },
    noneDay: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Boolean,
      default: false
    }
  },
  data: function(): DataType {
    return {
      yearMonth: "",
      dayOfWeek: "",
      dayOfMonth: "",
      date: ""
    };
  },
  methods: {
    onInput: function(value: string) {
      this.$emit("input", value);
    },
    inputFromYearMonth: function(value: string) {
      this.$emit("inputFromYearMonth", value);
    },
    inputFromDate: function(value: string) {
      this.$emit("inputFromDate", value);
    },
    inputToYearMonth: function(value: string) {
      this.$emit("inputToYearMonth", value);
    },
    inputToDate: function(value: string) {
      this.$emit("inputFromDate", value);
    },
    inputYearMonth: function() {
      if (this.requireDayOfMonth && this.yearMonth) {
        this.date = `${this.yearMonth}-01`;
        this.dayOfMonth = `${this.yearMonth}-01`;
        this.setDayOfWeek();
      } else {
        this.date = this.yearMonth;
        this.dayOfMonth = "";
        this.dayOfWeek = "";
      }
    },
    setDate: function() {
      this.date = this.value;
      const isYearMonthOnly = /^[0-9]{4}-[0-9]{2}$/.test(this.value);
      const day = dayjs(this.value, "YYYY-MM-DD", true);
      if (day.isValid() && !isYearMonthOnly) {
        this.yearMonth = day.format("YYYY-MM");
        this.dayOfMonth = day.format("YYYY-MM-DD");
        if (!this.noneDay && this.date && this.dayOfMonth) {
          this.setDayOfWeek();
        }
      } else {
        this.yearMonth = this.value;
        this.dayOfMonth = "";
        this.dayOfWeek = "";
      }
    },
    setDefaultDate: function() {
      if (!this.dayOfMonth || this.dayOfMonth === "") {
        this.date = `${this.yearMonth}-01`;
        this.dayOfMonth = `${this.yearMonth}-01`;
        this.setDayOfWeek();
      }
    },
    inputDayOfMonth: function(value: string) {
      const day = dayjs(this.dayOfMonth);
      if (day.isValid()) {
        this.date = `${this.yearMonth}-${day.format("DD")}`;
        this.yearMonth = day.format("YYYY-MM");
        this.dayOfMonth = day.format("YYYY-MM-DD");
        this.setDayOfWeek();
      } else {
        this.yearMonth = dayjs(this.yearMonth).format("YYYY-MM");
        this.date = this.yearMonth;
        this.dayOfWeek = "";
      }
    },
    setDayOfWeek() {
      const day = dayjs(this.date);
      if (day.isValid()) {
        this.dayOfWeek = dayjs(this.date).format("(dd)");
      }
    },
    validationPublicationDate(
      year: number,
      month: number,
      date: number,
      to = false
    ) {
      if (to && month === 0) {
        return true;
      }
      // 月入力チェック
      if (!(month >= 1 && month <= 12)) {
        return false;
      }
      // 日入力チェック
      if (date > 0) {
        var endOfDate = dayjs()
          .year(year)
          .month(month - 1)
          .endOf("month")
          .date();
        if (date != null && (date < 1 || date > endOfDate)) {
          return false;
        }
      }
      return true;
    }
  },
  computed: {
    year: function() {
      const day = dayjs(this.$data.date);
      if (day.isValid()) {
        return day.year();
      }
      return undefined;
    }
  },
  watch: {
    date: function(val: string) {
      this.$emit("input", val ? val : "");
    },
    value: function(val: string) {
      this.setDate();
    },
    noneDay: function(val: string) {
      this.setDate();
    }
  },
  mounted: function() {
    this.setDate();
  }
});
