import repository from "@/repositories/repository";

export type ClientStaff = {
  id?: number;
  client_id: number;
  name: string;
  email: string;
  tel: string;
  staff_type: number;
  disabled: number;
};

type Query = {
  id?: number;
  client_id: number;
  name: string;
  email: number;
  tel: string;
  staff_type: number;
  disabled: number;
};

export default {
  list: async (
    clientId: number,
    token: string,
    query?: Query
  ): Promise<ClientStaff[]> => {
    const response = await repository(token).get(
      `/api/client/staffs/search/${clientId}`
    );
    return response.data;
  },

  get: async (id: number, token: string): Promise<ClientStaff> => {
    const response = await repository(token).get(`/api/client/staffs/${id}`);
    return response.data;
  },

  store: async (data: any, token: string): Promise<ClientStaff> => {
    const response = await repository(token).post(`/api/client/staffs`, {
      data: data
    });
    return response.data;
  },

  update: async (
    id: number,
    data: ClientStaff,
    token: string
  ): Promise<void> => {
    const response = await repository(token).put(`/api/client/staffs/${id}`, {
      data: data
    });
    return response.data;
  },

  destroy: async (id: number, token: string): Promise<void> => {
    const response = await repository(token).delete(`/api/client/staffs/${id}`);
  }
};

export const newClientStaff: () => ClientStaff = () => ({
  id: 0,
  client_id: 0,
  name: "",
  email: "",
  tel: "",
  staff_type: 0,
  disabled: 0
});
