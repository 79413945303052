



















































































import Vue, { PropType } from "vue";

import dayjs, { Dayjs } from "dayjs";

import MediaFilter from "@/components/MediaFilter.vue";
import WakuMonthCalendarDateHeader from "@/components/WakuMonthCalendarDateHeader.vue";
import Deal from "@/models/deal";

import _ from "lodash";

type DataType = {
  currentDate: dayjs.Dayjs;
  filter: {
    media: string[];
  };
  isNewWakuActive: boolean;
};

export default Vue.extend({
  components: {
    MediaFilter,
    WakuMonthCalendarDateHeader
  },
  data: function(): DataType {
    return {
      currentDate: dayjs()
        .year(this.year)
        .month(this.month - 1)
        .startOf("month"), // 月初を基準に
      filter: {
        media: [] as string[]
      },
      isNewWakuActive: true
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().year()
    },
    month: {
      type: Number,
      required: true,
      default: dayjs().month() + 1,
      validator: function(value) {
        return 1 <= value && value <= 12;
      }
    },
    waku: {
      type: Object,
      required: true
    },
    adMedia: {
      type: Array,
      required: false
    },
    media: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  computed: {
    filteredMedia: function(): any[] {
      return this.adMedia.filter((x: any) => this.filter.media.includes(x.id));
    },
    showable: function(): boolean {
      if (
        this.adMedia !== undefined &&
        this.adMedia.length > 0 &&
        this.waku !== undefined &&
        this.waku[this.year] !== undefined &&
        this.waku[this.year][this.month] !== undefined
      ) {
        return true;
      }
      return false;
    },
    startOfCalendar: function(): dayjs.Dayjs {
      return dayjs()
        .year(this.year)
        .month(this.month - 1) // 1-12(prop) to 0-11(dayjs.month)
        .startOf("month")
        .day(0); // その週の日曜日
    },
    endOfCalendar: function(): dayjs.Dayjs {
      return dayjs()
        .year(this.year)
        .month(this.month - 1) // 1-12(prop) to 0-11(dayjs.month)
        .endOf("month")
        .day(6); // その週の土曜日
    },
    today: function() {
      return dayjs().startOf("day");
    },
    weeks: function() {
      const weeksCount = Math.ceil(
        this.endOfCalendar.diff(this.startOfCalendar, "day") / 7
      );
      const weeks: dayjs.Dayjs[][] = [];
      for (let w = 0; w < weeksCount; w++) {
        const week: dayjs.Dayjs[] = [];
        const startOfWeek = this.startOfCalendar.add(w, "week");
        for (let d = 0; d < 7; d++) {
          week.push(startOfWeek.add(d, "day"));
        }
        weeks.push(week);
      }
      return weeks;
    }
  },
  watch: {
    media: function() {
      this.filter.media = this.media;
    }
  },
  methods: {
    isMediaActive: function(id: string) {
      return this.filteredMedia.map(x => x.id).includes(id);
    },
    onChange: function(selected: any) {
      this.filter.media = selected;
      this.$emit("changeFilter", selected);
    },
    countWaku: function(
      day: Dayjs,
      mediaId: number,
      slotPriority: number
    ): number {
      const year = day.year();
      const month = day.month() + 1;
      const date = day.date();

      return this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][date]
            ? this.waku[year][month][date][mediaId]
              ? this.waku[year][month][date][mediaId][slotPriority]
                ? this.waku[year][month][date][mediaId][slotPriority].length
                : 0
              : 0
            : 0
          : 0
        : 0;
    },
    reservation: function(
      day: Dayjs,
      mediaId: number,
      slotPriority: number,
      index = 0
    ): boolean {
      const year = day.year();
      const month = day.month() + 1;
      const date = day.date();
      // 該当日の枠存在チェック
      if (this.isWaku(year, month, date, mediaId, slotPriority)) {
        const deals: Deal[] = this.waku[year][month][date][mediaId][
          slotPriority
        ];
        // 該当枠内にヨミ確度「M／J」の枠が1つ以上ある場合 true
        return deals.find(
          x =>
            x.sales_probability_def.name === "M" ||
            x.sales_probability_def.name === "J"
        )
          ? true
          : false;
      }
      return false;
    },
    slotLabel: function(deals: Array<any>) {
      if (deals.length === 1) {
        const deal = deals[0];
        const areaName = deal.ad_area?.name;
        const clientName = deal.client?.abbreviation;
        return `${areaName} ${clientName}`;
      }
      if (deals.length > 1) {
        return `LSP x ${deals.length}`;
      }
      return "";
    },
    getDeals: function(day: Dayjs, mediaId: number, slotPriority: number) {
      const year = day.year();
      const month = day.month() + 1;
      const date = day.date();

      return this.isWaku(year, month, date, mediaId, slotPriority)
        ? this.waku[year][month][date][mediaId][slotPriority]
        : [];
    },
    getWaku: function(
      day: Dayjs,
      mediaId: number,
      slotPriority: number,
      index = 0
    ) {
      const year = day.year();
      const month = day.month() + 1;
      const date = day.date();

      return this.isWaku(year, month, date, mediaId, slotPriority)
        ? this.waku[year][month][date][mediaId][slotPriority][index]
        : [];
    },
    isWaku: function(
      year: number,
      month: number,
      date: number,
      mediaId: number,
      slotPriority: number
    ): boolean {
      // 枠が存在する場合 true
      return this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][date]
            ? this.waku[year][month][date][mediaId]
              ? this.waku[year][month][date][mediaId][slotPriority]
              : false
            : false
          : false
        : false;
    },
    getCount: function(day: Dayjs): number {
      const year = day.year();
      const month = day.month() + 1;
      const date = day.date();
      if (
        this.waku[year] == undefined ||
        this.waku[year][month] == undefined ||
        this.waku[year][month][date] == undefined
      ) {
        return 0;
      }

      //CE系のみ //TODO 固定値
      const targetMedia = this.adMedia.filter((x: any) =>
        x.name.startsWith("CE")
      );
      const counts = targetMedia.map((media: any) => {
        if (!this.waku[year][month][date][media.id]) return 0;
        const deals = this.waku[year][month][date][media.id];
        if (!deals) return 0;
        if (Array.isArray(deals)) {
          return this.waku[year][month][date][media.id].length;
        }
        return Object.keys(deals)
          .map((key: string) => Number(key))
          .filter(slotPriority => slotPriority <= media.flame_count)
          .map(slotPriority =>
            deals[slotPriority] ? deals[slotPriority].length : 0
          );
      });
      const sum = _.sum(_.flatten(counts));
      return sum;
    }
  },
  mounted: function() {
    this.filter.media = this.media;
    if (this.media.length > 0) {
      (this as any).$refs.mediaFilter.changeSelected(this.media);
    }
  }
});
