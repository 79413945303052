






































































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewUserModal from "@/components/NewUserModal.vue";

import UserRepository, { User } from "@/repositories/users";
import RoleRepository, { Role } from "@/repositories/roles";
import UserRolesRepository, { UserRole } from "@/repositories/userRoles";
import * as AuthHelper from "@/helpers/auth";

type UserType = {
  id: number;
  name: string;
  email: string;
  role_name: string;
  disabled: number;
};

type DataType = {
  users: UserType[];
  getUsers: User[];
  getRoles: Role[];
  getUserRoles: UserRole[];
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
  success: boolean;
  updateUser: boolean;
  deleteUser: boolean;
  searchString: string;
  userId: number;
};

export default Vue.extend({
  components: {
    Menu,
    NewUserModal
  },
  data: function(): DataType {
    return {
      users: [],
      getUsers: [],
      getRoles: [],
      getUserRoles: [],
      isFormShown: false,
      isNew: true,
      loading: false,
      success: false,
      updateUser: false,
      deleteUser: false,
      searchString: "",
      userId: 0
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.users && this.users.length > 0;
    },
    isUserAdmin: function(): boolean {
      return AuthHelper.isUserAdmin();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.userId = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.isNew = false;
      this.userId = id;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newUserModal as any).show();
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function(users: User[]) {
      this.success = true;
      this.loading = true;
      this.getUsers = users;
      this.getUserRoles = await UserRolesRepository.list("");
      await this.setUsers();
      this.loading = false;
    },
    onUpdate: async function(users: User[]) {
      this.updateUser = true;
      this.loading = true;
      this.getUsers = users;
      this.getUserRoles = await UserRolesRepository.list("");
      await this.setUsers();
      this.loading = false;
    },
    onDelete: async function(users: User[]) {
      this.deleteUser = true;
      this.loading = true;
      this.getUsers = users;
      this.getUserRoles = await UserRolesRepository.list("");
      await this.setUsers();
      this.loading = false;
    },
    onSearch: async function() {
      this.loading = true;
      if (this.searchString != null && this.searchString != "") {
        this.getUsers = await UserRepository.search(
          this.searchString,
          "" /*TODO*/
        );
      } else {
        this.getUsers = await UserRepository.list("" /*TODO*/);
      }
      await this.setUsers();
      this.loading = false;
    },
    setUsers: async function() {
      this.users = [];
      var user: UserType;
      this.getUsers.forEach(element => {
        // 該当ユーザの情報格納
        user = {
          id: 0,
          name: "",
          email: "",
          role_name: "",
          disabled: 0
        };
        user.id = element.id;
        user.name = element.name;
        user.email = element.email;
        user.disabled = element.disabled;
        // 該当ユーザの権限を全て取得
        var userRole = this.getUserRoles.filter(x => x.user_id === element.id);
        userRole.forEach(element => {
          // 該当ユーザの権限名を格納
          var role = this.getRoles.find(x => x.id === element.role_id);
          if (role != undefined) {
            user.role_name = user.role_name + role.name + " ";
          }
        });
        this.users.push(user);
      });
    }
  },
  mounted: async function() {
    this.loading = true;
    this.getUsers = await UserRepository.list("" /*TODO*/, { all: true });
    this.getRoles = await RoleRepository.list("");
    this.getUserRoles = await UserRolesRepository.list("");
    await this.setUsers();
    this.loading = false;
  }
});
