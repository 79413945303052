





















































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import HelpModal from "@/components/HelpMailTemplate.vue";

import { EmailTag, emailtags } from "@/helpers/emailTag";

import EmailTemplatesRepository, {
  EmailTemplate
} from "@/repositories/emailTemplates";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  isHelp: boolean;
  errorMsg: string[];
  updateEmail: boolean;
  insertForm: number;
  template: EmailTemplate;
  tags: EmailTag[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      updateEmail: false,
      isError: false,
      isHelp: false,
      errorMsg: [],
      insertForm: 1,
      tags: emailtags,
      template: {
        id: 0,
        type: 0,
        title: "",
        text_header: "",
        text_body: "",
        text_footer: ""
      }
    };
  },
  components: {
    Menu,
    HelpModal
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return AuthHelper.isEmailTemplateEditable();
    }
  },
  methods: {
    insertTag: function(tag: string) {
      if (!this.editable) return;
      switch (this.insertForm) {
        case 1:
          this.template.text_header = this.template.text_header + tag;
          break;
        case 2:
          this.template.text_body = this.template.text_body + tag;
          break;
        case 3:
          this.template.text_footer = this.template.text_footer + tag;
          break;
      }
    },
    updateTmpllate: async function() {
      var action = confirm(
        "発注メールテンプレートを更新します。よろしいですか。"
      );
      if (!action) {
        return false;
      }
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      this.loading = true;
      this.template = await EmailTemplatesRepository.update(this.template, "");
      this.loading = false;
      this.updateEmail = true;
    },
    validation: function() {
      var msg: string[] = [];
      if (this.template.title === null || this.template.title === "") {
        msg.push("メールタイトルを入力してください。");
      }
      if (
        this.template.text_header === null ||
        this.template.text_header === ""
      ) {
        msg.push("メール本文(ヘッダー部)を入力してください。");
      }
      if (this.template.text_body === null || this.template.text_body === "") {
        msg.push("メール本文(請求部)を入力してください。");
      }
      if (
        this.template.text_footer === null ||
        this.template.text_footer === ""
      ) {
        msg.push("メール本文(フッター部)を入力してください。");
      }
      return msg;
    },
    closeModal() {
      this.isHelp = false;
    },
    showHelpModal() {
      this.isHelp = true;
      (this.$refs.helpModal as any).show();
    }
  },
  mounted: async function() {
    this.loading = true;
    this.template = await EmailTemplatesRepository.get(1, "");
    this.loading = false;
  }
});
