export type EmailTag = {
  code: number;
  name: string;
  desc: string;
};

export const emailTag = (code: number): string | undefined =>
  emailtags.find(x => x.code === code)?.name;

export const emailtags: EmailTag[] = [
  { code: 1, name: "client-name", desc: "クライアント名" },
  {
    code: 2,
    name: "client-staff-name",
    desc: "クライアント担当者名（担当者種別が「発注者」になっている担当者）"
  },
  {
    code: 3,
    name: "client-staff-tel",
    desc:
      "クライアント担当者の電話番号（担当者種別が「発注者」になっている担当者）"
  },
  {
    code: 4,
    name: "client-staff-email",
    desc:
      "クライアント担当者のメールアドレス（担当者種別が「発注者」になっている担当者）"
  },
  { code: 5, name: "media-name", desc: "掲載媒体名正式名称" },
  { code: 6, name: "media-price", desc: "掲載媒体費用" },
  { code: 7, name: "production-price", desc: "制作費用" },
  { code: 8, name: "published-date", desc: "掲載日" },
  {
    code: 9,
    name: "published-time",
    desc: "掲載時間（終日・調整枠の場合は5:00~29:00・夜帯の場合は19:00~25:00）"
  },
  { code: 10, name: "area-name", desc: "エリア名" },
  { code: 11, name: "comment", desc: "備考" },
  { code: 12, name: "billing-month", desc: "ご請求月" },
  { code: 13, name: "billing-year-month", desc: "ご請求年月" },
  { code: 14, name: "payment-year-month", desc: "入金予定年月" },
  {
    code: 15,
    name: "details-start",
    desc: "案件が複数選択された場合の複数表示範囲開始"
  },
  {
    code: 16,
    name: "details-end",
    desc: "案件が複数選択された場合の複数表示範囲終了"
  }
];
