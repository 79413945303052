






























































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import { AxiosError } from "axios";

import dayjs from "dayjs";
import weekYear from "dayjs/plugin/weekYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);

import Menu from "@/components/Menu.vue";
import WakuWeekCalendar from "@/components/WakuWeekCalendar.vue";
import NewWakuModal from "@/components/NewWakuModal.vue";

import WakuRepository from "@/repositories/waku";
import AdMediaRepository from "@/repositories/medias";
import { Deal } from "@/repositories/deals";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  filterMedia: string[];
  isNewFormShown: boolean;
  waku: unknown;
  adMedia: unknown[];
  message: string;
  success: boolean;
  warningMessage: string;
  warning: boolean;
  hasError: boolean;
  error?: string;
  loading: boolean;
};

export default Vue.extend({
  components: {
    Menu,
    WakuWeekCalendar,
    NewWakuModal
  },
  data: function(): DataType {
    return {
      filterMedia: [],
      isNewFormShown: false,
      waku: {},
      adMedia: [],
      message: "",
      success: false,
      warningMessage: "",
      warning: false,
      hasError: false,
      error: undefined,
      loading: true
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().weekYear()
    },
    week: {
      type: Number,
      required: true,
      default: dayjs().week(),
      validator: function(value) {
        return 1 <= value && value <= 54;
      }
    },
    media: {
      type: Array as PropType<string[]>,
      required: false
    }
  },
  computed: {
    currentDate: function() {
      return dayjs(`${this.year}-1-1`)
        .week(this.week)
        .startOf("week"); // 週の初めを基準に
    },
    editable: function() {
      // 掲載日が2日以上過去の場合新規登録を不可
      const pastDay = dayjs().add(1, "day");
      const current = dayjs(`${this.year}-1-1`)
        .week(this.week)
        .endOf("week");
      return AuthHelper.isDealEditor() && current.isAfter(pastDay);
    }
  },
  watch: {
    week: async function(val, oldVal) {
      if (val !== oldVal) {
        this.fetchWaku(this.year, this.week);
      }
    },
    media: function() {
      this.filterMedia = this.media;
    }
  },
  methods: {
    changeFilterMedia: function(filterMedia: any) {
      this.filterMedia = filterMedia;
    },
    startOfWeek: function(): dayjs.Dayjs {
      return this.currentDate.startOf("week");
    },
    endOfWeek: function(): dayjs.Dayjs {
      return this.currentDate.endOf("week");
    },
    isThisWeek: function(): boolean {
      const today = dayjs();
      const startOfThisWeek = dayjs(`${today.weekYear()}-1-1`)
        .week(today.week())
        .startOf("week");
      return startOfThisWeek.isSame(this.currentDate);
    },
    previousWeekRoute: function(): Record<string, unknown> {
      const nextWeek = this.currentDate.add(-1, "week");
      return this.weekRoute(nextWeek);
    },
    nextWeekRoute: function(): Record<string, unknown> {
      const nextWeek = this.currentDate.add(1, "week");
      return this.weekRoute(nextWeek);
    },
    thisWeekRoute: function(): Record<string, unknown> {
      const today = dayjs();
      const todayWeek = today.startOf("week");
      return this.weekRoute(todayWeek);
    },
    weekRoute: function(day: dayjs.Dayjs): Record<string, unknown> {
      return {
        name: "waku.week",
        params: {
          year: day.weekYear().toString(),
          week: day.week().toString(),
          media: this.filterMedia
        }
      };
    },
    addWeek: function(week: number) {
      const movedWeek = this.currentDate.add(week, "week");
      this.moveTo(movedWeek);
    },
    todayWeek: function() {
      const now = dayjs().startOf("week");
      this.moveTo(now);
    },
    moveTo: function(date: dayjs.Dayjs) {
      this.currentDate = date;
      this.$router.push({
        name: "waku.week",
        params: {
          year: date.weekYear().toString(),
          week: date.week().toString()
        }
      });
    },
    monthRoute: function() {
      return {
        name: "waku.month",
        params: {
          year: this.currentDate.year().toString(),
          month: (this.currentDate.month() + 1).toString(), // 0-11(dayjs.month) to 1-12(params)
          media: this.filterMedia
        }
      };
    },
    onNewWaku() {
      this.isNewFormShown = true;
    },
    showNewForm() {
      this.isNewFormShown = true;
      (this.$refs.newWakuModal as BModal).show();
    },
    closeNewForm() {
      this.isNewFormShown = false;
    },
    dateFormat(date: dayjs.Dayjs): string {
      return date.format("YYYY年M月D日");
    },
    onSuccess: async function(deal: Deal, waitingDeals: Deal[] = []) {
      this.message = deal.id
        ? "枠表案件の更新が完了しました。"
        : "枠表案件の新規登録が完了しました。";
      this.success = true;
      if (waitingDeals.length > 0) {
        this.message = [
          this.message,
          "キャンセル待ちがありますのでご確認ください。"
        ].join("\n");
        this.warning = true;
        this.warningMessage = waitingDeals
          .map((deal: Deal) => {
            // [掲載日：YYYY年M月D日　媒体：xxx　クライアント：xxx　タイトル：xxx]がキャンセル待ちになっています。
            return `[掲載日：${dayjs(deal.published_date.date.from).format(
              "YYYY年M月D日"
            )}　媒体：${deal.ad_media.name}　クライアント：${
              deal.client.name
            }　タイトル：${deal.title}]がキャンセル待ちになっています。`;
          })
          .join("\n");
      }
      this.fetchWaku(this.year, this.week, false);
    },
    onRemove: async function() {
      this.message = "枠表案件の予約を解除しました。";
      this.success = true;
      this.fetchWaku(this.year, this.week, false);
    },
    fail(error: AxiosError) {
      this.hasError = true;
      if (error.response?.status === 403) {
        this.error = "権限が不足しています。";
      } else {
        this.error = "エラーが発生しました";
      }
    },
    fetchWaku: async function(year: number, week: number, showLoading = true) {
      if (showLoading) {
        this.loading = true;
      }
      await WakuRepository.week("" /*TODO*/, {
        year: year,
        week: week
      }).then(waku => {
        this.waku = waku;
        if (year === this.year && week === this.week) {
          this.loading = false;
        }
      });
    }
  },
  mounted: async function() {
    await AdMediaRepository.list("" /*TODO*/).then(media => {
      this.loading = false;
      this.adMedia = media;
      this.filterMedia = media.map((x: any) => x.id);
    });

    this.fetchWaku(this.year, this.week);
  }
});
