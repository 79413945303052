
import Vue, { PropType } from "vue";
import { ChartOptions, PositionType, ChartLegendLabelItem } from "chart.js";
import { Line, Bar, mixins } from "vue-chartjs";

type Point = {
  label: string;
  budget: number;
  pointJ: number;
  pointM: number;
  pointA: number;
  pointNα: number;
  pointB: number;
  pointC: number;
  pointD: number;
  pointN: number;
  pointOchi: number;
  performance: number;
};

const datasets = {
  borderColor: "#ff0000",
  fill: false,
  lineTension: 0
};

export default Vue.extend({
  extends: Bar,
  mixins: [mixins.reactiveData, mixins.reactiveData],
  props: {
    points: {
      type: Array as PropType<Point[]>,
      default: function() {
        return [];
      }
    },
    options: {
      type: Object as PropType<ChartOptions>,
      default: function() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          fill: false,
          animation: undefined,
          legend: {
            position: "left" as PositionType,
            labels: {
              generateLabels: function(chart: Chart) {
                let datasets = chart.data ? chart.data.datasets || [] : [];
                datasets = datasets || [];
                const labels = datasets.map(function(dataset, i) {
                  return {
                    text: dataset.label,
                    fillStyle:
                      dataset.type === "line"
                        ? dataset.borderColor
                        : dataset.backgroundColor,
                    strokeStyle:
                      dataset.type === "bar"
                        ? dataset.backgroundColor
                        : dataset.borderColor,
                    index: dataset.type === "line" ? -i : 2 * datasets.length, // 凡例の表示順を調整 (line を上に、bar はグラフの表示順に合わせる)
                    hidden: !chart.isDatasetVisible(i)
                  } as ChartLegendLabelItem;
                });
                return labels.sort(
                  (x: ChartLegendLabelItem, y: ChartLegendLabelItem) =>
                    (x.index || -1) - (y.index || 0) || -1
                );
              }
            }
          },
          scales: {
            xAxes: [
              {
                stacked: true
              }
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  min: 0,
                  callback: function(label, index, labels) {
                    return Number(label).toLocaleString();
                  }
                }
              }
            ]
          }
        };
      }
    }
  },
  data() {
    return {};
  },
  watch: {
    points: function(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.createChart();
      }
    }
  },
  mounted: function(): void {
    this.createChart();
  },
  methods: {
    createChart: function() {
      const thisChart = (this as unknown) as Line;
      thisChart.renderChart(
        {
          labels: this.points.map(x => x.label),
          datasets: [
            {
              ...datasets,
              label: "予算",
              borderColor: "#FF0000",
              data: this.points.map(x => x.budget),
              type: "line",
              order: 0
            },
            {
              ...datasets,
              label: "J",
              backgroundColor: "#F26689",
              data: this.points.map(x => x.pointJ),
              type: "bar",
              order: 1
            },
            {
              ...datasets,
              label: "M",
              backgroundColor: "#FEB0B3",
              data: this.points.map(x => x.pointM),
              type: "bar",
              order: 2
            },
            {
              ...datasets,
              label: "A",
              backgroundColor: "#FFA000",
              data: this.points.map(x => x.pointA),
              type: "bar",
              order: 3
            },
            {
              ...datasets,
              label: "B",
              backgroundColor: "#FFC566",
              data: this.points.map(x => x.pointB),
              type: "bar",
              order: 4
            },
            {
              ...datasets,
              label: "Nα",
              backgroundColor: "#FFEEA1",
              data: this.points.map(x => x.pointNα),
              type: "bar",
              order: 5
            },
            {
              ...datasets,
              label: "C",
              backgroundColor: "#92D04F",
              data: this.points.map(x => x.pointC),
              type: "bar",
              order: 6
            },
            {
              ...datasets,
              label: "D",
              backgroundColor: "#11524D",
              data: this.points.map(x => x.pointD),
              type: "bar",
              order: 7
            },
            {
              ...datasets,
              label: "N",
              backgroundColor: "#27A599",
              data: this.points.map(x => x.pointN),
              type: "bar",
              order: 8
            }
          ]
        },
        this.options
      );
    }
  }
});
