import { AxiosResponse } from "axios";

export function downloadAttachedFileName(
  response: AxiosResponse,
  defaultFileName: string
): void {
  const filename = getAttachedFileName(response, defaultFileName);
  const blob = new Blob([response.data], {
    type: response.data.type
  });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
  if (link && link.parentNode) {
    link.parentNode.removeChild(link);
  }
}

function getAttachedFileName(
  response: AxiosResponse,
  defaultFileName: string
): string {
  const contentDisposition = response.headers["content-disposition"];
  const utf8MatchedFilename = contentDisposition.match(
    /filename\*=utf-8''(.+);?/
  );
  return utf8MatchedFilename
    ? decodeURI(utf8MatchedFilename[1])
    : contentDisposition.match(/filename=(.+)/)[1] ?? defaultFileName; // "登録リスト.xlsx";
}
