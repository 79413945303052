


































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";
import SaleChannelsRepository, {
  SaleChannel,
  newSaleChannel
} from "@/repositories/saleChannels";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  saleChannel: SaleChannel;
  errorMsg: string[];
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      isError: false,
      errorMsg: [],
      saleChannel: {
        id: 0,
        name: "",
        disabled: 0,
        deleted_at: "",
        agency: []
      }
    };
  },
  props: {
    isNew: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    shownModal: function(): boolean {
      return !this.loading;
    },
    editable: function(): boolean {
      return (
        AuthHelper.isSalesChannelWritable() ||
        AuthHelper.isSalesChannelUpdatable()
      );
    },
    deletable: function(): boolean {
      return AuthHelper.isSalesChannelDeletable();
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function(oldValue, newValue) {
      this.resetData();
      if (!this.isNew) {
        var saleChannel: SaleChannel;
        saleChannel = await SaleChannelsRepository.get(this.id, "");
        if (saleChannel != null) {
          this.saleChannel = saleChannel;
        }
      }
      this.loading = false;
    }
  },
  methods: {
    show: async function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.errorMsg = [];
      this.isError = false;
      this.saleChannel = newSaleChannel();
    },
    postSaleChannel: async function() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await SaleChannelsRepository.store(this.saleChannel, "");
      (this.$refs.modal as BModal).hide();
      this.$emit("success", result);
    },
    updateSaleChannel: async function() {
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      var result = await SaleChannelsRepository.update(
        this.id,
        this.saleChannel,
        ""
      );
      (this.$refs.modal as BModal).hide();
      this.$emit("update", result);
    },
    deleteSaleChannel: async function() {
      if (this.saleChannel.agency.length === 0) {
        var action = confirm("本当に削除してよろしいですか？");
        if (!action) {
          return false;
        }
        var result = await SaleChannelsRepository.destroy(this.id, "");
        (this.$refs.modal as BModal).hide();
        this.$emit("delete", result);
      } else {
        this.errorMsg = this.deleteValidation();
        if (this.errorMsg.length > 0) {
          this.isError = true;
          return false;
        }
      }
    },
    validation: function() {
      var msg: string[];
      msg = [];
      if (this.saleChannel.name === null || this.saleChannel.name === "") {
        msg.push("商流（代理店）名を入力してください。");
      }
      return msg;
    },
    deleteValidation: function() {
      var msg: string[];
      msg = [];
      if (this.saleChannel.agency.length > 0) {
        msg.push("担当者が登録済みのため削除できません。");
      }
      return msg;
    }
  }
});
