import repository from "@/repositories/repository";

export type Adid = {
  adid: number;
};

type Query = {
  adid: number;
};

export default {
  list: async (token: string, query?: Query): Promise<Adid> => {
    const response = await repository(token).get("/api/adid", {
      params: { ...query }
    });
    return response.data;
  },
  update: async (adid: Adid, token: string): Promise<Adid> => {
    const response = await repository(token).put(`/api/adid`, {
      data: adid
    });
    return response.data;
  }
};
