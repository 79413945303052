import repository from "@/repositories/repository";

export type RegisterCalendarQuery = {
  date_from: string;
  date_to: string;
};

export type RegisterCalendar = {
  id: string;
  registration_media: {
    code: number;
    name: string;
  };
  adid: string;
  published_date: string;
  time: {
    from: string;
    to: string;
  };
  client: {
    id: number;
    name: string;
  };
  registration_area: {
    id: number;
    name: string;
  };
  material_name: string;
  user: {
    id: number;
    name: string;
  };
  status: number; // 登録カレンダーステータス
  draft_sheet: {
    id: number;
    status: {
      // 入稿書ステータス
      code: number;
      name: string;
    };
  };
};

export default {
  list: async (query: RegisterCalendarQuery): Promise<RegisterCalendar[]> => {
    const response = await repository().get(`/api/registerCalendars/index`, {
      params: { ...query }
    });
    return response.data;
  },

  updateStatus: async (data: RegisterCalendar): Promise<void> => {
    await repository().put(`/api/registerCalendars/updateStatus/${data.id}`, {
      data: data
    });
  }
};

export const resetRegisterCalendar: () => RegisterCalendarQuery = () => ({
  date_from: "",
  date_to: ""
});
