import Vue from "vue";
import VueRouter, { Route } from "vue-router";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);

import store from "@/store";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Yomi from "../views/Yomi.vue";
import YomiManagement from "../views/YomiManagement.vue";
import DraftCalendar from "../views/DraftCalendar.vue";
import RegisterCalendar from "@/views/RegisterCalendar.vue";
import WakuMonth from "../views/WakuMonth.vue";
import WakuWeek from "../views/WakuWeek.vue";
import WakuDay from "../views/WakuDay.vue";
import Admin from "../views/Admin.vue";
import Clients from "../views/Clients.vue";
import Area from "../views/Area.vue";
import User from "../views/User.vue";
import ClientAttribute from "../views/ClientAttribute.vue";
import Item from "../views/Item.vue";
import ItemManagement from "../views/ItemManagement.vue";
import SaleChannel from "../views/SaleChannel.vue";
import SaleDefs from "../views/SaleDefs.vue";
import MailTemplate from "../views/MailTemplate.vue";
import FiscalYear from "../views/FiscalYear.vue";
import ClientStaff from "../views/ClientStaff.vue";
import AgencyStaff from "../views/AgencyStaff.vue";
import DraftSheet from "@/views/DraftSheet.vue";
import DraftDetail from "@/views/DraftDetail.vue";
import Output from "../views/Output.vue";
import Channel from "@/views/Channel.vue";
import RegistrationArea from "@/views/RegistrationArea.vue";
import Adid from "@/views/Adid.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  // ヨミ表
  {
    path: "/yomi",
    name: "Yomi",
    component: Yomi
  },
  // ヨミ表(管理用)
  {
    path: "/yomi/management",
    name: "YomiManagement",
    component: YomiManagement
  },
  // 入稿カレンダー
  {
    path: "/draft/calendar",
    name: "DraftCalendar",
    component: DraftCalendar
  },
  // 入稿書管理
  {
    path: "/draft/calendar/:draftDealId/draftSheets",
    name: "DraftSheets",
    component: DraftSheet
  },
  // 掲載内容詳細
  {
    // 登録リスト出力内容に含まれる URL なので変更する場合は影響を確認すること
    path: "/draft/sheets/:draftSheetId/edit/:adMediaId",
    name: "DraftSheets.edit",
    component: DraftDetail
  },
  //登録カレンダー
  {
    path: "/register/calendar",
    name: "RegisterCalendar",
    component: RegisterCalendar
  },
  // 枠表(月表示)
  {
    path: "/waku/month/:year/:month",
    name: "waku.month",
    component: WakuMonth,
    props: (route: Route) => {
      const now = dayjs();
      let year = Number.parseInt(route.params.year, 10);
      year = Number.isNaN(year) ? now.year() : year;
      let month = Number.parseInt(route.params.month, 10);
      month = Number.isNaN(month) ? now.month() + 1 : month;
      const media = route.params.media;
      return { year, month, media };
    }
  },
  {
    path: "/waku/month",
    redirect: () => {
      const now = dayjs();
      return `/waku/month/${now.year()}/${now.month() + 1}`;
    }
  },
  // 枠表(週表示)
  {
    path: "/waku/week/:year/:week",
    name: "waku.week",
    component: WakuWeek,
    props: (route: Route) => {
      const now = dayjs();
      let year = Number.parseInt(route.params.year, 10);
      year = Number.isNaN(year) ? now.year() : year;
      let week = Number.parseInt(route.params.week, 10);
      week = Number.isNaN(week) ? now.week() + 1 : week;
      const media = route.params.media;
      return { year, week, media };
    }
  },
  {
    path: "/waku/week",
    redirect: () => {
      const now = dayjs();
      return `/waku/week/${now.year()}/${now.week()}`;
    }
  },
  // 枠表(日表示)
  {
    path: "/waku/day/:year/:month/:date",
    name: "waku.day",
    component: WakuDay,
    props: (route: Route) => {
      const now = dayjs();
      let year = Number.parseInt(route.params.year, 10);
      year = Number.isNaN(year) ? now.year() : year;
      let month = Number.parseInt(route.params.month, 10);
      month = Number.isNaN(month) ? now.month() + 1 : month;
      let date = Number.parseInt(route.params.date, 10);
      date = Number.isNaN(date) ? now.date() : date;
      const media = route.params.media;
      return { year, month, date, media };
    }
  },
  {
    path: "/waku/day",
    redirect: () => {
      const now = dayjs();
      return `/waku/day/${now.year()}/${now.month() + 1}/${now.date()}`;
    }
  },

  // 管理画面
  {
    path: "/admin/home",
    name: "Admin",
    component: Admin
  },
  // クライアントマスタ
  {
    path: "/admin/clients",
    name: "Clients",
    component: Clients
  },
  // エリアマスタ
  {
    path: "/admin/area",
    name: "Area",
    component: Area
  },
  // ユーザーマスタ
  {
    path: "/admin/user",
    name: "User",
    component: User
  },
  // クライアント属性マスタ
  {
    path: "/admin/client/attribute",
    name: "ClientAttribute",
    component: ClientAttribute
  },
  // 商品媒体マスタ
  {
    path: "/admin/item",
    name: "Item",
    component: Item
  },
  // 商品媒体(管理用)マスタ
  {
    path: "/admin/item/management",
    name: "ItemManagement",
    component: ItemManagement
  },
  // 商流マスタ
  {
    path: "/admin/sale/channel",
    name: "SaleChannel",
    component: SaleChannel
  },
  // ヨミ確度マスタ
  {
    path: "/admin/sale/defs",
    name: "SaleDefs",
    component: SaleDefs
  },
  // メールテンプレートマスタ
  {
    path: "/admin/mail/template",
    name: "MailTemplate",
    component: MailTemplate
  },
  // 事業年度マスタ
  {
    path: "/admin/fiscal/year",
    name: "FiscalYear",
    component: FiscalYear
  },
  // クライアント担当者マスタ
  {
    path: "/admin/client/:id/staffs",
    name: "ClientStaff",
    component: ClientStaff
  },
  // 代理店担当者マスタ
  {
    path: "/admin/agency/:id/staff",
    name: "AgencyStaff",
    component: AgencyStaff
  },
  //出力画面
  {
    path: "/draft/output",
    name: "Output",
    component: Output
  },
  {
    path: "/admin/channel",
    name: "Channel",
    component: Channel
  },
  {
    path: "/admin/registration_area",
    name: "RegistrationArea",
    component: RegistrationArea
  },
  {
    path: "/admin/adid",
    name: "Adid",
    component: Adid
  },
  {
    path: "*",
    name: "Deafault",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

const routeNamesForGuest = ["Login"];

export const isRouteForGuest = (route: Route): boolean =>
  !!route.name && routeNamesForGuest.includes(route.name);

router.beforeEach((to, from, next) => {
  if (to === from) return false;

  const isAuthenticated = store.getters["auth/isLoggedIn"];
  if (isRouteForGuest(to)) {
    if (to.name === "Login") {
      store.dispatch("auth/logout");
    }
    next();
  } else {
    if (isAuthenticated) {
      store.dispatch("auth/getRole");
      next();
      return true;
    }
    store.dispatch("auth/login");
    next();
  }
});

export default router;
