



































































































































































































import Vue from "vue";
import { BModal } from "bootstrap-vue";

import ItemRepository, { Item, newItem } from "@/repositories/items";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import * as AuthHelper from "@/helpers/auth";

type DataType = {
  loading: boolean;
  isError: boolean;
  errorMsg: string[];
  item: Item;
  wakuType: {
    has_lsp: boolean;
    is_only_nationwide: boolean;
    is_region_specifiable: boolean;
    is_not_shared: boolean;
  };
};

export default Vue.extend({
  data: function(): DataType {
    return {
      loading: false,
      isError: false,
      errorMsg: [],
      item: newItem(),
      wakuType: {
        has_lsp: false,
        is_only_nationwide: false,
        is_region_specifiable: false,
        is_not_shared: false
      }
    };
  },
  props: {
    isNew: {
      type: Boolean,
      required: true,
      default: true
    },
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    editable: function(): boolean {
      return AuthHelper.isAdMediaEditable();
    },
    shownModal: function(): boolean {
      return !this.loading;
    }
  },
  watch: {
    isNew: function() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
    },
    id: async function() {
      this.resetData();
      if (!this.isNew) {
        var item = await ItemRepository.get(this.id, "");
        if (item != null) {
          this.item = item;
          this.wakuType.has_lsp = item.has_lsp > 0 ? true : false;
          this.wakuType.is_only_nationwide =
            item.is_only_nationwide > 0 ? true : false;
          this.wakuType.is_region_specifiable =
            item.is_region_specifiable > 0 ? true : false;
          this.wakuType.is_not_shared = item.is_not_shared > 0 ? true : false;
        }
      }
      this.loading = false;
    }
  },
  methods: {
    show() {
      if (this.isNew) {
        this.resetData();
        this.loading = false;
      }
      (this.$refs.modal as BModal).show();
    },
    resetData: function() {
      this.loading = true;
      this.isError = false;
      this.errorMsg = [];
      this.item = newItem();
      this.wakuType.has_lsp = false;
      this.wakuType.is_only_nationwide = false;
      this.wakuType.is_region_specifiable = false;
      this.wakuType.is_not_shared = false;
    },
    postItem: async function() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      this.setItem();
      var result = await ItemRepository.store(this.item, "");
      this.$emit("success", result);
      (this.$refs.modal as BModal).hide();
    },
    updateItem: async function() {
      this.isError = false;
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      this.setItem();
      var result = await ItemRepository.update(this.item, "");
      this.$emit("update", result);
      (this.$refs.modal as BModal).hide();
    },
    deleteItem: async function(id: number) {
      let action = confirm("本当に削除してもよろしいですか。");
      if (!action) {
        return false;
      }
      let result = await ItemRepository.destroy(id, "");
      this.$emit("delete", result);
      (this.$refs.modal as BModal).hide();
    },
    validation: function() {
      var msg: string[] = [];
      if (this.item.name === null || this.item.name === "") {
        msg.push("商品媒体名を入力してください。");
      }
      if (this.item.official_name === null || this.item.official_name === "") {
        msg.push("正式名称を入力してください。");
      }
      this.item.flame_count = Number(this.item.flame_count);
      if (this.item.flame_count === null || this.item.flame_count < 1) {
        msg.push("枠数を１以上入力してください。");
      }
      if (
        this.wakuType.has_lsp ||
        this.wakuType.is_only_nationwide ||
        this.wakuType.is_region_specifiable ||
        this.wakuType.is_not_shared
      ) {
      } else {
        msg.push("枠タイプを選択してください。");
      }
      this.item.materials_limit = Number(this.item.materials_limit);
      return msg;
    },
    setItem: function() {
      this.item.has_lsp = this.wakuType.has_lsp ? 1 : 0;
      this.item.is_only_nationwide = this.wakuType.is_only_nationwide ? 1 : 0;
      this.item.is_region_specifiable = this.wakuType.is_region_specifiable
        ? 1
        : 0;
      this.item.is_not_shared = this.wakuType.is_not_shared ? 1 : 0;
    }
  }
});
