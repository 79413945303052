























































































































































































































import Vue from "vue";
import Menu from "@/components/AdminMenu.vue";
import NewClientModal from "@/components/NewClientModal.vue";
import ClientRepository, { Client } from "@/repositories/clients";
import * as AuthHelper from "@/helpers/auth";
import { compare } from "@/helpers/sort";

type DataType = {
  clients: Client[];
  id: number;
  isFormShown: boolean;
  isNew: boolean;
  loading: boolean;
  fetched: boolean;
  success: boolean;
  updateClient: boolean;
  deleteClient: boolean;
  searchClient: string;
  clientId: number;
  sorts: {
    registration_code: boolean;
  };
};

export default Vue.extend({
  components: {
    Menu,
    NewClientModal
  },
  data: function(): DataType {
    return {
      id: 0,
      clients: [],
      isFormShown: false,
      isNew: true,
      loading: false,
      fetched: false,
      success: false,
      updateClient: false,
      deleteClient: false,
      searchClient: "",
      clientId: 0,
      sorts: {
        registration_code: false
      }
    };
  },
  computed: {
    listShown: function(): boolean {
      return !this.loading && this.clients && this.clients.length > 0;
    },
    isClientWritable: function(): boolean {
      return AuthHelper.isClientWritable();
    },
    isClientDeletable: function(): boolean {
      return AuthHelper.isClientDeletable();
    }
  },
  methods: {
    showNewForm() {
      this.isNew = true;
      this.id = 0;
      this.showModal();
    },
    showEditForm(id: number) {
      this.id = id;
      this.isNew = false;
      this.showModal();
    },
    showModal() {
      this.isFormShown = true;
      (this.$refs.newClientModal as any).show();
    },
    showStaffs(id: number) {
      this.clientId = id;
    },
    closeForm() {
      this.isFormShown = false;
    },
    onSuccess: async function() {
      this.success = true;
      this.clients = await ClientRepository.list("" /*TODO*/);
    },
    onSuccessUpdate: async function() {
      this.updateClient = true;
      this.clients = await ClientRepository.list("" /*TODO*/);
    },
    onDelete: async function() {
      this.deleteClient = true;
      this.clients = await ClientRepository.list("" /*TODO*/);
    },
    onSearch: async function() {
      if (this.searchClient !== null && this.searchClient !== "") {
        this.clients = await ClientRepository.search(
          this.searchClient,
          "" /*TODO*/
        );
      } else {
        this.clients = await ClientRepository.list("" /*TODO*/);
      }
    },
    sortBy: function(key: string) {
      switch (key) {
        case "registration_code": {
          this.sorts.registration_code = !this.sorts.registration_code;
          this.clients = this.clients.sort(
            compare(c => c.registration_code, this.sorts.registration_code)
          );
          break;
        }
      }
    }
  },
  mounted: async function() {
    this.loading = true;
    this.clients = await ClientRepository.list("" /*TODO*/);
    this.loading = false;
    this.fetched = true;
  }
});
