









































































































































































































import Vue from "vue";

import Menu from "@/components/Menu.vue";
import DraftHeader from "@/components/draftCalendars/DraftHeader.vue";

import repository from "@/repositories/repository";
import DraftDealRepository, { DraftDeal } from "@/repositories/draftDeals";
import DraftSheetRepository, {
  DraftSheet,
  newDraftSheet
} from "@/repositories/draftSheet";

import * as ResponseHelper from "@/helpers/response";
import RegistrationAreaModal from "@/components/modals/RegistrationAreaModal.vue";
import { RegistrationAreaModalArgs } from "@/repositories/registrationArea";
import {
  DraftSheetStatus,
  draftSheetStatuses,
  getDraftSheetStatusNameByCode
} from "@/helpers/draftSheetStatus";

type DataType = {
  running: boolean;
  error: boolean;
  success: boolean;
  successExport: boolean;
  successDelete: boolean;
  isNew: boolean;
  isRegistrationShown: boolean;
  draftSheets: DraftSheet[];
  draftDeal?: DraftDeal;
  draftDealId: number;
  previousStatus: number;
  selectedDraftSheet: DraftSheet;
  draftSheetStatuses: DraftSheetStatus[];
  isCreateDraftSheet: boolean;
};
export default Vue.extend({
  components: {
    RegistrationAreaModal,
    DraftHeader,
    Menu
  },
  data: function(): DataType {
    return {
      running: false,
      error: false,
      success: false,
      successExport: false,
      successDelete: false,
      isNew: false,
      isRegistrationShown: false,
      draftSheets: [],
      draftDeal: undefined,
      draftDealId: 0,
      previousStatus: 1,
      selectedDraftSheet: newDraftSheet(),
      draftSheetStatuses: draftSheetStatuses, // 入稿書ステータス
      isCreateDraftSheet: false
    };
  },
  methods: {
    onOrAfterWChecked: function(status: number): boolean {
      // 入稿書ステータスが「4:ダブルチェック完了」以降であるか
      return status <= 4;
    },
    showRegistrationModal(draftSheet?: DraftSheet, isClear = false) {
      this.isCreateDraftSheet = draftSheet !== undefined;
      this.selectedDraftSheet =
        draftSheet !== undefined ? draftSheet : newDraftSheet();
      // コピーの場合はエリア以外の情報をクリア
      if (draftSheet !== undefined && isClear) {
        this.selectedDraftSheet = newDraftSheet();
        this.selectedDraftSheet.registration_area =
          draftSheet.registration_area;
      }
      this.selectedDraftSheet.draft_deal.id = this.draftDealId;
      this.isRegistrationShown = true;
      (this.$refs.registrationAreaModal as any).show(
        this.selectedDraftSheet.registration_area.id
      );
    },
    getStatusNameByCode: function(code: number) {
      return getDraftSheetStatusNameByCode(code);
    },
    closeForm() {
      this.isRegistrationShown = false;
    },
    resetAlert: function() {
      this.error = false;
      this.success = false;
      this.successExport = false;
      this.successDelete = false;
    },
    onDelete: async function(draftSheet: DraftSheet) {
      this.running = true;
      this.resetAlert();
      if (window.confirm("入稿書情報を削除します。よろしいですか？")) {
        await DraftSheetRepository.delete("", draftSheet)
          .then(() => {
            this.onReload();
            this.successDelete = true;
          })
          .catch(() => {
            this.onError();
          })
          .finally(() => {
            this.running = false;
          });
      }
      this.running = false;
    },
    onReload: async function() {
      this.error = false;
      this.resetAlert();
      if (isNaN(this.draftDealId)) return false;
      this.running = true;
      await DraftSheetRepository.index("", {
        draft_deal_id: this.draftDealId
      })
        .then(response => {
          this.draftSheets = response;
        })
        .finally(() => {
          this.running = false;
        });
    },
    onError: function() {
      this.success = false;
      this.error = true;
    },
    onSuccess: function(isNew = true) {
      this.success = true;
      this.isNew = isNew;
    },
    exportDraftSheet: async function(draftSheetId: number) {
      this.running = true;
      this.resetAlert();
      await repository()
        .get(
          `/api/draftDeals/${this.draftDealId}/draftSheets/${draftSheetId}/export`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(response, "入稿書.xlsx");
          this.onReload();
          this.successExport = true;
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.running = false;
        });
    },
    create: async function() {
      // 入稿書レコード新規登録
      this.running = true;
      await DraftSheetRepository.store("", this.selectedDraftSheet)
        .then(() => {
          this.onReload();
          this.onSuccess();
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.running = false;
          (this.$refs.registrationAreaModal as any).close();
        });
    },
    update: async function() {
      // 入稿書レコード更新
      this.running = true;
      await DraftSheetRepository.update("", this.selectedDraftSheet)
        .then(() => {
          this.onReload();
          this.onSuccess(false);
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.running = false;
          (this.$refs.registrationAreaModal as any).close();
        });
    },
    save: function(registrationArea: RegistrationAreaModalArgs) {
      this.selectedDraftSheet.registration_area = registrationArea;
      if (this.selectedDraftSheet.id) {
        this.update();
      } else {
        this.create();
      }
    },
    getPreviousStatus: function(status: number) {
      this.previousStatus = status;
    },
    updateStatus: async function(draftSheet: DraftSheet, index: number) {
      if (confirm("入稿書情報を更新しますか？")) {
        this.running = true;
        await DraftSheetRepository.update("", draftSheet)
          .then(() => {
            this.onReload();
            this.onSuccess(false);
          })
          .catch(() => {
            this.onError();
          })
          .finally(() => {
            this.running = false;
          });
      } else {
        // 更新キャンセルの場合は更新前のステータスに戻す
        this.draftSheets[index].status = this.previousStatus;
      }
    }
  },
  mounted: async function() {
    const draftDealId = Number(this.$route.params.draftDealId);
    this.draftDeal = await DraftDealRepository.find(draftDealId);
    if (!isNaN(draftDealId)) {
      this.draftDealId = draftDealId;
      await this.onReload();
    }
  }
});
