
















import Vue from "vue";
export default Vue.extend({
  mounted() {
    // eslint-disable-next-line no-console
    console.log("Component mounted.");
  }
});
