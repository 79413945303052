



































































































import Vue from "vue";
import {
  CheckListQuery,
  resetCheckListQuery,
  CheckListAreas,
  CheckListAdMedias,
  CheckListArea,
  CheckListAdMedia
} from "@/repositories/checklist";
import dayjs from "dayjs";
import repository from "@/repositories/repository";
import * as ResponseHelper from "@/helpers/response";

type DataType = {
  running: boolean;
  checkListQuery: CheckListQuery;
  areas: CheckListArea[];
  adMedias: CheckListAdMedia[];
  isSuccess: boolean;
  message: string;
  isError: boolean;
  errorMsg: string[];
};

export default Vue.extend({
  components: {},
  data: function(): DataType {
    return {
      running: false,
      checkListQuery: resetCheckListQuery(),
      areas: CheckListAreas,
      adMedias: CheckListAdMedias,
      isSuccess: false,
      message: "",
      isError: false,
      errorMsg: []
    };
  },
  methods: {
    downloadCheckList: async function() {
      // アラート初期化、バリデーション
      this.isSuccess = false;
      this.message = "";
      this.errorMsg = [];
      this.errorMsg = this.validation();
      if (this.errorMsg.length > 0) {
        this.isError = true;
        return false;
      }
      this.running = true;
      await repository()
        .get(`/api/checkList/download/`, {
          params: { ...this.checkListQuery },
          responseType: "blob"
        })
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(
            response,
            dayjs().format("YYYYMMDD") + "チェックリスト.xlsx"
          );
          this.isSuccess = true;
          this.message = "チェックリストの出力が完了しました。";
        })
        .catch(error => {
          this.isError = true;
          if (error.response?.status === 400) {
            this.errorMsg.push("出力対象となるデータがありません。");
          } else {
            this.errorMsg.push("予期しないエラーが発生しました。");
          }
        })
        .finally(() => {
          this.running = false;
        });
    },
    validation: function() {
      let msg: string[];
      msg = [];
      // 日付_必須チェック
      if (
        this.checkListQuery.date_from === null ||
        this.checkListQuery.date_from === ""
      ) {
        msg.push("日付を入力してください。");
      }
      // チェック対象_必須チェック
      if (
        this.checkListQuery.days_after !== 0 &&
        !this.checkListQuery.days_after
      ) {
        msg.push("チェック対象を入力してください。");
      }
      // チェック対象_範囲チェック
      if (
        this.checkListQuery.days_after < 0 ||
        this.checkListQuery.days_after > 20
      ) {
        msg.push("チェック対象は0から20の範囲で入力してください。");
      }
      // 地域_必須チェック
      if (this.checkListQuery.check_area.code === 0) {
        msg.push("地域を選択してください。");
      }
      // 放送モデル_必須チェック
      if (this.checkListQuery.check_media.code === 0) {
        msg.push("放送モデルを選択してください。");
      }
      return msg;
    }
  }
});
