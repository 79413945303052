import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import router from "@/router";

export default (token?: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.MIX_BACKEND_WEB_API_SERVER,
    headers: headers(token),
    responseType: "json"
  });
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        router.push("/login").catch(() => {
          /* ignore 'redundant navigation' error */
        });
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

function headers(token?: string) {
  return token
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    : {
        "Content-Type": "application/json"
      };
}
