
































































































































































































































































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";

import FiscalYearRepository, { FiscalYear } from "@/repositories/fiscalYear";
import SalesGoalsByUserRepository, {
  SalesGoalsByUsers
} from "@/repositories/salesGoalsByUsers";
import SalesGoalsByMediaRepository, {
  SalesGoalsByMedias
} from "@/repositories/salesGoalsByMedia";
import SalesGoalsByUserAndAdMediaRepository, {
  SalesGoalsByUserAndAdMedia
} from "@/repositories/salesGoalsByUserAndAdMedia";

import UserRepository, { User } from "@/repositories/users";

type DataType = {
  isError: boolean;
  isAdd: boolean;
  isMin: boolean;
  today: dayjs.Dayjs;
  currentDate: dayjs.Dayjs;
  fiscalYear?: FiscalYear;
  headerDates: { name: string; is_quarter: boolean; is_error: boolean }[];
  success: { user: boolean; media: boolean; userMedia: boolean };
  userGoals: SalesGoalsByUsers[];
  mediaGoals: SalesGoalsByMedias[];
  userMediaGoals: SalesGoalsByUserAndAdMedia[]; // 個人×商品別目標
  currentUsers: { userIndex: number; index: number };
  currentMedias: { mediaIndex: number; index: number };
  currentUserMedias: { userIndex: number; mediaIndex: number; index: number };
  pastes: string[][];
};

export default Vue.extend({
  data: function(): DataType {
    const now = dayjs();
    return {
      isError: false,
      isAdd: false,
      isMin: false,
      today: now,
      currentDate: now,
      fiscalYear: undefined,
      headerDates: [],
      success: { user: false, media: false, userMedia: false },
      userGoals: [],
      mediaGoals: [],
      userMediaGoals: [], // 個人×商品別目標
      currentUsers: { userIndex: 0, index: 0 },
      currentMedias: { mediaIndex: 0, index: 0 },
      currentUserMedias: { userIndex: 0, mediaIndex: 0, index: 0 },
      pastes: []
    };
  },
  mounted: async function() {
    await this.getSalesGoal();
  },
  methods: {
    addYear: function(year: number) {
      this.isAdd = false;
      this.isMin = false;
      this.currentDate = this.currentDate.add(year, "year");
      if (this.currentDate.year() === this.today.year() + 1) {
        this.isAdd = true;
      } else if (this.currentDate.year() === this.today.year() - 9) {
        this.isMin = true;
      }
      this.getSalesGoal();
    },
    getSalesGoal: async function() {
      await this.getFiscalYear();
      if (this.fiscalYear == undefined) return false;
      const userGoals = await SalesGoalsByUserRepository.list("", {
        from: this.fiscalYear.begin,
        to: this.fiscalYear.end
      });
      this.userGoals = userGoals;

      const mediaGoals = await SalesGoalsByMediaRepository.list("", {
        from: this.fiscalYear.begin,
        to: this.fiscalYear.end
      });
      this.mediaGoals = mediaGoals;

      const userMediaGoals = await SalesGoalsByUserAndAdMediaRepository.list(
        "",
        {
          from: this.fiscalYear.begin,
          to: this.fiscalYear.end
        }
      );
      this.userMediaGoals = userMediaGoals;
    },
    getFiscalYear: async function() {
      let current = this.currentDate.date(1).format("YYYY-MM-DD");
      const fiscalYear = await FiscalYearRepository.get(current, "");
      this.fiscalYear = fiscalYear;
      let begin = dayjs(fiscalYear.begin);
      let end = dayjs(fiscalYear.end).date(1);
      let quarter = 1;
      let month = 0;
      let dates: {
        name: string;
        is_quarter: boolean;
        is_error: boolean;
      }[] = [];
      while (end.isAfter(begin)) {
        if (month < 1) {
          dates.push({
            name: String(quarter),
            is_quarter: true,
            is_error: false
          });
        }
        dates.push({
          name: begin.format("YYYY-MM-DD"),
          is_quarter: false,
          is_error: false
        });
        begin = begin.add(1, "month");
        if (month < 2) {
          month++;
        } else {
          month = 0;
          quarter++;
        }
      }
      dates.push({
        name: begin.format("YYYY-MM-DD"),
        is_quarter: false,
        is_error: false
      });
      this.headerDates = dates;
    },
    getHeader(header: { name: string; is_quarter: boolean }) {
      return header.is_quarter
        ? header.name + "Q"
        : dayjs(header.name).format("M月");
    },
    getQuarterScore: function(amounts: string[], index: number) {
      let score = 0;
      let indexes: number[] = [];
      switch (index) {
        case 0:
          indexes = [0, 1, 2];
          break;
        case 4:
          indexes = [3, 4, 5];
          break;
        case 8:
          indexes = [6, 7, 8];
          break;
        case 12:
          indexes = [9, 10, 11];
          break;
      }
      indexes.forEach(index => {
        if (amounts && amounts[index]) {
          score += Number(
            String(amounts[index])
              .split(",")
              .join("")
          );
        }
      });
      return score;
    },
    getIndex: function(index: number) {
      let quarter = 0;
      switch (index) {
        case 1:
        case 2:
        case 3:
          quarter = 1;
          break;
        case 5:
        case 6:
        case 7:
          quarter = 2;
          break;
        case 9:
        case 10:
        case 11:
          quarter = 3;
          break;
        case 13:
        case 14:
        case 15:
          quarter = 4;
          break;
      }
      return index - quarter;
    },
    getFirstHalfScore: function(amounts: string[]) {
      let score = 0;
      for (let i = 0; i < 6; i++) {
        if (amounts[i]) {
          score += Number(
            String(amounts[i])
              .split(",")
              .join("")
          );
        }
      }
      return score;
    },
    getSecondHalfScore: function(amounts: string[]) {
      let score = 0;
      for (let i = 6; i < 12; i++) {
        if (amounts[i]) {
          score += Number(
            String(amounts[i])
              .split(",")
              .join("")
          );
        }
      }
      return score;
    },
    getAllScore: function(amounts: string[]) {
      let score = 0;
      amounts.forEach(amount => {
        if (amount) {
          score += Number(
            String(amount)
              .split(",")
              .join("")
          );
        }
      });
      return score;
    },
    getError: function(index: number) {
      let userScores = 0;
      let score = 0;
      this.userGoals.forEach(userGoal => {
        if (userGoal && userGoal[0].amounts.amount[index]) {
          score = Number(userGoal[0].amounts.amount[index].split(",").join(""));
          if (!isNaN(score)) {
            userScores += score;
          }
        }
      });
      let mediaScores = 0;
      this.mediaGoals.forEach(mediaGoal => {
        if (mediaGoal && mediaGoal[0].amounts.amount[index]) {
          score = Number(
            mediaGoal[0].amounts.amount[index].split(",").join("")
          );
          if (!isNaN(score)) {
            mediaScores += score;
          }
        }
      });
      this.isError = userScores === mediaScores ? false : true;
      return this.isError;
    },
    setPrice: function(price: string) {
      if (price == "") {
        return "0";
      }
      price = String(price)
        .split(",")
        .join("");
      price = price.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(price) {
        return String.fromCharCode(price.charCodeAt(0) - 0xfee0);
      });
      if (!isNaN(Number(price))) {
        price = Number(price).toLocaleString();
      } else {
        price = "0";
      }
      return price;
    },
    setUserAmount: function(goalIndex: number, index: number) {
      let amountIndex = this.getIndex(index);
      if (
        this.userGoals &&
        this.userGoals[goalIndex] &&
        this.userGoals[goalIndex][0].amounts.amount[amountIndex]
      ) {
        let price = this.setPrice(
          String(this.userGoals[goalIndex][0].amounts.amount[amountIndex])
        );
        this.userGoals[goalIndex][0].amounts.amount.splice(
          amountIndex,
          1,
          price
        );
        this.headerDates[index].is_error = this.getError(amountIndex);
      }
    },
    setMediaAmount: function(goalIndex: number, index: number) {
      let amountIndex = this.getIndex(index);
      if (
        this.mediaGoals &&
        this.mediaGoals[goalIndex] &&
        this.mediaGoals[goalIndex][0].amounts.amount[amountIndex]
      ) {
        let price = this.setPrice(
          String(this.mediaGoals[goalIndex][0].amounts.amount[amountIndex])
        );
        this.mediaGoals[goalIndex][0].amounts.amount.splice(
          amountIndex,
          1,
          price
        );
        this.headerDates[index].is_error = this.getError(amountIndex);
      }
    },
    // 個人×商品別テーブルのinputのchangeイベントで発火する処理。引数の中身は(ユーザID、行のインデックス, 列のインデックス)
    setUserMediaAmount: function(
      userIndex: number, // 0から始まるuser判別インデックス
      mediaIndex: number, // 0から始まるmedia判別インデックス
      index: number // テーブルの横から何番目かのインデックス
    ) {
      let amountIndex = this.getIndex(index); // getIndexメソッドを通して何月のデータ(1月は0、2月は1)かを判別
      if (this.userMediaGoals) {
        let price = this.setPrice(
          String(
            this.userMediaGoals[userIndex][0].user.ad_media_mgt[mediaIndex][0]
              .amounts.amount[amountIndex]
          )
        );
        this.userMediaGoals[userIndex][0].user.ad_media_mgt[
          mediaIndex
        ][0].amounts.amount.splice(amountIndex, 1, price);
        // amountIndex番目の要素を一つpriceへ変更
      }
    },
    onPasteUser: function(evt: any) {
      let datas = String(evt.clipboardData.getData("text"))
        .split("\r\n")
        .join("\n")
        .split("\n");
      let pastes: string[][] = [];
      datas.forEach(data => {
        let cells = data.split("\t");
        pastes.push(cells);
      });
      this.pastes = pastes;
      setTimeout(this.setUserGoals, 1);
    },
    onPasteMedia: function(evt: any) {
      let datas = String(evt.clipboardData.getData("text"))
        .split("\r\n")
        .join("\n")
        .split("\n");
      let pastes: string[][] = [];
      datas.forEach(data => {
        let cells = data.split("\t");
        pastes.push(cells);
      });
      this.pastes = pastes;
      setTimeout(this.setMediaGoals, 1);
    },
    onPasteUserMedia: function(evt: any) {
      let datas = String(evt.clipboardData.getData("text"))
        .split("\r\n")
        .join("\n")
        .split("\n");
      let pastes: string[][] = [];
      datas.forEach(data => {
        let cells = data.split("\t");
        pastes.push(cells);
      });
      this.pastes = pastes;
      setTimeout(this.setUserMediaGoals, 1);
    },
    setUserGoals: function() {
      if (this.pastes.length > 0) {
        let current_u = this.currentUsers.userIndex;
        let current_a = this.getIndex(this.currentUsers.index);
        for (let u = 0; u < this.pastes.length; u++) {
          for (let a = 0; a < this.pastes[u].length; a++) {
            let price = this.setPrice(String(this.pastes[u][a]));
            this.userGoals[u + current_u][0].amounts.amount.splice(
              a + current_a,
              1,
              price
            );
            this.headerDates[this.currentUsers.index].is_error = this.getError(
              current_a
            );
          }
        }
        this.pastes = [];
      }
    },
    setMediaGoals: function() {
      if (this.pastes.length > 0) {
        let current_m = this.currentMedias.mediaIndex;
        let current_a = this.getIndex(this.currentMedias.index);
        for (let m = 0; m < this.pastes.length; m++) {
          for (let a = 0; a < this.pastes[m].length; a++) {
            let price = this.setPrice(String(this.pastes[m][a]));
            this.mediaGoals[m + current_m][0].amounts.amount.splice(
              a + current_a,
              1,
              price
            );
            this.headerDates[this.currentMedias.index].is_error = this.getError(
              current_a
            );
          }
        }
      }
    },
    setUserMediaGoals: function() {
      if (this.pastes.length > 0) {
        let current_u = this.currentUserMedias.userIndex;
        let current_m = this.currentUserMedias.mediaIndex;
        let current_a = this.getIndex(this.currentUserMedias.index);
        for (let u = 0; u < this.pastes.length; u++) {
          for (let m = 0; m < this.pastes.length; m++) {
            for (let a = 0; a < this.pastes[m].length; a++) {
              let price = this.setPrice(String(this.pastes[u][m][a]));
              this.userMediaGoals[u + current_u][0].user.ad_media_mgt[
                m + current_m
              ].amounts[0].amount.splice(a + current_a, 1, price);
            }
          }
        }
      }
    },
    postUserGoals: async function() {
      if (this.isError) return false;
      let action = confirm("担当者別の予算目標を更新します。よろしいですか。");
      if (!action) return false;
      if (this.fiscalYear && this.userGoals) {
        await SalesGoalsByUserRepository.store("", this.userGoals);
        this.success.user = true;
        const userGoals = await SalesGoalsByUserRepository.list("", {
          from: this.fiscalYear.begin,
          to: this.fiscalYear.end
        });
        this.userGoals = userGoals;
      }
    },
    postMediaGoals: async function() {
      if (this.isError) return false;
      let action = confirm("商品別の予算目標を更新します。よろしいですか。");
      if (!action) return false;
      if (this.fiscalYear && this.mediaGoals) {
        await SalesGoalsByMediaRepository.store("", this.mediaGoals);
        this.success.media = true;
        const mediaGoals = await SalesGoalsByMediaRepository.list("", {
          from: this.fiscalYear.begin,
          to: this.fiscalYear.end
        });
        this.mediaGoals = mediaGoals;
      }
    },
    postUserMediaGoals: async function() {
      let action = confirm(
        "個人×商品別の予算目標を更新します。よろしいですか。"
      );
      if (!action) return false;
      if (this.fiscalYear && this.userMediaGoals) {
        await SalesGoalsByUserAndAdMediaRepository.store(
          "",
          this.userMediaGoals
        );
        this.success.userMedia = true;
        // 更新が終了後に改めて一覧を取得
        const userMediaGoals = await SalesGoalsByUserAndAdMediaRepository.list(
          "",
          {
            from: this.fiscalYear.begin,
            to: this.fiscalYear.end
          }
        );
        this.userMediaGoals = userMediaGoals;
      }
    }
  }
});
