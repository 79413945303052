






























































































import Vue from "vue";
import repository from "@/repositories/repository";
import * as ResponseHelper from "@/helpers/response";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import Datepick from "@/components/calendars/Datepicker.vue";
import { AxiosError } from "axios";

const AdMediaExportType = {
  ALL: 0,
  OTD: 1,
  NCE: 2,
  GG_HTML: 3
} as const;

type AdMediaExportType = typeof AdMediaExportType[keyof typeof AdMediaExportType];

type DataType = {
  adMediaExportType: AdMediaExportType;
  dateFrom?: string;
  dateTo?: string;
  running: boolean;
  success: boolean;
  message: string;
  error: boolean;
  errorMessages: string[];
};

export default Vue.extend({
  components: {
    Datepick
  },
  data: function(): DataType {
    return {
      adMediaExportType: AdMediaExportType.ALL,
      dateFrom: undefined,
      dateTo: undefined,
      running: false,
      success: false,
      message: "",
      error: false,
      errorMessages: []
    };
  },
  methods: {
    resetAlert: function() {
      this.message = "";
      this.success = false;
      this.errorMessages = [];
      this.error = false;
    },
    download: async function() {
      // アラート初期化、バリデーション
      this.resetAlert();
      this.validationDate();
      if (this.errorMessages.length > 0) {
        this.error = true;
        return false;
      }
      let action = confirm(
        "登録リストのダウンロードを行います。よろしいですか？"
      );
      if (!action) {
        return false;
      }
      this.running = true;
      // TODO:thenとfinallyを作成して、簡潔にする
      const response = await repository()
        .get("/api/export/registrations", {
          params: {
            exportType: this.adMediaExportType,
            from: this.dateFrom,
            to: this.dateTo
          },
          responseType: "blob"
        })
        .catch(async (e: AxiosError) => {
          if (e.response) {
            const blob = (e.response.data as unknown) as Blob;
            const body = JSON.parse(await blob.text()) as {
              message: string;
              errors: [];
            };
            this.error = true;
            this.errorMessages = Object.values(body.errors).flat();
          }
          return null;
        });
      // レスポンスヘッダからファイル名を取得します
      if (response) {
        this.error = false;
        ResponseHelper.downloadAttachedFileName(response, "登録リスト.xlsx");
      }
      this.running = false;
      // 処理完了
      if (this.errorMessages.length === 0) {
        this.message = "登録リストの出力が完了しました。";
        this.success = true;
      }
    },
    // TODO:コードのリファクタ
    validationDate: function() {
      // 出力期間_必須チェック
      if (
        (this.dateFrom === null || this.dateFrom === undefined) &&
        (this.dateTo === null || this.dateTo === undefined)
      ) {
        this.errorMessages.push("出力期間の指定は必須です。");
        return false;
      }
      // 出力期間_fromが空
      if (this.dateFrom === null || this.dateFrom === undefined) {
        this.errorMessages.push(
          "日付は出力対象期間の開始日と終了日両方を入力してください。"
        );
        return false;
      }
      // 出力期間_toが空
      if (this.dateTo === null || this.dateTo === undefined) {
        this.errorMessages.push(
          "日付は出力対象期間の開始日と終了日両方を入力してください。"
        );
        return false;
      }
      // 出力期間_toがfromより同じ、もしくは後の日付でない時
      let from = dayjs(this.dateFrom);
      let to = dayjs(this.dateTo);
      dayjs.extend(isSameOrAfter);
      if (!to.isSameOrAfter(from)) {
        this.errorMessages.push("出力期間の指定が不正です。");
        return false;
      }
    }
  }
});
