































































































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { BModal } from "bootstrap-vue";
import _ from "lodash";

import dayjs from "dayjs";
import { AxiosError } from "axios";

import MediaFilter from "@/components/MediaFilter.vue";
import NewWakuModal from "@/components/NewWakuModal.vue";
import DeleteWakuModal from "@/components/DeleteWakuModal.vue";
import DealRepository, { Deal } from "@/repositories/deals";
import DealModel from "@/models/deal";
import * as AuthHelper from "@/helpers/auth";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

type DataType = {
  filter: {
    media: unknown[];
  };
  isFormShown: boolean;
  isDeleteFormShown: boolean;
  isCopy: boolean;
  dateReadonly: boolean;
  dealToEdit?: Deal;
};

export default Vue.extend({
  components: {
    MediaFilter,
    NewWakuModal,
    DeleteWakuModal
  },
  data: function(): DataType {
    return {
      filter: {
        media: []
      },
      isFormShown: false,
      isDeleteFormShown: false,
      isCopy: false,
      dateReadonly: true,
      dealToEdit: undefined
    };
  },
  props: {
    year: {
      type: Number,
      required: true,
      default: dayjs().year()
    },
    month: {
      type: Number,
      required: true,
      default: dayjs().month() + 1, // 0-11(dayjs.month) to 1-12(prop)
      validator: function(value) {
        return 1 <= value && value <= 12;
      }
    },
    date: {
      type: Number,
      required: true,
      default: dayjs().date(),
      validator: function(value) {
        return 1 <= value && value <= 31;
      }
    },
    waku: {
      type: Object,
      required: true
    },
    adMedia: {
      type: Array,
      required: true
    },
    media: {
      type: Array as PropType<string[]>,
      required: false
    },
    asAdmin: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    filteredMedia: function(): any[] {
      return this.adMedia.filter((x: any) => this.filter.media.includes(x.id));
    },
    showable: function(): boolean {
      return (
        this.waku &&
        this.waku[this.year] &&
        this.waku[this.year][this.month] &&
        this.waku[this.year][this.month][this.date] &&
        this.adMedia &&
        this.adMedia.length > 0
      );
    },
    isDealAdmin: function(): boolean {
      return AuthHelper.isDealAdmin();
    },
    isDealEditor: function(): boolean {
      return AuthHelper.isDealEditor();
    }
  },
  watch: {
    media: function(val) {
      if (val) {
        this.filter.media = val;
      }
    }
  },
  methods: {
    isMediaActive: function(id: string) {
      return this.filteredMedia.map(x => x.id).includes(id);
    },
    onChange: function(selected: any) {
      this.filter.media = selected;
      this.$emit("changeFilter", selected);
    },
    showForm() {
      this.isFormShown = true;
      const modal = this.$refs.editWakuModal as BModal;
      if (modal) {
        modal.show();
      }
    },
    closeForm() {
      this.isFormShown = false;
      this.dealToEdit = undefined;
    },
    asAdminChanged: function(asAdmin: boolean) {
      this.$emit("asAdminChanged", asAdmin);
    },
    rowspanByMedia: function(
      year: number,
      month: number,
      day: number,
      media: any
    ): number {
      const deals = this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][day]
            ? this.waku[year][month][day][media.id]
              ? this.waku[year][month][day][media.id]
              : {}
            : {}
          : {}
        : {};

      const dataLength = _.sum(
        _.range(1, media.flame_count + 1).map((slotNumber: number) => {
          const length = deals[slotNumber]
            ? deals[slotNumber].length === 0
              ? 1
              : deals[slotNumber].length
            : 1;

          return length;
        })
      );
      return _.max([dataLength, media.flame_count]);
    },
    countWaku: function(
      year: number,
      month: number,
      day: number,
      mediaId: number,
      slotPriority: number
    ): number {
      return this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][day]
            ? this.waku[year][month][day][mediaId]
              ? this.waku[year][month][day][mediaId][slotPriority]
                ? this.waku[year][month][day][mediaId][slotPriority].length
                : 0
              : 0
            : 0
          : 0
        : 0;
    },
    reservation: function(
      year: number,
      month: number,
      day: number,
      mediaId: number,
      slotPriority: number,
      index = 0
    ): boolean {
      return this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][day]
            ? this.waku[year][month][day][mediaId]
              ? this.waku[year][month][day][mediaId][slotPriority]
                ? this.waku[year][month][day][mediaId][slotPriority].length >
                  index
                  ? this.waku[year][month][day][mediaId][slotPriority][index]
                      .sales_probability_def.name === "M" ||
                    this.waku[year][month][day][mediaId][slotPriority][index]
                      .sales_probability_def.name === "J"
                  : false
                : false
              : false
            : false
          : false
        : false;
    },
    getWaku: function(
      year: number,
      month: number,
      day: number,
      mediaId: number,
      slotPriority: number
    ) {
      return this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][day]
            ? this.waku[year][month][day][mediaId]
              ? this.waku[year][month][day][mediaId][slotPriority]
              : []
            : []
          : []
        : [];
    },
    getWakuWaiting: function(
      year: number,
      month: number,
      day: number,
      media: any
    ) {
      const waku = this.waku[year]
        ? this.waku[year][month]
          ? this.waku[year][month][day]
            ? this.waku[year][month][day][media.id]
              ? this.waku[year][month][day][media.id]
              : undefined
            : undefined
          : undefined
        : undefined;

      if (!waku) return [];
      const deals = Object.keys(waku)
        .map(key => Number(key))
        .filter(slotPriority => slotPriority > media.flame_count)
        .map(slotPriority => waku[slotPriority])
        .flat();

      return deals;
    },
    getCount: function(
      year: number,
      month: number,
      day: number,
      media: any
    ): number {
      if (this.waku[year][month][day] == undefined) {
        return 0;
      }
      const waku = this.waku[year][month][day][media.id];
      if (!waku) return 0;
      if (Array.isArray(waku)) {
        return waku.length;
      }
      return _.sum(
        Object.keys(waku)
          .map((key: string) => Number(key))
          .filter(slotPriority => slotPriority <= media.flame_count)
          .map(slotPriority => waku[slotPriority].length)
      );
      // return waku != undefined ? Object.keys(waku).length : 0;
    },
    hasWaiting: function(
      year: number,
      month: number,
      day: number,
      media: any
    ): boolean {
      if (this.waku[year][month][day] == undefined) {
        return false;
      }
      const waku = this.waku[year][month][day][media.id];
      if (!waku) return false;
      if (Array.isArray(waku)) {
        return waku.length > media.flame_count;
      }
      return Object.keys(waku).some((key: string) => {
        const slotPriority = Number(key);
        return slotPriority > media.flame_count;
      });
    },
    openWaitingList: function(mediaId: number) {
      const modal = this.$refs[`modal:waiting:${mediaId}`] as BModal;
      if (!modal) return;
      if (Array.isArray(modal) && modal.length > 0) {
        modal[0].show();
        return;
      }
      modal.show();
    },
    workPlanType: function(type: number) {
      switch (type) {
        case 1:
          return "クライアント";
          break;

        case 2:
          return "IPG";
          break;

        case 3:
          return "ロゴ差替";
          break;

        default:
          return "未定";
          break;
      }
    },
    editable: function(deal: any): boolean {
      if (!AuthHelper.isDealEditor()) return false;
      var user = deal?.staff;
      if (user == undefined) {
        user = deal?.user;
      }
      const isOwn = user?.id ? AuthHelper.isOwn(user.id) : false;
      return this.asAdmin || isOwn;
    },
    isRemove: function(deal: any): boolean {
      const publishedDate = dayjs(deal?.published_date.date.from).startOf(
        "day"
      );
      const dayAfterTomorrow = dayjs()
        .startOf("day")
        .add(2, "day");

      // ヨミ角度「J]の案件の解除は詳細モーダルからのみ可能
      const def = deal?.sales_probability_def.name;
      if (def == "J") return false;

      // 「管理者として操作」チェック時は解除可
      if (this.isDealAdmin) return true;

      // 管理者以外の場合、枠表の掲載日 >= 明後日 なら解除可
      if (this.waku) {
        // 掲載日 >= 明後日なら true
        dayjs.extend(isSameOrAfter);
        return publishedDate.isSameOrAfter(dayAfterTomorrow);
      }
      return false;
    },
    edit: function(deal: Deal, isCopy = false) {
      this.isCopy = isCopy;
      this.dateReadonly = !isCopy;
      const user = this.$store.getters["auth/user"];
      this.dealToEdit = isCopy ? DealModel.clone(deal, user) : deal;
      if (this.dealToEdit && isCopy) {
        if (!this.dealToEdit.staff) {
          this.dealToEdit.staff = this.dealToEdit.user;
        }
        if (
          this.dealToEdit.sales_probability_def.name === "M" ||
          this.dealToEdit.sales_probability_def.name === "J"
        ) {
          this.dealToEdit.sales_probability_def = {};
        }
        const date = dayjs(new Date(this.year, this.month - 1, this.date));
        if (
          dayjs()
            .add(1, "day")
            .isAfter(date)
        ) {
          this.dealToEdit.published_date.date.from = dayjs()
            .add(2, "day")
            .format("YYYY-MM-DD");
        } else {
          this.dealToEdit.published_date.date.from = date.format("YYYY-MM-DD");
        }
      }
      this.showForm();
    },
    remove: function(deal: any) {
      this.dealToEdit = deal;
      this.isDeleteFormShown = true;
      const modal = this.$refs.deleteWakuModal as BModal;
      if (modal) {
        modal.show();
      }
    },
    onSuccess: async function(deal: Deal, waitingDeals: Deal[] = []) {
      this.$emit("success", deal, waitingDeals);
    },
    onRemove: async function(deal: any) {
      if (deal && deal.id) {
        await DealRepository.destroy(deal.id, "")
          .then(() => {
            this.$emit("remove");
          })
          .catch(this.handleError)
          .finally(() => {
            this.$emit("reload");
          });
        // this.$emit("beforeRemove");
      }
      this.$emit("remove");
    },
    handleError: function(error: AxiosError) {
      this.$emit("fail", error);
    },
    fail(error: AxiosError, deal: Deal) {
      this.$emit("fail", error, deal);
    }
  },
  mounted: function() {
    this.filter.media = this.media;
    if (this.media.length > 0) {
      (this as any).$refs.mediaFilter.changeSelected(this.media);
    }
  }
});
