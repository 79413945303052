





































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import dayjs from "dayjs";

import LongTermFilesModal from "@/components/modals/LongTermFilesModal.vue";
import {
  DraftSheetStatus,
  draftSheetStatuses
} from "@/helpers/draftSheetStatus";
import { PublicationTime, publicationTimes } from "@/helpers/publicationTime";
import { Destination, destinations } from "@/helpers/htmlDestination";
import ChannelRepository, { Channel } from "@/repositories/channels";
import DraftSheetRepository, {
  HtmlDraftDetail,
  newHtmlDraftDetail
} from "@/repositories/draftSheet";
import HtmlAdResourceRepository from "@/repositories/htmlAdResource";
import repository from "@/repositories/repository";
import * as ResponseHelper from "@/helpers/response";
import RegistrationAreaModal from "@/components/modals/RegistrationAreaModal.vue";
import { RegistrationAreaModalArgs } from "@/repositories/registrationArea";

type DataType = {
  isRegistrationAreaShown: boolean;
  isLongTermFilesShown: boolean;
  htmlDraftDetail: HtmlDraftDetail;
  draftStatuses: DraftSheetStatus[];
  publicationTimes: PublicationTime[];
  success: boolean;
  successUpload: boolean;
  successUpdateLongTerm: boolean;
  serverError: boolean;
  validationError: boolean;
  validationErrorMessages: string[];
  running: boolean;
  downloading: boolean;
  destinations: Destination[];
  channels: Channel[];
  filterName: string;
  draftSheetId?: number;
  resources: {
    top_banner_1?: File;
    top_banner_2?: File;
    popup_banner?: File;
  };
  successExport: boolean;
};

export default Vue.extend({
  components: {
    RegistrationAreaModal,
    LongTermFilesModal
  },
  data: function(): DataType {
    return {
      isRegistrationAreaShown: false,
      isLongTermFilesShown: false,
      htmlDraftDetail: newHtmlDraftDetail(),
      draftStatuses: draftSheetStatuses,
      publicationTimes: publicationTimes,
      success: false,
      successUpload: false,
      successUpdateLongTerm: false,
      serverError: false,
      validationError: false,
      validationErrorMessages: [],
      running: false,
      downloading: false,
      destinations: destinations,
      channels: [],
      filterName: "",
      draftSheetId: undefined,
      resources: {
        top_banner_1: undefined,
        top_banner_2: undefined,
        popup_banner: undefined
      },
      successExport: false
    };
  },
  computed: {
    isReExported: function(): boolean {
      return (
        this.htmlDraftDetail.html_ad_resource.stock_folder_path.path !== null && // 素材フォルダパスがあるか
        this.htmlDraftDetail.draft_sheet_path.path !== null && // 入稿書パスがあるか
        this.htmlDraftDetail.draft_deal.deal.work_plan_type.code !== 4 // 制作種別が「4: 未定」じゃないか
      );
    },
    isExported: function(): boolean {
      return (
        this.htmlDraftDetail.html_ad_resource.stock_folder_path.path !== null && // 素材フォルダパスがあるか
        this.htmlDraftDetail.draft_sheet_path.path === null && // 入稿書パスがないか
        this.htmlDraftDetail.draft_deal.deal.work_plan_type.code !== 4 // 制作種別が「4: 未定」じゃないか
      );
    },
    filterChannels: function() {
      return (this as any).channels.filter((channel: Channel) => {
        // チャンネルマスタで無効にされたチャンネルを削除
        return !channel.disabled && channel.name.match(this.filterName);
      });
    },
    isEnabledUpload: function(): boolean {
      const topBanner1 = this.resources.top_banner_1;
      const topBanner2 = this.resources.top_banner_2;
      const popupBanner = this.resources.popup_banner;

      // 何か1つでも素材をアップロードしている場合はtrue
      return (
        topBanner1 === undefined &&
        topBanner2 === undefined &&
        popupBanner === undefined
      );
    },
    isDisabledDownload: function(): boolean {
      const topBanner1 = this.htmlDraftDetail.html_ad_resource.top_banner_1
        .extend;
      const topBanner2 = this.htmlDraftDetail.html_ad_resource.top_banner_2
        .extend;
      const popupBanner = this.htmlDraftDetail.html_ad_resource.popup_banner
        .extend;

      // 何か1つでも素材をアップロードしている場合はtrue
      return topBanner1 === null && topBanner2 === null && popupBanner === null;
    }
  },
  methods: {
    showRegistrationAreaModal() {
      this.isRegistrationAreaShown = true;
      (this.$refs.registrationAreaModal as any).show(
        this.htmlDraftDetail.registration_area.id
      );
    },
    showLongTermFilesModal() {
      this.isLongTermFilesShown = true;
      (this.$refs.longTermFilesModal as any).show();
    },
    closeForm() {
      this.isRegistrationAreaShown = false;
      this.isLongTermFilesShown = false;
    },
    setRegistrationArea: function(registrationArea: RegistrationAreaModalArgs) {
      this.htmlDraftDetail.registration_area = registrationArea;
      (this.$refs.registrationAreaModal as any).close();
      this.closeForm();
    },
    exportDraftSheet: async function() {
      this.running = true;
      await repository()
        .get(
          `/api/draftDeals/${this.htmlDraftDetail.draft_deal.id}/draftSheets/${this.draftSheetId}/export`,
          {
            responseType: "blob"
          }
        )
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(response, "入稿書.xlsx");
          this.successExport = true;
          this.getHtmlDetail();
        })
        .catch(() => {
          this.onError();
        })
        .finally(() => {
          this.running = false;
        });
    },
    update: async function() {
      this.validationError = this.valid(this.htmlDraftDetail);
      if (!this.validationError) {
        this.running = true;
        await DraftSheetRepository.updateHtmlDetail(this.htmlDraftDetail)
          .then(() => {
            this.success = true;
            this.serverError = false;
            this.getHtmlDetail(); // 素材情報更新
          })
          .catch(() => {
            this.success = false;
            this.serverError = true;
          })
          .finally(() => {
            this.running = false;
          });
      }
    },
    valid: function(htmlDraftDetail: HtmlDraftDetail): boolean {
      this.validationErrorMessages = [];
      // 掲載時間
      if (!htmlDraftDetail.draft_deal.publication_time.code)
        this.validationErrorMessages.push("掲載時間を選択してください");

      // 登録用掲載時間
      const publishedTime = htmlDraftDetail.published_time;
      if (!publishedTime.from || !publishedTime.to)
        this.validationErrorMessages.push("登録用掲載時間を入力してください");

      if (
        publishedTime.from &&
        publishedTime.to &&
        Number(this.removeSemicolon(publishedTime.from)) >
          Number(this.removeSemicolon(publishedTime.to))
      )
        this.validationErrorMessages.push(
          "登録用掲載時間toはfromよりも後の時間を入力してください"
        );

      // 素材名
      if (!htmlDraftDetail.material_name)
        this.validationErrorMessages.push("素材名を入力してください");

      const htmlAdResource = htmlDraftDetail.html_ad_resource;
      // 遷移先
      if (!htmlAdResource.destination.code)
        this.validationErrorMessages.push("遷移先を選択してください");

      // チャンネル情報
      if (
        (htmlAdResource.destination.code === 2 ||
          htmlAdResource.destination.code === 3) &&
        !htmlAdResource.channel.id
      ) {
        this.validationErrorMessages.push("チャンネル情報を選択してください");
      }

      // 番組詳細
      if (htmlAdResource.destination.code === 2) {
        // 番組名
        if (!htmlAdResource.program.name)
          this.validationErrorMessages.push(
            "遷移先が番組詳細の場合は番組名を入力してください"
          );

        // 放送日時
        const broadCastDate = htmlAdResource.program.broadcast_date;
        const broadCastTime = htmlAdResource.program.broadcast_time;
        if (
          // 空欄がある場合
          !broadCastDate.from ||
          !broadCastDate.to ||
          !broadCastTime.from ||
          !broadCastTime.to
        )
          this.validationErrorMessages.push(
            "遷移先が番組詳細の場合は放送日時を入力してください"
          );

        if (
          // 空欄がない場合
          broadCastDate.from &&
          broadCastDate.to &&
          broadCastTime.from &&
          broadCastTime.to
        ) {
          if (
            // 放送日が同一かつ放送時間のfromがtoより後
            broadCastDate.from === broadCastDate.to &&
            Number(this.removeSemicolon(broadCastTime.from)) >
              Number(this.removeSemicolon(broadCastTime.to))
          )
            this.validationErrorMessages.push(
              "放送日時toはfromよりも後の日時を入力してください"
            );
          const broadCastDateFromDayJs = dayjs(broadCastDate.from);
          const broadCastDateToDayJs = dayjs(broadCastDate.to);
          if (
            // 放送日fromが放送日toより後
            broadCastDateFromDayJs.isAfter(broadCastDateToDayJs)
          )
            this.validationErrorMessages.push(
              "放送日時toはfromよりも後の日時を入力してください"
            );
        }
      }

      return this.validationErrorMessages.length > 0;
    },
    timeFormat29: function(
      is_from: boolean, // fromとtoで処理を分岐させる
      target: { from?: string; to?: string } // 登録用掲載時間で使う
    ) {
      // 掲載時間は5:00 ~ 29:00までを許容する（業務上の理由で24時間表記ではない)
      let time = "";
      if (is_from && target.from) {
        time = target.from; // fromの入力
      } else if (target.to) {
        time = target.to; // toの入力
      } else {
        return;
      }
      time = this.setHalfFont(time, ":");
      if (isNaN(Number(time))) time = "";
      if (time.length < 4) time = "0" + time;
      time = isNaN(Number(time))
        ? ""
        : time.substr(0, 2) + ":" + time.substr(2, 2);
      time = time.match(/^([0-2][0-9]):([0-5][0-9])$/) ? time : "";
      is_from ? (target.from = time) : (target.to = time);
    },
    timeFormat24: function(
      is_from: boolean, // fromとtoで処理を分岐させる
      target: { from?: string; to?: string } // 登録用掲載時間で使う
    ) {
      let time = "";
      if (is_from && target.from) {
        time = target.from; // fromの入力
      } else if (target.to) {
        time = target.to; // toの入力
      } else {
        return;
      }
      time = this.setHalfFont(time, ":");
      if (isNaN(Number(time))) time = "";
      if (time.length < 4) time = "0" + time;
      time = isNaN(Number(time))
        ? ""
        : time.substr(0, 2) + ":" + time.substr(2, 2);
      time = time.match(/:([0-5][0-9])$/) ? time : "";
      is_from ? (target.from = time) : (target.to = time);
    },
    setHalfFont: function(font: string, split: string) {
      if (font == "" || font == null) {
        return "";
      }
      // 全角入力された場合半角に自動で戻す
      const result = String(font)
        .split(split)
        .join("")
        .replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(font) {
          return String.fromCharCode(font.charCodeAt(0) - 0xfee0);
        });
      return result;
    },
    removeSemicolon: function(timeString: string): string {
      return timeString.replace(":", "");
    },
    updateHeaderInfo: function() {
      (this as any).$parent.htmlDraftDetail = this.htmlDraftDetail;
    },
    resetAdResource: function() {
      this.resources = {
        top_banner_1: undefined,
        top_banner_2: undefined,
        popup_banner: undefined
      };
    },
    onUploaded: function(e: any) {
      const files: Array<File> = e.target.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.setResource(file);
      }
    },
    setResource: function(file: File) {
      const fileType = file.type;
      if (fileType !== "image/jpeg") return false;

      const image = new Image();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const result = reader.result;
        if (typeof result === "string") {
          image.src = result;
          image.onload = () => {
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            // TOPバナー：横482px×縦90px
            if (width === 482 && height === 90) {
              // トップバナー1
              const fileName = file.name;
              // アニメーション秒数が0またはnullのときは_Aがなくてもトップバナー1とする
              if (
                this.htmlDraftDetail.html_ad_resource.animation_seconds === 0 ||
                this.htmlDraftDetail.html_ad_resource.animation_seconds === null
              ) {
                if (
                  fileName.match(/.jpg$/) !== null &&
                  !fileName.match(/_A.jpg$/) &&
                  !fileName.match(/_B.jpg$/)
                ) {
                  this.resources.top_banner_1 = file;
                  this.htmlDraftDetail.html_ad_resource.top_banner_1.src = URL.createObjectURL(
                    file
                  );
                  this.htmlDraftDetail.html_ad_resource.top_banner_1.name =
                    file.name;
                }
              } else if (fileName.match(/_A.jpg$/) !== null) {
                this.resources.top_banner_1 = file;
                this.htmlDraftDetail.html_ad_resource.top_banner_1.src = URL.createObjectURL(
                  file
                );
                this.htmlDraftDetail.html_ad_resource.top_banner_1.name =
                  file.name;
              }
              // トップバナー2
              // AN秒数が1秒以上の場合のみ「_B」がついている画像をトップバナー2に指定する
              if (this.htmlDraftDetail.html_ad_resource.animation_seconds) {
                if (fileName.match(/_B.jpg$/) !== null) {
                  this.resources.top_banner_2 = file;
                  this.htmlDraftDetail.html_ad_resource.top_banner_2.src = URL.createObjectURL(
                    file
                  );
                  this.htmlDraftDetail.html_ad_resource.top_banner_2.name =
                    file.name;
                }
              }
            }

            // ポップアップ：横1280px×縦600px
            if (width === 1280 && height === 600) {
              this.resources.popup_banner = file;
              this.htmlDraftDetail.html_ad_resource.popup_banner.src = URL.createObjectURL(
                file
              );
              this.htmlDraftDetail.html_ad_resource.popup_banner.name =
                file.name;
            }
          };
        }
      };
    },
    upload: async function() {
      const draftSheetId = this.htmlDraftDetail.id;
      if (draftSheetId === undefined) return false;

      // FormDataセット
      this.running = true;
      const formData: FormData | undefined = this.createFormData();
      if (formData === undefined) return false;

      // S3に格納処理
      await HtmlAdResourceRepository.upload(draftSheetId, formData)
        .then(() => {
          this.resetAdResource();
          this.getHtmlDetail();
          this.successUpload = true;
        })
        .catch(() => {
          this.serverError = true;
        })
        .finally(() => {
          this.running = false;
        });
    },
    createFormData: function(): FormData | undefined {
      const formData = new FormData();
      const resource = this.resources;
      let isAppend = false;

      // トップバナー1
      if (resource.top_banner_1) {
        formData.append("top_banner_1", resource.top_banner_1);
        isAppend = true;
      }

      // トップバナー2
      if (resource.top_banner_2) {
        formData.append("top_banner_2", resource.top_banner_2);
        isAppend = true;
      }

      // ポップアップバナー
      if (resource.popup_banner) {
        formData.append("popup_banner", resource.popup_banner);
        isAppend = true;
      }

      return isAppend ? formData : undefined;
    },
    downloadResources: async function() {
      this.downloading = true;
      const id = this.htmlDraftDetail.id;
      await repository()
        .get(`/api/draftSheets/${id}/htmlAdResources/export`, {
          responseType: "blob"
        })
        .then(response => {
          //レスポンスヘッダからファイル名を取得します
          ResponseHelper.downloadAttachedFileName(response, "広告素材.zip");
        })
        .catch(() => {
          this.serverError = true;
        })
        .finally(() => {
          this.downloading = false;
        });
    },
    setResourceMimeTypes: function() {
      // バイナリにmimeTypeを付与し画像を表示できるようにする
      // トップバナー1
      if (this.htmlDraftDetail.html_ad_resource.top_banner_1.binary) {
        let mimeType = this.findMimeType(
          this.htmlDraftDetail.html_ad_resource.top_banner_1.extend
        );
        // バイナリに情報を付与
        this.htmlDraftDetail.html_ad_resource.top_banner_1.src = this.addBinary(
          mimeType,
          this.htmlDraftDetail.html_ad_resource.top_banner_1.binary
        );
      }

      // トップバナー2
      if (this.htmlDraftDetail.html_ad_resource.top_banner_2.binary) {
        let mimeType = this.findMimeType(
          this.htmlDraftDetail.html_ad_resource.top_banner_2.extend
        );
        // バイナリに情報を付与
        this.htmlDraftDetail.html_ad_resource.top_banner_2.src = this.addBinary(
          mimeType,
          this.htmlDraftDetail.html_ad_resource.top_banner_2.binary
        );
      }

      // ポップアップバナー
      if (this.htmlDraftDetail.html_ad_resource.popup_banner.binary) {
        let mimeType = this.findMimeType(
          this.htmlDraftDetail.html_ad_resource.popup_banner.extend
        );
        // バイナリに情報を付与
        this.htmlDraftDetail.html_ad_resource.popup_banner.src = this.addBinary(
          mimeType,
          this.htmlDraftDetail.html_ad_resource.popup_banner.binary
        );
      }
    },
    addBinary: function(mimeType: string | null, binary: string): string {
      return mimeType !== null
        ? "data:" + mimeType + ";base64," + binary
        : binary;
    },
    findMimeType: function(extend?: string): string | null {
      if (!extend) return null;

      const fileType = extend.toLowerCase();
      switch (fileType) {
        case "png":
          return "image/png";
        case "jpeg":
        case "jpg":
          return "image/jpeg";
        case "pdo":
          return "";
        default:
          return "";
      }
    },
    getHtmlDetail: async function() {
      if (this.draftSheetId !== undefined) {
        this.running = true;
        await DraftSheetRepository.getHtmlDetail(this.draftSheetId)
          .then(response => {
            this.htmlDraftDetail = response;
            this.setResourceMimeTypes();
            this.updateHeaderInfo(); // ヘッダー情報の反映
          })
          .finally(() => {
            this.running = false;
          });
      }
    },
    onUpdateLongTerm: function() {
      this.getHtmlDetail();
      this.successUpdateLongTerm = true;
    },
    onError: function() {
      this.serverError = true;
    }
  },
  mounted: async function() {
    this.running = true;
    this.channels = await ChannelRepository.list();
    this.draftSheetId = Number(this.$route.params.draftSheetId);
    await this.getHtmlDetail();
    this.running = false;
  }
});
